import axios from "axios";
import { POST } from "../requestHandler";
import { LOCAL_KEYS } from "../GroupContext";
const baseUrl = process.env.REACT_APP_BACKEND_API;

// this file contains all calls to the authorization endpoints on the server.

// first call as part of PwC Identity, server function redirects user to the callback url and provides a code in the url params
const login = async () => {
  try {
    return `${baseUrl}/login`;
  } catch (err) {
    console.log("server error", err);
    return "";
  }
};

// second call as part of PwC Identity, calls endpoint to authenticate user and returns the jwt (token)
const getToken = async (params) => {
  try {
    const { data } = await axios.get(`${baseUrl}/authorize${params}`, {
      withCredentials: true,
    });
    localStorage.setItem(LOCAL_KEYS.lastRequest, Date.now());
    return {status: 200, data: data};
  } catch (err) {
    console.log(err);
    return {status: 400, err: err};
  }
};

// logs the user out by invalidating the token
const logout = async () => {
  const user = JSON.parse(localStorage.getItem(LOCAL_KEYS.user))
  console.log('logging out: ', user?.id_token)
  const response = await POST(`${baseUrl}/logout`, {id_token: user?.id_token});
  if (response?.status === 200) {
    if (response?.data) {
      console.log("redirecting to", response?.data['endsession'])
      window.location.href = response.data['endsession'];
    } else {
      window.location.herf = '';
    }
  }
  return response;
};

const refresh = async () => {
  const user = JSON.parse(localStorage.getItem(LOCAL_KEYS.user))
  const data = await POST(`${baseUrl}/refresh`, {refreshToken: user?.refresh_token});
  return data;
};

export default { login, getToken, logout, refresh };
