import { Button, Tooltip } from "@appkit4/react-components"
import "./customTooltipIcon.scss"

const CustomTooltipIcon = ({size, tooltipText}) => {
    return <Tooltip className={size === 'big' ? 'big-tooltip' : ''} content={() => {return <div>
            {tooltipText}
        </div>}}>
        <Button className='icon-btn' icon='icon-information-outline' kind='text' />
    </Tooltip>;
}

export default CustomTooltipIcon;