import { processDate } from "../../utils/dateProcessor";
import { setANGIETotals, setEBITDATotals, setNGIETotals, setQNGIETotals } from "./GroupDataCalcs";
//[TODO] remove the use of this method
const calculateComponentTotals = (poa) => {
    // NGIE section
    setNGIETotals(poa);

    // ANGIE section
    setANGIETotals(poa);

    // EBITDA section
    setEBITDATotals(poa);
    

    // QNGIE section
    setQNGIETotals(poa);

    return poa;
}

const getBFDTotal = (type, poa) => {
    const sorted_caps = poa?.adjusted_caps?.sort((a, b) => {
        const nameComparison = a?.company_name?.localeCompare(b?.company_name);
        return (nameComparison===0) ? (processDate(a.start_date) -  processDate(b.start_date) ) : nameComparison;
    })
    let total = 0;
    sorted_caps?.forEach((cap, index) => {
        const prevComp = index > 0 ? poa?.adjusted_caps[index - 1]?.company_ID : null;
        // for first AP of each company
        if ( index === 0 || cap?.company_ID !== prevComp) {
            const property = type + '_subtotal'
            total += cap?.hasOwnProperty(property) && !Number.isNaN(cap[property]) ? cap[property] : 0;
        }
    })
    return total;
}

export { calculateComponentTotals, getBFDTotal };