import "./customTooltip.scss";

const CustomTooltip = ({data}) => {
    return <>
        {/* {data.message && <div className="custom-tooltip" style={{ top: data.positionY + window.scrollY, left: data.positionX }}>{data.message}</div>} */}
        {data.message && <div className="custom-tooltip" style={{ bottom: window.innerHeight - data.positionY + 5, left: data.positionX - 5 }}>{data.message}</div>}
        {/* {data.message && <div className="custom-tooltip" style={{ bottom:0, left: data.positionX }}>{data.message}</div>} */}
    </>;
}

export default CustomTooltip;
