import { Button, Modal, Select } from "@appkit4/react-components"
import { useEffect, useState } from "react";
import sqldatabaseService from "../../../services/sqldatabase/sqldatabase.service";
import toast from "react-hot-toast";
import '../home.scss';

const ProcessCompanyAPModal = ({ groupList, fetchGroups, resetForm, setResetForm }) => {
    const [selectedGroup, setSelectGroup] = useState();
    const [buttonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        if(resetForm) {
            setSelectGroup('');
            setResetForm(false);
        }
    }, [resetForm]);

    const processGroup = () => {
        if (selectedGroup) {
            setButtonDisabled(true)
            const promise = sqldatabaseService.postProcessGroupCompanyAPs(selectedGroup)
                .then(response => {
                    if (response.status !== 200) {
                        // Return an object that includes the error message instead of throwing an error
                        console.log('response:', response);
                        return { error: true, message: `Error: Status ${response.status} - ${response.err}` };
                    }
                    return response;
                });

            return toast.promise(promise, {
                loading: `Processing Company APs for group: ${selectedGroup}`,
                success: (response) => {
                    // Check if there was an error
                    if (response.error) {
                        throw new Error(response.message);
                    } else {
                        fetchGroups();
                    }
                    setSelectGroup('');
                    return `Processed Company APs for group: ${selectedGroup}`;
                },
                error: (error) => {
                    // Show the error message in the toast
                    return `Failed to process Company APs. ${error.message}`;
                }
            })
            .then(() => {
                setButtonDisabled(false);
            });
        }
    }

    return (<div className="flex-space-between">
            <Select placeholder={'Select Group'} value={selectedGroup} style={{width: '35rem'}} searchable data={groupList} onSelect={(val) => setSelectGroup(val)} dropdownRenderMode={'portal'}></Select>
            <Button disabled={!selectedGroup || buttonDisabled} onClick={() => processGroup()}>Process Group Company APs</Button>
        </div>);
}

export default ProcessCompanyAPModal;
