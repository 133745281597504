import { Panel, Loading, Table, Column, Tooltip } from '@appkit4/react-components'; 
import { Button } from "../../components/ReadonlyAwareInputs";
import { useContext, useEffect, useState } from 'react';
import { BackButton, NextButton } from '../../components/ProgressNav';
import NewReportingCompanyModal from './NewReportingCompanyModal';
import RevocationModal from './RevocationModal';
import IneligibleModal from './IneligibleModal';
import { getGroupData, setGroupData, getSelectedPeriod, getPoaIndex, getGroupName, getFormattedSelectedPeriod } from '../../services/GroupContext';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import sqlService from '../../services/sqldatabase/sqldatabase.service';
import { AuthContext } from '../../services/AuthProvider';
import { processDateJS } from '../../utils/dateProcessor';
import CompanyPanel from '../../components/CompanyPanel';
import "./ReportingCompany.scss";
import CustomTableHeader from '../../components/tableHeader/tableHeader';
dayjs.extend(utc);


export const getGroupElectionsTableData = (groupData, periodData) => {
    const savedData = groupData;
    const currPoaData = periodData;

    return {
        "disregard-regulations": {
            election: 'Election to apply the Disregard Regulations to pre-1 April 2020 derivative contracts',
            statReference: 'Para 31 Sch 5 Finance (No2.) Act 2017',
            shortStatRef: 'Para 31 Sch 5 Finance (No2.) Act 2017',
            duration: 'Irrevocable but does not apply to contracts entered into after 1 April 2020',
            status: savedData?.disregard_regulations_election_year,
            dateMade: savedData?.disregard_regulations_election_year,
        },
        "group-ratio": {
            election: 'Group ratio election',
            statReference: 's398 / Paragraph 13 Schedule 7A',
            shortStatRef: 'Para 13 Sch 7A, s398',
            duration: 'Current period only',
            status: currPoaData?.group_ratio_election,
        },
        "group-ratio-blended": {
            election: 'Group ratio blended election',
            statReference: 's401 - 404 / Paragraph 14 Schedule 7A',
            shortStatRef: 'Para 14 Sch 7A, s401 - 404',
            duration: 'Current period only',
            status: currPoaData?.group_ratio_blended_election,
        },
        "group-ebitda": {
            election: 'Group EBITDA (chargeable gains) election',
            statReference: 's422 / Paragraph 15 Schedule 7A',
            shortStatRef: 'Para 15 Sch 7A, s422',
            duration: 'Irrevocable',
            status: savedData?.group_ebitda_gains,
            dateMade: savedData?.group_ebitda_gains_year,
        },
        "interest-alt-calc": {
            election: 'Interest allowance (alternative calculation) election',
            statReference: 's423 - 426 / Paragraph 16 Schedule 7A',
            shortStatRef: 'Para 16 Sch 7A, s423 - 426',
            duration: 'Irrevocable',
            status: savedData?.interest_allowance_alt_calc,
            dateMade: savedData?.interest_allowance_alt_calc_year,
        },
        "interest-non-con-investment": {
            election: 'Interest allowance (non-consolidated investment) election',
            statReference: 's427 - 429 / Paragraph 17 Schedule 7A',
            shortStatRef: 'Para 17 Sch 7A, s427 - 429',
            duration: 'Current period only',
            status: currPoaData?.election_interest_allowance_non_consolidated_investment,
        },
        "interest-con-partnership": {
            election: 'Interest allowance (consolidated partnership) election',
            statReference: 's430 / Paragraph 18 Schedule 7A',
            shortStatRef: 'Para 18 Sch 7A, s430',
            duration: 'Irrevocable',
            status: savedData?.interest_allowance_consolidated_partnership,
            dateMade: savedData?.interest_allowance_consolidated_partnership_year,
        },
        "period-not-ias-statements": {
            election: 'Election not to treat consolidated IAS financial statements as deemed to be drawn up for the relevant period',
            statReference: 's484',
            shortStatRef: 's484',
            duration: 'Current period only',
            status: currPoaData?.election_consolidated_ias,
        },
        "period-no-fin-statements": {
            election: 'Election to alter deemed period of account where no actual financial statements drawn up',
            statReference: 's484',
            shortStatRef: 's484',
            duration: 'Current period only',
            status: currPoaData?.election_no_statement_drawn_up,
        }
    }
}

const ReportingCompany = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isPosting, setIsPosting] = useState(false);
    const selectedPeriod = getSelectedPeriod();
    const auth = useContext(AuthContext);

    const [ultimateParent, setUltimateParent] = useState();
    const [activeCompanies, setActiveCompanies] = useState([]);
    const [pastCompanies, setPastCompanies] = useState([]);
    const [draftCompanies, setDraftCompanies] = useState([]);

    const [newCompanyModal, setNewCompanyModal] = useState(false);
    const [revocationModal, setRevocationModal] = useState(false);
    const [ineligibilityModal, setIneligibilityModal] = useState(false);
    const [activeCompanyForModals, setActiveCompanyForModals] = useState(null); // For modals
    const [companyToEdit, setCompanyToEdit] = useState(null);
    const [companies, setCompanies] = useState();

    const isReadOnly = auth?.isReadOnlyUser(getGroupName());

    const fullElectionsTableData = getGroupElectionsTableData(getGroupData(), selectedPeriod);
    const electionsTableData = [
        "group-ratio",
        "group-ratio-blended",
        "group-ebitda",
        "interest-alt-calc",
        "interest-non-con-investment",
        "interest-con-partnership"
    ].map(id => fullElectionsTableData[id])

    useEffect(() => {
        setIsLoading(true);
        const savedData = getGroupData();
        
        if (!savedData) {
            return;
        }

        if (!selectedPeriod){
            return;
        }

        console.log(savedData)

        setUltimateParent(savedData?.ult_parent_name);
        setCompanies(savedData?.companies?.filter(company => company?.uk_group_company));

        const currentPoAEnd = processDateJS(selectedPeriod?.period_end)?.utc();
        const today = dayjs();

        // If there are reporting companies in savedData
        if (savedData?.reporting_company && savedData?.reporting_company.length > 0) {
            const reportingCompanyList = savedData?.reporting_company?.map(Rcomp => {
                // Drafts may not exist in savedData.companies, so we handle them separately
                const company = savedData?.companies?.find((c) => c?.company_ID === Rcomp?.company_ID) || Rcomp;
                
                if (company) {
                    return {
                        company_name: company?.company_name,
                        tax_office: company?.tax_office,
                        utr: company?.utr,
                        company_ID: company?.company_ID,
                        first_appointed_period_end_date: Rcomp?.first_appointed_period_end_date,
                        appointment_date: Rcomp?.appointment_date,
                        appointer: Rcomp?.appointer,
                        nomination_link: Rcomp?.nomination_link,
                        reason_appointment_lapsed: Rcomp?.reason_appointment_lapsed,
                        revocation_date: Rcomp?.revocation_date,
                        first_period_appointment_lapsed: Rcomp?.first_period_appointment_lapsed,
                        status: Rcomp?.status, // Keep status for filtering
                    };
                }
                return null;
            });

            // **Filter Active Companies** 
            const active = reportingCompanyList?.filter((comp) => {
                if (!comp || !comp.status) return false;

                const poaEndDate = processDateJS(comp?.first_appointed_period_end_date)?.utc();
                const firstPeriodLapsed = processDateJS(comp?.first_period_appointment_lapsed)?.utc();
                const revocationDate = processDateJS(comp?.revocation_date)?.utc();

                if (comp.appointer === "HMRC" && poaEndDate?.isSame(currentPoAEnd)) {
                    return true;
                }

                if (comp.appointer === "Company" && poaEndDate?.isSameOrBefore(currentPoAEnd) && !comp.first_period_appointment_lapsed) {
                    return true;
                }

                if (comp.appointer === "Company" && poaEndDate?.isSameOrBefore(currentPoAEnd) && firstPeriodLapsed?.isAfter(currentPoAEnd)) {
                    return true;
                }

                if (comp.appointer === "Company" && poaEndDate?.isSameOrBefore(currentPoAEnd) && firstPeriodLapsed?.isSame(currentPoAEnd)
                    && comp.reason_appointment_lapsed === "Manual Revocation" && revocationDate?.isAfter(today)) {
                    return true;
                }

                return false;
            });

            setActiveCompanies(active);

            // **Filter Past Companies (Either Inactive or Matching Existing Logic)**
            const past = reportingCompanyList?.filter((comp) => {
                if (!comp || !comp.status) return false;

                const poaEndDate = processDateJS(comp?.first_appointed_period_end_date)?.utc();
                const firstPeriodLapsed = processDateJS(comp?.first_period_appointment_lapsed)?.utc();
                const revocationDate = processDateJS(comp?.revocation_date)?.utc();

                // Check if the company meets any of the existing past company conditions
                if (comp.appointer === "HMRC" && !poaEndDate?.isSame(currentPoAEnd)) {
                    return true;
                }

                if (comp.appointer === "Company" && poaEndDate?.isAfter(currentPoAEnd)) {
                    return true;
                }

                if (comp.appointer === "Company" && firstPeriodLapsed?.isBefore(currentPoAEnd)) {
                    return true;
                }

                if (comp.appointer === "Company" && firstPeriodLapsed?.isSame(currentPoAEnd)
                    && comp.reason_appointment_lapsed === "Manual Revocation" && revocationDate?.isSameOrBefore(today)) {
                    return true;
                }

                if (comp.appointer === "Company" && firstPeriodLapsed?.isSame(currentPoAEnd) && comp.reason_appointment_lapsed === 'Ineligibility') {
                    return true;
                }

                // If none of the conditions match, exclude the company
                return false;
            });

            setPastCompanies(past);

            // **Filter Draft Companies**
            const draft = reportingCompanyList?.filter((comp) => comp?.status === "Draft" && comp?.company_ID); 
            setDraftCompanies(draft);
        }

        setIsLoading(false);
    }, []);

const handleRevocation = (modifiedCompany) => {
    const { status, company_ID } = modifiedCompany;

    // Remove the company from all lists (active, draft, past) first
    setActiveCompanies(activeCompanies?.filter(company => company.company_ID !== company_ID));
    setDraftCompanies(draftCompanies?.filter(company => company.company_ID !== company_ID));
    setPastCompanies(pastCompanies?.filter(company => company.company_ID !== company_ID));

    // Move the company based on its status
    if (status === "Active") {
        // If the company is active, add it to the active companies
        setActiveCompanies([modifiedCompany]); // Ensure only one active company
    } else if (status === "Inactive") {
        // If the company is inactive (revoked), move it to past companies
        setPastCompanies(prevCompanies => [...prevCompanies, modifiedCompany]);
    } else if (status === "Draft") {
        // If the company is still a draft, keep it in the drafts
        setDraftCompanies(prev => [...prev, modifiedCompany]);
    }
};


    const save = () => {
        // Ensure only 0 or 1 active reporting company exists
        if (activeCompanies.length > 1) {
            toast.error('There should be no more than 1 active reporting company.');
            return;
        }

        setIsPosting(true);

        // Prepare data for PoA table
        const activeCompany = activeCompanies[0]; // Should be the only active company or undefined
        const reportingCompany = activeCompany ? activeCompany.company_name : null;
        const reportingCompanyID = activeCompany ? activeCompany.company_ID : null;
        const utr = activeCompany ? activeCompany.utr : null;

        // Update PoA with the current active company or null values if no active company exists
        const updatedPoA = getGroupData().periods_of_account.map((poa, index) => {
            if (index === getPoaIndex()) {
                return {
                    ...poa,
                    reporting_company: reportingCompany,
                    reporting_companyID: reportingCompanyID,
                    utr: utr,
                };
            }
            return poa;
        });

        // Save the updated PoA data in the groupData

        const newJson = {
            ...getGroupData(),
            periods_of_account: updatedPoA,
            reporting_company: [...activeCompanies, ...pastCompanies, ...draftCompanies],
        };

        setGroupData(newJson); // Update groupData state

        if (isReadOnly) {
            return;
        }

        const promise = sqlService?.postResponse(newJson);

        return toast.promise(promise, {
            loading: 'Saving...',
            success: (response) => {
                setIsPosting(false);
                if (response?.status === 200) {
                    return `Changes saved to database`;
                } else {
                    toast.error(`Failed to save changes to database`);
                }
            },
            error: 'Failed to save changes to database',
        });
    };

    const renderDateCell = (row, field) => {
        const dateValue = row?.[field];  // Safely access the field
        const processedDate = dayjs(dateValue, 'DD/MM/YYYY');  // Ensure it is processed as DD/MM/YYYY
        return processedDate.isValid() ? processedDate.format('DD/MM/YYYY') : '';  // Return 'N/A' if invalid
    };

    const isPeriodInModel = (periodEnd) => {
        const periodsOfAccount = getGroupData()?.periods_of_account?.map(poa => processDateJS(poa.period_end).format('YYYY-MM-DD'));
        const formattedPeriodEnd = processDateJS(periodEnd).format('YYYY-MM-DD');
        return periodsOfAccount.includes(formattedPeriodEnd);
    };

    // Function to handle editing a company
    const handleEditCompany = (company) => {
        setCompanyToEdit(company);  // Set the company data for editing
        setNewCompanyModal(true);   // Open the modal
    };

    const renderEditButton = (row) => {
        return (
            <Button onClick={() => {
                handleEditCompany(row); 
            }}>Edit</Button>
        );
    };

    const renderRevokedButton = (row) => {
        return (
            <>
                <Button onClick={() => { 
                    setRevocationModal(true); 
                    setActiveCompanyForModals(row);
                }}>Revoked</Button>
            </>
        );
    };

    const renderIneligibleButton = (row) => {
        return (
            <>
                <Button onClick={() => { 
                    setIneligibilityModal(true); 
                    setActiveCompanyForModals(row);
                }}>Ineligible</Button>
            </>
        );
    };

    const renderEditDraftButton = (row) => {
        return (
            <Button onClick={() => { handleEditCompany(row) }}>Edit Draft Company</Button>
        );
    };

    const renderGenerateNominationButton = (row) => {
        return (
            <Button
                onClick={() => console.log('Generate Nomination for', row)}
                disabled={!isPeriodInModel(row.first_appointed_period_end_date)} // Validation
            >
                Generate Nomination
            </Button>
        );
    };

    if (isLoading)
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            ></Loading>
        );

    return (
        <div className="ap-container">
            {/* Group Info */}
            <div className='ap-container mt-4'>
                <CompanyPanel />
            </div>

            <hr className="my-12" />

            {/* Active Companies */}
            <div className='ap-container mt-4'>
                <Panel title='Active Reporting Companies'>
                <div>
                    <p>In order to file a CIR return; generate, carry forward and utilise Unused Interest Allowance; reactivate previously disallowed interest expense; and to make certain elections it is necessary for the group to have a valid reporting company nomination in place. It is not possible to prepare and submit a CIR return without a valid reporting company nomination, although the CIR rules still apply in a modified manner. It is strongly recommended that a reporting company is nominated as soon as reasonably practical after the end of the Period of Account and in any case within 12 months of the end of the Period of Account.</p>
                    <p>In order to be valid, a notice to appoint a reporting company must:</p>
                    <ul style={{marginBottom: "20px"}}>
                        <li>Identify the reporting company, which must be a UK Group Company</li>
                        <li>Specify the first period of account of the group to which the appointment is to have effect. A valid appointment should apply to the specified period, and all subsequent periods unless revoked or the nominated company becomes ineligible</li>
                        <li>Be submitted to HMRC within the period of 12 months beginning immediately after the end of the specified period of account (within 6 months, or by 31 March 2018 if later, for appointments made before 12 February 2019)</li>
                        <li>Be authorised by at least 50% of the eligible companies in the group, and contain a list of the eligible companies that have authorised the notice and a statement that they constitute at least 50% of eligible companies.</li>
                    </ul>
                    <p>A list of the elections that can only be made by a reporting company (including the Group Ratio election) are listed in the table below. Any irrevocable election(s) made in a previous Period of Account where there was a valid reporting company in place should remain active as these cannot be revoked.</p>
                    <p>Please refer to Para 1, Schedule7A, TIOPA 2010 for further informaiton.</p>
                </div>
                    {activeCompanies.length === 0 ? (
                        <Button onClick={() => { setNewCompanyModal(true); setCompanyToEdit(null); }}>Add Reporting Company</Button>
                    ) : (
                        <Table originalData={activeCompanies} hasTitle disableDefaultSort skipInitialSort>
                            <Column field='company_name'>
                                <CustomTableHeader title="Name of Reporting Company" required={false} tooltipText={<p>This is the name of the reporting company, which must be an eligible UK Group Company.</p>} />
                            </Column>
                            <Column field='tax_office'>
                                <CustomTableHeader title="Tax Office" required={false} tooltipText={<p>This is the 3 digit tax office code number of the reporting company.</p>} />
                            </Column>
                            <Column field='utr'>
                                <CustomTableHeader title="UTR" required={false} tooltipText={<p>This is the 10 digit unique tax reference number of the reporting company.</p>} />
                            </Column>
                            <Column field='first_appointed_period_end_date' renderCell={renderDateCell}>
                                <CustomTableHeader title="End of First PoA" required={false} tooltipText={<p>This is the end of the first Period of Account for which the appointment has effect (the "relevant" period).</p>} />
                            </Column>
                            <Column field='appointer'>
                                <CustomTableHeader title="Appointer" required={false} tooltipText={<p>This identifies whether the appointment was made by company within the group or by HMRC. If the appointment was made by HMRC, it will not apply to future periods.</p>} />
                            </Column>
                            <Column field='appointment_date' renderCell={renderDateCell}>
                                <CustomTableHeader title="Date Appointed" required={false} tooltipText={<p>This is the date of appointment. If the appointment is made by a company within the group it must be made within the period of 12 months beginning immediately after the end of the specified period of account (6 months for appointments made before 12 February 2019). A transitional provision applies to ensure appointments made by 31 March 2018 that would otherwise be late are also valid.</p>} />
                            </Column>
                            <Column field='nomination_link'><p>Nomination Link</p></Column>
                            <Column field='company_name' renderCell={renderEditButton}></Column>
                            <Column field='company_name' renderCell={renderRevokedButton}>
                                <CustomTableHeader title="Revoked" size="big" required={false} tooltipText={<><p>A group may revoke a reporting company appointment by submitting a revocation notice.</p><p>In order to be valid, a notice to revoke a reporting company must:</p>
                                <ul style={{marginBottom: "20px"}}>
                                    <li>Specify the first period of account of the group to which the revocation is to have effect. The revocation should apply to the specified period, and all subsequent periods.</li>
                                    <li>Be submitted to HMRC within the period of 12 months beginning immediately after the end of the specified period of account (6 months for revocations made before 12 February 2019).</li>
                                    <li>Be authorised by at least 50% of the eligible companies in the group, and contain a list of the eligible companies that have authorised the notice and a statement that they constitute at least 50% of eligible companies.</li>
                                </ul>
                                <p>Once a revocation has been submitted, the group will not have a reporting company in place and, depending on the circumstances, it may be necessary to ensure that a new reporting company nomination is submitted within 12 months of the end of the first period of account for which the revocation has effect (i.e. the period of account specified in the revocation notice).</p>
                                <p>Refer to Paragraph 2, Schedule 7A, TIOPA 2010 for further information.</p>
                                </>} />
                            </Column>
                            <Column field='company_name' renderCell={renderIneligibleButton}>
                                <CustomTableHeader title="Ineligible" size="big" required={false} tooltipText={<><p>Where a reporting company has been appointed by the group (and not HMRC) the appointment applies to all subsequent periods unless revoked or the nominated company becomes inelgible. Where the company becomes ineligible (i.e. it becomes dormant and/or is not a UK group company at any point during the Period of Account) the nomination ceases for that period and all future periods.</p>
                                    <p>Once a reporting company becomes ineligible, the group will not have a reporting company in place and, depending on the circumstances, it may be necessary to ensure that a new reporting company nomination is submitted within 12 months of the end of the first period of account for which the reporting company becomes inelgible.</p>
                                </>}/>
                            </Column>
                        </Table>
                     
                    )}
                </Panel>

                <NewReportingCompanyModal
                    visible={newCompanyModal}
                    setVisible={setNewCompanyModal}
                    companies={companies}
                    setActiveCompanies={setActiveCompanies}
                    setDraftCompanies={setDraftCompanies}
                    companyToEdit={companyToEdit}
                    setPastCompanies={setPastCompanies}
                    selectedPeriod={selectedPeriod}
                />
                <RevocationModal
                    visible={revocationModal}
                    setVisible={setRevocationModal}
                    activeCompany={activeCompanyForModals}
                    onRevocation={handleRevocation}
                    selectedPeriod={selectedPeriod}
                />
                <IneligibleModal
                    visible={ineligibilityModal}
                    setVisible={setIneligibilityModal}
                    activeCompany={activeCompanyForModals}
                    onRevocation={handleRevocation}
                    selectedPeriod={selectedPeriod}
                />
            </div>

            {/* Past Companies */}
            <div className='ap-container mt-4'>
                <Panel title='Inactive Reporting Companies'>
                    <Table originalData={pastCompanies} hasTitle disableDefaultSort skipInitialSort className="no-margin">
                        <Column field='company_name'>
                            <CustomTableHeader title="Name of Reporting Company" required={false} tooltipText={<p>This is the name of the reporting company, which must be an eligible UK Group Company.</p>} />
                        </Column>
                        <Column field='tax_office'>
                            <CustomTableHeader title="Tax Office" required={false} tooltipText={<p>This is the name of the reporting company, which must be an eligible UK Group Company.</p>} />
                        </Column>
                        <Column field='utr'>
                            <CustomTableHeader title="UTR" required={false} tooltipText={<p>This is the 10 digit unique tax reference number of the reporting company.</p>} />
                        </Column>
                        <Column field='first_appointed_period_end_date' renderCell={renderDateCell}>
                            <CustomTableHeader title="End of First PoA" required={false} tooltipText={<p>This is the end of the first Period of Account for which the appointment has effect (the "relevant" period).</p>} /> 
                        </Column>
                        <Column field='appointer'>
                            <CustomTableHeader title="Appointer" required={false} tooltipText={<p>This identifies whether the appointment was made by company within the group or by HMRC. If the appointment was made by HMRC, it will not apply to future periods.</p>} /> 
                        </Column>
                        <Column field='appointment_date' renderCell={renderDateCell}>
                            <CustomTableHeader title="Date Appointed" required={false} tooltipText={<p>This is the date of appointment. If the appointment is made by a company within the group it must be made within the period of 12 months beginning immediately after the end of the specified period of account (6 months for appointments made before 12 February 2019). A transitional provision applies to ensure appointments made by 31 March 2018 that would otherwise be late are also valid.</p>} />                           
                        </Column>
                        <Column field='first_period_appointment_lapsed' renderCell={renderDateCell}>
                            <CustomTableHeader title="End of Last PoA" required={false} tooltipText={<p>This is the end of the last Period of Account for which the appointment has effect (the "relevant" period).</p>} />                           
                       </Column>
                        <Column field='reason_appointment_lapsed'>
                            <CustomTableHeader title="Reason for cessation" required={false} tooltipText={<p>This identifies whether the group revoked the appointment ("Manual Revocation") or whether the cessation was due to the company being inelibible ("Ineligibility").</p>} />                                                   
                        </Column>
                        <Column field='revocation_date' renderCell={renderDateCell}>
                            <CustomTableHeader title="Revocation Date (if revoked)" size="big" required={false} tooltipText={<p>This is the date of revocation. If the revocation is made by a company within the group it must be made within the period of 12 months beginning immediately after the end of the specified period of account (6 months for appointments made before 12 February 2019). A transitional provision applies to ensure revocations made by 31 March 2018 that would otherwise be late are also valid.</p>} />                                                   
                        </Column>
                    </Table>
                </Panel>
            </div>

            {/* Draft Companies */}
            <div className='ap-container mt-4'>
                <Panel title='Draft Reporting Companies'>
                    <Table originalData={draftCompanies} hasTitle disableDefaultSort skipInitialSort>
                        <Column field='company_name'>Name of Reporting Company</Column>
                        <Column field='tax_office'>Tax Office</Column>
                        <Column field='utr'>UTR</Column>
                        <Column field='first_appointed_period_end_date' renderCell={renderDateCell}>End of First PoA</Column>
                        <Column field='appointer'>Appointer</Column>
                        <Column field='company_name' renderCell={renderEditDraftButton}></Column>
                        <Column field='company_name' renderCell={renderGenerateNominationButton}></Column>
                    </Table>
                </Panel>
            </div>

            {/* Warning Elections */}
            <div className='ap-container mt-4'>
                <Panel title='Elections which require an active reporting company' >
                    <p>The following elections cannot be made in the return if there is no active reporting company, however any irrevocable election made in a previous CIR period should remain active as they cannot be revoked:</p>
                    <Table originalData={electionsTableData} hasTitle disableDefaultSort skipInitialSort >
                        <Column field='election'>Election</Column>
                        <Column field='statReference'>Statutory Reference (TIOPA 2010)</Column>
                        <Column field='duration'>Duration</Column>
                    </Table>
                </Panel>
            </div>

            {/* Save and Next buttons */}
            <div className='container-fluid mt-4'>
                <div className='d-flex justify-content-between align-items-center'> 
                    <div>
                        <BackButton disabled={isPosting}/>
                    </div>
                    <div className='d-flex gap-2'>
                        {activeCompanies.length > 1 && (
                            <p style={{ color: 'red' }}>Please ensure there is only one active reporting company before progressing.</p>
                        )}
                        <Button hiddenInReadonly loading={isPosting} disabled={ activeCompanies.length > 1} onClick={save}>Save</Button>
                        <NextButton loading={isPosting} preNavigation={save} disabled={activeCompanies.length > 1} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportingCompany;

