import React, { useContext } from 'react';
import { Modal, Button } from '@appkit4/react-components';
import { useNavigate } from 'react-router-dom';
import { setGroupData } from '../services/GroupContext';
import { AuthContext } from '../services/AuthProvider';

const ReadonlyModal = ({ visible, closeModal, userEmail, group }) => {
    const navigate = useNavigate();
    const auth = useContext(AuthContext)
    return (
        <Modal
            visible={visible}
            title={"Add new user"}
            ariaLabel={"Add new user"}
            onCancel={closeModal}
            modalStyle={{ width: '33.75rem' }}
            footerStyle={{ paddingTop: '8px', marginTop: '-8px', minHeight: '64px' }}
            footer={
                <>
                    <Button
                        onClick={() => {
                            setGroupData(group);
                            auth?.setReadonly(true)
                            closeModal();
                            navigate('/app/groupconfig');
                        }}
                        kind="secondary"
                    >Open in read-only mode</Button>
                </>
            }
        >

            <div>
                <p>
                    This group is currently being edited by {userEmail}. You can open it in read-only mode, but will not be able to make any changes until they close the group.
                </p>
            </div>
        </Modal>
    );
};

export default ReadonlyModal;
