import { processDateJS } from "./dateProcessor";

export function processFullReturn(savedData, poaIndex){
    const message = "©" + new Date().getFullYear() + " PricewaterhouseCoopers LLP. All rights reserved. ‘PwC’ refers to the UK member firm, and may sometimes refer to the PwC network. Each member firm is a separate legal entity. Please see www.pwc.com/structure for further details."
    console.log("Saved data:");
    console.log(savedData);
    console.log("PoA index", poaIndex)
    console.log(savedData.periods_of_account[poaIndex]);

    const companies = savedData?.companies?.filter(company => company?.uk_group_company);
    console.log('companies', companies)
    let activeCompany = {};
    if (savedData?.reporting_company && savedData?.reporting_company[0] != null) {
      const reportingCompanyList = savedData?.reporting_company?.map(Rcomp => {
        const company = savedData?.companies?.find((c) => c?.company_ID === Rcomp?.company_ID);
        if (company) {
          activeCompany = {
            ...company,
            first_appointed_period_end_date: Rcomp?.first_appointed_period_end_date,
            appointment_date: Rcomp?.appointment_date,
            appointer: Rcomp?.appointer,
            nomination_link: Rcomp?.nomination_link,
            reason_appointment_lapsed: Rcomp?.reason_appointment_lapsed,
            revocation_date: Rcomp?.revocation_date,
            first_period_appointment_lapsed: Rcomp?.first_period_appointment_lapsed
          };
        }
      });
    }
    
    const poaData = savedData?.periods_of_account[poaIndex]
    const group_restrictions = poaData?.group_disallowance > 0 ? 'Yes' : 'No';
    const group_reactivations = poaData?.group_interest_reactivation_cap > 0 ? 'Yes' : 'No';
    const gleC13 = savedData.periods_of_account[poaIndex]?.apply_group_ratio_election ? 'E' : '';
    const gleC14 = savedData.periods_of_account[poaIndex]?.group_ratio_blended_election ? 'E' : '';    
    const groupEBITDAGains = (savedData.periods_of_account[poaIndex]?.group_ebitda_gains_year && processDateJS(savedData.periods_of_account[poaIndex]?.period_end).isAfter(processDateJS(savedData.periods_of_account[poaIndex]?.group_ebitda_gains_year)) &&  savedData.periods_of_account[poaIndex]?.group_ebitda_gains) ? 'E' : '';
    const interestAllowance_alternative = (savedData.periods_of_account[poaIndex]?.interest_allowance_alt_calc_year && processDateJS(savedData.periods_of_account[poaIndex]?.period_end).isBefore(processDateJS(savedData.periods_of_account[poaIndex]?.interest_allowance_alt_calc_year)) && savedData.periods_of_account[poaIndex]?.interest_allowance_alt_calc) ? 'E' : '';
    const interestAllowance_nonConsolidated = savedData.periods_of_account[poaIndex]?.election_interest_allowance_non_consolidated_investment ? 'E' : '';
    const interestAllowance_consolidated = (savedData.periods_of_account[poaIndex]?.interest_allowance_consolidated_partnership_year&& processDateJS(savedData.periods_of_account[poaIndex]?.period_end).isAfter(processDateJS(savedData.periods_of_account[poaIndex]?.interest_allowance_consolidated_partnership_year)) && savedData.periods_of_account[poaIndex]?.interest_allowance_consolidated_partnership) ? 'E' : '';
    const arrayOfInvestorGroups = savedData.periods_of_account[poaIndex]?.investor_group_data;
    let investorGroupNames = '';
    if (arrayOfInvestorGroups){
      for (let i = 0; i < arrayOfInvestorGroups.length; i++){
        if(investorGroupNames === ''){ investorGroupNames = arrayOfInvestorGroups[i].investor_group}
        else {investorGroupNames = investorGroupNames + ", " +(arrayOfInvestorGroups[i].investor_group)} 
    }}
    const groupRatioBlended = savedData.periods_of_account[poaIndex]?.group_ratio_blended_election ? 'Yes: ' + investorGroupNames : '';
    const ultimateParent = savedData.companies.find(comp => comp.company_ID === savedData.ult_parent_ID)
    console.log(ultimateParent)
    const ultimate_parent_name = ultimateParent?.company_name;
    const ultimate_parent_utr = ultimateParent?.utr
    const reporting_company = savedData.reporting_company.find(comp => comp.status === 'Active')
    const reporting_company_name = reporting_company?.company_name
    const reporting_company_utr = reporting_company?.utr
    const revised_return = poaData.return_status?.type === "Amended" ? 'Yes' : 'No' 
    const poaStart = processDateJS(savedData.periods_of_account[poaIndex]?.period_start).format('DD/MM/YYYY')
    const poaEnd = processDateJS(savedData.periods_of_account[poaIndex]?.period_end).format('DD/MM/YYYY')

    
    const disallowed_amount = poaData?.group_disallowance ? poaData?.group_disallowance : 0 ;
    const interest_restrictions = disallowed_amount > 0 ? "Yes" : "No";
    const reactivation_cap = poaData?.group_interest_reactivation_cap ? poaData?.group_interest_reactivation_cap : 0 ;
    const reactivations = reactivation_cap > 0 ? "Yes" : "No";
    const aggr_ntie = poaData?.aggregate_net_tax_interest_expense ? poaData?.aggregate_net_tax_interest_expense : 0 ;
    const aggr_ntii = poaData?.aggregate_net_tax_interest_income ? poaData?.aggregate_net_tax_interest_income : 0 ;
    const aggr_ebitda = poaData?.aggregate_tax_ebitda ? poaData?.aggregate_tax_ebitda : 0 ;
    const int_all_bfwd = poaData?.group_bf_interest_allowance ? poaData?.group_bf_interest_allowance : 0 ;
    const int_all_period = poaData?.group_interest_allowance ? poaData?.group_interest_allowance : 0 ;
    const int_capacity_period = poaData?.group_interest_capacity ? poaData?.group_interest_capacity : 0 ;
    const adj_ngie = poaData?.adjusted_net_group_interest_expense ? poaData?.adjusted_net_group_interest_expense : 0 ;;
    const group_ratio_election = poaData?.apply_group_ratio_election;
    const qual_ngie = group_ratio_election ? (poaData?.qualifying_net_group_interest_expense ? poaData?.qualifying_net_group_interest_expense : 0 ) : '';
    const group_ebitda = group_ratio_election ? (poaData?.group_ebitda ? poaData?.group_ebitda : 0 ) : '';
    const group_ratio = group_ratio_election ? (poaData?.gr_percentage ? poaData?.gr_percentage : 0 ) : '';
    const poaInvestors = poaData?.investor_group_data
    const investorsArray = poaInvestors?.map(investor => investor['investor_group']);
    const investorsList = investorsArray ? (investorsArray.length > 1 ? investorsArray.join(', ') : investorsArray[0]) : '';

    
    const excelData = {
        // Group Period Return Type tab values
        start_period_account: ['','Start of period of account', '',poaStart],
        end_period_account:   ['','End of period of account', '',poaEnd],
        reporting_company:    ['','Reporting compamny', 'Name'],
        rep_comp_name:        ['','Reporting company', 'Name', reporting_company_name],
        rep_comp_UTR:         ['',' ', 'UTR (10 Digits)', reporting_company_utr],
        ultimate_parent:      ['','Ultimate parent', 'Name', ultimate_parent_name],
        ult_parent_line2:     ['','', 'UTR (if any, 10 digits)',ultimate_parent_utr],
        ult_parent_line3:     ['','', 'Jurisdiction of incorporation', savedData?.ult_parent_incorporation_juristriction],
        ult_parent_line4:     ['','', 'Legal Entity Identifier (LEI)', savedData?.ult_parent_lei],
        ult_parent_optional:  ['','', 'Name by which group is to be known','(no data mapped - this message will be deleted after testing)'],
        abbreviated_election: ['','Abbreviated return election?', 'No'],
        revised_return:       ['','Revised return', revised_return],
        subject_to_restrictions: ['','Group subject to interest restrictions?', group_restrictions],
        subject_to_reactivations: ['','Group subject to interest reactivations?', group_reactivations],


        // Group Level Elections tab values
        gleRepComp:           ['', 'Name of reporting company', reporting_company_name],
        gleUltParent:         ['', 'Ultimate parent or name of group',  ultimate_parent_name],
        gleEndPoA:            ['', 'End of period of account', poaEnd],
        gleRow13:             ['', 'Group ratio election (enter here only)', gleC13],
        gleRow14:             ['', 'Group ratio (blended) election (see below)', gleC14],
        gleRow15:             ['', 'Group EBITDA (chargeable gains) election', groupEBITDAGains, 'irrevocable'],
        gleRow16:             ['', 'Interest allowance (alternative calculation) election', interestAllowance_alternative,'irrevocable',],
        gleRow17:             ['', 'Interest allowance (non-consolidated investment) election (see below)', interestAllowance_nonConsolidated,],
        gleRow18:             ['', 'Interest allowance (consolidated partnerships) election (see below)', interestAllowance_consolidated, 'irrevocable',],
        gleRow23:             ['', groupRatioBlended],
        gleRow27:             ['', investorsList],
        gleRow31:             ['', 'TBC'],


        // UK Group Companies tab values
        ukGC_Row7:            ['','Name of reporting company', '', reporting_company_name, '',''],
        ukGC_Row8:            ['','Ultimate parent ', '',ultimate_parent_name, '',''],
        ukGC_Row9:            ['','Name used for group, if applicable', '', 'TBC (not currently saved in the group data?', '',''],
        ukGC_Row10:           ['','End of period of account', '', processDateJS(savedData.periods_of_account[poaIndex]?.period_end).format('DD/MM/YYYY'), '',''],
    
        // Group level amounts values
        gla_Row7:             ['', 'Name reporting company', reporting_company_name],
        gla_Row8:             ['', 'Ultimate parent', ultimate_parent_name],
        gla_Row9:             ['', 'Name used for group, if applicable', 'TBC (not currently saved in the group data?'],
        gla_Row10:            ['', 'End of period of account', processDateJS(savedData.periods_of_account[poaIndex]?.period_end).format('DD/MM/YYYY')],
        gla_Row17:            ['','Subject to interest restriction? Enter yes or no',interest_restrictions],
        gla_Row18:            ['','Total disallowed amount ',disallowed_amount],
        gla_Row19:            ['','Subject to reactivations? Enter yes or no',reactivations],
        gla_Row20:            ['','Interest reactivation cap ',reactivation_cap],
        gla_Row22:            ['','Aggregate net tax-interest expense',aggr_ntie],
        gla_Row23:            ['','Aggregate net tax-interest income',aggr_ntii],
        gla_Row24:            ['','Aggregate tax-EBITDA',aggr_ebitda],
        gla_Row25:            ['','Interest allowance brought forward',int_all_bfwd],
        gla_Row26:            ['','Interest allowance for period',int_all_period],
        gla_Row27:            ['','Interest capacity for period',int_capacity_period],
        gla_Row29:            ['','Adjusted net group interest expense ',adj_ngie],
        gla_Row31:            ['','Only where group ratio election made',''],
        gla_Row32:            ['','Qualifying net group-interest expense',qual_ngie],
        gla_Row33:            ['','Group-EBITDA',group_ebitda],
        gla_Row34:            ['','Group ratio',group_ratio],

        // Allocated Restrictions values
        aR_Row316:            ['', 'Total of allocated restrictions', '', '', '', '', '', '', ''],
        aR_Row317:            ['', 'Total disallowed amount', '', '', '', '', '', '', '', disallowed_amount], 

        // Allocated Reactivations values
        aReact_Row315:        ['', 'Total reactivation', '', ''],
        aReact_Row316:        ['', 'Reactivation cap', '', '', reactivation_cap], 
      }

    // initialises the structure to insert the rows into the sheet
    const ukGroupCompaniesTabList = [
      ['','','','10 digits','C/NC','Y/N','','Zero, if net income','Zero, if net expense','Can be negative']
    ]
    for (let i = 0; i < 300; i++){
      let row = ['', i + 1, '', '', '','', '', '', '', '']
      ukGroupCompaniesTabList.push(row)
    }

    const getCompanyData = (companyID) => {
      const company = companies.filter(company => company.company_ID === companyID);
      return company;
    }

    const fullCAPData = savedData?.company_accounting_periods

    const getCAPData = (companyID) => {
      const companyCAP = fullCAPData.filter(CAP => CAP.company_ID === companyID);
      return companyCAP;
    }

    const getAPData = (companyID) => {
      const companyAP = poaData.adjusted_caps.filter(ap => ap.company_ID === companyID);
      return companyAP;
    }

    const getCompanyNtiei = (company_ID) => {
      let ntieiTotal = 0; 
      //console.log(poaData.adjusted_caps)
      for (let entry in poaData.adjusted_caps){
        let cap = poaData.adjusted_caps[entry];
        if (cap && cap.company_ID === company_ID){
          if (cap.company_net_tax_interest_expense_income_gbp){
            // looks like company_net_tax_interest_expense_income_gbp is not populated for any of the groups I tested on - to be checked
            ntieiTotal += cap.company_net_tax_interest_expense_income_gbp;
          }
        }
      }
      //console.log('ntieiTotal for ', company_ID, ': ', ntieiTotal)
      return ntieiTotal
    }
  
    const getCompanyTaxEbitda = (company_ID) => {
      let ebitdaTotal = 0; 
      //console.log('searching ', company_ID)
      for (let entry in poaData.adjusted_caps){
        let cap = poaData.adjusted_caps[entry];
        //console.log(cap)
        if (cap && cap.company_ID === company_ID){
          if (cap.tax_ebitda){
            if(isNaN(cap.tax_ebitda)) {
              ebitdaTotal += cap.tax_ebitda['value']
            }
            else {
              ebitdaTotal += cap.tax_ebitda;
            }
          }
        }
      }
      //console.log('ebitdaTotal for ', company_ID, ': ', ebitdaTotal)
      return ebitdaTotal;
    }

    // insert companies into the sheet rows
    for (let i = 0; i < companies.length; i++){
      let companyData = getCompanyData(companies[i].company_ID);
      let companyCAPData = getCAPData(companyData.companyID);
      let companyAPData = getAPData(companyData.companyID);       
      let rowNumber = i + 1;
      let companyName = companies[i].company_name;
      let companyUTR = companies[i].utr;
      let companyNC = companyAPData.non_consenting_company && companyAPData.non_consenting_company === true ? 'NC' : 'C';
      let companyQIC = companyCAPData.election_s433_s434 && companyCAPData.election_s433_s434 === true ? 'Y' : 'N';
      let ntiei = getCompanyNtiei(companies[i].company_ID);
      let ntie = ntiei > 0 ? 0 : ntiei;
      let ntii = ntiei < 0 ? 0 : ntiei;
      let taxEbitda = getCompanyTaxEbitda(companies[i].company_ID);

      let row = ['',rowNumber,companyName,companyUTR,companyNC,companyQIC,'',ntie,ntii,taxEbitda];
      ukGroupCompaniesTabList[i+1] = row;
    }
    //console.log(ukGroupCompaniesTabList)

    const adjustedCapsData = poaData?.adjusted_caps;

    const findAdjustedCapsForCompany = (company_ID) =>{
      const adjustedCapsForCompany = adjustedCapsData.filter(aC => aC.company_ID === company_ID);
      return adjustedCapsForCompany;
    }

    // initialises the structure to insert the rows into the sheet
    const allocatedRestrictionsList = [
      ['', '', '', '', 'DD/MM/YYYY', 'DD/MM/YYYY', 'DD/MM/YYYY', 'Enter an amount or "0" (or leave blank)','','','']
    ]
    for (let i = 0; i < 300; i++){
      let row = ['', i + 1, '', '', '','', '', '', '', '']
      allocatedRestrictionsList.push(row)
    }
    // insert companies into the sheet rows
    for (let i = 0; i < companies.length; i++){
      let companyData = getCompanyData(companies[i].company_ID);
      let companyCAPData = getCAPData(companyData.companyID);
      let companyAPData = getAPData(companyData.companyID);       
      let rowNumber = i + 1;
      let companyName = companies[i].company_name;
      let nonConsenting = companyAPData.non_consenting_company && companyAPData.non_consenting_company === true ? 'NC' : '';
      let adjustedCapsForCompany = findAdjustedCapsForCompany(companies[i].company_ID)
      console.log(adjustedCapsForCompany[0]?.cpd_total)
      let ap1Ends = adjustedCapsForCompany[0] ? processDateJS(adjustedCapsForCompany[0].ap_end_date).format('DD/MM/YYYY') : '';
      let ap2Ends = adjustedCapsForCompany[1] ? processDateJS(adjustedCapsForCompany[0].ap_end_date).format('DD/MM/YYYY') : '';
      let ap3Ends = adjustedCapsForCompany[2] ? processDateJS(adjustedCapsForCompany[0].ap_end_date).format('DD/MM/YYYY') : '';
      let ap1Disallowance = adjustedCapsForCompany[0] ? adjustedCapsForCompany[0].cpd_total : "";
      let ap2Disallowance = adjustedCapsForCompany[1] ? adjustedCapsForCompany[1].cpd_total : "";
      let ap3Disallowance = adjustedCapsForCompany[2] ? adjustedCapsForCompany[2].cpd_total : "";

      let row = ['',rowNumber, companyName, nonConsenting, ap1Ends, ap2Ends, ap3Ends, ap1Disallowance, ap2Disallowance, ap3Disallowance];
      allocatedRestrictionsList[i+1] = row;
    }


    const group_period_data = [
        [], //1
        ['','Before submission export entire workbook in PDF format'],
        [],
        ['','This template is suitable for up to 300 companies. It can be used where some company accounting periods do not end on the same date as the worldwide group period of account'],
        [], //5
        [], //6
        ['','Interest Restriction Return Page 1 (Full return)'], //7
        [], //8
        ['','Identifies worldwide group, return period and type of interest restriction return (Full or abbreviated return)'], //9
        [], //10
        ['','Period of account covered by interest restriction return'],
        excelData.start_period_account,
        excelData.end_period_account,
        [],
        excelData.rep_comp_name, //15
        excelData.rep_comp_UTR,
        [],
        excelData.ultimate_parent,
        excelData.ult_parent_line2,
        excelData.ult_parent_line3, //20
        excelData.ult_parent_line4,
        excelData.ult_parent_optional,
        [],
        ['','Type of return', 'Enter Yes or No'],
        excelData.abbreviated_election, //25
        excelData.revised_return,
        ['', 'For a revised return, specify differences in white space box provided below '],
        [],
        ['','Whether subject to interest restriction or reactivation (if so, full return required)', 'Enter Yes or No'],
        excelData.subject_to_restrictions, //30
        excelData.subject_to_reactivations,
        [],
        ['','Ways in which revised return differs from the return it supersedes'],
        ['','A brief narrative is sufficient, e.g allocation of restictions changed or full return replaces abbreviated return'],
        ['', '(no data mapped - this message will be deleted after testing)'], //35
        [],
        ['','Free space to include notes to the return'],
        ['', '(no data mapped - this message will be deleted after testing)'],
        [],
        ['','Information required only in the unusual circumstances where there are deemed parents (s477 or 478 TIOPA 2010)'], //40
        ['','', 'Name', 'UTR, if any, 10 digits'],
        ['','Deemed parent (1)', '(no data mapped - this message will be deleted after testing)'],
        ['','Deemed parent (2)', '(no data mapped - this message will be deleted after testing)'],
        ['','Deemed parent (3)', '(no data mapped - this message will be deleted after testing)'],
        [], //45
        ['','Key'],
        ['','Descriptive Field', 'Note'],
        [],
        [],
        [], //50


    ]

    const group_level_elections_data = [
      [], //1
      ['','Interest Restriction Return Page 2'],
      [],
      ['','Group level elections (full return)'],
      [], //5
      ['','Fields below copy from p1'],
      excelData.gleRepComp,
      excelData.gleUltParent,
      [],
      excelData.gleEndPoA, //10
      [],
      ['','Group level elections (Other than abbreviated return election)', 'Enter E to elect', 'Enter R to revoke'],
      excelData.gleRow13,
      excelData.gleRow14,
      excelData.gleRow15, //15
      excelData.gleRow16,
      excelData.gleRow17,
      excelData.gleRow18,
      [],
      [], //20
      ['','Group ratio blended election'],
      ['','Enter below the investor group(s) to which election relates (enlarge box or insert rows if necessary)'],
      excelData.gleRow23,
      [],
      ['','Interest allowance (consolidated partnerships) election'], //25
      ['','Enter below partnership or partnerships to which election relates (including partnership UTR(s) where applicable), enlarge box or insert rows if necessary'],
      excelData.gleRow27,
      [],
      ['','Interest allowance (consolidated partnerships) election '],
      ['','Enter below partnership or partnerships to which election relates (including partnership UTR(s) where applicable), enlarge box or insert rows if necessary'], //30
      excelData.gleRow31,
      [],
      ['','Key'],
      ['','Descriptive field or date','Note']
    ]

    const group_companies_data_pt1 = [
      [], //1
      ['','Interest Restriction Return Page 3'],
      [],
      ['','Identifies UK group companies and shows net tax-interest and tax-EBITDA amounts',],
      [], //5
      ['','Fields below copy from p1',],
      excelData.ukGC_Row7,
      excelData.ukGC_Row8,
      excelData.ukGC_Row9,
      excelData.ukGC_Row10,//10
      [],
      ['', '', 'Full return', '', '', '', '','Full return only'],
      ['', '', '', '', '', '', '','Forms part of statement of calculations'],
      ['', 'Index no.', 'UK Group companies - names', 'UTR', 'Enter NC if non-consenting', 'QIC election?', '', 'Net tax-interest expense', 'Net tax-interest income', 'Tax-EBITDA']]

    const group_companies_data_pt2 = [
      [], //316
      ['', '', '', '', '', '', '','Group totals (full return only)'],
      ['', '', '', '', '', '', '','Sub-total (optional)', 'Sub-total (optional)'],
      ['', '', '', '', '', '', '','TBC', 'TBC'],
      ['', '', 'Key', '', '', '', '',],
      ['', '', 'Descriptive field or date', 'Numerical entry, £', 'Note', '', '', 'Aggregate net tax-interest expense', 'Aggregate net tax-interest income', 'Aggregate tax-EBITDA (not negative)'],
      ['', '', '', '', '', '', '', 'TBC', 'TBC', 'TBC'],
    ]

    const array1 = group_companies_data_pt1.concat(ukGroupCompaniesTabList);
    const group_companies_full_data = array1.concat(group_companies_data_pt2);
      

    const group_level_amounts_data = [
      [], //1
      ['', 'Interest Restriction Return Page 4'],
      [],
      ['', 'Group level amounts required in statement of calculations (Full return)'],
      [], //5
      ['','Fields below copy from p1'],
      excelData.gla_Row7,
      excelData.gla_Row8,
      excelData.gla_Row9,
      excelData.gla_Row10, //10
      [],
      ['', 'For glossary of terms see end of CFM guidance after CFM99110'],
      ['', 'For guidance on core CIR rules see CFM95200'],
      [],
      ['', 'Statement of Calculations', 'Full return only'], //15
      [],
      excelData.gla_Row17,
      excelData.gla_Row18,
      excelData.gla_Row19,
      excelData.gla_Row20, //20
      [],
      excelData.gla_Row22,
      excelData.gla_Row23,
      excelData.gla_Row24,
      excelData.gla_Row25, //25
      excelData.gla_Row26,
      excelData.gla_Row27,
      [],
      excelData.gla_Row29,  
      [], //30
      excelData.gla_Row32,
      excelData.gla_Row33,
      excelData.gla_Row34,
      [],
      [], //35
      ['', 'Key'],
      ['','Descriptive field or date', 'Numerical entry, £'],
      ['', 'Note']     
    ];

    const allocated_restrictions_data_pt1 = [
      [], //1
      ['', 'Interest Restriction Return Page 5' ],
      [],
      ['', 'Statement of allocated interest restrictions (Full return and where interest restriction arises, only)'],
      [], //5
      ['', 'Fields below copy from p1'],
      excelData.ukGC_Row7,
      excelData.ukGC_Row8,
      excelData.ukGC_Row9,
      excelData.ukGC_Row10,//10
      [],
      ['', 'Index no.', 'Name of company', 'Enter N if non-consenting', 'Dates of company APs', '', '', 'Disallowance','Disallowance','Disallowance',''],
      ['', '', 'Cells below copy from sheet 3', '', 'AP1 ends', 'AP2 ends', 'AP3 ends', 'Relevant AP 1','Relevant AP 2','Relevant AP 3',''],        
    ];

    const allocated_restrictions_data_pt2 = [
      excelData.aR_Row316, //316
      excelData.aR_Row317,
      [],
      ['', 'Key'],
      ['', 'Descriptive field or date', 'Numerical entry, £', '', 'Note']

    ];
    const aRArray1 = allocated_restrictions_data_pt1.concat(allocatedRestrictionsList);
    const allocated_restrictions_data = aRArray1.concat(allocated_restrictions_data_pt2);
   
    const allocated_reactivations_data_pt1 = [
      [], //1
      ['', 'Interest Restriction Return Page 6' ],
      [],
      ['', 'Statement of allocated interest reactivations (Full return and where interest reactivation arises, only)'],
      [], //5
      ['', 'Fields below copy from p1'],
      excelData.ukGC_Row7,
      excelData.ukGC_Row8,
      excelData.ukGC_Row9,
      excelData.ukGC_Row10,//10
      [],
      ['', 'Index no.', 'Name of company', 'Disallowances less earlier reactivations brought forward start of AP1', 'Reactivation  - for period of account.  (Apply to earlier company APs before later ones)'],
      ['', '', '', '', 'Enter an amount or "0" in each box']      
    ];   
    
    const allocated_reactivations_data_pt2 = [];
    for (let i = 0; i < 300; i++){
      let row = ['', i + 1, '', '', '','', '', '', '', '']
      allocated_reactivations_data_pt2.push(row)
    }
    // insert companies into the sheet rows
    for (let i = 0; i < companies.length; i++){ 
      let rowNumber = i + 1;
      let companyName = companies[i].company_name;
      let adjustedCapsForCompany = findAdjustedCapsForCompany(companies[i].company_ID);
      let bfdTotal = adjustedCapsForCompany[0] ? adjustedCapsForCompany[0].bfd_subtotal : "";
      let cprTotal = adjustedCapsForCompany[0] ? adjustedCapsForCompany[0].cpr_total : "";      
      let row = ['',rowNumber, companyName, bfdTotal, cprTotal];
      allocated_reactivations_data_pt2[i] = row;
    }

    const allocated_reactivations_data_pt3 = [
      [],
      excelData.aReact_Row315, 
      excelData.aReact_Row316, 
      [], 
      ['', 'Key'],
      ['', 'Descriptive field or date', 'Numerical entry, £', '', 'Note']
    ]
    
    const aRestrictionsArray1 = allocated_reactivations_data_pt1.concat(allocated_reactivations_data_pt2)
    const allocated_reactivations_data = aRestrictionsArray1.concat(allocated_reactivations_data_pt3)

return [group_period_data, group_level_elections_data, group_companies_full_data, group_level_amounts_data, allocated_restrictions_data, allocated_reactivations_data]
}