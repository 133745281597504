import "./tableHeader.scss";
import CustomTooltipIcon from "../customTooltipIcon/customTooltipIcon";

const CustomTableHeader = ({title, required, tooltipText, key, size}) => {
    return <th className="col th-vertical-align-top" key={key}>
        <div className='th-flex-container'>
            <p className="text-align-left">{title}{required && <span style={{ color: 'red' }}>*</span>}</p>
            {tooltipText && <CustomTooltipIcon size={size} tooltipText={tooltipText}/>}
        </div>
    </th>;
}

export default CustomTableHeader;
