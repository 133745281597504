import { Panel, Table, Loading, Column } from '@appkit4/react-components'; 
import { Button } from "../components/ReadonlyAwareInputs";
import { useContext, useEffect, useState } from 'react';
import { BackButton, NextButton } from '../components/ProgressNav';
import RevCalcHeader from './ReviewCalcHeader';
import ReviewCalcNav from './revCalcNavigation';
import EditAutomaticAllocations from './EditAutomaticAllocations';
import { getGroupData, setGroupData, getSelectedPeriod, getPoaIndex, getGroupName, getFormattedSelectedPeriod } from '../services/GroupContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { calculateComponentTotals } from '../services/calculations/ComponentTotals';
import { getExcessDebtCapPriorPeriod } from '../services/calculations/excessDebtCap';
import getReviewCalc1Values from '../services/calculations/calc1';
import toast from 'react-hot-toast';
import sqlService from '../services/sqldatabase/sqldatabase.service'
import { AuthContext } from '../services/AuthProvider';
import { processDateJS } from '../utils/dateProcessor';
import CompanyPanel from '../components/CompanyPanel';
dayjs.extend(utc)

const ReviewCalc1 = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isPosting, setIsPosting] = useState(false);
    const [isEditingAdjustment, setEditingAdjustment] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState();
    const selectedPeriod = getSelectedPeriod();
    const {period_start, period_end} = selectedPeriod;
    const auth = useContext(AuthContext);
    const [adjusted_caps, set_adjusted_caps] = useState()
    const [updateTableValues, setUpdateTableValues] = useState(false);

    let savedData = getGroupData()
    console.log(getPoaIndex())
    console.log(selectedPeriod)
    console.log(savedData?.periods_of_account)
    useEffect(() => {
        setIsLoading(true);
        console.log('calcs to update table')
        if (!savedData || !selectedPeriod || getPoaIndex() < 0) {
            return;
        }

        const EDC_poa = getExcessDebtCapPriorPeriod(savedData, savedData?.periods_of_account[getPoaIndex()])
        const totals_poa = calculateComponentTotals(EDC_poa);
        savedData.periods_of_account[getPoaIndex()] = totals_poa;

        const calculation_values = getReviewCalc1Values(getPoaIndex(), savedData)
        set_adjusted_caps(calculation_values);
        console.log('calculation_values', calculation_values)

        const data = [];
        calculation_values?.forEach(cap => {
            if(cap) {
                const company_name = savedData?.companies?.filter(c => c?.company_ID === cap?.company_ID)[0]?.company_name;
    
                data?.push({
                    ...cap,
                    company_name: company_name,
                    company_ID: cap?.company_ID,
                    doc_id: cap?.doc_id,
                    accounting_period: processDateJS(cap?.start_date)?.format('DD/MM/YYYY') + " - " + processDateJS(cap?.end_date)?.format('DD/MM/YYYY'),
                    currency: cap?.currency ? cap?.currency : "GBP",
                    net_tax_interest_expense: cap?.net_tax_interest_expense ? cap?.net_tax_interest_expense : 0,
                    tax_ebitda: cap?.tax_ebitda ? cap?.tax_ebitda : 0,
                    status: cap?.adjustment_status ? cap?.adjustment_status : 'N/A',
                    edit: cap?.inclusion_factor < 1 ? true : false
                })
            }
        })
        data?.sort((a,b) => a?.adjustment_status?.localeCompare(b?.adjustment_status));

        setTableData(data)
        setIsLoading(false);
        console.log('table data is: ', data)
    }, [updateTableValues]);

    const [tableData, setTableData] = useState([])

    const updateGroupData = (adjusted_caps) => {
        console.log('saving to group data')
        if (!selectedPeriod || !period_start || !period_end) {
            toast.error('Failed to find the selected period')
            return;
        }
        if (!savedData) {
            return;
        }

        const poa_index = savedData?.periods_of_account?.findIndex(poa => processDateJS(poa?.period_start)?.isSame(processDateJS(period_start)));
        if (poa_index === -1) {
            toast.error('Failed to find the data for the selected period')
            return;
        }

        const poa = savedData?.periods_of_account[poa_index];
        poa.adjusted_caps = adjusted_caps//poa_adjusted_caps;
        savedData.periods_of_account[poa_index] = poa;
        console.log('updateGroupData function reviewcalc1 poa =', poa);
        // flag to trigger re-run of calculations after values are updated.
        setUpdateTableValues(!updateTableValues)
        console.log('saved data after saving changes ', savedData)
        setGroupData(savedData);
    }

    const save = () => {
        updateGroupData(adjusted_caps);

        if (auth?.isReadOnlyUser(getGroupName())) {
            setGroupData(savedData);
            return;
        }

        setIsPosting(true);

        const promise = sqlService?.postResponse(savedData);
        return toast.promise(promise, {
            loading: 'Saving...',
            success: (response) => {
                setGroupData(savedData);
                setIsPosting(false);
                return `Changes saved to database`
            },
            error: 'Failed to save changes to database',
        })
    }

    const editAdjustmentCell = (row, field) => {
        return <Button
            viewEditButton
            neverReadonly
            onClick={() => {
                setSelectedCompany(row);
                setEditingAdjustment(row[field]);
            }}
            disabled={!row[field]}
        />
    }

    if (isLoading)
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            ></Loading>
        );

    if (isEditingAdjustment) {
        return (
           <EditAutomaticAllocations navBack={setEditingAdjustment} company_period={selectedCompany} set_company_period={setSelectedCompany} adjusted_caps={adjusted_caps} set_adjusted_caps={set_adjusted_caps} updateGroupData={updateGroupData} />
        );
    }
    return (
        <div className="ap-container">
            <div className="ap-container mt-4">
                <CompanyPanel />
            </div>
            <p></p>

            <Panel>
                <RevCalcHeader groupname="Review Calculations - Allocations of Tax-EBITDA and Tax-Interest" />
                <ReviewCalcNav currentStep={0} />
                <p>Where a UK Group Company has an accounting period which differs from the period of account of the worldwide group and/or has been acquired or disposed of during the period, its Tax-EBITDA and Tax-Interest amounts must be apportioned between the regarded and disregarded accounting periods. A disregarded period is any part of the company’s accounting period which falls outside the period of account of the worldwide group, or during which the company is not a member of the worldwide group.</p>
                <p>The apportionment must be undertaken on a just and reasonable basis. The CIR Analyser automatically calculates the apportionment on a time apportionment approach. This may reflect a just and reasonable apportionment, but it may also be necessary to amend the automatic allocation to reflect a different basis of apportionment - for example, where transactions impact only either a regarded or disregarded period, the results of those transactions may need to be wholly apportioned to the relevant period.</p>
                <p>The table sets out the Tax-EBITDA and Tax-Interest allocated to the regarded part of the relevant accounting period for each UK Group Company. Where a company has both regarded and disregarded periods, the edit button in the table below is not disabled and the allocations can be amended by pressing the “edit” button.</p>
                

                <Table originalData={tableData} hasTitle striped>
                    <Column field="company_name">Company Name</Column>
                    <Column field="accounting_period">Relevant Accounting Period</Column>
                    <Column field="currency">Local Currency</Column>
                    <Column field="net_tax_interest_expense" renderCell={(row, field) => { return row?.net_tax_interest_expense ? Math.round(Number(row?.net_tax_interest_expense)).toLocaleString() : '0'}}>Net Tax-Interest Expense / (Income)</Column>
                    <Column field="tax_ebitda" renderCell={(row, field) => { return row?.tax_ebitda ? Math.round(Number(row?.tax_ebitda)).toLocaleString() : '0'}}>Tax-EBITDA</Column>
                    <Column field="status">Adjustment status</Column>
                    <Column field="edit" renderCell={editAdjustmentCell}>Automatic adjustment</Column>
                </Table>

                <div className='container-fluid mt-4'>
                    <div className='d-flex justify-content-between align-items-center'> 
                        <div>
                            <BackButton disabled={isPosting}/>
                        </div>
                        <div className='d-flex gap-2'>
                            <NextButton loading={isPosting} preNavigation={save} />
                        </div>
                    </div>
                </div>
               
            </Panel>
        </div>
    );
};

export default ReviewCalc1;