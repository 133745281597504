import { Button, Input, InputNumber, Modal, Select } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import '../home.scss';
import sqldatabaseService from "../../../services/sqldatabase/sqldatabase.service";

const DeleteGroupModal = ({ groupList, fetchGroups, resetForm, setResetForm }) => {
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);

    useEffect(() => {
        if(resetForm) {
            setSelectedGroups([]);
            setResetForm(false);
        }
    }, [resetForm]);

    const deleteGroups = async () => {
        setConfirmButtonDisabled(true);
        if (selectedGroups.length > 0) {
            setButtonsDisabled(true);
            const promise = sqldatabaseService.postDeleteGroups(selectedGroups)
                .then(response => {
                    if (response.status !== 200) {
                        // Return an object that includes the error message instead of throwing an error
                        return { error: true, message: `Error: Status ${response.status} - ${response.err}` };
                    }
                    return response;
                });
    
            return toast.promise(promise, {
                loading: `Deleting ${selectedGroups.length} groups...`,
                success: (response) => {
                    // Check if there was an error
                    if (response.error) {
                        throw new Error(response.message);
                    }
                    setConfirmButtonDisabled(false);
                    setConfirmationModalVisible(false);
                    setSelectedGroups([]);
                    fetchGroups();
                    return `Deleted ${selectedGroups.length} groups.`;
                },
                error: (response) => {
                    // Show the error message in the toast
                    return `Failed to delete groups.`;
                }
            }).then(() => setButtonsDisabled(false));
        }
    }

    const cancelDeleteGroups = () => {
        setSelectedGroups([]);
        setConfirmationModalVisible(false);
        setConfirmButtonDisabled(false);
    }

    return (
        <div>
            <div className='flex-space-between'>
                <Select placeholder={'Select Groups'} value={selectedGroups} multiple className='col' style={{width: '35rem'}} searchable data={groupList} onSelect={(val) => {
                    // console.log('selection happened:', val);
                    // setConfirmationModalVisible(true);
                    setSelectedGroups(val);
                }} dropdownRenderMode={'portal'}></Select>
                <div style={{ flex: '1 0 10px'}}></div>
                <Button className='col-2 m-1' disabled={selectedGroups.length === 0} onClick={() => {
                    setConfirmationModalVisible(true);
                }}>Delete Groups</Button>
            </div>

            <Modal
                visible={confirmationModalVisible}
                title={"Admin Pannel"}
                ariaLabel={"Admin Pannel"}
                onCancel={() => cancelDeleteGroups()}
                maskCloseable={false}
                modalStyle={{width: '70rem'}}
                footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '100px' }}
                header={""}
                icons={""}
            >
                <h1>You are about to permanently delete {selectedGroups.length} group{selectedGroups.length > 1 && 's'}. <br></br>This action cannot be undone and all associated data (including responses and subcollections) will be removed.</h1>
                <br/>
                <div className="custom-scrollable">
                    <ul>
                        {selectedGroups.map((group) => {
                            return <li>{group}</li>
                        })}
                    </ul>
                </div>
                <br/>
                <div className="flex-end">
                    <Button onClick={() => cancelDeleteGroups()} disabled={confirmButtonDisabled} loading={confirmButtonDisabled}>Cancel</Button>
                    <Button onClick={() => deleteGroups()} disabled={confirmButtonDisabled} loading={confirmButtonDisabled}>Confirm</Button>
                </div>
            </Modal>
        </div>)
}

export default DeleteGroupModal;