import React from "react";

const PrivacyStatement = () => {

  return (
    <div className="ap-container">
      <h2>Privacy statement</h2>
      <h3>Introduction</h3>
      <p>
        PwC is strongly committed to protecting data. This data security
        statement describes why and how we collect and use data. It applies to
        data provided to us through the Saratoga benchmarking program. We may
        use data provided to us for any of the purposes described in this
        statement.
      </p>

      <p>
        “PwC” (and “we”, “us”, or “our”) refers to PricewaterhouseCoopers LLP,
        the limited partnership registered in England under registration no.
        OC303525 and with its registration address at 1 Embankment Place,
        London, WC2N 6RH, and such other PwC member firm in the UK and their
        subsidiaries (such as PwC Legal ME LLP) that: (1) is a contracting party
        for the purposes of providing or receiving services, (2) posted a
        position for which you are applying, or (3) you have a role or
        relationship with. Each member firm in the PwC network is a separate
        legal entity and a separate controller for personal data. For further
        details, please see{" "}
        <a
          className="ap-link"
          href="https://www.pwc.com/structure"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.pwc.com/structure
        </a>
        . (Please note this privacy statement does not cover the activities of
        Embankment Place Primary Healthcare Ltd (“the Clinic”), which is not
        included within the definition of “PwC”, “we”, “us”, and “our”).
      </p>

      <h3>Not personally identifiable data</h3>
      <p>
        The data being collected as part of the Saratoga Impact data form is not
        individual level data, and therefore privacy laws have no direct
        application. In any event, if any of the data submitted could be
        considered personal data, then our privacy statement, which outlines how
        we process personal data would apply.
      </p>

      <h3>Security</h3>
      <p>
        We take the security of all the data we hold very seriously. We adhere
        to internationally recognised security standards and our information
        security management system relating to client confidential data is
        independently certified as complying with the requirements of ISO/IEC
        27001: 2013. We have a framework of policies, procedures and training in
        place covering data protection, confidentiality and security and
        regularly review the appropriateness of the measures we have in place to
        keep the data we hold secure.
      </p>

      <h3>Data storage</h3>
      <p>
        The data provided to Saratoga as part of the Impact program will be
        stored in our benchmarking databases. We will take appropriate technical
        and organisational security measures to preserve the confidentiality of
        this information as outlined in this statement.
      </p>

      <h3>Data retention</h3>
      <p>
        We retain the data provided to us for as long as is considered necessary
        for the purpose for which it was collected (including as required by
        applicable law or regulation). In the absence of specific legal,
        regulatory or contractual requirements, our baseline retention period
        for records and other documentary evidence created in the provision of
        services is 7 years.
      </p>

      <h3>When and how we share data</h3>
      <p>
        We will only share data with others when we are legally permitted to do
        so. When we share data with others, we put contractual arrangements and
        security mechanisms in place as appropriate to protect the data and to
        comply with our data protection, confidentiality and security standards.
      </p>

      <p>
        We are part of a global network of firms and in common with other
        professional service providers, we use third parties located in other
        countries to help us run our business. As a result, data may be
        transferred outside the United Kingdom. We will carry out such transfers
        only where we have a lawful basis to do so, including to a recipient who
        provides an adequate level of protection for data.
      </p>

      <p>Data held by us may be transferred to:</p>

      <ul>
        <li>
          <b>Other PwC member firms</b>
        </li>
        <ul>
          <li>
            See{" "}
            <a
              className="ap-link"
              href="https://www.pwc.com/gx/en/about/office-locations.html#/"
              target="_blank"
              rel="noopener noreferrer"
            >
              details of our member firm locations
            </a>
            .
          </li>
          <li>
            We may share personal data with other PwC member firms where
            necessary for administrative purposes and to provide professional
            services to our clients.
          </li>
          <li>
            We store on our or other PwC member firm servers in the EU,
            Singapore and the USA. IT support and services are provided by PwC
            India and PwC’s IT Services company.
          </li>
          <li>
            Our business contacts are visible to and used by other PwC member
            firms to learn more about a contact, client or opportunity they have
            an interest in (please see the{" "}
            <a
              className="ap-link"
              href="https://www.pwc.co.uk/who-we-are/privacy-statement/business-contacts.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Business contacts
            </a>{" "}
            section of the privacy statement for more information about our
            processing of this type of data).
          </li>
        </ul>

        <li>
          <b>
            Third party organisations that provide applications/functionality,
            data processing or IT services to us.
          </b>{" "}
          We use third parties to support us in providing our services and to
          help provide, run and manage our internal IT systems. For example,
          providers of information technology, cloud based software as a
          service, identity management, website hosting and management, data
          analysis, data back-up, security and storage services. The servers are
          located in secure data centres around the world. Further details of
          these providers are set out in our{" "}
          <a
            className="ap-link"
            href="https://www.pwc.co.uk/who-we-are/privacy-statement.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy statement
          </a>
          .
        </li>
        <li>
          <b>Our clients.</b> Where we need to process data to provide
          professional services to our clients, we may share data in our
          deliverables (such as the reports we create). However, the data shared
          to clients would only be in aggregate form according to the data
          aggregation guidelines outlined below.
        </li>
      </ul>

      <h3>Data aggregation</h3>
      <p>
        The data may be combined with information of other parties to provide
        reports and services to other parties including the Saratoga Impact
        dashboard deliverable, as long as no one individual organisation’s
        results can be identified from those reports and services.
      </p>

      <p>
        The benchmarking process for the Saratoga Impact dashboard will use a
        matching process to assemble a peer group of at least 8 comparable
        organisations for each benchmarked field. This aggregates the data to a
        level that will mean no single peer’s data can be reasonably isolated by
        any other participant. The report provided will compare your
        organisation’s data to the peer group quartiles or average as
        appropriate for the measure shown.
      </p>

      <h3>Peer group comparison</h3>
      <p>
        The complimentary report you will receive in exchange for participation
        will prioritise matching by industry sector and cannot be customised
        further. If you would like to discuss engaging with us to benchmark
        against alternative peer groups, please contact{" "}
        <a className="ap-link" href={"mailto:gbl_saratoga@pwc.com"}>
          gbl_saratoga@pwc.com
        </a>
        . Please note that further benchmarking will require contractual
        engagement and therefore may not be possible if your organisation is
        subject to restrictions on engagement with PwC (including audit or SEC
        restrictions).
      </p>

      <h3>Updating your data submission</h3>
      <p>
        Data can be updated outside the annual data cycle. Please inform us by
        email to{" "}
        <a className="ap-link" href={"mailto:gbl_saratoga@pwc.com"}>
          gbl_saratoga@pwc.com
        </a>
        that you would like to modify your submission and we will be in touch to
        discuss adjustments. The benchmarks and reports will be rerun on a
        quarterly basis, so we will be able to confirm when updates to your
        dashboard will be visible.
      </p>

      <h3>Completeness of the data submission</h3>

      <p>
        If you do not have access to/cannot calculate/am not willing to provide
        some of the information requested on the form you can still submit the
        form without this information. We understand in some cases extracting
        data related to some fields may be difficult. For some fields, it will
        be acceptable to return your submission without filling in this data,
        but you will not be able to see benchmarks that involve any field that
        you did not submit; these fields are coloured as optional in the form.
      </p>

      <p>
        For the remaining fields in any given module you are completing, we ask
        that you provide information for each field; if you believe this is
        impossible in a reasonable time frame or the information requested is
        not applicable to your organisation then please let us know as or before
        you submit the return.
      </p>
    </div>
  );
};

export default PrivacyStatement;