import { Panel,  Loading,  Table, Column,  Accordion, AccordionItem,  Tooltip } from '@appkit4/react-components'; 
import { Button, Input, CalendarPicker, Checkbox } from "../components/ReadonlyAwareInputs";
import { useContext, useEffect, useState } from 'react';
import { getGroupData, setGroupData, getSelectedPeriod, getPoaIndex, getGroupName } from '../services/GroupContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BackButton, NextButton } from '../components/ProgressNav.js';
import { processDateJS } from '../utils/dateProcessor';
import { processExcel } from '../utils/ExcelOutput.js';
import { processFullReturn } from '../utils/FullReturnOutput';
import { formatFullReturn } from '../utils/FullReturnFormatting';
import { processAbbreviatedReturn } from '../utils/AbbReturnOutput';
import { formatAbbreviatedReturn } from '../utils/AbbrReturnFormatting';
import {saveAs} from "file-saver";
import toast from 'react-hot-toast';
import { getGroupElectionsTableData } from './reporting-company/ReportingCompany.js';
import sqldatabaseService from '../services/sqldatabase/sqldatabase.service.js';
import { AuthContext } from '../services/AuthProvider.js';
import { defaultReturnStatus, formatReturnStatus, validReturnTypes } from './GroupPOA/GroupPOA.js';
import { addCharge } from '../services/Charging.service.js';

const ExcelJS = require('exceljs');

dayjs.extend(utc)

const chargeTableData = [
    ["1 - 5", "£800"],
    ["6 - 15", "£1,100"],
    ["16 - 50", "£1,600"],
    ["51+", "£2,300",],
]

const Outputs = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isPosting, setIsPosting] = useState(false);
    const selectedPeriod = getSelectedPeriod();
    const savedData = getGroupData();
    const poaIndex = getPoaIndex();
    const auth = useContext(AuthContext);

    const [returnStatus, setReturnStatus] = useState(defaultReturnStatus);
    const [isReturnTypeValid, setIsReturnTypeValid] = useState(false);
    const [isRevisionValid, setIsRevisionValid] = useState(false);
    const [shouldSubmit, setShouldSubmit] = useState(false);

    const [wbsCode, setWbsCode] = useState("");
    const [wbsCodeError, setWbsCodeError] = useState(false);

    const [returnsEnabled, setReturnsEnabled] = useState(false);
    useEffect(() => {
        const newWbsCodeError = !String(wbsCode).match(/^\d{11}/);
        setWbsCodeError(newWbsCodeError);
        setReturnsEnabled(
            wbsCode && !newWbsCodeError
            && returnStatus?.reviewConfirmed && returnStatus?.reviewedBy?.length > 0
            && returnStatus?.isFinalised && ! returnStatus?.withoutReportingCompany && ! returnStatus?.isSubmitted
        )
    }, [wbsCode, returnStatus])
    
    useEffect(() => {
        if (!returnStatus) return;

        setIsReturnTypeValid(Object.values(validReturnTypes).includes(returnStatus.type));
        
        const revisedNotes = returnStatus?.revisedNotes;
        setIsRevisionValid( returnStatus?.isRevised ? revisedNotes[revisedNotes.length-1]?.length > 0 : true );
    }, [returnStatus])

    const fullElectionsTableData = getGroupElectionsTableData(savedData, selectedPeriod);
    const electionsTableData = [
        "group-ratio",
        "group-ratio-blended",
        "group-ebitda",
        "interest-alt-calc",
        "interest-non-con-investment",
        "interest-con-partnership"
    ].map(id => fullElectionsTableData[id])

    const isElectionThisYear = (table, property) => table[property].status 
        && dayjs(table[property].dateMade).isBefore(selectedPeriod.end_date);

    const shouldDisableReturnWithoutReportingCompany = [
        fullElectionsTableData['group-ratio'].status,
        fullElectionsTableData['group-ratio-blended'].status,
        isElectionThisYear( fullElectionsTableData, 'interest-alt-calc'),
        fullElectionsTableData['interest-non-con-investment'].status,
        isElectionThisYear( fullElectionsTableData, 'interest-con-partnership'),
        getSelectedPeriod().adjusted_caps.some( cap => !cap.non_consenting_company ),
    ].some( v => v === true );

    const hasActiveCompany = Boolean(getSelectedPeriod().reporting_company ?? false);

    // create file in browser
    const jsonFileName = savedData?.group_name + " - Summary output - " + processDateJS(selectedPeriod?.period_start)?.format('DD/MM/YYYY') + " - " + processDateJS(selectedPeriod?.period_start)?.format('DD/MM/YYYY');
    const blob = new Blob([JSON.stringify(savedData, null, 2)], { type: "application/json" });

    function downloadJson(){
        saveAs(blob, jsonFileName + '.json');
    }

    // download an Excel file with the draft return - full
    async function downloadFullReturn() {
        console.log('Export data:', savedData.periods_of_account[poaIndex]);
        console.log('Selected:', selectedPeriod);
        
        const workbook = new ExcelJS.Workbook();
        const group_period_sheet = workbook?.addWorksheet('Group Period Return Type');
        const group_level_elections_sheet = workbook?.addWorksheet('Group Level Elections');
        const uk_group_companies_sheet = workbook?.addWorksheet('UK Group Companies');
        const group_level_amounts_sheet = workbook?.addWorksheet('Group Level Amounts');
        const allocated_restrictions_sheet = workbook?.addWorksheet('Allocated Restrictions');
        const allocated_reactivations_sheet = workbook?.addWorksheet('Allocated Reactivations');

        const full_return_data = processFullReturn(savedData, poaIndex, selectedPeriod);

        const group_period_return_data = full_return_data[0];
        group_period_sheet?.addRows(group_period_return_data);

        const group_level_elections_data = full_return_data[1];
        group_level_elections_sheet?.addRows(group_level_elections_data);

        const uk_group_companies_data = full_return_data[2];
        uk_group_companies_sheet?.addRows(uk_group_companies_data);

        const group_level_amounts_data = full_return_data[3];
        group_level_amounts_sheet?.addRows(group_level_amounts_data);

        const allocated_restrictions_data = full_return_data[4];
        allocated_restrictions_sheet?.addRows(allocated_restrictions_data);

        const allocated_reactivations_data = full_return_data[5];
        allocated_reactivations_sheet?.addRows(allocated_reactivations_data);
        
        formatFullReturn(group_period_sheet, group_level_elections_sheet, uk_group_companies_sheet, group_level_amounts_sheet, allocated_restrictions_sheet, allocated_reactivations_sheet)

        const period_start = processDateJS(selectedPeriod?.period_start)?.format('DD/MM/YYYY')
        const period_end = processDateJS(selectedPeriod?.period_end)?.format('DD/MM/YYYY')
        const fullReportFilename = savedData?.group_name + " - Full return draft - " + period_start + " - " + period_end; 
        const excelFileName = fullReportFilename + ".xlsx"
        const buffer = await workbook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const blob = new Blob([buffer], {type: fileType});
        saveAs(blob, excelFileName);
      }

    async function donwloadAbbreviatedReturn(){
        const workbook = new ExcelJS.Workbook();
        const group_period_sheet = workbook?.addWorksheet('Group Period Return Type');
        const group_level_elections_sheet = workbook?.addWorksheet('Group Level Elections');
        const uk_group_companies_sheet = workbook?.addWorksheet('UK Group Companies');

        const full_return_data = processAbbreviatedReturn(savedData, poaIndex, selectedPeriod);
        const group_period_return_data = full_return_data[0];
        group_period_sheet?.addRows(group_period_return_data);

        const group_level_elections_data = full_return_data[1];
        group_level_elections_sheet?.addRows(group_level_elections_data);

        const uk_group_companies_data = full_return_data[2];
        uk_group_companies_sheet?.addRows(uk_group_companies_data);

        formatAbbreviatedReturn(group_period_sheet, group_level_elections_sheet, uk_group_companies_sheet)

        const period_start = processDateJS(selectedPeriod?.period_start)?.format('DD/MM/YYYY')
        const period_end = processDateJS(selectedPeriod?.period_end)?.format('DD/MM/YYYY')
        const fullReportFilename = savedData?.group_name + " - Abbreviated return draft - " + period_start + " - " + period_end; 
        const excelFileName = fullReportFilename + ".xlsx"
        const buffer = await workbook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const blob = new Blob([buffer], {type: fileType});
        saveAs(blob, excelFileName);
    }

    //processExcel(json)
    async function excelDataDownload(){

        const workBook = new ExcelJS.Workbook();
        const CompanyPeriods = workBook?.addWorksheet('Company Accounting Periods', {views:[{state: 'frozen', xSplit: 1, ySplit:1}]})
        const CIR_Calculations = workBook?.addWorksheet('CIR Calculations')
        const CIR_Attributes = workBook?.addWorksheet('CIR Attributes')
        
        
        const sheet1 = processExcel(savedData)[0];
        const sheet2 = processExcel(savedData)[1];
        const sheet3 = processExcel(savedData)[2];
        CompanyPeriods?.addRows(sheet1)
        CIR_Calculations?.addRows(sheet2)
        CIR_Attributes?.addRows(sheet3)

        //Styling for Company accounting periods

        const bolds1 = [1,14,16,27,29,32,34,45,47,49,57,59,61,69,71,73,81,83,89,91,101,122,125,126,128,130,137,145]

        for(let j = 0;j<bolds1?.length;j++){
            CompanyPeriods.getRow(bolds1[j]).font = {bold:true}
        }

        CompanyPeriods.getColumn(1).width = 40
        CompanyPeriods.getColumn(1).alignment = {wrapText:true}
        CompanyPeriods.getRow(8).numFmt = '0%';

        for(let a =0;a<processExcel(savedData)[3]?.length;a++){
            CompanyPeriods.getColumn(a+2).width = 15
            CompanyPeriods.getColumn(a+2).alignment = {horizontal: "right"}
        }

        //Styling for CIR Calculations

        CIR_Calculations.getColumn(1).width = 40
        CIR_Calculations.getColumn(2).width = 25
        CIR_Calculations.getColumn(3).width = 15
        CIR_Calculations.getColumn(2).alignment = {horizontal: "right"}
        CIR_Calculations.getColumn(1).alignment = {wrapText:true}

        CIR_Calculations.getRow(1).font = {underline:true, bold:true}
        CIR_Calculations.getRow(15).font = {underline:true,bold:true}
        CIR_Calculations.getRow(33).font = {underline:true,bold:true}

        const bolds2 = [2,16,36,37]

        for(let j = 0;j<bolds2?.length;j++){
            CIR_Calculations.getRow(bolds2[j]).font = {bold:true}
        }

        //Styling for CIR Attributes
        CIR_Attributes.getColumn(1).width = 40
        CIR_Attributes.getColumn(2).width = 20
        CIR_Attributes.getColumn(3).width = 15
        CIR_Attributes.getColumn(2).alignment = {horizontal: "right"}
        CIR_Attributes.getColumn(1).alignment = {wrapText:true}
        CIR_Attributes.getCell("A31").alignment = {wrapText:false}
        CIR_Attributes.getCell("A32").alignment = {wrapText:false}
        CIR_Attributes.getCell("A33").alignment = {wrapText:false}
        CIR_Attributes.getCell("A34").alignment = {wrapText:false}
        CIR_Attributes.getRow(31).font = {color:{argb:"FF00001"}}
        CIR_Attributes.getRow(33).font = {color:{argb:"FF00001"}}
        CIR_Attributes.getRow(1).font = {underline:true, bold:true}
        CIR_Attributes.getRow(14).font = {underline:true,bold:true}
        CIR_Attributes.getRow(22).font = {underline:true,bold:true}

        const bolds3 = [2,8,10,11,15,20,23,26,28]

        for(let j = 0;j<bolds3.length;j++){
            CIR_Attributes.getRow(bolds3[j]).font = {bold:true}
        }

        const excelFileName = jsonFileName + ".xlsx"

        const buffer = await workBook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        const blob = new Blob([buffer], {type: fileType});
        saveAs(blob, excelFileName);

    }

    const notImplemented = () => {
        toast("This feature is not fully implemented", { "icon": "⚠️" });
    }

    useEffect(() => {
        const saved_data = getGroupData();
        if (!saved_data) {
            return;
        }
        const poaIndex = getGroupData()?.periods_of_account?.findIndex(poa => processDateJS(poa?.period_start)?.isSame(processDateJS(selectedPeriod?.period_start)) && processDateJS(poa?.period_end)?.isSame(processDateJS(selectedPeriod?.period_end)));
        if (poaIndex === -1){
            return;
        }

        setWbsCode(saved_data?.wbsCode || "");

        const selectedDataPeriod = savedData?.periods_of_account[poaIndex];
        if (selectedDataPeriod?.return_status){
            console.log("Found existing status", selectedDataPeriod?.return_status);
            setReturnStatus(selectedDataPeriod?.return_status);
        }
        
        setIsLoading(false);
    }, [])

    const save = (returnStatus) => {
        const savedData = getGroupData();

        if (poaIndex === -1)
            throw `Error: failed to find period of account`;

        setIsPosting(true);

        if (!wbsCodeError && wbsCode?.length > 0)
            savedData.wbsCode = wbsCode;

        let chargePromise =  null;
        if (shouldSubmit){
            chargePromise = addCharge(returnStatus.type, [], wbsCode, selectedPeriod, getGroupData());
            returnStatus = { ...returnStatus, isSubmitted: true };
        }
        
        savedData.periods_of_account[poaIndex].return_status = returnStatus;
        
        if (auth?.isReadOnlyUser(getGroupName())) {
            setGroupData(savedData);
            return;
        }

        const savePromise = sqldatabaseService?.postResponse(savedData);
        return toast.promise( Promise.all([ chargePromise, savePromise ]), {
            loading: 'Saving return data...',
            success: () => {
                setGroupData(savedData);
                if (shouldSubmit){
                    setReturnStatus({ ...returnStatus, isSubmitted: true });
                    setShouldSubmit(false);
                }
                setIsPosting(false);
                return 'Saved return data!'
            },
            error: 'Something went wrong saving return status!',
        })
    }


    if (isLoading)
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            ></Loading>
        );
    return (
        <div >
            <div className="ap-container">
                <Panel className='mt-2'>
                    <div className='row mb-3 col-4'>
                        <label>Status of reporting company in the current period:</label>
                        <Input readonly value={formatReturnStatus(returnStatus)}
                        />                
                    </div>
                    { !returnStatus?.isFinalised &&
                        <Button onClick={() => {
                            setReturnStatus({ ...returnStatus, isFinalised: true }); 
                        }}
                        >Finalise draft reporting company</Button>
                    }
                </Panel>

                <Panel className='mt-2'>
                    <p>Please enter a WBS code below and confirm acceptance of the below recharges in order to generate app outputs:</p>
                    <p>Generating an original full return incurs a recharge based on the number of UK group companies, as per the table below, an addition to any visuals fees that may have been chargeable for One Source extractions.<br />A revised full return incurs a fixed recharge of £500.<br />Abbreviated returns, whether original or revised, incur a fixed recharge of £250, and generating modelling outputs incurs a £1,000 fixed charge.</p>
                    <Table originalData={chargeTableData} hasTitle disableDefaultSort skipInitialSort >
                        <Column field='0'>Number of UK group companies</Column>
                        <Column field='1'>Fixed recharge</Column>
                    </Table>

                    { !returnStatus?.isFinalised && <>
                        <NextButton preNavigation={() => save(returnStatus)} nextPage="/app/reportingcompany">Add Reporting Company</NextButton>
                    </>
                    }


                    <label className="col-sm-2 col-form-label align-self-center">WBS code:</label>
                    <Input
                        title="WBS Code"
                        className="col-2"
                        value={wbsCode}
                        enabledWhenFinalised
                        error={ returnStatus.isFinalised && wbsCodeError }
                        onChange={(value) => {
                            value= String(value).trim().slice(0, 11);

                            setWbsCode(value);
                        }}
                    />

                    <label className="col-sm-2 col-form-label align-self-center">Reviewed by:</label>
                    <Input 
                        value={returnStatus?.reviewedBy || ""} 
                        onChange={(value) => setReturnStatus({...returnStatus, reviewedBy: value.trimStart()})} 
                        error={ returnStatus.isFinalised && returnStatus?.reviewedBy?.length === 0}
                        enabledWhenFinalised
                    />
                    <div className='d-flex flex-row justify-content'>
                        <Checkbox enabledWhenFinalised checked={returnStatus?.reviewConfirmed ?? false} onChange={(value) => { setReturnStatus({...returnStatus, reviewConfirmed: value, reviewedBy: returnStatus?.reviewedBy?.trimEnd()}) }}/>
                        <p>Please confirm that the CIR calculation has been appropriately reviewed before generating the return</p>
                    </div>

                </Panel>

                <Panel className='mt-2'>
                    <div className='row space-between'>
                        <p className="col-8">Selecting this option generates standard reports, detailed excel outputs and visualisation inputs</p>
                        <Button
                            className="col-4"
                            neverReadonly 
                            onClick={() => {
                                downloadJson();
                                excelDataDownload();
                            }} 
                            icon="icon-download-light-outline"
                        >
                            Generate modelling outputs
                        </Button>
                    </div>
                    <div className='row space-between'>
                        <p className="col-8">Selecting this option generates full return templates, in addition to standard reports, detailed excel outputs and visualisation inputs. This option is only available if there is an active reporting company.</p>
                        <Button 
                            className="col-4"
                            enabledWhenFinalised
                            onClick={async () => { 
                                downloadJson();
                                excelDataDownload();
                                downloadFullReturn();

                                const newStatus = { ...returnStatus, type: validReturnTypes.full };
                                setReturnStatus(newStatus); 
                                await save(newStatus);
                            }}
                            disabled={!returnsEnabled || !hasActiveCompany || selectedPeriod.group_interest_reactivation_available !== 0}
                            icon="icon-download-light-outline"
                            >
                            Generate full return
                        </Button>
                    </div>
                    <div className='row space-between'>
                        <p className="col-8">Selecting this option generates abbreviated return templates, in addition to standard reports, detailed excel outputs and visualisation inputs. This option is only available if there is an active reporting company, and if there were no disallowances in the period.</p>
                        <Button 
                            className="col-4"
                            enabledWhenFinalised
                            onClick={async () => {
                                downloadJson();
                                excelDataDownload();
                                donwloadAbbreviatedReturn();

                                const newStatus = { ...returnStatus, type: validReturnTypes.abbreviated };
                                setReturnStatus(newStatus);
                                await save(newStatus);
                            }}
                            disabled={!returnsEnabled || !hasActiveCompany || selectedPeriod.group_interest_reactivation_available !== 0 || selectedPeriod.group_disallowance !== 0}
                            icon="icon-download-light-outline"
                            >
                            Generate abbreviated return
                        </Button>
                    </div>
                    <div className='row space-between'>
                        <p className="col-8">Selecting this option generates standard reports, detailed excel outputs and visualisation inputs. This option is only available if all companies are non-consenting, and none of the elections below have been made. This is the only option available if there is no active reporting company in the period.</p>
                        <Button
                            className="col-4" 
                            enabledWhenFinalised
                            onClick={() => {
                                setReturnStatus({ ...returnStatus, isFinalised: true, withoutReportingCompany: true }) 
                                downloadJson();
                                excelDataDownload();
                            }}
                            disabled={!returnsEnabled || shouldDisableReturnWithoutReportingCompany || hasActiveCompany}
                            icon="icon-download-light-outline"
                            >
                            Finalise calculations without reporting company
                        </Button>
                    </div>
                    
                    { returnStatus?.isRevised && <>
                        <label className="col-sm-2 col-form-label align-self-center">Revised return notes:</label>
                        <Input value={returnStatus?.revisedNotes?.[returnStatus?.revisedNotes?.length-1]} onChange={(value) => {
                            setReturnStatus({
                                revisedNotes: returnStatus.revisedNotes.splice(-1,1, value),
                                ...returnStatus, 
                            })
                        }}
                        />
                    </>}

                </Panel>
                
                <Panel className='mt-2'>
                    <Accordion>
                        <AccordionItem itemKey='1' title={'Elections which require an active reporting company'}>
                            <p>The following elections cannot be made in the return if there is no active reporting company, however any irrevocable election made in a previous CIR period should remain active as they cannot be revoked:</p>
                            <Table originalData={electionsTableData} hasTitle disableDefaultSort skipInitialSort >
                            <Column field='election'>Group Elections</Column>
                            <Column field='statReference'>Statutory Reference </Column>
                            <Column field='duration'>Duration</Column>
                            <Column field='status' renderCell={(row, field)=> row[field] === true ? "Active": "Inactive" }>Status</Column>
                            <Column field='dateMade' renderCell={(row, field) => row[field] ? dayjs(row[field])?.year() : '' }>Year in which election was made (if provided)</Column>
                            </Table>
                        </AccordionItem>
                    </Accordion>
                </Panel>

                <Panel className='mt-2' hidden={returnStatus.withoutReportingCompany}>
                    <Input className='col-4' value={returnStatus?.type} readonly />
                    <div className='d-flex flex-row justify-content'>
                        <Checkbox enabledWhenFinalised disabled={!isReturnTypeValid || !isRevisionValid} checked={shouldSubmit || returnStatus.isSubmitted} onChange={(value) => { setShouldSubmit(value); }}/>
                        <p>I confirm the return has been submitted</p>
                    </div>

                    <Tooltip content={"TODO late submission, enquiries, and how these impact deadlines"}>
                        <div>
                            <label className="col-sm-2 col-form-label align-self-center">Date of submission:</label>
                            <CalendarPicker
                                value={processDateJS(returnStatus?.submittedDate)}
                                minDate={processDateJS(selectedPeriod?.period_end)}
                                enabledWhenFinalised
                                onChange={(date) => {
                                    setReturnStatus({...returnStatus, submittedDate: processDateJS(date)?.toString()})
                                }}
                            />
                        </div>
                    </Tooltip>
                </Panel>
            </div>
            <div className='container-fluid mt-4'>
                <div className='d-flex justify-content-between align-items-center'> 
                    <div>
                        <BackButton disabled={isPosting}/>
                    </div>
                    <div className='d-flex gap-2'>
                        <Button hiddenInReadonly loading={isPosting} onClick={() => save(returnStatus)}>Save</Button>
                        <NextButton preNavigation={() => save(returnStatus)} loading={isPosting} nextPage="/app/grouppoa">Return to Group POA</NextButton>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Outputs;