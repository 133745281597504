import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { processDateJS } from '../utils/dateProcessor';
dayjs.extend(utc)

export const LOCAL_KEYS = {
  groupJson: "json",
  selectedPeriodIndex:"poaIndex",
  cachedLocation: "cachedLocation",
  userReadonly: "userReadonly",
  user: "user",
  lastRequest: "lastRequest",
  timerID: "timerID",
}

export const getGroupData = () => {
  return JSON.parse(localStorage.getItem( LOCAL_KEYS.groupJson ));
}

export const setGroupData = (json) => {
  localStorage.setItem( LOCAL_KEYS.groupJson, JSON.stringify(json));
}

export const setSelectedPeriodData = (properties, merge=false) => {
  const periodIndex = getPoaIndex();
  
  if ( periodIndex === -1 ){
    console.error( "Error setting selected period data, no selected period" );
    return;
  }

  const tmpData = { ...getGroupData() };
  const tmpPeriod = { ...( merge ? tmpData.periods_of_account[ periodIndex ] : {}), ...properties };
  tmpData.periods_of_account[ periodIndex ] = tmpPeriod;
  setGroupData( tmpData );
}

export const getPeriodByIndex = (index) => {
  const groupData = getGroupData();

  if ( ! groupData ){
    console.error( "Error getting period: no group data");
    return null;
  }

  if ( (index ?? null) === null || index < 0 || index >= groupData?.periods_of_account?.length ){
    console.error( "Error getting period: index out of bounds" );
    return null;
  }

  return groupData.periods_of_account[ index ];
}

export const setPoaIndex = ( poaIndexValue ) => {
  localStorage.setItem( LOCAL_KEYS.selectedPeriodIndex, JSON.stringify( poaIndexValue ) );
}

export const getPoaIndex = () => {
  return JSON.parse( localStorage.getItem( LOCAL_KEYS.selectedPeriodIndex ) ) ?? -1;
}

export const getSelectedPeriod = () => {
  return getPeriodByIndex( getPoaIndex() )
}

export const getFormattedSelectedPeriod = () => {
  const selectedPeriod = getSelectedPeriod();
  if (!selectedPeriod) {
    return 'No period selected';
  }

  if (!selectedPeriod?.period_start || !selectedPeriod?.period_end) {
    return 'Selected Period Start or End dates are missing';
  }

  const start = selectedPeriod?.period_start;
  const end = selectedPeriod?.period_end;
  return `${processDateJS(start)?.format('DD/MM/YYYY')} - ${processDateJS(end)?.format('DD/MM/YYYY')}`
}

/**
  * 
  * @returns the group name
  */
export const getGroupName = () => {
  return getGroupData()?.group_name ?? '';
}

/**
  * Returns all the information relating to the period of accounts
  * @returns 
  */
export const getPeriodOfAccounts = () => {
  return getGroupData()?.poa ?? {}
}

/**
  * A helper function to get the years for which we have PoA data
  */
export const getPoAYears = () => {
  return Object.keys(getPeriodOfAccounts())?.map(poa => poa?.year) ?? [];
}

export const getPOAEndDates = () => {
  const data = getGroupData();
  const POAEndDates = data?.periods_of_account?.map(poa => {
    return processDateJS(poa?.period_end)?.format('DD/MM/YYYY');
  })
  return POAEndDates
}

export const getFirstPOAStartDate = () => {
  return getGroupData()?.model_first_period_start || undefined;
}

export const getLastNavigatedLocation = () => {
  return localStorage.getItem( LOCAL_KEYS.cachedLocation ) || "/";
}

export const setLastNavigatedLocation = ( newPage ) => {
  return localStorage.setItem( LOCAL_KEYS.cachedLocation, newPage?.toLocaleLowerCase() ?? "/" );
}

export const getPeriodIsReadonly = (period) => {
  if (period === null || period === undefined){
    return null;
  }

  return period?.return_status?.isFinalised;
}

export const getCurrentPeriodIsFinalized = () => {
  return getPeriodIsReadonly( getSelectedPeriod() );
}

export const getUserIsReadonly = () => {
  return JSON.parse(localStorage.getItem( LOCAL_KEYS.userReadonly )) ?? false;
}


const localJson = JSON.parse(localStorage.getItem( LOCAL_KEYS.groupJson ));
if (localJson) {
  if(localJson?.group_name) {
    toast.success(`Found cached group: ${localJson?.group_name}`);
  } else {
    toast('No cached group', {icon:"⚠️"});
  }

  const selected_period_index = JSON.parse( sessionStorage.getItem( LOCAL_KEYS.selectedPeriodIndex ) );
  if ( selected_period_index ){
    setPoaIndex( selected_period_index );
  }
} else {
  setLastNavigatedLocation( "/" )
  setPoaIndex( -1 );
}