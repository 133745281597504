import { Panel, Select, Button, Loading } from '@appkit4/react-components';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from "react";
import toast from 'react-hot-toast';
import { AuthContext } from '../../services/AuthProvider';
import { setGroupData } from '../../services/GroupContext';
import sqlService from '../../services/sqldatabase/sqldatabase.service';
import ReadonlyModal from '../ReadonlyModal';
import banner from "../../assets/banner.png";
import RequestAccessModal from '../../components/RequestAccessModal';
import './home.scss';
import AdminPanel from './components/AdminPanel';

const Welcome = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [groupName, setGroupName] = useState('')
  const [requestGroup, setRequestGroup] = useState('')
  const [userGroupList, setUserGroupList] = useState([])
  const [otherGroupList, setOtherGroupList] = useState([])
  const [fisrtLoad, setFirstLoad] = useState(true)
  const [requestAccessModal, setRequestAccessModal] = useState(false)
  const [readonlyModal, setReadonlyModal] = useState({visible: false, userEmail: ''})
  const navigate = useNavigate();
  const navToNew = () => navigate('/app/new');
  const auth = useContext(AuthContext);

  const [isReadOnlyUser, setIsReadOnlyUser] = useState(auth?.isReadOnlyUser());

  const [adminPanelVisible, setAdminPanelVisible] = useState(false);
  const isAdmin = auth.isAdmin();

  const fetchGroups = async () => {
    fisrtLoad&&setIsLoading(true)
    setFirstLoad(false)
    try {
      // TODO: (User Management) Only load groups that the user has access to 
      const response = await sqlService?.getGroupList()
      if (response?.status === 200) {
        // set group lists that user does / doesn't have access to
        const allGroups = response?.data
        const userScope = auth?.getScope()
        let userGroups = [];
        userScope?.forEach((scope) => {
          console.log(scope)
          if (scope?.group === '*') {
            console.log('admin true')
            userGroups = allGroups;
          }
          else {
            userGroups?.push(scope.group);
          }
        })
        const otherGroups = allGroups?.filter((group) => !userGroups?.includes(group))?.sort((a, b) => a?.localeCompare(b));
        userGroups = userGroups?.sort((a, b) => a?.localeCompare(b));
        setUserGroupList(userGroups);
        setOtherGroupList(otherGroups);
        setIsLoading(false);
      }
      else {
        toast.error(`Failed to download group list: ${response?.err}`, {
          duration: 30 * 1000 // 30s
        })
      }
    } catch (error) {
      console.log(error);
      toast.error(`Failed to download group list: ${error?.message}`, {
        duration: 30 * 1000 // 30s
      })
    }
  }
  useEffect(() => {
    fetchGroups();
  }, []);
  
  useEffect(() => {
    setIsReadOnlyUser(auth?.isReadOnlyUser(groupName));
  }, [groupName])

  // submit function run on opening a group
  const fetchGroupData = (readOnly = false) => {
    setIsLoading(true)
    const promise = sqlService?.getGroupData(groupName, readOnly)
    toast.promise(promise, {
      loading: 'Saving...',
      success: (response) => {
        const data = response?.data;
        if (response?.status === 200 && data && data?.hasOwnProperty('group_data') && data?.hasOwnProperty('readonly')) {
          if (readOnly) {
            auth?.setReadonly(true)
          }
          if (data?.readonly?.value) {
            setReadonlyModal({visible: true, userEmail: data?.readonly?.user, group: data?.group_data});
            setIsLoading(false);
            return `Loaded group: ${groupName}`
          }
            setGroupData(data?.group_data)
            setIsLoading(false);
            navigate('/app/groupConfig');
            return `Loaded group: ${groupName}`
        } else {
          toast.error(`Failed to get group:  ${response?.err}`)
          setIsLoading(false);
        }
      },
      error: (error) => {
        setIsLoading(false);
        return `Failed to get group: ${error};`
      }
    })
  }

  if (isLoading)
    return (
      <Loading
        loadingType="linear"
        indeterminate={true}
        compact={false}
        className="page-loader"
      />
    );
  if (readonlyModal?.visible)
    return (
      <ReadonlyModal
        visible={readonlyModal?.visible}
        closeModal={() => setReadonlyModal({visible: false, userEmail: ''})}
        userEmail={readonlyModal?.userEmail}
        group={readonlyModal?.group}
      />
    )
  return (
    <div className="ap-container">
            <div className="row my-2" >
              <div className="col-6 test">
                <h1 className="title m-3">
                  Hello {auth?.getFirstName()}. <br></br>Welcome to Corporate Interest Restriction Analyser!
                </h1>
                <p style={{ color: "white" }} className="m-3">
                Select or request access to an existing group from the drop down menus below, or create a new group
                </p>
              </div>
              <div className="col-6 test2">
                <img src={banner} alt="banner" className="banner" />
              </div>
            </div>
      <Panel className='mt-4 mb-4 custom-padding-top'>
        <p>The “select an existing group” dropdown menu allows the user to select any group for which they have been given access. Once the relevant group is selected, the user can select “Edit group” to edit information in that group, or “Open read-only” to view, but not edit, information for that group.</p>
        <p>The “Request access to an existing group” dropdown menu allows the user to select any group for which they wish to be granted access. Once the relevant group is selected, the user can select “Request access”.</p>
        <p>Where a group does not currently exist, the user can create a new group by clicking on the “Create new group” button, which will take the user to the Group setup page.</p>
      </Panel>
      <Panel className='custom-padding-top'>
        <div className='row'>
          <p className='col-3'>Select an existing group:</p>
          <Select className='col' searchable data={userGroupList} onClick={()=>{fetchGroups()}} onSelect={(val) => setGroupName(val)}></Select>
          <Button className='col-2 m-1' searchable disabled={groupName?.length === 0 || isReadOnlyUser} onClick={() => fetchGroupData(false)}>Edit group</Button>
          <Button className='col-2 m-1' searchable disabled={groupName?.length === 0} onClick={() => fetchGroupData(true)}>Open read-only</Button>
        </div>
        <div className='row'>
          <p className='col-3'>Request access to an existing group:</p>
          <Select className='col' searchable data={otherGroupList} onClick={()=>{fetchGroups()}} onSelect={(val) => setRequestGroup(val)} ></Select>
          <Button className='col-4' onClick={() =>{
              setRequestAccessModal(true)
            }
             } disabled={requestGroup?.length === 0}>Request access</Button>
        </div>
        <RequestAccessModal {...{requestAccessModal,setRequestAccessModal,requestGroup}} />
        <div className='flex-center'>
          <div><Button onClick={navToNew}>Create new group</Button></div>
          {isAdmin && <div><Button onClick={() => setAdminPanelVisible(true)}>Admin Panel</Button></div>}
        </div>

        <AdminPanel
          visible={adminPanelVisible}
          setVisible={setAdminPanelVisible}
          userGroupList={userGroupList}
          fetchGroups={fetchGroups}
        >
        </AdminPanel>
      </Panel>
    </div>
  );
};

export default Welcome;