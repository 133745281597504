import { processDateJS } from "../../utils/dateProcessor";

const bfdToBfdadjMap = [
    {bfd: 'bfd_non_trading_loan_relationships', bfdadj: 'bfdadj_non_trading_loan_relationships'},
    {bfd: 'bfd_non_trading_derivatives', bfdadj: 'bfdadj_non_trading_derivatives'},
    {bfd: 'bfd_trading_loan_relationships', bfdadj: 'bfdadj_trading_loan_relationships'},
    {bfd: 'bfd_trading_derivatives', bfdadj: 'bfdadj_trading_derivatives'},
    {bfd: 'bfd_other_financing', bfdadj: 'bfdadj_other_financing'},
    {bfd: 'bfd_subtotal', bfdadj: 'bfdadj_subtotal'},
]

export function copyBFDValuesToAdjustments (adjusted_cap) {
    bfdToBfdadjMap.forEach(pair => {
        if (adjusted_cap.hasOwnProperty(pair.bfd)) {
            adjusted_cap[pair.bfdadj] = - adjusted_cap[pair.bfd];
        }
    });
}

export function adjustedCapCustomCondition (adj_cap, savedData, period_start) {
    const company = savedData.companies.find(c => c.company_ID === adj_cap.company_ID);
    return (
        (
            adj_cap.trade_uncommercial_or_non_statutory ||
            adj_cap.cta10_change_in_ownership_rules ||
            adj_cap.Cta10_change_in_ownership_rules_acquisition
        ) &&
        (
            processDateJS(adj_cap.inclusion_period_start)?.isSame(processDateJS(period_start)) ||
            processDateJS(adj_cap.inclusion_period_start)?.isSame(processDateJS(company.date_join)) ||
            processDateJS(adj_cap.start_date)?.isBefore(processDateJS(savedData.model_first_period_start))
        )
    );
}
