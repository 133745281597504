import { useContext } from "react";
import { AuthContext } from "../services/AuthProvider";
import { Button } from "@appkit4/react-components";
import { LOCAL_KEYS } from "../services/GroupContext";

const Logout = () => {
  const auth = useContext(AuthContext);
  auth.logout();
  clearInterval(localStorage.getItem(LOCAL_KEYS.timerID));
  return (
    <div className="ap-container">
        <h1>
            You have been logged out!
        </h1>
        <Button onClick={()=>{window.location.assign("/")}}>
          Log back in
        </Button>
    </div>
  );
};

export default Logout;