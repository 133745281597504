import { Panel, Tooltip, Loading } from '@appkit4/react-components'; 
import { Input, CalendarPicker, Switch, InputNumber, Select, Button } from "../../components/ReadonlyAwareInputs";
import { useContext, useEffect, useState } from 'react';
import { BackButton, NextButton, ProgressNav } from '../../components/ProgressNav';
import { getGroupData, setGroupData, getGroupName, getFirstPOAStartDate } from '../../services/GroupContext';
import toast from 'react-hot-toast';
import countries from '../../utils/Countries';
import sqlService from '../../services/sqldatabase/sqldatabase.service';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AuthContext } from '../../services/AuthProvider';
import ReadonlyBanner from '../ReadonlyBanner';
import { getDefaultPeriodEnd, getMaxPeriodEnd, getOriginatingPoaMinStartDateJS, processDate, processDateJS } from '../../utils/dateProcessor';
import "./groupConfig.scss";
import CustomTableHeader from '../../components/tableHeader/tableHeader';
import CustomTooltipIcon from '../../components/customTooltipIcon/customTooltipIcon';
dayjs.extend(utc)

const LEI_REGEX = /^\w{20}$/;

const addInterestAllowanceToolTipTitle = "Click to add new Unused Interest Allowance";
const positiveNumErrorMessage = 'Number must be positive';

const GroupConfig = () => {
    const [isLoading, setIsLoading] = useState(true)
    const auth = useContext(AuthContext)
    const [localData, setLocalData] = useState('');

    const [groupName, setGroupName] = useState('');
    const [companies, setCompanies] = useState('');
    const [ultParentName, setUltParentName] = useState('');
    const [ultParentJurisdiction, setUltParentJurisdiction] = useState('');
    const [ultParentLEI, setUltParentLEI] = useState('');
    const [firstPoAStart, setFirstPoAStart] = useState();
    const [firstPoAEnd, setFirstPoAEnd] = useState();

    // Siva: Long variables but I would rather that than non-descripitive names
    const [disregard_regulations_to_derivative_contract, set_disregard_regulations_to_derivative_contract] = useState(false);
    const [disregard_regulations_election_year, set_disregard_regulations_election_year] = useState(undefined);
    const [disregard_regulation_comment, set_disregard_regulation_comment] = useState();

    const [group_ebitda_gains, set_group_ebitda_gains] = useState(false);
    const [group_ebitda_gains_year, set_group_ebitda_gains_year] = useState(undefined);
    const [group_ebitda_gains_comment, set_group_ebitda_gains_comment] = useState();

    const [interest_allowance_alt_calc, set_interest_allowance_alt_calc] = useState(false);
    const [interest_allowance_alt_calc_year, set_interest_allowance_alt_calc_year] = useState(undefined);
    const [interest_allowance_alt_calc_comment, set_interest_allowance_alt_calc_comment] = useState();

    const [interest_allowance_consolidated_partnership, set_interest_allowance_consolidated_partnership] = useState(false);
    const [interest_allowance_consolidated_partnership_year, set_interest_allowance_consolidated_partnership_year] = useState(undefined);
    const [interest_allowance_consolidated_partnership_comment, set_interest_allowance_consolidated_partnership_comment] = useState();

    const [CPTable, setCPTable] = useState([])
    const [duplicateCPErrors, setDuplicateCPErrors] = useState([]);

    const [excess_debt_cap_currency, set_excess_debt_cap_currency] = useState('');
    const [excess_debt_cap_before_model, set_excess_debt_cap_before_model] = useState(0);

    const [isError, setIsError] = useState(false);
    
    const [isFirstPoaEndDateValid, setIsFirstPoaEndDateValid] = useState(true);

    const [isUnusedInterestStartDatesValid, setIsUnusedInterestStartDatesValid] = useState();
    const [isUnusedInterestEndDatesValid, setIsUnusedInterestEndDatesValid] = useState();
    const [isUnusedInterestRowValid, setIsUnusedInterestRowValid] = useState();

    const [unusedInterest, setUnusedInterest] = useState();

    const [ultParentSearch, setUltParentSearch] = useState('');

    const [calendarPickerWidth, setCalendarPickerWidth] = useState(150);

    const [isSaveDisabled, setIsSaveDisabled] = useState(false);

    const [electionsDatesValid, setElectionsDatesValid] = useState({
        date1: true,
        date2: true,
        date3: true,
        date4: true
    });

    const isReadOnly = auth?.isReadOnlyUser(getGroupName());

    const selectedGroupData = getGroupData();

    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window?.innerWidth;
            if(windowWidth <= 1160) {
                setCalendarPickerWidth(100);
            } else if (windowWidth <= 1200) {
                setCalendarPickerWidth(120);
            } else if (windowWidth <= 1250) {
                setCalendarPickerWidth(130);
            } else if(windowWidth <= 1520) {
                setCalendarPickerWidth(150);
            } else {
                setCalendarPickerWidth(200);
            }
        };

        window?.addEventListener('resize', handleResize);

        return () => {
            window?.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setIsUnusedInterestRowValid(isUnusedInterestStartDatesValid?.map((isValid, index) => isValid && isUnusedInterestEndDatesValid[index]));
    }, [isUnusedInterestStartDatesValid, isUnusedInterestEndDatesValid]);

    useEffect(() => {
        setDuplicateCPErrors(CPTable?.map(
            (outerV, outerI) => CPTable?.some(
                (innerV, innerI) => outerV === '' || (outerV === innerV && outerI !== innerI)
            )
        ));
    }, [CPTable])

    useEffect(() => {
        let isDisabled = false;
        if (
            isError ||
            isUnusedInterestEndDatesValid?.includes(false) ||
            isUnusedInterestStartDatesValid?.includes(false) ||
            !isFirstPoaEndDateValid ||
            duplicateCPErrors?.some(value => value === true) ||
            !Object.values(electionsDatesValid)?.every(value => value)
        ) {
            isDisabled = true;
        }

        setIsSaveDisabled(isDisabled);
    }, [
        isError,
        isUnusedInterestStartDatesValid,
        isUnusedInterestEndDatesValid,
        isFirstPoaEndDateValid,
        electionsDatesValid?.date1,
        electionsDatesValid?.date2,
        electionsDatesValid?.date3,
        electionsDatesValid?.date4,
        electionsDatesValid,
        duplicateCPErrors
    ]);

    const sortByStartDate = (inputList) => {
        return inputList?.sort((a, b) => {
            return processDateJS(a?.start_date)?.diff(processDateJS(b?.start_date), 'day');
        });
    };

    useEffect(() => {
        (async () => {
            const savedData = getGroupData();  // Having the getGroupData() call in line 28 meant it didn't load for some reason? 
            if (!savedData) {
                return
            }

            setGroupName(savedData?.group_name);
            setCompanies(savedData?.companies || {})
            setUltParentJurisdiction(savedData?.ult_parent_incorporation_juristriction || '')
            setUltParentLEI(savedData?.ult_parent_lei || '');
            setFirstPoAStart(new Date(savedData?.model_first_period_start) || undefined)
            setFirstPoAEnd(new Date(savedData?.model_first_period_end) || undefined);

            var companies = savedData?.companies;
            const groupID = savedData?.group_id;

            // If companies is still null, maybe it errored out above?
            if (!companies) {
                companies = []
            }

            setCompanies(companies);
            const ultParent = companies?.find((comp) => comp?.company_ID === savedData?.ult_parent_ID)
            setUltParentName(ultParent ? ultParent?.company_name : '')

            let unusedInterest = savedData?.unused_interest;
            if (!unusedInterest || unusedInterest?.length === 0) {
                unusedInterest = []
            } else {
                unusedInterest = unusedInterest?.map(r => {
                    return {
                        ...r,
                        start_date: new Date(r?.start_date),
                        end_date: new Date(r?.end_date)
                    }
                })
            }

            unusedInterest = sortByStartDate(unusedInterest);

            const get_date_or_undefined = (saved_data, key) => {
                const value = saved_data[key];
                return value ? new Date(value) : undefined;
            }

            set_disregard_regulations_to_derivative_contract(savedData?.disregard_regulations_to_derivative_contracts)
            set_disregard_regulations_election_year(get_date_or_undefined(savedData, 'disregard_regulations_election_year'))
            set_disregard_regulation_comment(savedData?.disregard_regulation_comment)

            set_group_ebitda_gains(savedData?.group_ebitda_gains)
            set_group_ebitda_gains_year(get_date_or_undefined(savedData, 'group_ebitda_gains_year'))
            set_group_ebitda_gains_comment(savedData?.group_ebitda_gains_comment)

            set_interest_allowance_alt_calc(savedData?.interest_allowance_alt_calc)
            set_interest_allowance_alt_calc_year(get_date_or_undefined(savedData, 'interest_allowance_alt_calc_year'))
            set_interest_allowance_alt_calc_comment(savedData?.interest_allowance_alt_calc_comment)

            set_interest_allowance_consolidated_partnership(savedData?.interest_allowance_consolidated_partnership)
            set_interest_allowance_consolidated_partnership_year(get_date_or_undefined(savedData, 'interest_allowance_consolidated_partnership_year'))
            set_interest_allowance_consolidated_partnership_comment(savedData?.interest_allowance_consolidated_partnership_comment)
            if (savedData?.hasOwnProperty('consolidated_partnerships') && savedData?.consolidated_partnerships?.length > 0) {
                setCPTable(savedData?.consolidated_partnerships)
            }

            set_excess_debt_cap_currency('GBP')//(savedData.excess_debt_cap_currency)
            set_excess_debt_cap_before_model(savedData?.excess_debt_capt_before_model != null ? savedData?.excess_debt_capt_before_model : 0);
            setUnusedInterest(unusedInterest);
            const validatorList = unusedInterest?.map(i => true);
            setIsUnusedInterestStartDatesValid(validatorList);
            setIsUnusedInterestEndDatesValid(validatorList);

            setLocalData(savedData);
            //setGroupData(savedData)
            setIsLoading(false);
        })();
    }, [])

    const setUltimateParentName = (value) => {
        resetUltimateParent()

        const companySection = companies;
        // find or generate an entry for the company
        const foundCompany = companySection?.find(company => company?.company_name === value);
        if (!foundCompany) {
            companySection?.push({ company_name: value, company_ID: Math.random()?.toString(36)?.substring(2, 22) })
        }
        setCompanies(companySection);
        setUltParentName(value);
    }

    const resetUltimateParent = () => {
        setUltParentName('')
        setUltParentJurisdiction('')
        setUltParentLEI('');
    }



    const save = () => {
        if (isError) {
            throw 'There is an error. Please fix before proceeding...';
        }

        const ultParent = companies?.find((comp) => comp?.company_name === ultParentName);
        const ultParentID = ultParent ? ultParent?.company_ID : '';
        const newJson = {
            ...getGroupData(),
            ult_parent_ID: ultParentID,
            ult_parent_incorporation_juristriction: ultParentJurisdiction,
            ult_parent_lei: ultParentLEI,
            model_first_period_start: firstPoAStart,
            model_first_period_end: firstPoAEnd,
            excess_debt_cap_currency: excess_debt_cap_currency,
            excess_debt_capt_before_model: excess_debt_cap_before_model,
            companies: companies,
            disregard_regulations_to_derivative_contracts: disregard_regulations_to_derivative_contract,
            disregard_regulations_election_year: disregard_regulations_election_year,
            disregard_regulation_comment: disregard_regulation_comment,
            group_ebitda_gains: group_ebitda_gains,
            group_ebitda_gains_year: group_ebitda_gains_year,
            group_ebitda_gains_comment: group_ebitda_gains_comment,
            interest_allowance_alt_calc: interest_allowance_alt_calc,
            interest_allowance_alt_calc_year: interest_allowance_alt_calc_year,
            interest_allowance_alt_calc_comment: interest_allowance_alt_calc_comment,
            interest_allowance_consolidated_partnership: interest_allowance_consolidated_partnership,
            interest_allowance_consolidated_partnership_year: interest_allowance_consolidated_partnership_year,
            interest_allowance_consolidated_partnership_comment: interest_allowance_consolidated_partnership_comment,
            consolidated_partnerships: CPTable,
            unused_interest: unusedInterest
        };

        setGroupData(newJson);
        
        if (auth.isReadOnlyUser(getGroupName())) {
            return;
        }
        const result = sqlService?.postResponse(newJson);
        return toast.promise(result, {
            loading: 'Saving group configuration...',
                success: 'Saved group configuration',
                error: 'Failed to save group configuration!',
        });
    };



    // A helper method to ensure that the data that is currently contained in unused interest is valid
    const isUnusuedInterestValid = () => {
        if (!unusedInterest || unusedInterest?.length === 0) {
            return true;
        }

        // Check the individual rows are valid 
        for (const index in unusedInterest) {
            const row = unusedInterest[index];

            const startDate = processDateJS(row?.start_date);
            if (!startDate) {
                return false;
            }

            const endDate = processDateJS(row?.end_date);
            if (!endDate) {
                return false;
            }

            if (endDate?.isSame(startDate) || endDate?.isBefore(startDate)) {
                return false;
            }

            if (endDate?.isAfter(getMaxPeriodEnd(startDate))) {
                return false;
            }

            if (!is_reactivations_valid(row)) {
                return false;
            }

            if (!is_reactivations_prior_model_valid(row)) {
                return false;
            }
        }

        if (is_unusued_interest_dates_valid()) {
            return false;
        }

        return true;
    }

    const is_reactivations_valid = (row) => {
        if (!row) {
            return true;
        }

        const max_reactivations = Math.max(row?.interest_allowance - row?.interest_expense, 0);
        if (row?.reactivations > max_reactivations) {
            return false;
        }

        return true;
    }

    const is_reactivations_prior_model_valid = (row) => {
        if (!row) {
            return true;
        }

        const max_reactivations_prior_model = Math.max(row?.interest_allowance - row?.interest_expense - row?.reactivations, 0);
        if (row?.reactivations_prior_model_use > max_reactivations_prior_model) {
            return false;
        }

        return true;
    }


    const is_unusued_interest_dates_valid = () => {
        const dates = unusedInterest?.map(obj => ({
            start_date: obj?.start_date ? processDateJS(obj?.start_date) : undefined,
            end_date: obj?.end_date ? processDateJS(obj?.end_date) : undefined
        }));

        for (let i = 0; i < dates?.length; i++) {
            const current_row = dates[i];

            for (let j = i + 1; j < dates?.length; j++) {
                const next_row = dates[j];

                if (current_row?.start_date && next_row?.start_date &&
                    current_row?.start_date?.isBefore(next_row?.end_date) &&
                    next_row?.start_date?.isBefore(current_row?.end_date)) {
                    return true;
                }
            }
        }
        return false;
    }


    // update error values when fields with validation change
    useEffect(() => {
        var errorFlag = false;
        if (!ultParentName) {
            errorFlag = true;
        } else if (ultParentLEI && !ultParentLEI?.match(LEI_REGEX)) {
            errorFlag = true;
        } else if (ultParentJurisdiction?.length === 0) {
            errorFlag = true;
        }
        else if (!isUnusuedInterestValid()) {
            errorFlag = true;
        } else if (!firstPoAStart) {
            errorFlag = true;
        } else if (!firstPoAEnd) {
            errorFlag = true;
        } else if (excess_debt_cap_before_model) {
            if (!Number.isInteger(excess_debt_cap_before_model)) {
                errorFlag = true;
            }  
        }
        setIsError(errorFlag);
    }, [ultParentLEI, ultParentJurisdiction, unusedInterest, firstPoAStart, firstPoAEnd, excess_debt_cap_before_model, excess_debt_cap_currency])

    const tableChange = (index, value, name) => {
        if (name === 'start_date' || name === 'end_date') {
            value = processDate(value);
        }
        const rowsInput = [...unusedInterest];
        rowsInput[index][name] = value;
        rowsInput[index]['unused'] = Math.max(rowsInput[index]['interest_allowance'] - rowsInput[index]['interest_expense'] - rowsInput[index]['reactivations'], 0);
        rowsInput[index]['unused_available'] = rowsInput[index]['unused'] - rowsInput[index]['reactivations_prior_model_use']

        if (name === 'start_date' && rowsInput[index]['start_date']) {
            rowsInput[index]['end_date'] = getDefaultPeriodEnd(rowsInput[index]['start_date']);
        }
        const sortedList = sortByStartDate(rowsInput);
        setUnusedInterest(sortedList);
    }

    const addTableRows = () => {
        let new_period_start = undefined;
        let new_period_end = undefined
        if (unusedInterest?.length > 0) {
            const previousData = sortByStartDate(unusedInterest)
            const lastPoA = previousData[previousData?.length - 1];
            new_period_start = processDateJS(lastPoA?.end_date)?.add(1, 'day');
            new_period_end = getDefaultPeriodEnd(new_period_start)?.toDate();
            new_period_start = processDate(new_period_start)
        }
        let validatorList = [...isUnusedInterestStartDatesValid, true];
        setIsUnusedInterestStartDatesValid(validatorList);
        
        const newUnusedInterestList = [...unusedInterest,
            {
                "start_date": new_period_start,
                "end_date": new_period_end,
                "interest_allowance": "",
                "interest_expense": "",
                "reactivations": "",
                "unused": "",
                "reactivations_prior_model_use": "",
                "unused_available": ""
            }]
            
        const isNewEndDateValid = processDateJS(new_period_end)?.isSameOrBefore(calculate_max_date_from_list(newUnusedInterestList?.length-1, newUnusedInterestList)) ?? true;
        validatorList = [...isUnusedInterestEndDatesValid, isNewEndDateValid];
        setIsUnusedInterestEndDatesValid(validatorList);

        setUnusedInterest(newUnusedInterestList);
    }

    const deleteTableRows = (e, index) => {
        const rows = [...unusedInterest];
        rows?.splice(index, 1);
        setUnusedInterest(rows);

        let validatorList = [...isUnusedInterestStartDatesValid];
        validatorList?.splice(index, 1);
        setIsUnusedInterestStartDatesValid(validatorList);

        validatorList = [...isUnusedInterestEndDatesValid];
        validatorList?.splice(index, 1);
        setIsUnusedInterestEndDatesValid(validatorList);
    }

    const calculate_min_date = (index) => {
        const row = unusedInterest[index];
        if (!row?.start_date) {
            return getOriginatingPoaMinStartDateJS();
        }
        const min_date = processDateJS(row?.start_date)?.add(1, 'day');
        return min_date?.toDate();
    }

    const calculate_max_date = (index) => {
        return calculate_max_date_from_list(index, unusedInterest);
    }
    
    const calculate_max_date_from_list = (index, list) => {
        const row = list[index];
        return dayjs.min(
            processDateJS(getFirstPOAStartDate()),
            getMaxPeriodEnd(row?.start_date),
            processDateJS(firstPoAStart)?.subtract(1, 'day'),
        ) ?? null;
    }

    // functions for handling consolidated partnership table
    const addCPTableRows = () => {
        setCPTable((prevState) => ([...prevState, '']));
    }

    const deleteCPTableRows = (e, index) => {
        const rows = [...CPTable];
        rows?.splice(index, 1);
        setCPTable(rows);
    }

    const CPtableChange = (index, value) => {
        const rowsInput = [...CPTable];
        rowsInput[index] = value;
        setCPTable(rowsInput);
    }

    if (isLoading) {
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            ></Loading>
        );
    }

    const getInputNumber = (data, index, key, max = undefined) => {
        return <InputNumber
            style={{ minWidth: calendarPickerWidth - 50 }}
            format=','
            className="col"
            value={data[key] || 0}
            errorNode={data[key] > max ? `${key} must be bigger than ${max}` : positiveNumErrorMessage}
            error={data[key] < 0 ?? false}
            min={0}
            max={max}
            onChange={(evnt) => { tableChange(index, evnt, key) }}
        />;
    }

    return (
        <div className="ap-container">
            {isReadOnly &&
                <ReadonlyBanner />
            }
            <Panel title='Initial Group Information' className='mt-4'>
                <div className='row'>
       
                    <p className='col-4 flex-align'>Group Name <span style={{ color: 'red' }}>*</span>
                        <CustomTooltipIcon size="big" tooltipText={<p>This is automatically populated based on the details provided when the group was initially set up on the CIR Analyser.</p>}/>
                    </p>

                    <Input
                        className='col'
                        title={"Group Name"}
                        hideTitleOnInput
                        value={localData['group_name']}
                        disabled={true}
                        required={true}
                    />
                </div>

                <div className='row'>
                    <p className='col-4 flex-align'>Name of Ultimate Parent <span style={{ color: 'red' }}>*</span>
                    <CustomTooltipIcon size="big" tooltipText={<>
                            <p>This is the Ultimate Parent Entity (“UPE”) of the CIR group. The UPE must be a “relevant entity” - either a company, or an entity for which the shares or other interests are listed on a recognised stock exchange and are sufficiently widely held.</p>
                            <p>The UPE must broadly be a standalone company or a member of a group as defined by International Accounting Standards that is not a consolidated subsidiary of another relevant entity which is itself not a consolidated subsidiary of a relevant entity.</p>
                            <p>Refer to s473 - s478 TIOPA 2010 for further details.</p>
                        </>}/>
                    </p>
                    <Select
                        className='col'
                        placeholder="Name of Ultimate Parent"
                        hideTitleOnInput
                        searchable
                        onSearch={(val) => setUltParentSearch(val)}
                        data={companies?.map(c => c?.company_name)}
                        onSelect={(value) => setUltimateParentName(value)}
                        onClear={resetUltimateParent}
                        value={ultParentName}
                        error={!ultParentName}
                        defaultValue={ultParentName}
                        required={true}
                        noResultFound={
                            <span>
                                {ultParentSearch?.length === 0 ?
                                    <><button>No companies found, please enter a value</button></>
                                    :
                                    <><button onClick={() => {
                                        setUltimateParentName(ultParentSearch)
                                    }}>Add new company: '{ultParentSearch}'</button></>
                                }
                            </span>
                        }
                    />
                </div>
                <div className='row'>
                    <p className='col-4 flex-align'>Jurisdiction of Incorporation of Ultimate Parent: <span style={{ color: 'red' }}>*</span>
                        <CustomTooltipIcon size="big" tooltipText={<p>This is the jurisdiction in which the Ultimate Parent for the Group is incorporated. The jursidiction can be selected using the drop down list or by entering the jurisdiction into the search box.</p>}/>
                    </p>
                    <Select className='col' searchable data={countries}
                        readonly
                        value={ultParentJurisdiction}
                        onSelect={(value) => setUltParentJurisdiction(value)}
                        onClear={() => setUltParentJurisdiction('')}
                        placeholder='Select Jurisdiction'
                        error={!ultParentJurisdiction}
                        hideTitleOnInput
                        required={true}
                    ></Select>
                </div>
                <div className='row'>
                    <p className='col-4 flex-align'>LEI of Ultimate Parent (if available):
                        <CustomTooltipIcon size="big" tooltipText={<p>Where the UPE of a CIR group has a Legal Entity Identification number, it is a requirement that this is included in the relevant CIR return. A link to a website that can be used to confirm whether or not an LEI number exists is included in the User Guide.</p>}/>
                    </p>
                    <Input className='col'
                        value={ultParentLEI}
                        onChange={(value) => setUltParentLEI(value)}

                        error={ultParentLEI && !ultParentLEI?.match(LEI_REGEX)}
                        placeholder={'LEI of Ultimate Parent '}
                        hideTitleOnInput

                    ></Input>
                </div>
                <div className='row'>
                    <p className='col-4 flex-align'>First Period of Account for which the Model is used (Start): <span style={{ color: 'red' }}>*</span>
                        <CustomTooltipIcon size="big" tooltipText={<>
                            <p>This is the first period of account for which the CIR Analyser is being used and will not necessarily be the first CIR period of the group.</p>
                            <p>The CIR period generally follows the period for which consolidated financial statements are drawn up for the group or the period for which consolidated financial statements are treated as having been drawn up. Refer to s479 - s489 TIOPA 2010 for further details.</p>
                            </>}/>
                    </p>
                    <CalendarPicker
                        className='col'
                        fieldTitle='First PoA Start'
                        hideTitleOnInput
                        value={firstPoAStart}
                        format='DD/MM/YYYY' placeholder='dd/mm/yyyy'
                        minDate={getOriginatingPoaMinStartDateJS()}
                        onChange={(value) => {
                            setFirstPoAStart(processDate(value));
                            setFirstPoAEnd(getDefaultPeriodEnd(value)?.toDate())
                        }}
                        required={true}
                    />
                </div>
                <div className='row'>
                    <p className='col-4 flex-align'>First Period of Account for which the Model is used (End): <span style={{ color: 'red' }}>*</span>
                        <CustomTooltipIcon size="big" tooltipText={<>
                                <p>This is the first period of account for which the CIR Analyser is being used and will not necessarily be the first CIR period of the group.</p>
                                <p>Under s487 TIOPA 2010 it is not possible for a CIR period of account to exceed 18 months in duration. Where this is the case, specific rules apply to ignore the actual financial statements and determine deemed financial statements for CIR purposes.</p>
                            </>}/>
                    </p>
                    <CalendarPicker
                        title="group_name"
                        className='col'
                        fieldTitle='First PoA End'
                        hideTitleOnInput
                        value={firstPoAEnd} format='DD/MM/YYYY' placeholder='dd/mm/yyyy'
                        onChange={(value, isValid) => {
                            setFirstPoAEnd(processDate(value));
                            setIsFirstPoaEndDateValid(isValid);
                        }}
                        minDate={firstPoAStart}
                        maxDate={getMaxPeriodEnd(firstPoAStart)}
                        required={true}
                    ></CalendarPicker>
                </div>
            </Panel>

            <Panel title='Irrevocable Elections' className='mt-4'>
                <p>The following elections are irrevocable for the Group once made.  Please select all elections that have been made by the Group in any period before the first period for which the model is used:</p>
                <div className='row'>
                    <p className='col-4 flex-align'>Apply the Disregard Regulations to pre-1 April 2020 derivative contracts
                        <CustomTooltipIcon size="big" tooltipText={<>
                                <p>This election could only be made before 1 April 2018 by a company which was a UK group company of a CIR group on 1 April 2017. To be effective, an election must have been made by every UK group company of the worldwide group on 1 April 2017.</p>
                                <p>The effect of the election is to treat the company as having made an election for Disregard Regulations 7, 8, and 9 to apply for derivative contracts entered into by the company before 1 April 2020 for the purposes of calculating Tax-Interest Expense/Income and Tax-EBITDA of the company or a relevant transferee company. The election does not apply to derivative contracts entered into after this date.</p>
                                <p>Refer to Paragraph 31, Schedule 5, Finance (No2) Act 2017 for further details.</p>
                            </>}/>
                    </p>

                    <div className='col-1 d-flex' style={{height:40}} >
                        <Switch  showIndicator style={{width:"fit-content"}} 
                            checked={disregard_regulations_to_derivative_contract}
                            onChange={(value) => {
                                if (!value) {
                                    set_disregard_regulations_election_year(undefined);
                                    set_disregard_regulation_comment('')
                                }
                                set_disregard_regulations_to_derivative_contract(value)
                            }}
                        />

                    </div>

                    <CalendarPicker
                        fieldTitle='Date of Election'
                        className='col-2'
                        fieldWidth={200}
                        maxDate={processDateJS(new Date(2018, 2, 31))}
                        format='DD/MM/YYYY'
                        placeholder='dd/mm/yyyy'
                        disabled={!disregard_regulations_to_derivative_contract}
                        value={disregard_regulations_election_year}
                        onChange={(value, isValid) => {
                            const date = processDate(value);
                            if(date) {
                                set_disregard_regulations_election_year(date);
                                setElectionsDatesValid(prevState => ({
                                    ...prevState,
                                    date1: isValid
                                }));
                            } else {
                                setElectionsDatesValid(prevState => ({
                                    ...prevState,
                                    date1: true
                                })); 
                            }
                        }}
 
                    />
                    <Input className='col' disabled={!disregard_regulations_to_derivative_contract} title='Comment' value={disregard_regulation_comment} onChange={(val) => set_disregard_regulation_comment(val)} />
                </div>

                <div className='row'>
                    <p className='col-4 flex-align'>Group EBITDA (chargeable gains) election
                        <CustomTooltipIcon size="big" tooltipText={<>
                            <p>This election can only be made where a reporting company has been appointed.</p>
                            <p>Where the election applies, the effect is broadly that, for the purposes of computing Group EBITDA for the group ratio, the recalculated profits amount under s419(1) in respect of disposals of relevant assets is calculated on a chargeable gains basis, rather than an accounting basis. The mechanics of the election is complicated - for example it is necessary to assume, inter alia, that SSE does not apply. Relevant losses generated can be carried forward to future periods.</p>
                            <p>Refer to s422 TIOPA 2010 and Paragraph 15, Schedule 7A, TIOPA 2010 for further details.</p>
                        </>}/>
                    </p>
                                        <div className='col-1 d-flex' style={{height:40}} >
                        <Switch className='col-1' showIndicator style={{width:"fit-content"}}
                            checked={group_ebitda_gains}
                            onChange={(value) => {
                                if (!value) {
                                    set_group_ebitda_gains_year(undefined);
                                    set_group_ebitda_gains_comment('')
                                }
                                set_group_ebitda_gains(value)
                            }}
                        />
                    </div>
                    <CalendarPicker
                        fieldTitle="Date of Election"
                        className='col-2'
                        fieldWidth={200}
                        format='DD/MM/YYYY'
                        disabled={!group_ebitda_gains}
                        value={group_ebitda_gains_year}
                        placeholder='dd/mm/yyyy'
                        maxDate={processDateJS(selectedGroupData?.model_first_period_end)}
                        onChange={(value, isValid) => {
                            const date = processDate(value);
                            if (date) {
                                set_group_ebitda_gains_year(date);
                                setElectionsDatesValid(prevState => ({
                                    ...prevState,
                                    date2: isValid
                                }));
                            } else {
                                setElectionsDatesValid(prevState => ({
                                    ...prevState,
                                    date2: true
                                }));
                            }
                        }}
                    />
                    <Input className='col' disabled={!group_ebitda_gains} title='Comment' value={group_ebitda_gains_comment} onChange={(val) => set_group_ebitda_gains_comment(val)} />
                </div>

                <div className='row'>
                    <p className='col-4 flex-align'>Interest allowance (alternative calculation) election
                        <CustomTooltipIcon size="big" tooltipText={<>
                            <p>This election can only be made where a reporting company has been appointed.</p>
                            <p>Where the election applies, the effect is that, for the purposes of computing ANGIE, QNGIE and Group EBITDA, adjustments are made to reflect capitalised interest, employer pension contributions, unpaid employees remuneration, employee share acquisitions and changes in accounting policy applying tax principles, rather than accounting principles. As the election impacts ANGIE, it can be relevant even where no Group Ratio election is made.</p>
                            <p>Refer to s423 - s426 TIOPA 2010 and Paragraph 16, Schedule 7A, TIOPA 2010 for further details.</p>
                        </>}/>
                    </p>
                    <div className='col-1 d-flex' style={{height:40}} >
                        <Switch className='col-1' showIndicator style={{width:"fit-content"}}
                            checked={interest_allowance_alt_calc}
                            onChange={(value) => {
                                if (!value) {
                                    set_interest_allowance_alt_calc_year(undefined);
                                    set_interest_allowance_alt_calc_comment('')
                                }
                                set_interest_allowance_alt_calc(value)
                            }}
                        />
                    </div>
                    <CalendarPicker
                        fieldTitle="Date of Election"
                        className='col-2'
                        fieldWidth={200}
                        format='DD/MM/YYYY'
                        disabled={!interest_allowance_alt_calc}
                        value={interest_allowance_alt_calc_year}
                        placeholder='dd/mm/yyyy'
                        maxDate={processDateJS(selectedGroupData?.model_first_period_end)}
                        onChange={(value, isValid) => {
                            const date = processDate(value);
                            if (date) {
                                set_interest_allowance_alt_calc_year(processDate(date))
                                setElectionsDatesValid(prevState => ({
                                    ...prevState,
                                    date3: isValid
                                }));
                            } else {
                                setElectionsDatesValid(prevState => ({
                                    ...prevState,
                                    date3: true
                                }));
                            }
                        }}
                    />
                    <Input className='col' disabled={!interest_allowance_alt_calc} title='Comment' value={interest_allowance_alt_calc_comment} onChange={(val) => set_interest_allowance_alt_calc_comment(val)} />
                </div>

                <div className='row'>
                    <p className='col-4 flex-align'>Interest allowance (consolidated partnership) election
                        <CustomTooltipIcon size="big" tooltipText={<>
                                <p>This election can only be made where a reporting company has been appointed.</p>
                                <p>The election must specify one or more consolidated partnerships of the worldwide group. It is possible to make subsequent elections specifying one or more additional consolidated partnerships.</p>
                                <p>Where the election applies, the effect is that, for the purposes of computing ANGIE, QNGIE and Group-EBITDA, adjustments are made such that the specified partnership is treated as if it were accounted for using the equity method.</p>
                                <p>Refer to s430 TIOPA 2010 and Paragraph 18, Schedule 7A, TIOPA 2010 for further details.</p>
                        </>}/>
                    </p>
                    <div className='col-1 d-flex' style={{height:40}} >
                        <Switch style={{width:"fit-content"}}
                            className='col-1'
                            showIndicator
                            checked={interest_allowance_consolidated_partnership}
                            onChange={(value) => {
                                if (!value) {
                                    set_interest_allowance_consolidated_partnership_year(undefined);
                                    set_interest_allowance_consolidated_partnership_comment('')
                                }
                                set_interest_allowance_consolidated_partnership(value)
                            }}
                        />
                    </div>
                    <CalendarPicker
                        fieldTitle="Date of Election"
                        className='col-2'
                        fieldWidth={200}
                        format='DD/MM/YYYY'
                        placeholder='dd/mm/yyyy'
                        disabled={!interest_allowance_consolidated_partnership}
                        value={interest_allowance_consolidated_partnership_year}
                        maxDate={processDateJS(selectedGroupData?.model_first_period_end)}
                        onChange={(value, isValid) => {
                            const date = processDate(value);
                            if (date) {
                                set_interest_allowance_consolidated_partnership_year(date)
                                setElectionsDatesValid(prevState => ({
                                    ...prevState,
                                    date4: isValid
                                }));
                            } else {
                                setElectionsDatesValid(prevState => ({
                                    ...prevState,
                                    date4: true
                                }));
                            }
                        }}
                    />
                    <Input
                        className='col'
                        disabled={!interest_allowance_consolidated_partnership}
                        title='Comment'
                        value={interest_allowance_consolidated_partnership_comment}
                        onChange={(val) => set_interest_allowance_consolidated_partnership_comment(val)}
                    />
                </div>
                {interest_allowance_consolidated_partnership &&
                    <div className=''>
                        <table className="table ap-px-spacing-4" style={{ width: '100%' }}>
                            <thead st>
                                <tr>
                                    { /* Need to */}

                                    <th className="col-6" key={1}>Consolidated partnerships</th>
                                    <th className="col-1"></th>
                                    <th className="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {CPTable?.map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td key={1}>
                                                <Input
                                                    className="col"
                                                    value={data}
                                                    title='Please provide consolidated partnership name'
                                                    onChange={(val) => { CPtableChange(index, val) }}
                                                    error={duplicateCPErrors[index]}
                                                />
                                            </td>
                                            <td><Button className="btn btn-outline-danger" onClick={(e) => deleteCPTableRows(e, index)}><span className="Appkit4-icon icon-close-outline"></span></Button></td>
                                            <td></td>
                                        </tr>
                                    )
                                })}
                                <tr>
                                    <td><Button className='col' kind='secondary' onClick={addCPTableRows}>Add consolidated partnership</Button></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
            </Panel>

            <Panel title='Excess Debt Cap Brought Forward (Group Attribute)' className='mt-4'>
                <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{display: "flex"}}>
                        <p className='col'>Excess Debt Cap Generated in period immediately before first period of account for which the model is used (in GBP):</p>
                            <CustomTooltipIcon size="big" tooltipText={<>
                                <p>The Excess Debt Cap is a cumulative measure such that the Excess Debt Cap generated in the immediately preceding period is the total Excess Debt Cap brought forward. In general, Excess Debt Cap is generated where there is a disallowance that is not due to ANGIE (or QNGIE) being the limiting factor.</p>
                                <p>The Excess Debt Cap generated in a period of account is calculated as the Fixed or Group Ratio Debt Cap for the generating period (as relevant) less 30% or the Group Ratio % (as relevant) of the Aggregate Tax-EBITDA for the generating period.</p>
                                <p>This amount is then capped by the carry forward limit, which effectively restricts any increase in the period to the total disallowed amount for the Period of Account. This is calculated as the sum of the Excess Debt Cap generated in the Period of Account immediately preceding the generating period and the total disallowed amount arising in the generating period.</p>
                                <p>Refer to s400 TIOPA 2010 for further details.</p>
                            </>}/>
                    </div>
                    <InputNumber className='col'
                        min={0}
                        format=','
                        value={excess_debt_cap_before_model}
                        prefix='GBP'
                        title='GBP'
                        onChange={(value) => {set_excess_debt_cap_before_model((value))}}
                        error={!Number.isInteger(excess_debt_cap_before_model)}
                        errorNode='Excess debt cap needs to be an integer'
                    />
                </div>
            </Panel>
            <Panel title={<div style={{display: "flex", alignItems: "center"}}>
                <span>Unused Interest Allowance (Group Attribute)</span>
                <CustomTooltipIcon size="big" tooltipText={<>
                    <p>Unused interest allowance may be created where there is no disallowance in the period and the Interest Allowance exceeds the Aggregate Net Tax-Interest Expense. It can then be carried forward and added to the Interest Allowance of future periods in calculating Interest Capacity. This may therefore potentially reduce future disallowed amounts. For Unused Interest Allowance to be available in a period, a full interest restriction return must have been submitted for the period in which the unused allowance is generated and every return through to the current period.</p>
                    <p>Unused Interest Allowance expires after five years and there are complex calculations in s395 TIOPA 2010 to establish the unexpired amounts where the period of account of the group has changed within this five year period.</p>
                    <p>This schedule allows you to input Unused Interest Allowance which has arisen in Periods of Account prior to the first period of account for which the tool is to be used.  It is recommended that up to six years of prior information is inputted into the model, but that where an abbreviated return has been submitted, no information in relation to that period or any prior period is included in this section.</p>
                    <p>Refer to s392 - s395A TIOPA 2010 for further details.</p>
                </>}/>
                </div>} className='mt-4'>
                <p>Please enter any unused interest allowances generated by the group in all periods prior to the first period of account for which the model is used.</p>
                <table className="table">
                    <thead style={{ marginBottom: '10px', }}>
                        <tr>
                            {/* <th className="col-1 two-line-ellipsis table-head" key={1}>
                                <div style={{display: "flex"}}>
                                    <p>Originating Period of Account Start Date <span style={{ color: 'red' }}>*</span></p>
                                    <Tooltip content={"This is the start of the period of account in which the unused interest allowance being entered arose."}>
                                        <Button className='tooltip-btn' icon='icon-information-outline' kind='text' />
                                    </Tooltip>
                                </div>
                            </th> */}

                            <CustomTableHeader title="Originating Period of Account Start Date" key={1} required={true} tooltipText={<p>This is the start of the period of account in which the unused interest allowance being entered arose.</p>} />

                            {/* <th className="col-1 two-line-ellipsis table-head" key={2}>
                                <div style={{display: "flex"}}>
                                    <p>Originating Period of Account End Date <span style={{ color: 'red' }}>*</span></p>
                                    <Tooltip content={"This is the end of the period of account in which the unused interest allowance being entered arose."}>
                                        <Button className='tooltip-btn' icon='icon-information-outline' kind='text' />
                                    </Tooltip>
                                </div>
                            </th> */}

                            <CustomTableHeader title="Originating Period of Account End Date" key={2} required={true} tooltipText={<p>This is the end of the period of account in which the unused interest allowance being entered arose.</p>} />

                            {/* <th className="col-1 two-line-ellipsis table-head" key={3}>
                                <div style={{display: "flex"}}>
                                    <p>Interest Allowance in Originating Period</p>
                                    <Tooltip content={"This is the Interest Allowance that was included within the Interest Restriction Return ('IRR' / 'CIR Return') for the Period of Account. It should be a positive amount in Pounds Sterling (GBP)."}>
                                        <Button className='tooltip-btn' icon='icon-information-outline' kind='text' />
                                    </Tooltip>
                                </div>
                            </th> */}

                            <CustomTableHeader title="Interest Allowance in Originating Period" key={3} required={false} tooltipText={<p>This is the Interest Allowance that was included within the Interest Restriction Return ('IRR' / 'CIR Return') for the Period of Account. It should be a positive amount in Pounds Sterling (GBP).</p>} />

                            {/* <th className="col-1 two-line-ellipsis table-head" key={4}>
                                <div style={{display: "flex"}}>
                                    <p>Aggregate Net Tax-Interest Expense for Originating Period</p>
                                    <Tooltip content={"This is the Aggregate Net Tax-Interest Expense that was included within the Interest Restriction Return ('IRR' / 'CIR Return') for the Period of Account. It should be a positive amount in Pounds Sterling (GBP)."}>
                                        <Button className='tooltip-btn' icon='icon-information-outline' kind='text' />
                                    </Tooltip>
                                </div>
                            </th> */}

                            <CustomTableHeader title="Aggregate Net Tax-Interest Expense for Originating Period" key={4} required={false} tooltipText={<p>This is the Aggregate Net Tax-Interest Expense that was included within the Interest Restriction Return ('IRR' / 'CIR Return') for the Period of Account. It should be a positive amount in Pounds Sterling (GBP).</p>} />

                            {/* <th className="col-1 two-line-ellipsis table-head" key={5}>
                                <div style={{display: "flex"}}>
                                    <p>Reactivations in Originating Period</p>
                                    <Tooltip content={"This is any Reactivations that were included within the Interest Restriction Return ('IRR' / 'CIR Return') for the Period of Account. It should be a positive amount in Pounds Sterling (GBP)."}>
                                        <Button className='tooltip-btn' icon='icon-information-outline' kind='text' />
                                    </Tooltip>
                                </div>
                            </th> */}

                            <CustomTableHeader title="Reactivations in Originating Period" key={5} required={false} tooltipText={<p>This is any Reactivations that were included within the Interest Restriction Return ('IRR' / 'CIR Return') for the Period of Account. It should be a positive amount in Pounds Sterling (GBP).</p>} />

                            {/* <th className="col-1 two-line-ellipsis table-head" key={6}>
                                <div style={{display: "flex"}}>
                                    <p>Unused Interest allowance generated in originating period</p>
                                    <Tooltip content={"This is an automatic calculation which calculates the amount of Unused Interest Allowance arising in the period which is available for use in later periods - it is calculated as the Interest Allowance, less Aggregate Net Tax Interest Expense, less Reactivations in the period (but cannot be negative)."}>
                                        <Button className='tooltip-btn' icon='icon-information-outline' kind='text' />
                                    </Tooltip>
                                </div>
                            </th> */}

                            <CustomTableHeader title="Unused Interest allowance generated in originating period" key={6} required={false} tooltipText={<p>This is an automatic calculation which calculates the amount of Unused Interest Allowance arising in the period which is available for use in later periods - it is calculated as the Interest Allowance, less Aggregate Net Tax Interest Expense, less Reactivations in the period (but cannot be negative).</p>} />

                            {/* <th className="col-1 two-line-ellipsis table-head" key={7}>
                                <div style={{display: "flex"}}>
                                    <p>Amounts Utilised Prior to First Period of Account for which the model is used</p>
                                    <Tooltip content={"This is the full amount of any Unused Interest Allowance which was utilised in subsequent periods (but prior to the first period for which the model is used). It should be a positive amount in Pounds Sterling (GBP)."}>
                                        <Button className='tooltip-btn' icon='icon-information-outline' kind='text' />
                                    </Tooltip>
                                </div>
                            </th> */}

                            <CustomTableHeader title="Amounts Utilised Prior to First Period of Account for which the model is used" key={7} required={false} tooltipText={<p>This is the full amount of any Unused Interest Allowance which was utilised in subsequent periods (but prior to the first period for which the model is used). It should be a positive amount in Pounds Sterling (GBP).</p>} />


                            {/* <th className="col-1 two-line-ellipsis table-head" key={8}>
                                <div style={{display: "flex"}}>
                                    <p>Unused Interest Allowance Potentially Available in Future Periods</p>
                                    <Tooltip content={"This is an automatic calculation which determines the amount of Unused Interest Allowance which potentially remains available for use in later periods (subject to expiry). It is calculated as Unused Interest Allowance generated in the period less Amounts Utilised Prior to the First Period of Account for which the model is used."}>
                                        <Button className='tooltip-btn' icon='icon-information-outline' kind='text' />
                                    </Tooltip>
                                </div>
                            </th> */}

                            <CustomTableHeader title="Unused Interest Allowance Potentially Available in Future Periods" key={8} required={false} tooltipText={<p>This is an automatic calculation which determines the amount of Unused Interest Allowance which potentially remains available for use in later periods (subject to expiry). It is calculated as Unused Interest Allowance generated in the period less Amounts Utilised Prior to the First Period of Account for which the model is used.</p>} />

                            <th style={{ textAlign: 'left', verticalAlign: 'top' }} className='col-1'>
                                <Button add disabled={!isUnusuedInterestValid()} className="btn btn-outline-success" title={addInterestAllowanceToolTipTitle} onClick={() => {
                                    if (isUnusuedInterestValid()) {
                                        addTableRows();
                                    }
                                }}>
                                    <span className="Appkit4-icon icon-plus-outline"></span>
                                </Button></th>
                        </tr>
                    </thead>
                    <tbody>
                        {unusedInterest?.map((data, index) => {
                            return (
                                <tr key={index}>
                                    <td key={1} style={{ verticalAlign: isUnusedInterestRowValid && isUnusedInterestRowValid[index] ? 'middle' : 'top' }}>
                                        <CalendarPicker required='true' fieldWidth={calendarPickerWidth} format='DD/MM/YYYY' placeholder='dd/mm/yyyy' className="col" value={data['start_date']} minDate={getOriginatingPoaMinStartDateJS()} maxDate={getFirstPOAStartDate()} onChange={(evnt, isValid) => {
                                            tableChange(index, evnt, 'start_date');
                                            const validatorList = [...isUnusedInterestStartDatesValid];
                                            validatorList[index] = isValid;
                                            setIsUnusedInterestStartDatesValid(validatorList);
                                        }} />
                                    </td>
                                    <td key={2} style={{ verticalAlign: isUnusedInterestRowValid && isUnusedInterestRowValid[index] ? 'middle' : 'top' }}>
                                        <CalendarPicker style={{ verticalAlign: isUnusedInterestRowValid && isUnusedInterestRowValid[index] ? 'top' : 'middle' }} fieldWidth={calendarPickerWidth} format='DD/MM/YYYY' placeholder='dd/mm/yyyy' className="col" value={data['end_date']} minDate={calculate_min_date(index)} maxDate={calculate_max_date(index)} required={true && data['start_date']} onChange={(evnt, isValid) => {
                                            tableChange(index, evnt, 'end_date');
                                            const validatorList = [...isUnusedInterestEndDatesValid];
                                            validatorList[index] = isValid;
                                            setIsUnusedInterestEndDatesValid(validatorList);
                                        }} />
                                    </td>
                                    <td key={3} style={{ verticalAlign: isUnusedInterestRowValid && isUnusedInterestRowValid[index] ? 'middle' : 'top' }}>
                                        {getInputNumber(data, index, 'interest_allowance')}
                                    </td>
                                    <td key={4} style={{ verticalAlign: isUnusedInterestRowValid && isUnusedInterestRowValid[index] ? 'middle' : 'top' }}>
                                        {getInputNumber(data, index, 'interest_expense')}
                                    </td>
                                    <td key={5} style={{ verticalAlign: isUnusedInterestRowValid && isUnusedInterestRowValid[index] ? 'middle' : 'top' }}>
                                        {getInputNumber(data, index, 'reactivations', Math.max(data['interest_allowance'] - data['interest_expense'], 0))}
                                    </td>
                                    <td key={6} style={{ verticalAlign: isUnusedInterestRowValid && isUnusedInterestRowValid[index] ? 'middle' : 'top' }}>
                                        <InputNumber style={{ minWidth: calendarPickerWidth - 50 }} format=',' className="col" value={data['unused'] || 0} readonly />
                                    </td>
                                    <td key={7} style={{ verticalAlign: isUnusedInterestRowValid && isUnusedInterestRowValid[index] ? 'middle' : 'top' }}>
                                        <InputNumber style={{ minWidth: calendarPickerWidth - 50 }} format=',' className="col" value={data['reactivations_prior_model_use'] || 0} error={!is_reactivations_prior_model_valid(data) || (data['reactivations_prior_model_use'] < 0 ?? false)} min={0} max={Math.max(data['interest_allowance'] - data['interest_expense'] - data['reactivations'], 0)} onChange={(evnt) => { tableChange(index, evnt, 'reactivations_prior_model_use') }} />
                                    </td>
                                    <td key={8} style={{ verticalAlign: isUnusedInterestRowValid && isUnusedInterestRowValid[index] ? 'middle' : 'top' }}>
                                        <InputNumber style={{ minWidth: calendarPickerWidth - 50 }} format=',' className="col" value={data['unused_available'] || 0} readonly />
                                    </td>
                                    <td style={{
                                        verticalAlign: isUnusedInterestRowValid && isUnusedInterestRowValid[index] ? 'middle' : 'top',
                                        paddingTop: isUnusedInterestRowValid && isUnusedInterestRowValid[index] ? '' : '0.35rem'
                                    }}><Button className="btn btn-outline-danger" onClick={(e) => deleteTableRows(e, index)}><span className="Appkit4-icon icon-close-outline"></span></Button></td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
                <div class='row'>
                    <div class='col'>
                        <Button style={{ float: 'left' }} disabled={!isUnusuedInterestValid()} title={addInterestAllowanceToolTipTitle} onClick={() => {
                            if (isUnusuedInterestValid()) {
                                addTableRows();
                            }
                        }}>
                            Add new period
                        </Button>
                    </div>
                </div>

            </Panel>

            <div className='container-fluid mt-4'>
                <div className='d-flex justify-content-between align-items-center'> 
                    <div>
                        <BackButton/>
                    </div>
                    <div className='d-flex gap-2'>
                        <Button hiddenInReadonly disabled={ isSaveDisabled} onClick={save}>Save</Button>
                        <NextButton preNavigation={save} disabled={isSaveDisabled} />
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default GroupConfig;