import { Panel, Select, Button, Input, CalendarPicker, Loading, Tab, Tabs, Tooltip } from '@appkit4/react-components';
import { useEffect, useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import sqlService from '../../services/sqldatabase/sqldatabase.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import toast from 'react-hot-toast';
import { setGroupData } from '../../services/GroupContext';
import { AuthContext } from '../../services/AuthProvider';
import { get_group_companies, get_onesource_groups } from '../../services/dredger/dredger.service';
import { getDefaultPeriodEnd, getMaxPeriodEnd, processDate, processDateJS, getOriginatingPoaMinStartDateJS } from '../../utils/dateProcessor';
import { defaultReturnStatus } from '../GroupPOA/GroupPOA';
import "./newGroup.scss";

dayjs.extend(utc)

export const getDefaultPeriod = ( model_first_period_start, model_first_period_end ) => {
    return {
        "period_start": model_first_period_start,
        "period_end": model_first_period_end,
        "return_status": {...defaultReturnStatus},
        "ratio_type": 'Fixed Ratio',
        "disallowance_reactivation": "",
        "group_excess_debt_cap": "",
        "unused_interest_allowance": "",
        "unused_allowance": "",
    };
}

const NewGroup = () => {
    const BLOCKED_CHARACTERS = ['/', "\\"]

    const [isLoading, setIsLoading] = useState(true)

    const [oneSourceGroups, setOneSourceGroups] = useState([]);

    const [selected_group, set_selected_group] = useState(''); // The name of the group that has been selected  on the dropdown
    const [groupName, setGroupName] = useState('')
    const [group_id, setGroupID] = useState();
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(processDateJS(getDefaultPeriodEnd(dayjs.utc()).toDate()));
    const [endDateError, setEndDateError] = useState('')
    const [canSubmit, setCanSubmit] = useState(false);
    const [group_name_error, set_group_name_error] = useState('');

    const auth = useContext(AuthContext)

    const navigate = useNavigate();

    const [tabIndex, setTabIndex] = useState(0);
    const onTabChange = (i, value) => {
        setGroupName('')
        setTabIndex(i);
    }

    const isFromOneSource = () => {
        return tabIndex === 0;
    }

    const isEndDateValid = useCallback((newEndDate) => {
        const end = processDateJS(newEndDate);
        const start = processDateJS(startDate)

        if (end?.isBefore(startDate)) {
            setEndDateError('End Date is before the Start Date')
            return false;
        }

        let maximumDate = start?.add(18, 'months', 'day')?.subtract(1, 'day');

        if (end?.isAfter(maximumDate)) {
            setEndDateError('The difference between Start Date and End Date cannot exceed 18 months')
            return false;
        }

        setEndDateError('');
        return true;
    }, [startDate, endDate]);

    const [lookup, setLookup] = useState([])

    // Function to handle change in start date
    const handleStartDateChange = (value) => {
        const newStartDate = processDate(value);
        setStartDate(newStartDate);
        // Calculate end date by adding 1 year to start date
        const newEndDate = new Date(newStartDate);
        newEndDate?.setFullYear(newEndDate?.getFullYear() + 1)
        newEndDate?.setDate(newEndDate?.getDate() - 1);
        setEndDate(newEndDate);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                const userEmail = auth?.getEmail()
                const response = await get_onesource_groups(userEmail);
                let formattedGroups = []
                const initialLookup = {};
                if (response?.status === 200) {
                    formattedGroups = response?.data?.map(val => {
                        return {
                            value: val?.GroupName,
                            label: val?.GroupName,
                        }
                    });

                    for (const entry of response?.data) {
                        initialLookup[entry?.GroupName] = entry?.GroupID;
                    }
                }
                setLookup(initialLookup);

                setOneSourceGroups(formattedGroups)
                setIsLoading(false);

            } catch (error) {
                console.error('Error fetching data:', error);
                setOneSourceGroups([{ label: "Failed to load from OneSource" }])
                setIsLoading(false)
            }
        }

        fetchData();
    }, [])


    useEffect(() => {
        if (!groupName || groupName === '') {
            setCanSubmit(false);
            return;
        }

        for (const char of BLOCKED_CHARACTERS) {
            if (groupName?.includes(char)) {
                setCanSubmit(false);
                set_group_name_error(`Group Name cannot contain "${char}"`)
                return
            }
        }

        if (!isEndDateValid()) {
            setCanSubmit(false);
            return;
        }


        if (startDate < getOriginatingPoaMinStartDateJS()) {
            setCanSubmit(false);
            return;
        }

        set_group_name_error('')
        setCanSubmit(true);
    }, [groupName, endDate, startDate])

    useEffect(() => {
        if (!groupName) {
            return
        }
        const groupID = lookup[groupName];
        if (groupID) {
            setGroupID(groupID)
        }
    }, [groupName])


    const submit = async () => {
        var result;
        try {
            result = await sqlService?.getGroupList();
        } catch (error) {
            toast.error(`Error: ${error?.message}`)
            return;
        }
        const responses = result?.data?.map(response => response?.trim()?.toLowerCase());
        if (responses?.includes(groupName?.trim()?.toLowerCase())) {
            toast.error(`The group name already exists in the CIR app. Please enter a different name to create a new group, or request access to the group from the homepage.`)
            return;
        }
        const userEmail = auth?.getEmail()
        if (!userEmail) {
            toast.error(`User profile could not be found, failed to create group`)
            return;
        }
        let data = {
            'group_name': groupName,
            'group_id': group_id ? group_id : 'null',
            'model_first_period_start': startDate,
            'model_first_period_end': endDate,
            'periods_of_account': [getDefaultPeriod(startDate, endDate)],
        }

        // If group_id is set here, this means data was pulled from OneSource.
        // Let's fetch the data neccessary here
        if (group_id) {
            try {
                const companies = await get_group_companies(Number(group_id), userEmail);
                data = { ...data, companies };
            } catch (err) {
                toast.error(`Failed to fetch OneSource companies for selected group, please try again!`)
                console.error(err);
                return;
            }
        }

        result = sqlService?.postNewGroup(data)
            .then( async response => {
                if (response.status !== 200) {
                    throw response.err;
                }
            });
        toast.promise(result, {
            loading: 'Saving...',
            success: () => {
                auth.addScope({ group: groupName, role: 'Supervisor' });
                setGroupData(data)
                console.log('data', data);
                navigate('/app/userAccess');
                return `Created new group: ${groupName}`
            },
            error: 'Failed to save',
        })
    }

    if (isLoading) {
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            />
        );
    }

    return (
        <div className="ap-container">
            <Panel title="Group Setup" className='mt-4 mb-4'>
                <p>The legislation governing Financial statements and periods of account is located at s479 - s489.</p>
                <p>The CIR period generally follows the period for which consolidated financial statements are drawn up for the group or the period for which consolidated financial statements are treated as having been drawn up. Where the consolidated financial statements are not considered to be drawn up in an appropriate manner, or not drawn up at all, s481-485 provide specific rules to determine the period of account and deem appropriate financial statements to have been prepared. These are subject to an election under s486.</p>
                <p>Note that under s487 it is not possible for a CIR period of account to exceed 18 months in duration - where this is the case, specific rules apply to ignore the actual financial statements and determine deemed financial statements for CIR purposes.</p>
            </Panel>
            <Panel title="Add a new group">
                <p>When creating a new group, you can choose a group that exists in OneSource or manually create your own</p>

                <Tabs stretched type='filled' activeIndex={tabIndex} onTabChange={onTabChange}>
                    <Tab label='From OneSource' value="From OneSource"></Tab>
                    <Tab label='Manual Entry' value="Manual Entry"></Tab>
                </Tabs>

                {isFromOneSource() ?
                    <>
                        <i class="Appkit4-icon icon-circle-warning-outline"></i><span> Please contact <a href="mailto:uk_cir_app_support@pwc.com?subject=New Group Creation" target="_blank">uk_cir_app_support@pwc.com</a> if you cannot find a group that is in OneSource</span>
                        <div className='row mt-1'>
                            <Select
                                searchable
                                data={oneSourceGroups}
                                placeholder='Select from an existing group from OneSource'
                                searchPlaceholder='Start typing the name of the group...'
                                searchType={"secondary"}
                                onSelect={(value) => { 
                                    set_selected_group(value)
                                    setGroupName(value) 
                                }}
                            />

                            {selected_group &&
                                <Input
                                    style={{ paddingTop: 10 }}
                                    value={groupName}
                                    title='Edit Group Name'
                                    onChange={(val) => setGroupName(val)}
                                    errorNode={<a style={{color: 'indianred'}}>{group_name_error}</a>}
                                    error={group_name_error !== ''}
                                />
                            }
                        </div>
                    </>
                    :
                    <>
                        <div className='row'>
                            <Input
                                title="Group Name"
                                className='col'
                                onChange={(name) => setGroupName(name)}
                                errorNode={<a style={{color: 'indianred'}}>{group_name_error}</a>}
                                error={group_name_error !== ''}
                            />
                        </div>
                    </>
                }
            </Panel>

            <Panel title="First Period of Account" style={{ marginTop: 20 }}>
                <div>
                    <p>Please enter the first CIR return Period of Account (PoA) for which this tool is used:	</p>
                    <div className="row mb-3 w-full">
                        <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                            <CalendarPicker
                                fieldTitle="PoA Start Date"
                                placeholder='dd/mm/yyyy'
                                format='DD/MM/YYYY'
                                value={startDate}
                                minDate={getOriginatingPoaMinStartDateJS()}
                                onChange={handleStartDateChange}
                            />
                            <Tooltip style={{ maxWidth: "50rem" }} content={() => {return <div>
                                    <p>This is the first period of account for which the CIR Analyser is being used and will not necessarily be the first CIR period of the group.</p>
                                    <p>The CIR period generally follows the period for which consolidated financial statements are drawn up for the group or the period for which consolidated financial statements are treated as having been drawn up. Refer to s479 - s489 TIOPA 2010 for further details.</p>
                                </div>}}>
                                    <Button className='tooltip-btn' icon='icon-information-outline' kind='text' />
                            </Tooltip>
                        </div>
                    </div>


                    <div className='row'>
                        <div style={{display: "flex", alignItems: "center", gap: "5px"}}>
                            <CalendarPicker
                                fieldTitle="PoA End Date"
                                placeholder='dd/mm/yyyy'
                                value={endDate}
                                format='DD/MM/YYYY'
                                useCustomValidation
                                error={endDateError !== ''}
                                customErrorNode={endDateError}
                                onChange={(newDate) => {
                                    setEndDate(processDate(newDate));
                                    isEndDateValid(newDate);
                                }}
                                minDate={startDate}
                                maxDate={getMaxPeriodEnd(startDate)}
                            />
                            <Tooltip style={{ maxWidth: "50rem" }} content={() => {return <div>
                                    <p>This is the first period of account for which the CIR Analyser is being used and will not necessarily be the first CIR period of the group.</p>
                                    <p>Under s487 TIOPA 2010 it is not possible for a CIR period of account to exceed 18 months in duration. Where this is the case, specific rules apply to ignore the actual financial statements and determine deemed financial statements for CIR purposes. Refer to s479 - s489 TIOPA 2010 for further details.</p>
                                </div>}}>
                                    <Button className='tooltip-btn' icon='icon-information-outline' kind='text' />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </Panel>

            <div className='d-flex justify-content-end ap-container mt-4'>
                <Button disabled={!canSubmit} onClick={submit}>
                    Create group
                </Button>
            </div>
        </div>

    );
};

export default NewGroup;
