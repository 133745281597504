import { useCallback, useEffect, useState } from "react";
import { processDateJS } from "../../../utils/dateProcessor";
import { generateCAPId } from "../../../utils/capProcessor";
import { GridCellKind } from "@glideapps/glide-data-grid";
import { DataEditor } from "../../../components/ReadonlyAwareInputs";
import '../editCFD.scss';
import { calculateCFD, convertGBPToLC, convertLCToGBP, recalculateTotal, setBFDasPrevCFD } from "../../../utils/adjustedCapsUtil";
import { formatNumber } from "../../../utils/formatters";
import { getLightOrangeDataEditorColor } from "../../../utils/stylingUtil";

const CFDDataEditor = ({adjustedCaps, setAdjustedCaps, disallowanceDataSections}) => {
    const [tableColumns, setTableColumns] = useState([]);

    const rowData = disallowanceDataSections.flatMap(data => [
        { displayValue: data.title },
        ...data?.col1Data?.map(element => {
            return {
                displayValue: element.displayValue,
                key: element.key,
                editable: data.editable,
                ...(data.validation && { validation: data.validation })
            }
        })
    ]);

    const getDateHeader = (cap) => {
        return String(processDateJS(cap.start_date)?.format('DD/MM/YYYY')) +
            ' - ' +
            String(processDateJS(cap.end_date)?.format('DD/MM/YYYY'))
    }

    useEffect(() => {
        const temp = [
            { title: '', id: 'attribute', width: (Math.max(370, window?.innerWidth / 6)) },
            ...(adjustedCaps && adjustedCaps.length > 0
                ? adjustedCaps.map((adjustedCap) => ({
                    title: getDateHeader(adjustedCap),
                    id: generateCAPId(adjustedCap),
                    group: adjustedCap.company_name,
                    // width: (Math.max(200, window?.innerWidth / 8))
                  }))
                : [])
        ];
        setTableColumns(temp)
    }, []);

    const getData = ([col, row]) => {
        const regularCellTheme = {bgCell: getLightOrangeDataEditorColor()};
        if(adjustedCaps) {
            const adjustedCap = adjustedCaps[col-1];
        
            if (col === 0) {
                return {
                    kind: GridCellKind.Text,
                    data: rowData[row].displayValue,
                    allowOverlay: false,
                    displayData: rowData[row].displayValue,
                    ...(rowData[row].key ? {themeOverride: regularCellTheme} : {})
                };
            } else if (adjustedCap) {
                if (rowData[row].key) {
                    const isEditable = rowData[row].displayValue !== 'Total' ? rowData[row].editable : false;
                    return {
                        kind: GridCellKind.Number,
                        data: String(adjustedCap[rowData[row].key] || 0),
                        allowOverlay: isEditable,
                        displayData: formatNumber(adjustedCap[rowData[row].key] || 0),
                        ...(!isEditable ? {themeOverride: regularCellTheme} : {})
                    };
                } else {
                    return {
                        kind: GridCellKind.Text,
                        data: String(rowData[row].displayValue),
                        allowOverlay: false,
                        displayData: rowData[row].displayValue,
                        span: [0, adjustedCaps.length]
                    }
                }
            }
        }
    }
    
    const onCellEdited = (cell, newValue) => {
        const [col, row] = cell;
        const calculatedCaps = [...adjustedCaps];
        const edittedIndex = col-1;
        calculatedCaps[edittedIndex] = {
            ...calculatedCaps[edittedIndex],
            [rowData[row].key]: newValue.data
        }
        
        convertLCToGBP(calculatedCaps[edittedIndex]);
        recalculateTotal(calculatedCaps[edittedIndex], 'bfd');
        recalculateTotal(calculatedCaps[edittedIndex], 'cfd');
        calculateCFD(calculatedCaps[edittedIndex]);
        
        const processedCompanies = [];
        
        calculatedCaps?.forEach((cap, index) => {
            if(!processedCompanies.includes(cap.company_ID)) {
                //first adjustedCap for each company
                processedCompanies.push(cap.company_ID);
            } else {
                if (index > (edittedIndex)) {
                    const prevCap = calculatedCaps[index - 1];
                    setBFDasPrevCFD(cap, prevCap);
                    calculateCFD(cap);
                    convertGBPToLC(cap);
                }
            }
        });
        
        convertGBPToLC(calculatedCaps[edittedIndex]);
        
        setAdjustedCaps(calculatedCaps);
    }

    const getTheme = (i) => {
        const row = rowData[i];

        if(!row.key) {
            return {
                baseFontStyle: "600 15px",
                bgCell: "#F4F3F7"
            }
        }
    }

    const validateCell = (cell, newValue) => {
        const [col, row] = cell;
        
        let cfdKey = rowData[row]?.key?.split('_');
        cfdKey[0] = 'cfd';
        cfdKey = cfdKey.join('_');
        const cfdValue = adjustedCaps[col-1][cfdKey];

        const oldValue = adjustedCaps[col-1][rowData[row].key];

        if(newValue.data && rowData[row].validation === 'negative') {
            return (
                newValue.data <= 0 &&
                cfdValue - oldValue + newValue.data >=0
            );
        }

        return true;
    }

    return (
        <div className="tableDiv">
            <DataEditor
                getCellContent={getData} 
                columns={tableColumns} 
                rows={rowData.length}
                onCellEdited={onCellEdited}
                freezeColumns={1}
                freezeTrailingRows={7}
                smoothScrollX
                smoothScrollY
                height={'85vh'}
                validateCell={validateCell}
                getRowThemeOverride={getTheme}
            />
        </div>
    );
}

export default CFDDataEditor;
