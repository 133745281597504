import { Loading, Modal, Accordion, AccordionItem } from '@appkit4/react-components';
import { Button, Input, InputNumber } from "../../components/ReadonlyAwareInputs";
import { useState, useEffect } from 'react';
import { setQNGIETotals } from '../../services/calculations/GroupDataCalcs';
import toast from 'react-hot-toast';
import { WarningModal, generateTableRow, handleCancel, renderPrimaryAccordionTitle, renderSummary, renderTableKeyList, sumArray } from './groupDataUtil';
import { formatCurrency } from "../../utils/formatters";


const QNGIE = ({ setVisible, currentPeriod, setCurrentPeriod, save, errorState: [isError, setIsError], isParentError, isReadOnly }) => {
    const [isLoading, setIsLoading] = useState(false)

    const tables = {
        'expenses_related_party': generateTableRow('Relevant expense amounts included in ANGIE which relate to transactions with, or amounts owed to a person who is a related party of a member of the group - s414(3)(a)', useState([{ description: '', value: '' }]), useState(false), 'negative'),
        'expenses_dependent_securities': generateTableRow('Relevant expense amounts included in ANGIE which relate to results-dependent securities - s414(3)(b)', useState([{ description: '', value: '' }]), useState(false), 'negative'),
        'expenses_equity_notes': generateTableRow('Relevant expense amounts included in ANGIE which relate to equity notes - s414(3)(c)', useState([{ description: '', value: '' }]), useState(false), 'negative'),
        'specialist_regimes_adjustments': generateTableRow('Other adjustments required as a result of specialist regimes', useState([{ description: '', value: '' }]), useState(false)),
        'miscellaneous_adjustments': generateTableRow('other adjustments for which there is no space above', useState([{ description: '', value: '' }]), useState(false)),
        
        //Interest Allowance (non-consolidated)
        'qngie_proportion_associated_groups_s427_5': generateTableRow('Adjustment to include the appropriate proportion of the QNGIE of each associated worldwide group headed by a company specified in the election - s427(5)', useState([{ description: '', value: '' }]), useState(false), 'positive'),
        
        //Adjustments for Qualifying Infrastructure Companies
        'exclude_qic_income_exempt_s440_s442_2b': generateTableRow('Adjustment to exclude relevant income amounts of a QIC that were exempted under s440 and are included in the group financial statements - s442(2)(b)', useState([{ description: '', value: '' }]), useState(false)),
        'exclude_qic_expense_exempt_s438_s442_2b': generateTableRow('Adjustment to exclude relevant expense amounts of a QIC that were exempted under s438 (including as applied by s439) and are included in the group financial statements - s442(2)(b)', useState([{ description: '', value: '' }]), useState(false)),
    };


    const downwardAdjustments = [
        tables['expenses_related_party']?.state[0], 
        tables['expenses_dependent_securities']?.state[0], 
        tables['expenses_equity_notes']?.state[0],
        tables['specialist_regimes_adjustments']?.state[0],
        tables['miscellaneous_adjustments']?.state[0],
    ];

    const infraCompanyAdjustments = [
        tables['exclude_qic_income_exempt_s440_s442_2b']?.state[0],
        tables['exclude_qic_expense_exempt_s438_s442_2b']?.state[0]
    ]

    const [activeKeys, setActiveKeys] = useState("");
    const [qngieSubTotal, setQngieSubTotal] = useState(0);
    const [angieBeforeAdjustments, setAngieBeforeAnyAdjustments] = useState(currentPeriod?.angie_amount_before_any_adjustments ? currentPeriod?.angie_amount_before_any_adjustments : 0)
    const onClickAccordion = (activeKeys) => {
        setActiveKeys(activeKeys)
    }

    useEffect (() => {
        let total = 
            sumArray(downwardAdjustments) + 
            sumArray([tables['qngie_proportion_associated_groups_s427_5']?.state[0]]) +
            sumArray(infraCompanyAdjustments); 
        setQngieSubTotal(total);
      }, [downwardAdjustments, tables['qngie_proportion_associated_groups_s427_5']?.state[0], infraCompanyAdjustments])

    const calculateQNGIE = () => {
        let total =
            sumArray(downwardAdjustments) +
            sumArray([tables['qngie_proportion_associated_groups_s427_5']?.state[0]]) +
            sumArray(infraCompanyAdjustments) ;   
        if (currentPeriod?.angie_amount_before_any_adjustments) {
            total += currentPeriod?.angie_amount_before_any_adjustments;
        }   
        return Math.round(total);
    }

    const submit = () => {
        const newPeriod = {
            ...currentPeriod,

            qngie: Object.keys(tables)?.reduce( (acc, k) => {
                acc[k] = tables[k]?.state[0];
                return acc;
            } , {})
        }
        
        setQNGIETotals(newPeriod);

        setCurrentPeriod(newPeriod);
        const promise = save(newPeriod);
        toast.promise(promise, {
            loading: 'Saving QNGIE data...',
            success: () => {
                setVisible(false);
                return 'Saved QNGIE data!'
            },
            error: 'Something went wrong saving QNGIE data',
        })
    }

    useEffect(() => {
        setIsError( Object.keys(tables)?.some( k => tables[k]?.error[0]) )
      }, [Object.keys(tables)?.map(k => tables[k]?.error[0])])

    useEffect(() => {
        const qngie = currentPeriod?.qngie;
        if (!qngie) {
            return;
        }

        Object.keys(tables)?.forEach(key => {
            tables[key]?.state[1](qngie[key])
        })
    }, [])

    const [isModalChanged, setIsModalChanged] = useState(false);

    useEffect(() => {
        let total = calculateQNGIE();
        if (currentPeriod?.local_curr_qualifying_net_group_interest_expense !== total) {
            setIsModalChanged(true);
        } else {
            setIsModalChanged(false);
        }
    }, [calculateQNGIE()]);

    const [showWarningModal, setShowWarningModal] = useState(false);

    if (isLoading) {
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            ></Loading>
        );
    }
    return (
        <>
            <Modal
                visible={true}
                title={`Qualifying Net Group-Interest Expense`}
                onCancel={() => handleCancel(isModalChanged, isReadOnly, setVisible, setShowWarningModal)}
                maskCloseable={false}
                modalStyle={{ width: '90%', maxHeight: '90%' }}
                bodyStyle={{ maxHeight: '90%' }}
            >
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <div className="row">
                                    <p className="col-1">Selected input value currency:</p>
                                    <Input
                                        className="col"
                                        disabled
                                        value={currentPeriod?.currency}
                                    />
                                </div>
                            </td>
                            <td>
                                <div className="row">
                                    <p className="col-4">
                                        Entered FX rate: 1 GBP = X Local Currency (if required):
                                    </p>
                                    <InputNumber
                                        className="col"
                                        value={currentPeriod?.fx_rate}
                                        disabled
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className='row group-data-modal-top-input-header'>
                    <p>
                        Changes to the currency and exchange rate can be made in the Net group-interest expense section
                    </p>
                </div>

                {isError && <div className='err'>Description missing - a description must be provided for every value.</div>}



                <Accordion multiple={false} onClick={onClickAccordion} activeKeys={activeKeys}>
                    <AccordionItem title={renderPrimaryAccordionTitle('ANGIE - John to add comment', angieBeforeAdjustments)} itemKey="1">
                    </AccordionItem>
                    <AccordionItem title={renderPrimaryAccordionTitle('Downward Adjustments', sumArray(downwardAdjustments))} itemKey="2">
                        {renderTableKeyList(tables, [
                            'expenses_related_party',
                            'expenses_dependent_securities',
                            'expenses_equity_notes',
                            'specialist_regimes_adjustments',
                            'miscellaneous_adjustments',
                        ])}
                    </AccordionItem>

                    <AccordionItem title={renderPrimaryAccordionTitle('Interest Allowance (non-consolidated investment election)', sumArray([tables['qngie_proportion_associated_groups_s427_5']?.state[0]]))} itemKey="3">
                        {renderTableKeyList(tables, [
                            'qngie_proportion_associated_groups_s427_5',
                        ])}
                    </AccordionItem>

                    <AccordionItem title={renderPrimaryAccordionTitle('Adjustments for Qualifying Infrastructure Companies', sumArray(infraCompanyAdjustments))} itemKey="4">
                        {renderTableKeyList(tables, [
                            'exclude_qic_income_exempt_s440_s442_2b',
                            'exclude_qic_expense_exempt_s438_s442_2b',
                        ])}
                    </AccordionItem>
                </Accordion>

                <h3>Summary</h3>

                {renderSummary(currentPeriod?.currency, currentPeriod?.fx_rate, (qngieSubTotal + angieBeforeAdjustments))}


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', paddingTop: '30px', gap: 10 }}>
                    <Button neverReadonly onClick={() => handleCancel(isModalChanged, isReadOnly, setVisible, setShowWarningModal)} kind="secondary">Cancel</Button>
                    <Button hiddenInReadonly disabled={isError || isParentError} onClick={submit}>Submit</Button>
                </div>
            </Modal>

            <WarningModal
                visible={showWarningModal}
                setIsVisible={setShowWarningModal}
                callBackFunc={setVisible}
            >
            </WarningModal>
        </>

    );
};

export default QNGIE;
