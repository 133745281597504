
import { processDateJS } from "./dateProcessor";

export function processExcel(savedData){
    let companies = []
    const message = "©" + new Date().getFullYear() + " PricewaterhouseCoopers LLP. All rights reserved. ‘PwC’ refers to the UK member firm, and may sometimes refer to the PwC network. Each member firm is a separate legal entity. Please see www.pwc.com/structure for further details."

    const excelData = {
        compNames: ["Company name"],
        APSD: ['Accounting Period Start Date'],
        APED: ["Accounting Period End Date"],
        period_length: ["Account Period length"],
        date_joined: ["Date joined group"],
        date_lefted: ["Date left group"],
        regarded_period: ["Regarded period for CIR (days)"],
        disregarded_percent: ["Percentage of the period that is regarded"],

        currency: ["Currency"],
        fx: ["Exchange rate (1 GBP = X Currency)"],

        ntlr_totalPerSource:[ "Total Per Source Sheet For Period"],
        note_1: ["Less: Included in the above"],
        ntlr_exclude_exchange: ["Exclude Exchange Gains (Losses)"],
        ntlr_exclude_impairment: ["Exclude Impairment Gains (Losses)"],
        ntlr_exclude_tax: ["Exclude Tax Interest Income Subject to Double Tax Relief (s388 TIOPA 2010)"],
        ntlr_adjustment_amortised: ["Adjustment for election for amortised cost basis for fair valued creditor relationships"], 
        ntlr_adjustment_election: ["Adjustment for election for Qualifying Infrastructure Company"],
        ntlr_exclude_benefit: ["Exclude defined benefit pension income/expense included on loan relationships statement"],
        ntlr_bank_charges: ["Add: Bank charges and similar interest type expenses (if not included in loan relationships above)"],
        ntlr_adjustment_gaap: ["Adjustment for GAAP transition items"],
        ntlr_adjustment_cost: ["Adjustment for finance costs associated with leases"],
        ntlr_trading_relationships: ["Net tax-interest expense/(income) - Non-trading loan relationships"],
        ntlr_total_net_tax: ["Total net tax-interest expense/(income)"],

        tlr_totalPerSource: ["Total Per Source Sheet For Period"],
        tlr_exclude_exchange: ["Exclude Exchange Gains (Losses)"],
        tlr_exclude_impairment: ["Exclude Impairment Gains (Losses)"],
        tlr_exclude_tax: ["Exclude Tax Interest Income Subject to Double Tax Relief (s388 TIOPA 2010)"],
        tlr_adjustment_amortised: ["Adjustment for election for amortised cost basis for fair valued creditor relationships"],
        tlr_adjustment_election: ["Adjustment for election for Qualifying Infrastructure Company"],
        tlr_exclude_benefit: ["Exclude defined benefit pension income/expense included on loan relationships statement"],
        tlr_bank_charges: ["Add: Bank charges and similar interest type expenses (if not included in loan relationships above)"],
        tlr_adjustment_gaap: ["Adjustment for GAAP transition items"],
        tlr_adjustment_cost: ["Adjustment for finance costs associated with leases"],
        tlr_trading_relationships: ["Net tax-interest expense/(income) - Trading loan relationships"],
    
        ntdc_totalPerSource: ["Total Per Source Sheet For Period"],
        ntdc_exclude_exchange: ["Exchange gains/(losses)"],
        ntdc_exclude_impairment: ["Impairment gains/(losses)"],
        ntdc_dtr_s388: ["DTR - s388"],
        ntdc_adjustment_disregard: ["Adjustment for election to apply the Disregard Regulations to pre 1 April 2020 derivative contracts (Regulations 7,8 and 9)"],
        ntdc_adjustment_qualifying: ["Adjustment for election for Qualifying Infrastructure Company"],
        ntdc_exclude_ordinary_trade: ["Exclude amounts on contracts arising in the ordinary course of trade"],
        ntdc_exclude_derivatives_without_subject_matters: ["Exclude amounts derivatives without an underlying subject matter specified in s384(4) / s387(4)"],
        ntdc_adjustment_gaap: ["Adjustment for GAAP transition items"],
        ntdc_trading_contracts: ["Net tax-interest expense/(income) - Non-trading derivative contracts"],
    
        tdc_totalPerSource: ["Total Per Source Sheet For Period"],
        tdc_exclude_exchange: ["Exchange gains/(losses)"],
        tdc_exclude_impairment: ["Impairment gains/(losses)"],
        tdc_dtr_s388: ["DTR - s388"],
        tdc_adjustment_disregard: ["Adjustment for election to apply the Disregard Regulations to pre 1 April 2020 derivative contracts (Regulations 7,8 and 9)"],
        tdc_adjustment_qualifying: ["Adjustment for election for Qualifying Infrastructure Company"],
        tdc_exclude_ordinary_trade: ["Exclude amounts on contracts arising in the ordinary course of trade"],
        tdc_exclude_derivatives_without_subject_matters: ["Exclude amounts derivatives without an underlying subject matter specified in s384(4) / s387(4)"],
        tdc_adjustment_gaap: ["Adjustment for GAAP transition items"],
        tdc_trading_contracts: ["Net tax-interest expense/(income) - Trading derivative contracts"],
        
        ofa_totalPerSource: ["Total Per Source Sheet For Period"],
        ofa_adjustment_finance_lease: ["Adjustment to include Expense (income) associated with finance leases",""],
        ofa_adjustment_debt_factoring: ["Adjustment to include Expense (income) associated with debt factoring or similar transactions",""],
        ofa_adjustment_service_concession: ["Adjustment to include Expense (income) associated with service concession arrangement if and to the extent accounted for as a financial liability",""],
        ofa_dtr_s388: ["DTR - s388"],
        ofa_adjustment_election: ["Adjustment for election for Qualifying Infrastructure Company"],
        ofa_adjustment_gaap: ["Adjustment for GAAP transition items"],
        ofa_trading_relationships: ["Net tax-interest expense/(income) - Other financing arrangements"],

        cte_a: ["Adjusted corporation tax earnings - Condition A (TIOPA 2010, s406(3))"],
        cte_b: ["Adjusted corporation tax earnings - Condition B (TIOPA 2010, s406(4))"],
        cte_restrictions: ["(Restrictions) and reactivations included in above"],
        tebitda_adjustments: ["Adjustments"],
        cte_sum: ["Corporation tax earnings (excluding restrictions and reactivations)"],
    
        s407_net_tax: ["Net tax-interest expense/(income) - s407(1)(a)"],
        s407_capital_allowances: ["Capital allowances and charges - s407(1)(b)"],
        s407_debits_credits: ["Relevant intangible debits and credits - s407(1)(c)"],
        s407_automatic_adjustments: ["Adjustment to intangible debits and credits brought in automatically (e.g. realisation gains above original cost)"],
        s407_tiopa_other_period_losses: ["Losses from other periods - s407(1)(d)(i) and (ii)"],
        s407_loan_relationships: ["Loan relationship deficits from other periods - s407(1)(e)"],
        s407_management_expenses: ["Expenses of management from other periods - s407(1)(f)"],
        s407_group_relief: ["Group relief - s407(1)(g)"],

        s407_expenditure_credit: ["R&D expenditure credit - s407(3)(a)"],
        s407_additional_relief: ["R&D additional relief - s407(3)(b)"],
        s407_trading_loss: ["Deemed trading loss for pre-trading expenditure - s407(3)(c)"],
        s407_contaminated_land: ["Contaminated land relief - s407(3)(d)"],
        s407_creative_industries: ["Creative industries - s407(3)(e)-(j)"],
        s407_charitable: ["Qualifying charitable donation (from current or earlier period) - s407(3)(k)"],
        s407_patent_box: ["Profits from patent box chargeable at a lower rate - s407(3)(l)"],
        s407_dtr_equal: ["DTR adjustment - s409 (where effective tax rate <> UK tax rate)"],
        s407_dtr_not_equal: ["DTR adjustment - s409 (Where effective tax rate equals UK tax rate)"],
        s407_adjustment_election: ["Adjustment for election for Qualifying Infrastructure Company"],
        s407_adjustment_gaap: ["Adjustment for GAAP transition items"],
        s407_finance_lease: ["Finance lease depreciation - s460(1)(d)"],
        s407_reversal_lease: ["Reversal of right-of-use lease depreciation in respect of operating leases"],
        s407_long_funding_lease: ["Long funding lease deduction - s460(1)(a)"],
        s407_lease_rentals_receivable: ["Capital element of finance lease rentals receivable - s460(1)(c)"],
        s407_long_funding_lessee: ["Long funding lessee - amount by which a deduction is reduced under section 379 of CTA 2010 - s460(1)(b)"],
        s407_miscellaneous_losses: ["Adjustment for miscellaneous losses not included in Condition B above"],
        s407_unincluded_group_relief: ["Group relief included above not covered by s407(1)(g)"],
        s407_tax_ebitda: ["Tax-EBITDA Adjustment for election to apply the Disregard Regulations to pre 1 April 2020 derivative contracts (Regulations 7, 8 and 9)",""],
        s407_tax_ebitda_total: ["Tax-EBITDA - Total for period"],

        tax_ebitda_adjustments: ["Tax-EBITDA Adjustments (to apportionment calculation only)"],
        total_tax_ebitda: ["Total Tax-EBITDA"],

        note_2: ["Reversal of right-of-use lease depreciation in respect of operating leases"],
    
        cir_consenting: ["Non-consenting company?"],
        cir_neglibible_trade: ["Trade small or neglibible?"],
        cir_uncommercial_trade: ["trade uncommercial or non-statutory?"],
        cir_investment_ceased: ["Investment business ceased?"],
        cir_CTA10_ownership: ["CTA19 Change in Company Ownership rules apply?"],

        bfd_ntlr: ["BFD - Non-trading loan relationship amounts"],
        bfd_ntd: ["BFD - Non-trading derivative amounts"],
        bfd_tlr: ["BFD - Trading loan relationship amounts"],
        bfd_td: ["BFD - Trading derivatie amounts"],
        bfd_ofa: ["BFD - Other financing amounts"],
        bfd_total: ["BFD - Total"],

        company_reit: ["REIT"],
        company_ring_fence: ["Ring fence company"],
        company_investment: ["Investment company"],
        company_insurance: ["Insurance company"],
        company_shipping: ["Shipping company"],
        company_cooperative: ["Cooperative/community benefit society etc."],
        company_charity: ["Charity"],
        company_banking: ["Banking company"],

        //CIR Calculations

        aggregate_ebitda: ["Aggregate Tax EBITDA","A"],
        fixed_ratio: ["Fixed ratio (30%)", "B = (A x 30%)"],
        angie: ["Adjusted Net Group Interest Expense (ANGIE)","C"],
        excess_debt: ["Excess Debt cap brought forward","D"],
        fixed_debt: ["Fixed Ratio Debt Cap (s400(1))","E = (C + D)"],
        basic_interest: ["Basic interest allowance - lower of B and E (s397(1))","F"],
        antii: ["Aggregate Net Tax Interest Income (ANTII)","G"],
        interest_allowance: ["Interest allowance (s396(1))","H = (F + G)"],

        qngie: ["Qualifying Net Group Interest Expense (QNGIE)","A"],
        group_ebitda: ["Group EBITDA","B"],
        group_ratio: ["Group ratio % (s399)","C = (A / B)"],

        aggregate_ebitda2: ["Aggregate Tax EBITDA","D"],
        group_ratio_ebitda: ["Group ratio % of Tax EBITDA","E = (D X C)"],

        excess_debt2: ["Excess Debt cap brought forward","F"],
        group_debt_cap: ["Group ratio debt cap (s400(2))","G = (A + F)"],
        

        group_basic_interest: ["Basic interest allowance - lower of E and G (s398(1))","H"],
        group_interest_allowance: ["Interest allowance (s396(1))","H = (F + G)"],

        group_ratio_election: ["Group Ratio Election being made?"],
        computation_int_allowance: [ "Interest allowance","A"],
        unused_allowance: ["Unused interest allowance brought forward","B"],
        de_minimis: ["De minimis (Note: Assumes no leap year)","C"],
        interest_capacity: ["Interest capacity (Greater of interest allowance plus brought forward allowance and de minimis) (s392)","D = Greater of (A + B) and C"],
        aggregate_interest_expense: ["Aggregate Net Tax Interest Expense","E"],
        disallowed_amount: ["Disallowed Amount (s373(1)-(2))","F = (E - D)"],
        available_reactivation: ["Amount available for Reactivation (Group reactivation cap) (s373(3)-(4))","G = (A - E)"],

        //CIR attributes

        current_int_allowance: ["Interest allowance for the current period","A"],
        current_tax_interest_expense: ["Aggregate Net Tax Interest Expense for current period","B"],
        reactivations: ["Reactivations in the current period","C"],
        current_yr_interest: ["Current Year Interest allowance used in current period","D (B + C)"],
        unused_interest_gen: ["Unused Interest Allowance generated in current period","E (A - D)"],
        unused_interest_bf: ["Unused Interest Allowance b/f used in current period (Period TBC)","F (B - A)"],

        excess_debt_previous: [ "Excess debt cap brought forward from previous period","A"],
        current_debt_cap: ["Current period debt cap (s400(1)-(2))","B"],
        group_ratio_percent: ["30% or Group Ratio % of Aggregate Tax-EBITDA","C"],
        generated_debt_cap: ["Excess debt cap generated in current period (s400(3)-(5)) before the application of the carry forward limit","D (B - C)"],

        excess_immediate: ["Excess debt cap generated in immediately preceding period","A"],
        disallowed_period: ["Disallowed amount for current period","E"],
        carry_limit: ["Carry forward limit","F (A + E)"],
        excess_carry_limit: ["Excess debt cap generated in the period after the application of the carry-forward limit", "Lower of D and F"],

    }

    //Company accounting periods
    let ntlr_potentialVariables;
    let tlr_potentialVariables;
    let ntdc_potentialVariables;
    let tdc_potentialVariables;
    let ofa_potentialVariables;
    let cte_potentialVariables;
    let adjustments_potentialVariables;
    let reliefs_potentialVariables;
    let bfd_potentialVariables;
    let cir_potentialVariables;
    let company_potentialVariables;

    //CIR calculations
    let fixed_ratio_variables;
    let group_ratio_variables;
    let disallowed_variables;

    //CIR attributes
    let unused_variables;
    let excess_variables;
    let carry_forward_variables;
 
    for(let i=0; i<savedData.periods_of_account.length; i++){
        if(savedData.periods_of_account[i].adjusted_caps !== undefined){
            fixed_ratio_variables = [
                [savedData.periods_of_account[i].aggregate_tax_ebitda, excelData.aggregate_ebitda],
                [(savedData.periods_of_account[i].aggregate_tax_ebitda) * 0.3, excelData.fixed_ratio],
                [savedData.periods_of_account[i].adjusted_net_group_interest_expense, excelData.angie],
                [savedData.periods_of_account[i].excess_debt_cap_from_prior_period, excelData.excess_debt],
                [savedData.periods_of_account[i].fr_debt_cap, excelData.fixed_debt],
                [savedData.periods_of_account[i].fr_basic_interest_allowance, excelData.basic_interest],
                [savedData.periods_of_account[i].aggregate_net_tax_interest_income, excelData.antii],
                [savedData.periods_of_account[i].fr_interest_allowance, excelData.interest_allowance],
            ]

            group_ratio_variables = [
                [savedData.periods_of_account[i].qualifying_net_group_interest_expense, excelData.qngie],
                [savedData.periods_of_account[i].group_ebitda, excelData.group_ebitda],
                [savedData.periods_of_account[i].gr_percentage, excelData.group_ratio],
                [savedData.periods_of_account[i].aggregate_tax_ebitda, excelData.aggregate_ebitda2],
                [(savedData.periods_of_account[i].gr_percentage) * (savedData.periods_of_account[i].aggregate_tax_ebitda), excelData.group_ratio_ebitda],
                [savedData.periods_of_account[i].gr_debt_cap, excelData.group_debt_cap],
                [savedData.periods_of_account[i].gr_basic_interest_allowance, excelData.group_basic_interest],
                [savedData.periods_of_account[i].gr_interest_allowance, excelData.group_interest_allowance],
            ]

            disallowed_variables = [
                [savedData.periods_of_account[i].apply_group_ratio_election, excelData.group_ratio_election],
                [savedData.periods_of_account[i].group_interest_allowance, excelData.computation_int_allowance],
                [savedData.periods_of_account[i].group_bf_interest_allowance, excelData.unused_allowance],
                [savedData.periods_of_account[i].interest_capacity_de_minimis, excelData.de_minimis],
                [savedData.periods_of_account[i].group_interest_capacity, excelData.interest_capacity],
                [savedData.periods_of_account[i].aggregate_net_tax_interest_expense, excelData.aggregate_interest_expense],
                [savedData.periods_of_account[i].group_disallowance, excelData.disallowed_amount],
                [savedData.periods_of_account[i].group_interest_reactivation_cap, excelData.available_reactivation],
            ]
            
            //CIR calculations
            for(let x=0;x<fixed_ratio_variables.length;x++){
                if(fixed_ratio_variables[x][0] !== undefined){
                    fixed_ratio_variables[x][1].push(fixed_ratio_variables[x][0])
                }else{
                    fixed_ratio_variables[x][1].push(0)
                } 
            }
            for(let x=0;x<group_ratio_variables.length;x++){
                if(group_ratio_variables[x][0] !== undefined){
                    group_ratio_variables[x][1].push(group_ratio_variables[x][0])
                }else{
                    group_ratio_variables[x][1].push(0)
                } 
            }
            for(let x=0;x<disallowed_variables.length;x++){
                if(disallowed_variables[x][0] !== undefined){
                    if(disallowed_variables[x][0] === true){
                        disallowed_variables[x][1].push("Yes")
                    }else if(disallowed_variables[x][0] === false){
                        disallowed_variables[x][1].push("No")
                    }else{
                        disallowed_variables[x][1].push(disallowed_variables[x][0])
                    }
                }else{
                    disallowed_variables[x][1].push(0)
                    
                } 
            }

            unused_variables = [
                [savedData.periods_of_account[i].group_interest_allowance, 0, excelData.current_int_allowance],
                [savedData.periods_of_account[i].aggregate_net_tax_interest_expense, 0, excelData.current_tax_interest_expense],
            ]

            excess_variables = [
                [savedData.periods_of_account[i].excess_debt_cap_from_prior_period, 0, excelData.excess_debt_previous],
                [savedData.periods_of_account[i].group_debt_cap, 0, excelData.current_debt_cap],
                [(savedData.periods_of_account[i].aggregate_tax_ebitda) * (savedData.periods_of_account[i].group_percentage), 0, excelData.group_ratio_percent],
                [(savedData.periods_of_account[i].group_debt_cap) - ((savedData.periods_of_account[i].aggregate_tax_ebitda) * (savedData.periods_of_account[i].group_percentage)), 0, excelData.generated_debt_cap],
            ]

            carry_forward_variables = [
                [savedData.periods_of_account[i].excess_debt_cap_from_prior_period, 0, excelData.excess_immediate],
                [savedData.periods_of_account[i].group_disallowance, 0, excelData.disallowed_period],
                [(savedData.periods_of_account[i].excess_debt_cap_from_prior_period) + (savedData.periods_of_account[i].group_disallowance), 0, excelData.carry_limit],
                [Math.min((savedData.periods_of_account[i].excess_debt_cap_from_prior_period) + (savedData.periods_of_account[i].group_disallowance), (savedData.periods_of_account[i].group_debt_cap) - ((savedData.periods_of_account[i].aggregate_tax_ebitda) * (savedData.periods_of_account[i].group_percentage))), 0, excelData.excess_carry_limit],
            ]

            //CIR attributes
            for(let x=0;x<unused_variables.length;x++){
                if(unused_variables[x][0] !== undefined){
                    unused_variables[x][2].push(unused_variables[x][0])
                }else{
                    unused_variables[x][2].push(unused_variables[x][1])
                    
                } 
            }
            for(let x=0;x<excess_variables.length;x++){
                if(excess_variables[x][0] !== undefined){
                    excess_variables[x][2].push(excess_variables[x][0])
                }else{
                    excess_variables[x][2].push(excess_variables[x][1])
                    
                } 
            }
            for(let x=0;x<carry_forward_variables.length;x++){
                if(carry_forward_variables[x][0] !== undefined){
                    carry_forward_variables[x][2].push(carry_forward_variables[x][0])
                }else{
                    carry_forward_variables[x][2].push(carry_forward_variables[x][1])
                    
                } 
            }
            for(let a=0;a<savedData.periods_of_account[i].adjusted_caps.length;a++){
                companies.push(savedData.periods_of_account[i].adjusted_caps[a].company_name)
                //console.log(savedData.periods_of_account[i].adjusted_caps[a].company_name)
                //console.log(savedData.periods_of_account[i].adjusted_caps[a])
                excelData.compNames.push(savedData.periods_of_account[i].adjusted_caps[a].company_name)
                excelData.currency.push(savedData.periods_of_account[i].adjusted_caps[a].currency)
                excelData.APSD.push(processDateJS(savedData.periods_of_account[i].adjusted_caps[a].start_date).format('DD/MM/YYYY'))
                excelData.APED.push(processDateJS(savedData.periods_of_account[i].adjusted_caps[a].end_date).format('DD/MM/YYYY'))
                excelData.regarded_period.push(savedData.periods_of_account[i].adjusted_caps[a].inclusion_period_length);
                excelData.disregarded_percent.push(savedData.periods_of_account[i].adjusted_caps[a].inclusion_factor)
                excelData.fx.push(savedData.periods_of_account[i].adjusted_caps[a].fx_rate)
            

                for(let c =0;c<savedData.companies.length;c++){
                    //checking the data is for the right company
                        if(savedData.companies[c].company_id === savedData.periods_of_account[i].adjusted_caps[a].company_id){
                            if(processDateJS(savedData.companies[c].date_join) != undefined){
                                excelData.date_joined.push(processDateJS(savedData.companies[c].date_join).format("DD/MM/YYYY"))
                            }else{
                                excelData.date_joined.push("N/A")
                                                
                            }
                            if(processDateJS(savedData.companies[c].date_left) != undefined){
                                excelData.date_lefted.push(processDateJS(savedData.companies[c].date_left).format("DD/MM/YYYY"))
                            }else{
                                excelData.date_lefted.push("N/A")
                            }
                        }
                        break
                }

                ntlr_potentialVariables = [
                    [savedData.periods_of_account[i].adjusted_caps[a].nltr_total_per_source_sheet_for_period, 0, excelData.ntlr_totalPerSource],
                    [savedData.periods_of_account[i].adjusted_caps[a].nltr_exclude_exchange_gains_losses, 0, excelData.ntlr_exclude_exchange],
                    [savedData.periods_of_account[i].adjusted_caps[a].nltr_exclude_impairment_gains_losses, 0, excelData.ntlr_exclude_impairment],
                    [savedData.periods_of_account[i].adjusted_caps[a].nltr_exclude_tax_interest_income_double_relief, 0, excelData.ntlr_exclude_tax],
                    [savedData.periods_of_account[i].adjusted_caps[a].nltr_adjustment_amortised_cost_basis, 0, excelData.ntlr_adjustment_amortised],
                    [savedData.periods_of_account[i].adjusted_caps[a].nltr_adjustment_qualifying_election, 0, excelData.ntlr_adjustment_election],
                    [savedData.periods_of_account[i].adjusted_caps[a].nltr_exclude_defined_benefit_pension, 0, excelData.ntlr_exclude_benefit],
                    [savedData.periods_of_account[i].adjusted_caps[a].nltr_bank_expenses, 0, excelData.ntlr_bank_charges],
                    [savedData.periods_of_account[i].adjusted_caps[a].nltr_adjustment_gaap_transition, 0, excelData.ntlr_adjustment_gaap],
                    [savedData.periods_of_account[i].adjusted_caps[a].nltr_adjustment_lease_finance_cost, 0, excelData.ntlr_adjustment_cost],
                    [savedData.periods_of_account[i].adjusted_caps[a].ntlr_subtotal, 0, excelData.ntlr_trading_relationships],
                ]
                tlr_potentialVariables = [
                    [savedData.periods_of_account[i].adjusted_caps[a].tlr_total_per_source_sheet_for_period, 0, excelData.tlr_totalPerSource],
                    [savedData.periods_of_account[i].adjusted_caps[a].tlr_exclude_exchange_gains_losses, 0, excelData.tlr_exclude_exchange],
                    [savedData.periods_of_account[i].adjusted_caps[a].tlr_exclude_impairment_gains_losses, 0, excelData.tlr_exclude_impairment],
                    [savedData.periods_of_account[i].adjusted_caps[a].tlr_exclude_tax_interest_income_double_relief, 0, excelData.tlr_exclude_tax],
                    [savedData.periods_of_account[i].adjusted_caps[a].tlr_adjustment_amortised_cost_basis, 0, excelData.tlr_adjustment_amortised],
                    [savedData.periods_of_account[i].adjusted_caps[a].tlr_adjustment_qualifying_election, 0, excelData.tlr_adjustment_election],
                    [savedData.periods_of_account[i].adjusted_caps[a].tlr_exclude_defined_benefit_pension, 0, excelData.tlr_exclude_benefit],
                    [savedData.periods_of_account[i].adjusted_caps[a].tlr_bank_expenses, 0, excelData.tlr_bank_charges],
                    [savedData.periods_of_account[i].adjusted_caps[a].tlr_adjustment_gaap_transition, 0, excelData.tlr_adjustment_gaap],
                    [savedData.periods_of_account[i].adjusted_caps[a].tlr_adjustment_lease_finance_cost, 0, excelData.tlr_adjustment_cost],
                    [savedData.periods_of_account[i].adjusted_caps[a].tlr_subtotal, 0, excelData.tlr_trading_relationships],
                ]

//                 
                for(let x=0;x<ntlr_potentialVariables.length;x++){
                    
                    if(ntlr_potentialVariables[x][0] !== undefined && ntlr_potentialVariables[x][0].value !== null){
                        let value = typeof ntlr_potentialVariables[x][0] === 'object' ? ntlr_potentialVariables[x][0].value : ntlr_potentialVariables[x][0]
                        //console.log(value)
                        ntlr_potentialVariables[x][2].push(value)
                    }else{
                        ntlr_potentialVariables[x][2].push(ntlr_potentialVariables[x][1])
                    }
                    if(tlr_potentialVariables[x][0] !== undefined && tlr_potentialVariables[x][0].value != null){
                        let value = typeof tlr_potentialVariables[x][0] === 'object' ? tlr_potentialVariables[x][0].value : tlr_potentialVariables[x][0]
                        tlr_potentialVariables[x][2].push(value)
                    }else{
                        tlr_potentialVariables[x][2].push(tlr_potentialVariables[x][1])
                    }
                }
                //console.log(excelData.ntlr_totalPerSource)
                ntdc_potentialVariables = [
                    [savedData.periods_of_account[i].adjusted_caps[a].ntdc_total_per_source_sheet_for_period, 0, excelData.ntdc_totalPerSource],
                    [savedData.periods_of_account[i].adjusted_caps[a].ntdc_exclude_exchange_gains_losses, 0, excelData.ntdc_exclude_exchange],
                    [savedData.periods_of_account[i].adjusted_caps[a].ntdc_exclude_impairment_gains_losses, 0, excelData.ntdc_exclude_impairment],
                    [savedData.periods_of_account[i].adjusted_caps[a].ntdc_exclude_tax_interest_income_double_relief, 0, excelData.ntdc_dtr_s388],
                    [savedData.periods_of_account[i].adjusted_caps[a].ntdc_adjustment_disregard_regulations, 0, excelData.ntdc_adjustment_disregard],
                    [savedData.periods_of_account[i].adjusted_caps[a].ntdc_adjustment_qualifying_election, 0, excelData.ntdc_adjustment_qualifying],
                    [savedData.periods_of_account[i].adjusted_caps[a].ntdc_exclude_ordinary_trade, 0, excelData.ntdc_exclude_ordinary_trade], // todo why are the same and other arent?
                    [savedData.periods_of_account[i].adjusted_caps[a].ntdc_exclude_derivatives_without_subject_matters, 0, excelData.ntdc_exclude_derivatives_without_subject_matters],
                    [savedData.periods_of_account[i].adjusted_caps[a].ntdc_adjustment_gaap_transition, 0, excelData.ntdc_adjustment_gaap],
                    [savedData.periods_of_account[i].adjusted_caps[a].ntdc_subtotal, 0, excelData.ntdc_trading_contracts],
                ]
                
                tdc_potentialVariables = [
                    [savedData.periods_of_account[i].adjusted_caps[a].tdc_total_per_source_sheet_for_period, 0, excelData.tdc_totalPerSource],
                    [savedData.periods_of_account[i].adjusted_caps[a].tdc_exclude_exchange_gains_losses, 0, excelData.tdc_exclude_exchange],
                    [savedData.periods_of_account[i].adjusted_caps[a].tdc_exclude_impairment_gains_losses, 0, excelData.tdc_exclude_impairment],
                    [savedData.periods_of_account[i].adjusted_caps[a].tdc_exclude_tax_interest_income_double_relief, 0, excelData.tdc_dtr_s388],
                    [savedData.periods_of_account[i].adjusted_caps[a].tdc_adjustment_disregard_regulations, 0, excelData.tdc_adjustment_disregard],
                    [savedData.periods_of_account[i].adjusted_caps[a].tdc_adjustment_qualifying_election, 0, excelData.tdc_adjustment_qualifying],
                    [savedData.periods_of_account[i].adjusted_caps[a].tdc_exclude_ordinary_trade, 0, excelData.tdc_exclude_ordinary_trade],
                    [savedData.periods_of_account[i].adjusted_caps[a].tdc_exclude_derivatives_without_subject_matters, 0, excelData.tdc_exclude_derivatives_without_subject_matters],
                    [savedData.periods_of_account[i].adjusted_caps[a].tdc_adjustment_gaap_transition, 0, excelData.tdc_adjustment_gaap],
                    [savedData.periods_of_account[i].adjusted_caps[a].tdc_subtotal, 0, excelData.tdc_trading_contracts],
                ]
                
                for(let x=0;x<ntdc_potentialVariables.length;x++){
                    if(ntdc_potentialVariables[x][0] !== undefined && ntdc_potentialVariables[x][0].value != null){
                        let value = typeof ntdc_potentialVariables[x][0]  === 'object' ? ntdc_potentialVariables[x][0].value : ntdc_potentialVariables[x][0]
                        ntdc_potentialVariables[x][2].push(value)
                    }else{
                        ntdc_potentialVariables[x][2].push(ntdc_potentialVariables[x][1])
                    } 
                    if(tdc_potentialVariables[x][0] !== undefined && tdc_potentialVariables[x][0].value != null){
                        let value = typeof tdc_potentialVariables[x][0]  === 'object' ? tdc_potentialVariables[x][0].value : tdc_potentialVariables[x][0]
                        tdc_potentialVariables[x][2].push(value)
                    }else{
                        tdc_potentialVariables[x][2].push(tdc_potentialVariables[x][1])
                    } 
                }
                
                ofa_potentialVariables = [
                    [savedData.periods_of_account[i].adjusted_caps[a].ofa_total_per_source_sheet_for_period, 0, excelData.ofa_totalPerSource],
                    [savedData.periods_of_account[i].adjusted_caps[a].ofa_exclude_tax_interest_income_double_relief, 0, excelData.ofa_dtr_s388],
                    [savedData.periods_of_account[i].adjusted_caps[a].ofa_adjustment_qualifying_election, 0, excelData.ofa_adjustment_election],
                    [savedData.periods_of_account[i].adjusted_caps[a].ofa_adjustment_gaap_transition, 0, excelData.ofa_adjustment_gaap],
                    [savedData.periods_of_account[i].adjusted_caps[a].ofa_subtotal, 0, excelData.ofa_trading_relationships],
                ]

                for(let x=0;x<ofa_potentialVariables.length;x++){
                    if(ofa_potentialVariables[x][0] !== undefined && ofa_potentialVariables[x][0].value != null){
                        let value = typeof ofa_potentialVariables[x][0]  === 'object' ? ofa_potentialVariables[x][0].value : ofa_potentialVariables[x][0]
                        ofa_potentialVariables[x][2].push(value)
                    }else{
                        ofa_potentialVariables[x][2].push(ofa_potentialVariables[x][1])
                    } 
                }
                
                cte_potentialVariables = [
                    [savedData.periods_of_account[i].adjusted_caps[a].cte_condition_a, 0, excelData.cte_a],
                    [savedData.periods_of_account[i].adjusted_caps[a].cte_condition_b, 0, excelData.cte_b],
                    [savedData.periods_of_account[i].adjusted_caps[a].cte_restrictions_reactivations_included, 0, excelData.cte_restrictions],
                    [savedData.periods_of_account[i].adjusted_caps[a].tebitda_adjustments, 0, excelData.tebitda_adjustments],
                ]

                let cte_total =0;
                for(let x=0;x<cte_potentialVariables.length;x++){
                    if(cte_potentialVariables[x][0] !== undefined && cte_potentialVariables[x][0].value != null){
                        let value = typeof cte_potentialVariables[x][0] === 'object' ? cte_potentialVariables[x][0].value : cte_potentialVariables[x][0]
                        cte_potentialVariables[x][2].push(value)
                        cte_total = cte_total + value
                    }else{
                        cte_potentialVariables[x][2].push(cte_potentialVariables[x][1])
                    } 
                }
                //Total of all variables in above section
                excelData.cte_sum.push(cte_total)

                adjustments_potentialVariables = [
                    [savedData.periods_of_account[i].adjusted_caps[a].net_tax_interest_expense, 0, excelData.s407_net_tax],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_tiopa_capital_allowance_charges, 0, excelData.s407_capital_allowances],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_tiopa_relevant_intangible_debits_credits, 0, excelData.s407_debits_credits],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_tiopa_adjustment_intangible_debit_credits, 0, excelData.s407_automatic_adjustments],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_tiopa_other_period_losses , 0, excelData.s407_tiopa_other_period_losses],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_tiopa_loan_relationship_deficts_other_periods, 0, excelData.s407_loan_relationships],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_tiopa_expenses_management_other_periods, 0, excelData.s407_management_expenses],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_tiopa_group_relief, 0, excelData.s407_group_relief],
                ]
                
                for(let x=0;x<adjustments_potentialVariables.length;x++){
                    if(adjustments_potentialVariables[x][0] !== undefined && adjustments_potentialVariables[x][0].value != null){
                        let value = typeof adjustments_potentialVariables[x][0]  === 'object' ? adjustments_potentialVariables[x][0].value : adjustments_potentialVariables[x][0]
                        adjustments_potentialVariables[x][2].push(adjustments_potentialVariables[x][0].value)
                    }else{
                        if(adjustments_potentialVariables[x][0] !== undefined){
                            adjustments_potentialVariables[x][2].push(adjustments_potentialVariables[x][0])
                        }else{
                            adjustments_potentialVariables[x][2].push(adjustments_potentialVariables[x][1])
                        }     
                    }                    
                }
                for(let c=0;c<savedData.company_accounting_periods.length;c++){
                    if(savedData.company_accounting_periods[c].company_id===savedData.periods_of_account[i].adjusted_caps[a].company_id){
                        let calculate_ebitda = 
                            (savedData.periods_of_account[i].adjusted_caps[a].tax_ebitda.value ? savedData.periods_of_account[i].adjusted_caps[a].tax_ebitda.value : 0) - 
                            (savedData.company_accounting_periods[c].tax_ebitda ? savedData.company_accounting_periods[c].tax_ebitda : 0)
                        excelData.tax_ebitda_adjustments.push(calculate_ebitda)
                    }
                    break
                }         
                reliefs_potentialVariables = [
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_rd_expenditure_credit, 0, excelData.s407_expenditure_credit],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_rd_additional_relief, 0, excelData.s407_additional_relief],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_trading_loss, 0, excelData.s407_trading_loss],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_contaminated_land_relief, 0, excelData.s407_contaminated_land],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_creative_industries, 0, excelData.s407_creative_industries],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_charitable_donation, 0, excelData.s407_charitable],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_patent_box_profits, 0, excelData.s407_patent_box],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_dtr_adjustment_tax_rate_equal_uk, 0, excelData.s407_dtr_equal],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_dtr_adjustment_tax_rate_not_equal_uk, 0, excelData.s407_dtr_not_equal],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_adjustment_qualifying_infrastructure, 0, excelData.s407_adjustment_election],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_adjustment_gaap_items, 0, excelData.s407_adjustment_gaap],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_finance_lease_depreciation, 0, excelData.s407_finance_lease],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_long_funding_lease_deduction, 0, excelData.s407_long_funding_lease],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_finance_lease_rentals_receivable, 0, excelData.s407_lease_rentals_receivable],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_long_funding_lessee, 0, excelData.s407_long_funding_lessee],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_adjustment_misc_losses, 0, excelData.s407_miscellaneous_losses],
                    [savedData.periods_of_account[i].adjusted_caps[a].s407_relief_group_relief, 0, excelData.s407_unincluded_group_relief],
                    [savedData.periods_of_account[i].adjusted_caps[a].tax_ebitda, 0, excelData.s407_tax_ebitda_total],
                    [savedData.periods_of_account[i].adjusted_caps[a].tax_ebitda, 0, excelData.total_tax_ebitda],
                ]

                for(let x=0;x<reliefs_potentialVariables.length;x++){
                    if(reliefs_potentialVariables[x][0] !== undefined && reliefs_potentialVariables[x][0].value != null){
                        let value = typeof reliefs_potentialVariables[x][0]  === 'object' ? reliefs_potentialVariables[x][0].value : reliefs_potentialVariables[x][0]
                        reliefs_potentialVariables[x][2].push(value)
                    }else{
                        reliefs_potentialVariables[x][2].push(reliefs_potentialVariables[x][1])
                    } 
                }
                bfd_potentialVariables = [
                    [savedData.periods_of_account[i].adjusted_caps[a].bfd_non_trading_loan_relationships, 0, excelData.bfd_ntlr],
                    [savedData.periods_of_account[i].adjusted_caps[a].bfd_non_trading_derivatives, 0, excelData.bfd_ntd],
                    [savedData.periods_of_account[i].adjusted_caps[a].bfd_trading_loan_relationships, 0, excelData.bfd_tlr],
                    [savedData.periods_of_account[i].adjusted_caps[a].bfd_trading_derivatives, 0, excelData.bfd_td],
                    [savedData.periods_of_account[i].adjusted_caps[a].bfd_other_financing, 0, excelData.bfd_ofa],
                ]
                cir_potentialVariables = [
                    [savedData.periods_of_account[i].adjusted_caps[a].non_consenting_company, "-", excelData.cir_consenting],
                    [savedData.periods_of_account[i].adjusted_caps[a].trade_small_or_negligible, "-", excelData.cir_neglibible_trade],
                    [savedData.periods_of_account[i].adjusted_caps[a].trade_uncommercial_or_non_statutory, "-", excelData.cir_uncommercial_trade],
                    [savedData.periods_of_account[i].adjusted_caps[a].investment_business_ceased, "-", excelData.cir_investment_ceased],
                    [savedData.periods_of_account[i].adjusted_caps[a].cta10_change_in_ownership_rules, "-", excelData.cir_CTA10_ownership],
                ]
                company_potentialVariables = [
                    [savedData.periods_of_account[i].adjusted_caps[a].type_reit_company, "-", excelData.company_reit],
                    [savedData.periods_of_account[i].adjusted_caps[a].type_ring_fence_company, "-", excelData.company_ring_fence],
                    [savedData.periods_of_account[i].adjusted_caps[a].type_investment_manager, "-", excelData.company_investment],
                    [savedData.periods_of_account[i].adjusted_caps[a].type_insurance_company, "-", excelData.company_insurance],
                    [savedData.periods_of_account[i].adjusted_caps[a].type_shipping_company, "-", excelData.company_shipping],
                    [savedData.periods_of_account[i].adjusted_caps[a].type_cooperative_company, "-", excelData.company_cooperative],
                    [savedData.periods_of_account[i].adjusted_caps[a].type_charity_company, "-", excelData.company_charity],
                    [savedData.periods_of_account[i].adjusted_caps[a].type_banking_company, "-", excelData.company_banking],
                ]

                let bfd_sum =0;
                for(let x=0;x<bfd_potentialVariables.length;x++){
                    if(bfd_potentialVariables[x][0] !== undefined){
                        bfd_potentialVariables[x][2].push(bfd_potentialVariables[x][0])
                        bfd_sum = bfd_sum + bfd_potentialVariables[x][0]
                    }else{
                        bfd_potentialVariables[x][2].push(bfd_potentialVariables[x][1])
                    } 
                }
                excelData.bfd_total.push(bfd_sum)
                
                //True/False sections
                for(let x=0;x<cir_potentialVariables.length;x++){
                    if(cir_potentialVariables[x][0] !== undefined){
                        if(cir_potentialVariables[x][0]){
                            cir_potentialVariables[x][2].push("Yes")
                        }else{
                            cir_potentialVariables[x][2].push("No")
                        }
                    }else{
                        cir_potentialVariables[x][2].push(cir_potentialVariables[x][1])
                    } 
                }

                for(let x=0;x<company_potentialVariables.length;x++){
                    if(company_potentialVariables[x][0] !== undefined){
                        if(company_potentialVariables[x][0]){
                            company_potentialVariables[x][2].push("Yes")
                        }else{
                            company_potentialVariables[x][2].push("No")
                        }
                    }else{
                        company_potentialVariables[x][2].push(company_potentialVariables[x][1])
                    } 
                } 
                           
                    
                

                    
            
            }
        }
    }

    
    const output1 = [excelData.compNames, 
        excelData.APSD,
        excelData.APED,
        excelData.period_length,
        excelData.date_joined,
        excelData.date_lefted,
        excelData.regarded_period,
        excelData.disregarded_percent,
        [],
        excelData.currency,
        excelData.fx,
        [],
        [],
        [ "Net tax-interest expense/(income) section - Non-trading loan relationships"],
        excelData.ntlr_totalPerSource,
        excelData.note_1,
        excelData.ntlr_exclude_exchange,
        excelData.ntlr_exclude_impairment,
        excelData.ntlr_exclude_tax,
        excelData.ntlr_adjustment_amortised,
        excelData.ntlr_adjustment_election,
        excelData.ntlr_exclude_benefit,
        excelData.ntlr_bank_charges,
        excelData.ntlr_adjustment_gaap,
        excelData.ntlr_adjustment_cost,
        [],
        excelData.ntlr_trading_relationships,
        [],
        excelData.ntlr_total_net_tax,
        [],
        [],
        [ "Net tax-interest expense/(income) section - Trading loan relationships"],
        excelData.tlr_totalPerSource,
        excelData.note_1,
        excelData.tlr_exclude_exchange,
        excelData.tlr_exclude_impairment,
        excelData.tlr_exclude_tax,
        excelData.tlr_adjustment_amortised,
        excelData.tlr_adjustment_election,
        excelData.tlr_exclude_benefit,
        excelData.tlr_bank_charges,
        excelData.tlr_adjustment_gaap,
        excelData.tlr_adjustment_cost,
        [],
        excelData.tlr_trading_relationships,
        
        [],
        ["Net tax-interest expense/(income) section - Non-trading derivative contracts"],
        excelData.ntdc_totalPerSource,
        excelData.note_1,
        excelData.ntdc_exclude_exchange,
        excelData.ntdc_exclude_impairment,
        excelData.ntdc_dtr_s388,
        excelData.ntdc_adjustment_disregard,
        excelData.ntdc_adjustment_qualifying,
        excelData.ntdc_exclude_ordinary_trade,
        excelData.ntdc_exclude_derivatives_without_subject_matters,
        excelData.ntdc_adjustment_gaap,
        [],
        excelData.ntdc_trading_contracts,
    
        [],
        ["Net tax-interest expense/(income) section - Trading derivative contracts"],
        excelData.tdc_totalPerSource,
        excelData.note_1,
        excelData.tdc_exclude_exchange,
        excelData.tdc_exclude_impairment,
        excelData.tdc_dtr_s388,
        excelData.tdc_adjustment_disregard,
        excelData.tdc_adjustment_qualifying,
        excelData.tdc_exclude_ordinary_trade,
        excelData.tdc_exclude_derivatives_without_subject_matters,
        excelData.tdc_adjustment_gaap,
        [],
        excelData.tdc_trading_contracts,

        [],
        ["Net tax-interest expense/(income) section - Other financing arrangements"],
        excelData.ofa_totalPerSource,
        excelData.note_1,
        excelData.ofa_adjustment_finance_lease,
        excelData.ofa_adjustment_debt_factoring,
        excelData.ofa_adjustment_service_concession,
        excelData.ofa_dtr_s388,
        excelData.ofa_adjustment_election,
        excelData.ofa_adjustment_gaap,
        [],
        excelData.ofa_trading_relationships,

        [],
        ["Tax-EBITDA section"],  
        excelData.cte_a,
        excelData.cte_b,
        excelData.cte_restrictions,
        excelData.tebitda_adjustments,
        [],
        excelData.cte_sum,
    
        [],
        ["Adjustments - s407 TIOPA 2010"],
        excelData.s407_net_tax,
        excelData.s407_capital_allowances,
        excelData.s407_debits_credits,
        excelData.s407_automatic_adjustments,
        excelData.s407_tiopa_other_period_losses,
        excelData.s407_loan_relationships,
        excelData.s407_management_expenses,
        excelData.s407_group_relief,
        [],
        ["Qualifying tax reliefs - s407(3)"],
        excelData.s407_expenditure_credit,
        excelData.s407_additional_relief,
        excelData.s407_trading_loss,
        excelData.s407_contaminated_land,
        excelData.s407_creative_industries,
        excelData.s407_charitable,
        excelData.s407_patent_box,
        excelData.s407_dtr_equal,
        excelData.s407_dtr_not_equal,
        excelData.s407_adjustment_election,
        excelData.s407_adjustment_gaap,
        excelData.s407_finance_lease,
        excelData.note_2,
        excelData.s407_long_funding_lease,
        excelData.s407_lease_rentals_receivable,
        excelData.s407_long_funding_lessee,
        excelData.s407_miscellaneous_losses,
        excelData.s407_unincluded_group_relief,
        excelData.s407_tax_ebitda,
        [],
        excelData.s407_tax_ebitda_total,
        [],
        [],
        excelData.s407_tax_ebitda_total,
        excelData.tax_ebitda_adjustments,
        [],
        excelData.total_tax_ebitda,

        [],
        ["CIR specific attributes"],
        excelData.cir_consenting,
        excelData.cir_neglibible_trade,
        excelData.cir_uncommercial_trade,
        excelData.cir_investment_ceased,
        excelData.cir_CTA10_ownership,
        [],
        ["Brought forward disallowance balances"],
        excelData.bfd_ntlr,
        excelData.bfd_ntd,
        excelData.bfd_tlr,
        excelData.bfd_td,
        excelData.bfd_ofa,
        excelData.bfd_total,
        [],
        ["Company Type"],
        excelData.company_reit,
        excelData.company_ring_fence,
        excelData.company_investment,
        excelData.company_insurance,
        excelData.company_shipping,
        excelData.company_cooperative,
        excelData.company_charity,
        excelData.company_banking,
    

    ]

    const output2 = [
        ["Fixed Ratio Computation"],
        ["","","GBP"],
        excelData.aggregate_ebitda,
        excelData.fixed_ratio,
        [],
        excelData.angie,
        excelData.excess_debt,
        excelData.fixed_debt,
        [],
        excelData.basic_interest,
        excelData.antii,
        excelData.interest_allowance,
        [],
        [],
        ["Group Ratio Computation"],
        ["","","GBP"],
        excelData.qngie,
        excelData.group_ebitda,
        excelData.group_ratio,
        [],
        excelData.aggregate_ebitda2,
        excelData.group_ratio_ebitda,
        [],
        excelData.qngie,
        excelData.excess_debt2,
        excelData.group_debt_cap,
        [],
        excelData.group_basic_interest,
        excelData.antii,
        excelData.group_interest_allowance,
        [],
        [],
        ["Computation of Disallowance/reactivation"],
        [],
        [],
        excelData.group_ratio_election,
        ["","","GBP"],
        excelData.computation_int_allowance,
        excelData.unused_allowance,
        [],
        excelData.de_minimis,
        [],
        excelData.interest_capacity,
        [],
        excelData.aggregate_interest_expense,
        [],
        excelData.disallowed_amount,
        [],
        excelData.available_reactivation,
        [],
    ]

    const output3 = [
        ["Unused Interest Allowance (s394)"],
         
        ["","","GBP"],
        [],
        excelData.current_int_allowance,
        [],
        excelData.current_tax_interest_expense,
        excelData.reactivations,
        excelData.current_yr_interest,
        [],
        excelData.unused_interest_gen,
        excelData.unused_interest_bf,
        [],
        [],
        ["Excess Debt Cap (s400)"],
        ["","","GBP"],
        excelData.excess_debt_previous,
        [],
        excelData.current_debt_cap,
        excelData.group_ratio_percent,
        excelData.generated_debt_cap,
        [],
        ["Carrry forward limit"],
        ["","","GBP"],
        excelData.excess_immediate,
        excelData.disallowed_period,
        excelData.carry_limit,
        [],
        excelData.excess_carry_limit,
        [],
        [],
        ["This document has been prepared only for [client name] and solely for the purpose and on the terms agreed with [client name]. We accept no liability (including for negligence) to anyone else in connection with this document, and it may not be provided to anyone else."],
        ["If you receive a request under freedom of information legislation to disclose any information we provided to you, you will consult with us promptly before any disclosure."],
        ["[This is a draft prepared for discussion purposes only and should not be relied upon; the contents are subject to amendment or withdrawal and our final conclusions and findings will be set out in our final deliverable.]"],
        [message],
    ]

return [output1, output2,output3,companies]
}