import { useNavigate } from "react-router-dom";
import { Steppers, Stepper } from "@appkit4/react-components";
import { steps as navSteps } from "../components/ProgressNav";

function ReviewCalcNav({currentStep }) {
  const stepperSpace = 280;

  const steps = navSteps[9].children;
  
  const navigate = useNavigate();
  return (
    <div className="row">
      <div className="d-flex flex-row w-100" style={{padding: "0em 1em"}}>
        <div className="mx-1" >
          <Steppers space={stepperSpace} activeIndex={currentStep} onActiveIndexChange={(index) => navigate(steps[index].path)}>
            {steps?.map(({name}, index) => {
              if (index <= currentStep)
                return (
                  <Stepper
                    key={`${index}-${name}`}
                    // label={name}
                  />
                );
            })}
          </Steppers>
        </div>
        <div className="">
          <Steppers space={stepperSpace} readonly activeIndex={-1}>
            {steps?.map(({name}, index) => {
              if (index > currentStep)
                return (
                  <Stepper
                    key={index} 
                    // label={name}
                  />
                );
            })}
          </Steppers>
        </div>
      </div>
    </div>
  );
}
export default ReviewCalcNav;

