import { calculateUnusedAllowanceData } from "./allowanceCalc";
import { getGroupFixedRatioValues1, getGroupFixedRatioValues2 } from "./group-fixedRatioCalculations";


const getReviewCalc2Values = (savedData, poaIndex) => {

    // this function calculates the first half of the values for group / fixed ratios (up to the point where unused allowance values are used)
    // code to get fr_interest_allowance and gr_interest_allowance
    try {
        const poa = savedData?.periods_of_account[poaIndex];
        const updatedPOA = getGroupFixedRatioValues1(poa);
        savedData.periods_of_account[poaIndex] = updatedPOA;
    }
    catch (error) {
        // there were no adjusted caps values
        console.log(error)
        return;
    }
    // code to calculate aggregated FR/GR unused and unexpired allowance
    // need these datapoints: 
    savedData = calculateUnusedAllowanceData(savedData, poaIndex);
    
    // second half of group / fixed ratio calculations which are dependant on the unused allowance function.
    const poa2 = savedData?.periods_of_account[poaIndex];
    const updatedPOA2 = getGroupFixedRatioValues2(poa2);
    //savedData.periods_of_account[poaIndex] = updatedPOA2;
    
    return updatedPOA2;
}

export { getReviewCalc2Values };