import { 
  Button as ReactButton,
  Input as ReactInput,
  CalendarPicker as ReactCalendarPicker,
  Switch as ReactSwitch,
  InputNumber as ReactInputNumber,
  Select as ReactSelect,
  Combobox as ReactCombobox,
  Checkbox as ReactCheckbox,
} from "@appkit4/react-components";
import { DataEditor as GlideDataEditor } from "@glideapps/glide-data-grid";

import { getCurrentPeriodIsFinalized, getUserIsReadonly } from "../services/GroupContext";
import { periodAccessPages } from "./ProgressNav";

export function getIsReadonly (enabledWhenFinalised) {
  const periodIsReadonly = periodAccessPages.includes(window.location.pathname.toLowerCase()) && getCurrentPeriodIsFinalized() && !enabledWhenFinalised;
  
  return getUserIsReadonly() || periodIsReadonly;
}

function SimpleReadOnlyAwareComponent(Component, { children, neverReadonly, enabledWhenFinalised, hiddenInReadonly, disabled, ...args }){
  const isReadonly = getIsReadonly(enabledWhenFinalised);

  return !(hiddenInReadonly && isReadonly) &&
      (<Component
        disabled={ disabled || (isReadonly && !neverReadonly ) }
        { ...args }
      >
        { children }
      </Component>)
}

function Button({ viewEditButton, children, ...args }){
  const isReadonly = getIsReadonly();
  children = viewEditButton ? isReadonly ? "View" : "Edit" : children;

  return SimpleReadOnlyAwareComponent( ReactButton, { children, ...args} )
}

function Input( args ){
  return SimpleReadOnlyAwareComponent( ReactInput, args );
}

function CalendarPicker( args ){
  return SimpleReadOnlyAwareComponent( ReactCalendarPicker, args );
}

function Switch( args ){
  return SimpleReadOnlyAwareComponent( ReactSwitch, args );
}

function InputNumber( args ){
  return SimpleReadOnlyAwareComponent( ReactInputNumber, args );
}

function Select( args ){
  return SimpleReadOnlyAwareComponent( ReactSelect, args );
}

function Combobox( args ){
  return SimpleReadOnlyAwareComponent( ReactCombobox, args );
}

function Checkbox( args ){
  return SimpleReadOnlyAwareComponent( ReactCheckbox, args );
}

function DataEditor({ getCellContent, onCellEdited, ...args }){
  const isReadonly = getIsReadonly();

  const readonlyGetCellContent = (...args) => {
    const cellContent = getCellContent(...args);
    cellContent.allowOverlay = !isReadonly && cellContent.allowOverlay;
    return cellContent;
  }

  const readonlyOnCellEdited = (...args) => {
    if (isReadonly) 
      return;

    onCellEdited(...args);
  }

  return <GlideDataEditor
          {...args}
          getCellContent={readonlyGetCellContent}
          onCellEdited={readonlyOnCellEdited}
        />
}


export { Button, Input, CalendarPicker, Switch, InputNumber, Select, Combobox, Checkbox, DataEditor };