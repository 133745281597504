import { createContext, useContext, useMemo, useState, useEffect } from "react";
import React from "react";
import "core-js/stable/atob";
import { jwtDecode } from "jwt-decode";
import { useErrorBoundary } from "react-error-boundary";
import { LOCAL_KEYS } from "./GroupContext";

// auth context stores the token and the user info which can be accessed from anywhere inside the app
const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const { showBoundary } = useErrorBoundary();
  const userLocalStorage = localStorage?.getItem(LOCAL_KEYS.user);
  const [user, setUser] = useState(
    userLocalStorage && userLocalStorage !== "undefined"
      ? JSON.parse(userLocalStorage)
      : null
  );
  const [readonly, setReadonly] = useState(
    localStorage.getItem(LOCAL_KEYS.userReadonly)
      ? JSON.parse(localStorage.getItem(LOCAL_KEYS.userReadonly))
      : false
  );

  useEffect(() => {
    console.log(LOCAL_KEYS.userReadonly, readonly)

  }, [readonly])

  useEffect(() => {
    if (user) {
      localStorage.setItem(LOCAL_KEYS.user, JSON.stringify(user));
    }
  }, [user]);

  useEffect(() => {
    if (readonly !== null) {
      localStorage.setItem(LOCAL_KEYS.userReadonly, JSON.stringify(readonly));
    }
  }, [readonly]);

  // call this function when you want to authenticate the user
  const login = async (data) => {
    console.log('@auth provider: logging in data = ', data)
    setUser(data);
    localStorage.setItem(LOCAL_KEYS.user, JSON.stringify(data));
  };

  // call this function to sign out logged in user
  const logout = () => {
    console.log('removing user from storage')
    setUser(null);
    localStorage.removeItem(LOCAL_KEYS.user);
    // stop counting time since last request (as user is already logged out)
    localStorage.removeItem(LOCAL_KEYS.lastRequest)
  };

  const getInitials = () => {
    if (user) {
      try {
        const userDetails = jwtDecode(user?.id_token);
        const initials = userDetails ? userDetails?.given_name[0] + userDetails?.family_name[0] : "";
        return initials;
      }
      catch {
        return "";
      }
    }
    return "";
  }

  const getFirstName = () => {
    if (user) {
      try {
        const userDetails = jwtDecode(user?.id_token);
        const name = userDetails ? userDetails?.given_name : "";
        return name;
      }
      catch {
        return "";
      }
    }
    return "";
  }

  const getScope = () => {
    const user = JSON.parse(localStorage.getItem(LOCAL_KEYS.user))
    if (user) {
      try {
        return user?.scope;
      }
      catch {
        return [];
      }
    }
    return [];
  }

  const addScope = ( scope ) => {
    const user = JSON.parse(localStorage.getItem(LOCAL_KEYS.user));
    user.scope = [...user.scope, scope];
    localStorage.setItem(LOCAL_KEYS.user, JSON.stringify(user));
  }

  const getEmail = () => {
    if (user) {
      try {
        const userDetails = jwtDecode(user?.id_token);
        return userDetails?.preferredMail;
      }
      catch {
        return "";
      }
    }
    return "";
  }

  const hasUser = () => {
    return user ? true : false;
  }

  const isAdmin = () => {
    if (hasUser()) {
      const adminScope = user?.scope?.find((scope) => scope?.group === '*')
      if (adminScope){
        return true;
      }
      return false;
    }
    showBoundary("User is not logged in");
  }

  const isReadOnlyUser = (groupName) => {
    if (hasUser()) {
      if (JSON.parse(localStorage.getItem(LOCAL_KEYS.userReadonly))) {
        return true;
      }
      if (isAdmin()) {
        return false;
      }
      if(groupName) {
        const groupScope = user?.scope?.find((scope) => scope?.group === groupName);
        if ( groupScope?.['role'] === "Reader" ) {
          return true;
        }
      }
      return false;
    }
    // throw error here if user is not logged in?
  }

  const userCanManageAccess = (groupName) => {
    if (hasUser()) {
      if (JSON.parse(localStorage.getItem(LOCAL_KEYS.userReadonly))) {
        return false;
      }
      if (isAdmin()) {
        return true;
      }
      const groupScope = getScope()?.find((scope) => scope?.group === groupName);
      if ( groupScope?.['role'] === "Supervisor" ) {
        return true;
      }
    }
    return false;
  }

  const value = useMemo(
    () => ({
      getInitials,
      hasUser,
      login,
      logout,
      getScope,
      isReadOnlyUser,
      userCanManageAccess,
      getEmail,
      getFirstName,
      setReadonly,
      isAdmin,
      addScope,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const auth = useContext(AuthContext);
  return auth;
  //return useContext(AuthContext)
};

export { AuthContext, AuthProvider };
