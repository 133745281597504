import { Panel, Loading } from '@appkit4/react-components'; 
import { Switch, InputNumber, DataEditor } from "../components/ReadonlyAwareInputs";
import { useEffect, useState, useCallback, useContext } from 'react';
import { BackButton, NextButton } from '../components/ProgressNav';
import RevCalcHeader from './ReviewCalcHeader';
import ReviewCalcNav from './revCalcNavigation';
import { getGroupData, setGroupData, getSelectedPeriod, getPoaIndex, getGroupName } from '../services/GroupContext';
import toast from 'react-hot-toast';
import "@glideapps/glide-data-grid/dist/index.css";
import {
    GridCellKind,
} from "@glideapps/glide-data-grid";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { calculate_reactivation_subtotals, getReviewCalc4Values } from '../services/calculations/calc4';
import { AuthContext } from '../services/AuthProvider';
import { processDateJS } from '../utils/dateProcessor';
dayjs.extend(utc)

const ReviewCalc4 = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState('')

    const auth = useContext(AuthContext)
    const savedData = getGroupData();
    const savedPOAData = getSelectedPeriod();
    const [allocationReadonly, setAllocationReadonly] = useState(false);
    const [comp_reactivation_table_data, set_comp_reactivation_table_data] = useState([]);
    const [comp_reactivation_table_columns, set_comp_reactivation_table_columns] = useState([]);
    const [remainingCompReactivation, setRemainingCompReactivation] = useState([]);
    const comp_reactivation_table_rows = [
        { metric_id: "interest_reactivation_cap_gbp", display_name: "Company Interest reactivation cap", mandatory: true, editable: true, type: 'number' },
        { metric_id: "amount_available_for_reactivation_gbp", display_name: "Amount available for reactivation", mandatory: true, type: 'number' },
        { metric_id: "allocated_reactivation", display_name: "Reactivation allocation", mandatory: true, editable: !allocationReadonly, type: 'number_total' }];
    const [ap_reactivation_table_data, set_ap_reactivation_table_data] = useState([]);
    const [ap_reactivation_table_columns, set_ap_reactivation_table_columns] = useState([]);
    const ap_reactivation_table_rows = [
        { metric_id: "start_date", display_name: "Accounting Period Start Date", mandatory: true, type: 'date' },
        { metric_id: "end_date", display_name: "Accounting Period End Date", mandatory: true, type: 'date' },
        { metric_id: "allocated_reactivation", display_name: "Reactivation allocated to the company", mandatory: true, type: 'number' },
        { metric_id: "election_override_automatic_allocations", display_name: "Apply s380(3) election, to override the automatic allocation of reactivated amounts", mandatory: true, editable: true, type: 'boolean' },
        { metric_id: "reactivation_amount_gbp", display_name: "Company - Amount available for reactivation", mandatory: true, type: 'number' },
        { metric_id: "ntlr_reactivation_amount_gbp", display_name: "NTLR tax-interest expense - available for reactivation", mandatory: true, type: 'number' },
        { metric_id: "cpr_non_trading_loan_relationships_gbp", display_name: "NTLR reactivated amount", mandatory: true, type: 'edit_number' },
        { metric_id: "ntdc_reactivation_amount_gbp", display_name: "NTDC tax-interest expense - available for reactivation", mandatory: true, type: 'number' },
        { metric_id: "cpr_non_trading_derivatives_gbp", display_name: "NTDC reactivated amount", mandatory: true, type: 'edit_number' },
        { metric_id: "tlr_reactivation_amount_gbp", display_name: "TLR tax-interest expense - available for reactivation", mandatory: true, type: 'number' },
        { metric_id: "cpr_trading_loan_relationships_gbp", display_name: "TLR reactivated amount", mandatory: true, type: 'edit_number' },
        { metric_id: "tdc_reactivation_amount_gbp", display_name: "TDC tax-interest expense - available for reactivation", mandatory: true, type: 'number' },
        { metric_id: "cpr_trading_derivatives_gbp", display_name: "TDC reactivated amount", mandatory: true, type: 'edit_number' },
        { metric_id: "ofa_reactivation_amount_gbp", display_name: "OFA tax-interest expense - available for reactivation", mandatory: true, type: 'number' },
        { metric_id: "cpr_other_financing_amounts_gbp", display_name: "OFA reactivated amount", mandatory: true, type: 'edit_number' },
        { metric_id: "remaining_reactivation", display_name: "Remaining reactivation to be allocated", mandatory: true, type: 'reactivation_total'}];
    

    // get calculated values
    useEffect(() => {
        const savedData = getGroupData();
        if (getPoaIndex() > -1 ) {
            const poa = getReviewCalc4Values(savedData?.periods_of_account[getPoaIndex()], savedData);
            if (poa) {
                savedData.periods_of_account[getPoaIndex()] = poa;
                setGroupData(savedData);
                setupTableData(savedData)
            }
            else {
                console.log('calculations failed')
            }
        }
        else {
            toast.error(`could not find POA data`, {
                duration: 30 * 1000 // 30s
              })
        }
    }, [])

    const setupTableData = (calculationResult) => {
        const savedPOAData = calculationResult?.periods_of_account[getPoaIndex() > -1 ? getPoaIndex() : 0]
        let group_reactivation_amount = 0;
        savedPOAData?.adjusted_caps?.forEach((cap) => {
            group_reactivation_amount += cap?.reactivation_amount_gbp || 0; 
        })
        setAllocationReadonly(savedPOAData?.group_interest_reactivation_cap > group_reactivation_amount);
        let temp_comp_reactivation_table_columns = [{ title: "", width: 200 }, { title: "Group Total", width: 200 }];
        savedPOAData?.adjusted_caps?.forEach((company, index) => {
            const prevComp = index > 0 ? savedPOAData?.adjusted_caps[index - 1]?.company_ID : null;
            if (index === 0 || company?.company_ID !== prevComp) {
                const companyName = calculationResult?.companies?.filter((c) => c?.company_ID === company?.company_ID)[0]['company_name']
                temp_comp_reactivation_table_columns?.push({ title: companyName, width: 200 })
                // also set default allocated_reactivation value
                savedPOAData.adjusted_caps[index].allocated_reactivation = (savedPOAData?.adjusted_caps[index]?.allocated_reactivation && !isNaN(savedPOAData?.adjusted_caps[index]?.allocated_reactivation)) ? savedPOAData?.adjusted_caps[index]?.allocated_reactivation : savedPOAData?.adjusted_caps[index]?.reactivation_amount_gbp;
            }
        })
        set_comp_reactivation_table_columns(temp_comp_reactivation_table_columns);
        let tempTableDataBuilder = [];
        comp_reactivation_table_rows?.forEach((rowHeader) => {
            let tempRowDataBuilder = [];
            let total = 0;
            savedPOAData?.adjusted_caps?.forEach((company, index) => {
                const prevComp = index > 0 ? savedPOAData?.adjusted_caps[index - 1]?.company_ID : null;
                if (index === 0 || company?.company_ID !== prevComp) {
                    if (rowHeader?.type?.includes('blank')) {
                        tempRowDataBuilder?.push("")
                    }
                    else if (company?.hasOwnProperty(rowHeader?.metric_id)) {
                        tempRowDataBuilder?.push(company[rowHeader?.metric_id]);
                        if (rowHeader?.type?.includes('number_total')) {
                            total += company[rowHeader?.metric_id];
                        }
                    }
                    else {
                        tempRowDataBuilder?.push('No value')
                    }
                }
            })
            if (rowHeader?.type?.includes('number_total')) {
                tempRowDataBuilder?.unshift(total)
            }
            else tempRowDataBuilder?.unshift("")
            tempTableDataBuilder?.push(tempRowDataBuilder);
        })
        set_comp_reactivation_table_data(tempTableDataBuilder);
        let reactivation_total = 0;
        comp_reactivation_table_columns?.forEach((col, index) => {
            if (index > 0 && tempTableDataBuilder[1][index] !== undefined && tempTableDataBuilder[2][index] !== undefined) {
                reactivation_total += tempTableDataBuilder[1][index] - tempTableDataBuilder[2][index]
            }
        })
        setRemainingCompReactivation(Math.min(reactivation_total, savedPOAData.group_interest_reactivation_cap));
        let temp_ap_reactivation_table_columns = [{ title: "", width: 200 }];
        savedPOAData?.adjusted_caps?.forEach((company, index) => {
            const prevComp = index > 0 ? savedPOAData?.adjusted_caps[index - 1]?.company_ID : null;
            if (index === 0 || company?.company_ID !== prevComp) {
                const companyName = calculationResult?.companies?.filter((c) => c?.company_ID === company?.company_ID)[0]['company_name']
                temp_ap_reactivation_table_columns?.push({ title: companyName, width: 200 })
                savedPOAData.adjusted_caps[index].election_override_automatic_allocations = savedPOAData?.adjusted_caps[index]?.election_override_automatic_allocations || false;
            }
        })
        set_ap_reactivation_table_columns(temp_ap_reactivation_table_columns)
        tempTableDataBuilder = [];
        ap_reactivation_table_rows?.forEach((rowHeader) => {
            let tempRowDataBuilder = [];
            savedPOAData?.adjusted_caps?.forEach((company, index) => {
                const prevComp = index > 0 ? savedPOAData?.adjusted_caps[index - 1]?.company_ID : null;
                if (index === 0 || company?.company_ID !== prevComp) {
                    if (rowHeader?.type?.includes('blank')) {
                        tempRowDataBuilder?.push("")
                    }
                    else if (rowHeader?.type?.includes('reactivation_total')) {
                        let remaining_reactivation = company?.allocated_reactivation - company?.cpr_non_trading_loan_relationships_gbp - company?.cpr_non_trading_derivatives_gbp - company?.cpr_trading_loan_relationships_gbp - company?.cpr_trading_derivatives_gbp - company?.cpr_other_financing_amounts_gbp;
                        tempRowDataBuilder?.push(remaining_reactivation)
                    }
                    else if (company?.hasOwnProperty(rowHeader?.metric_id)) {
                        tempRowDataBuilder?.push(company[rowHeader?.metric_id]);
                    }
                    else tempRowDataBuilder?.push('No value')
                }
            })
            tempTableDataBuilder?.push(tempRowDataBuilder);
        })
        set_ap_reactivation_table_data(tempTableDataBuilder);
    }

    // function to render the table cells
    const renderCompTable = useCallback(([col, row], rows, data) => {
        const metric = rows[row];
        if (metric?.type === 'blank') {
            return {
                kind: GridCellKind.Text,
                allowOverlay: false,
                data: " ",
                displayData: " ",
                span: [0, savedData?.length]
            };
        }

        if (metric?.type === 'header' || metric?.type === 'subheader' || metric?.type === 'note') {
            return {
                kind: GridCellKind.Text,
                allowOverlay: false,
                data: metric?.display_name,
                displayData: metric?.display_name,
                span: [0, savedData?.length]
            };
        }

        // Col 0 is the metric name
        if (col === 0) {
            return {
                kind: GridCellKind.Text,
                data: metric?.display_name,
                allowOverlay: false,
                displayData: metric?.display_name,
                allowWrapping: true
            };
        }

        // set up default values
        let cellType = GridCellKind.Text;
        let value = data[row][col-1];
        let displayValue = value ? String(value) : '0';

        // Col 1 is the totals column
        if (col === 1) {
            if (metric?.type?.includes('number_total')) {
                return {
                    kind: GridCellKind.Number,
                    data: value,
                    displayData: value ? Math?.round(value)?.toLocaleString() : '0',
                };
            }
            return {
                kind: GridCellKind.Text,
                allowOverlay: false,
                data: " ",
                displayData: " ",
                span: [0, savedData?.length]
            };
        }

        // if cell is only editable on selection of a checkbox
        if (metric?.type?.includes('edit_number')) {
            return {
                kind: GridCellKind.Number,
                data: value,
                allowOverlay: data[3][col-1],
                displayData: value ? Math?.round(value)?.toLocaleString() : '0',
            };
        }
        else if (metric?.type?.includes('date')) {
            value = displayValue = value ? processDateJS(value)?.format('DD/MM/YYYY') : '';
        } else if (metric?.type?.includes('number')) {
            cellType = GridCellKind.Number;
            displayValue = value ? Math?.round(value)?.toLocaleString() : '0';
        } else if (metric?.type?.includes('boolean')) {
            cellType = GridCellKind.Boolean
            if (value === 1 || value === true) {
                value = displayValue = true;
            } else if (value === 0 || value === false) {
                value = displayValue = false;
            } else {
                value = displayValue = false;
            }
        }
        return {
            kind: cellType,
            data: value,
            allowOverlay: metric?.hasOwnProperty('editable') ? metric?.editable : false,
            displayData: displayValue,
        };
    }, []);


    // function to render the table cells
    const renderAPTable = useCallback(([col, row], rows, data) => {
        const metric = rows[row];
        if (metric?.type === 'blank') {
            return {
                kind: GridCellKind.Text,
                allowOverlay: false,
                data: " ",
                displayData: " ",
                span: [0, savedData?.length]
            };
        }

        if (metric?.type === 'header' || metric?.type === 'subheader' || metric?.type === 'note') {
            return {
                kind: GridCellKind.Text,
                allowOverlay: false,
                data: metric?.display_name,
                displayData: metric?.display_name,
                span: [0, savedData?.length]
            };
        }

        // Col 0 is the metric name
        if (col === 0) {
            return {
                kind: GridCellKind.Text,
                data: metric?.display_name,
                allowOverlay: false,
                displayData: metric?.display_name,
                allowWrapping: true
            };
        }

        // set up default values
        let cellType = GridCellKind.Text;
        let value = data[row][col-1];
        let displayValue = value ? String(value) : '0';
        
        // if cell is only editable on selection of a checkbox
        if (metric?.type?.includes('edit_number')) {
            return {
                kind: GridCellKind.Number,
                data: value,
                allowOverlay: data[3][col-1],
                displayData: value ? Math?.round(value)?.toLocaleString() : '0',
            };
        }
        else if (metric?.type?.includes('date')) {
            value = displayValue = value ? processDateJS(value)?.format('DD/MM/YYYY') : '';
        } else if (metric?.type?.includes('number')) {
            cellType = GridCellKind?.Number;
            displayValue = value ? Math.round(value)?.toLocaleString() : '0';
        } else if (metric?.type?.includes('boolean')) {
            cellType = GridCellKind.Boolean
            if (value === 1 || value === true) {
                value = displayValue = true;
            } else if (value === 0 || value === false) {
                value = displayValue = false;
            } else {
                value = displayValue = false;
            }
        }
        return {
            kind: cellType,
            data: value,
            allowOverlay: metric?.hasOwnProperty('editable') ? metric?.editable : false,
            displayData: displayValue,
        };
    }, []);

    const [rowGrouping, setRowGrouping] = useState(() => ({
        groups: [{
            headerIndex: 0,
            isCollapsed: false
        }, {
            headerIndex: 10,
            isCollapsed: true,
            subGroups: [{
                headerIndex: 15,
                isCollapsed: false
            }, {
                headerIndex: 20,
                isCollapsed: false
            }]
        }, {
            headerIndex: 30,
            isCollapsed: false
        }],
        height: 55,
        navigationBehavior: "block",
        selectionBehavior: "block-spanning",
        themeOverride: {
            bgCell: "rgba(0, 100, 255, 0.1)"
        }
    }));
    const [zeroRemainingAllocation,setZeroRemainingAllocation] = useState(false)

    const validateReactivationAllocation = ()=>{
        return zeroRemainingAllocation?comp_reactivation_table_data[2].some(e=>e!=0):Math.round(remainingCompReactivation)?.toLocaleString()!=0
    }

    if (isLoading)
    return (
      <Loading
        loadingType="linear"
        indeterminate={true}
        compact={false}
        className="page-loader"
      ></Loading>
    );
    return (
        <div className="ap-container">
            <Panel>
                <RevCalcHeader groupname = "Reactivation of interest" />
                <ReviewCalcNav currentStep={3}/>
                <p>Where a group has an 'amount available for reactivation' in respect of a return period, this amount needs to be allocated among the companies in the group, subject to each company's interest reactivation cap.</p>
                <p className='col-4'>Group Interest Reactivation Cap: <strong>{Math.round(savedPOAData.group_interest_reactivation_cap)?.toLocaleString()}</strong></p>
                <h2>Company - Reactivation Allocation</h2>
                <p>Where an amount is available for reactivation in respect of the Period of Account of a Group, this needs to be allocated among the companies within the group, subject to each company's reactivation limit.</p>
                <p className='err'>{isError}</p>
                <Switch className='mb-2' checked={zeroRemainingAllocation} onChange={()=>setZeroRemainingAllocation(!zeroRemainingAllocation)} >
                Toggle this box if you intend to finalise the calculation without a reporting company or make an abbreviated return. In these cases, no reactivations can be made in the period, and all allocations must be set to zero.
                    </Switch>
                <div id="portal" style={{ position: 'fixed', left: 0, top: 0, zIndex: 9999 }} />
                <DataEditor
                        columns={comp_reactivation_table_columns}
                        getCellContent={([col, row]) => renderCompTable([col, row], comp_reactivation_table_rows, comp_reactivation_table_data)}
                        rows={comp_reactivation_table_rows?.length}
                        rowGrouping={rowGrouping}
                        onCellEdited={([col, row], newValue) => {
                            const metric = comp_reactivation_table_rows[row];
                            if (!metric) return;

                            let value = newValue?.data;
                            if (metric?.type === 'date') {
                                const dayjsDate = processDateJS(value)?.format('DD/MM/YYYY');
                                if (!dayjsDate?.isValid()) {
                                    toast.error('Date must be entered in dd/mm/yyyy format');
                                    return;
                                }
                                value = dayjsDate?.toDate();
                            }

                            let temp_comp_reactivation_data = [...comp_reactivation_table_data];
                            
                            if (value < 0 || value > temp_comp_reactivation_data[row - 1][col - 1]) {
                                setIsError('Table Error: Reactivation allocation must be a number greater than 0 and less than the amount available for reactivation');
                            } else {
                                setIsError('');
                            }

                            temp_comp_reactivation_data[row][col - 1] = value || 0;

                            if (metric.metric_id === 'allocated_reactivation' && ["D Ltd", "C Ltd", "A Ltd", "B Ltd"].includes(comp_reactivation_table_columns[col].title)) {
                                const companyName = comp_reactivation_table_columns[col].title;
                                const apTableColumnIndex = ap_reactivation_table_columns.findIndex(col => col.title === companyName);

                                if (apTableColumnIndex !== -1) {
                                    let temp_ap_reactivation_data = [...ap_reactivation_table_data];
                                    const apReactivationRowIndex = ap_reactivation_table_rows.findIndex(row => row.metric_id === 'allocated_reactivation');
                                    temp_ap_reactivation_data[apReactivationRowIndex][apTableColumnIndex - 1] = value;
                                    set_ap_reactivation_table_data(temp_ap_reactivation_data);
                                }
                            }

                            const totalsColumn = 0;
                            let total = 0;
                            if (metric?.type?.includes('number_total')) {
                                temp_comp_reactivation_data[row]?.forEach((val, index) => {
                                    if (!isNaN(parseInt(val)) && index > totalsColumn) {
                                        total += parseInt(val);
                                    }
                                });
                            }
                            temp_comp_reactivation_data[row][totalsColumn] = total;
                            set_comp_reactivation_table_data(temp_comp_reactivation_data);

                            let reactivation_total = 0;
                            comp_reactivation_table_columns?.forEach((col, index) => {
                                if (index > 0 && temp_comp_reactivation_data[1][index] !== undefined && temp_comp_reactivation_data[2][index] !== undefined) {
                                    reactivation_total += temp_comp_reactivation_data[1][index] - temp_comp_reactivation_data[2][index];
                                }
                            });
                            setRemainingCompReactivation(Math.min(reactivation_total, savedPOAData.group_interest_reactivation_cap));
                        }}
                        getRowThemeOverride={i => {
                            const metric = comp_reactivation_table_rows[i];
                            if (metric) {
                                if (metric?.type === 'blank') {
                                    return {
                                        bgCell: "#F4F3F7"
                                    }
                                }

                                if (metric?.type === 'header') {
                                    return {
                                        baseFontStyle: "600 18px",
                                    }
                                }

                                if (metric?.type === 'subheader') {
                                    return {
                                        baseFontStyle: "600 15px",
                                    }
                                }

                                if (metric?.type === 'note') {
                                    return {
                                        baseFontStyle: "600 12px",
                                    }
                                }
                            }
                        }}
                    />
                <div className='row'>
                    <div className='col-3'>Remaining reactivation to be allocated</div>
                    <InputNumber
                        className='col-auto'
                        value={Math.round(remainingCompReactivation)?.toLocaleString()}
                        readonly
                    ></InputNumber>
                </div>
                <h2>Accounting Period - Reactivation allocation</h2>
                <p className='mb-0'>Where a company has an allocated reactivation amount in respect of the Period of Account of a Group, the reactivated tax-interest amounts are brought into account in the earliest relevant accounting period.
                <br/>By default, tax-interest expense amounts must be brought into account in the following order:</p>
                <ol className='mb-2'>
                    <li>Non-trading debits in respect of loan relationships</li>
                    <li>Non-trading debits in respect of derivative contracts</li>
                    <li>Debits in respect of loan relationships treated as expenses of trade</li>
                    <li>Debits in respect of derivative contracts treated as expenses of trade</li>
                    <li>Finance leases, debt factoring and service concession arrangements</li>
                </ol>
                <p>A company may make an election for this order not to apply for an accounting period, in which case the company must specify the particular tax-interest expense amounts that are to be left out of account:</p>
                <DataEditor
                        columns={ap_reactivation_table_columns}
                        getCellContent={([col, row]) => renderAPTable([col, row], ap_reactivation_table_rows, ap_reactivation_table_data)}
                        rows={ap_reactivation_table_rows?.length}
                        rowGrouping={rowGrouping}
                        onCellEdited={([col, row], newValue) => {
                            const metric = ap_reactivation_table_rows[row];
                            if (!metric) {
                                return;
                            }

                            let value = newValue?.data;
                            if (metric?.type === 'date') {
                                const dayjsDate = processDateJS(value)?.format('DD/MM/YYYY');
                                if (!dayjsDate?.isValid()) {
                                    toast.error('Date must be entered in dd/mm/yyyy format')
                                    return
                                }
                                value = dayjsDate?.toDate()
                            }
                            let temp_ap_reactivation_data = [...ap_reactivation_table_data]
                            temp_ap_reactivation_data[row][col-1] = isNaN(value) ? 0 : Math.round(value) 
                            if (metric?.metric_id === 'election_override_automatic_allocations') {
                                temp_ap_reactivation_data[row][col - 1] = value;
                                if (value === false) {
                                    const cap = savedPOAData?.adjusted_caps?.find((cap) => cap?.company_name === ap_reactivation_table_columns[col]?.title && processDateJS(cap?.start_date)?.isSame(processDateJS(temp_ap_reactivation_data[0][col-1])))
                                    if (cap) {
                                        const newSubtotals = calculate_reactivation_subtotals(cap);
                                        temp_ap_reactivation_data[6][col-1] = newSubtotals?.cpr_non_trading_loan_relationships_gbp;
                                        temp_ap_reactivation_data[8][col-1] = newSubtotals?.cpr_non_trading_derivatives_gbp;
                                        temp_ap_reactivation_data[10][col-1] = newSubtotals?.cpr_trading_loan_relationships_gbp;
                                        temp_ap_reactivation_data[12][col-1] = newSubtotals?.cpr_trading_derivatives_gbp;
                                        temp_ap_reactivation_data[14][col-1] = newSubtotals?.cpr_other_financing_amounts_gbp;
                                    }
                                }
                            }
                            if (metric?.type === 'boolean') {
                                temp_ap_reactivation_data[3][col-1] = value;
                            }
                            temp_ap_reactivation_data[15][col-1] = temp_ap_reactivation_data[4][col-1]
                            - temp_ap_reactivation_data[6][col-1]
                            - temp_ap_reactivation_data[8][col-1]
                            - temp_ap_reactivation_data[10][col-1]
                            - temp_ap_reactivation_data[12][col-1]
                            - temp_ap_reactivation_data[14][col-1]
                            set_ap_reactivation_table_data(temp_ap_reactivation_data)
                            // // Siva: TODO If the business team ask to implement column reordering on this page, we will need to update this to search by compnay name 
                            // // This is because the order in the data variable will be different 
                        }}

                        getRowThemeOverride={i => {
                            const metric = ap_reactivation_table_rows[i];
                            if (metric) {
                                if (metric?.type === 'blank') {
                                    return {
                                        bgCell: "#F4F3F7"
                                    }
                                }

                                if (metric?.type === 'header') {
                                    return {
                                        baseFontStyle: "600 18px",
                                    }
                                }

                                if (metric?.type === 'subheader') {
                                    return {
                                        baseFontStyle: "600 15px",
                                    }
                                }

                                if (metric?.type === 'note') {
                                    return {
                                        baseFontStyle: "600 12px",
                                    }
                                }
                            }
                        }}
                    />

                <div className='container-fluid mt-4'>
                    <div className='d-flex justify-content-between align-items-center'> 
                        <div>
                            <BackButton/>
                        </div>
                        <div className='d-flex gap-2'>
                            <NextButton disabled={isError?.length > 0 || validateReactivationAllocation()}>Next</NextButton>
                        </div>
                    </div>
                </div>

            </Panel>
        </div>
    );
};

export default ReviewCalc4;
