import { Panel,  Table, Column } from '@appkit4/react-components'; 
import { Button, Input, InputNumber, Checkbox } from "../components/ReadonlyAwareInputs";
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { getGroupData, getGroupName, getFormattedSelectedPeriod, getSelectedPeriod } from '../services/GroupContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { metrics } from '../services/calculations/EntityCalculations';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { AuthContext } from '../services/AuthProvider';
import { processDateJS } from '../utils/dateProcessor';
dayjs.extend(isSameOrBefore);
dayjs.extend(utc)
const EditAutomaticAllocations = ({ navBack, company_period, set_company_period, adjusted_caps, set_adjusted_caps, updateGroupData }) => {
    const selectedPeriod = getSelectedPeriod();
    const auth = useContext(AuthContext)
    const savedData = getGroupData();

    const fetch_data = (calculation_group) => {
        const filtered_metrics = metrics?.filter(metric => metric?.calculation_group && metric?.calculation_group?.includes(calculation_group));
        const original_cap = savedData?.company_accounting_periods?.find((cap) => cap?.company_ID === company_period?.company_ID && cap?.start_date === company_period?.start_date)
        return filtered_metrics?.map(metric => {
            const input_row = company_period[metric?.metric_id];
            const original_row = original_cap[metric?.metric_id]
            const inclusion_factor = company_period?.calculated_data?.inclusion_factor;
            let manually_adjusted = false;
            let prior_amount = 0;
            let current_amount = 0;
            let ap_total = 0;
            if (original_row) {
                ap_total = original_row;
            }
            if (input_row) {
                manually_adjusted = input_row?.hasOwnProperty('manual_adjustment') ? input_row?.manual_adjustment : false;
                current_amount = input_row?.hasOwnProperty('value') ? input_row?.value : input_row;
            }
            // If no manual adjustments apply the inclusion factor
            if (manually_adjusted === false && inclusion_factor) {
                current_amount = current_amount * inclusion_factor;
            }
            // get all poas which the accounting period covers
            const prior_poas = savedData?.periods_of_account?.filter((poa) => processDateJS(poa?.period_end)?.isAfter(processDateJS(company_period?.start_date)) && processDateJS(poa?.period_start)?.isBefore(processDateJS(selectedPeriod?.period_start)))
            prior_poas?.forEach((poa) => {
                // get matching ap from adjusted caps
                const matching_cap = poa?.adjusted_caps?.find((cap) => cap?.start_date === company_period?.start_date && cap?.end_date === company_period?.end_date && cap?.company_ID === company_period?.company_ID)
                const matching_row = matching_cap?.[metric?.metric_id];
                prior_amount += matching_row && matching_row?.hasOwnProperty('value') ?  matching_row?.value : matching_row;
            })
            const future_amount = ap_total - current_amount - prior_amount;
            return {
                id: metric?.metric_id,
                description: metric?.display_name,
                manual_adjustment: manually_adjusted,
                accounting_period_total: ap_total,
                prior_cir_amount: prior_amount,
                current_cir_amount: current_amount,
                future_cir_amount: future_amount
            }
        })
    }

    const [ntlr_data, set_ntlr_data] = useState(fetch_data('ntlr'))
    const [ntdc_data, set_ntdc_data] = useState(fetch_data('ntdc'))
    const [tlr_data, set_tlr_data] = useState(fetch_data('tlr'))
    const [tdc_data, set_tdc_data] = useState(fetch_data('tdc'))
    const [ofa_data, set_ofa_data] = useState(fetch_data('ofa'))
    const [tax_ebitda_data, set_tax_ebitda_data] = useState(fetch_data('tax_ebitda'))

    const render_current_cir_input = (row, table_data, set_table_data) => {
        return <InputNumber
            value={Math.round(Number(row?.current_cir_amount))}
            readonly={!row?.manual_adjustment || processDateJS(company_period?.end_date)?.isSameOrBefore(processDateJS(selectedPeriod?.period_end))}
            format=','
            onChange={(val) => {
                const updatedRow = { ...row };
                updatedRow.current_cir_amount = val;
                updatedRow.future_cir_amount = updatedRow?.accounting_period_total - val - updatedRow?.prior_cir_amount;
                const index = table_data?.findIndex(r => r?.description === row?.description);
                if (index === -1) {
                    toast.error(`Failed to update current CIR amount for ${row?.description}`);
                    return
                }
                const newData = [...table_data];
                newData[index] = updatedRow;
                set_table_data(newData);
            }}
            min={0}
            max={row?.accounting_period_total - row?.prior_cir_amount}
        />
    }
    const render_prior_cir_input = (row, table_data, set_table_data) => {
        if (processDateJS(company_period?.start_date)?.isSameOrAfter(processDateJS(selectedPeriod?.period_start))) {
            return '';
        }
        if (!processDateJS(selectedPeriod?.period_start)?.isSame(processDateJS(savedData?.model_first_period_start))) {
            return formatNumber(row['prior_cir_amount'])
        }
        return <InputNumber
            value={Math.round(Number(row?.prior_cir_amount))}
            format=','
            onChange={(val) => {
                const updatedRow = { ...row };
                updatedRow.prior_cir_amount = val;
                // if the AP ends before the period end, current holds the balance
                if (processDateJS(company_period?.end_date)?.isSameOrBefore(processDateJS(selectedPeriod?.period_end))) {
                    updatedRow.current_cir_amount = updatedRow?.accounting_period_total - val;
                }
                else { // if AP ends after the period end, future holds the balance
                    updatedRow.future_cir_amount = updatedRow?.accounting_period_total - val - updatedRow?.current_cir_amount;
                }
                const index = table_data?.findIndex(r => r?.description === row?.description);
                if (index === -1) {
                    toast.error(`Failed to update current CIR amount for ${row?.description}`);
                    return
                }
                const newData = [...table_data];
                newData[index] = updatedRow;
                set_table_data(newData);
            }}
            min={0}
            max={row?.accounting_period_total}
        />
    }

    const render_future_cir_input = (value) => {
        if (processDateJS(company_period?.end_date).isSameOrBefore(processDateJS(selectedPeriod?.period_end))) {
            return '';
        }
        return formatNumber(value)
    }

    const formatNumber = (value) => {
        if (!value) return ''; // Return empty string if value is null or undefined
        // Convert value to a number, round to the nearest integer, and add commas for thousands and millions separator
        return Math.round(Number(value))?.toLocaleString('en-US');
    };

    const render_manual_adjustment = (row, set_table) => {
        if (!row?.hasOwnProperty('manual_adjustment')) {
            return <></>;
        }

        return <Checkbox
            className='col'
            checked={row?.manual_adjustment}
            onChange={(checkedValue) => {
                const updatedRow = { ...row };
                updatedRow.manual_adjustment = checkedValue;
                if (!checkedValue) { // reset any adjustments when the box is unticked
                    // if the AP ends before the period end, current holds the balance
                    if (processDateJS(company_period?.end_date)?.isSameOrBefore(processDateJS(selectedPeriod?.period_end))) {
                        updatedRow.current_cir_amount = updatedRow?.accounting_period_total - updatedRow?.prior_cir_amount;
                    }
                    else { // if AP ends after the period end, future holds the balance
                        updatedRow.current_cir_amount = updatedRow?.accounting_period_total * company_period?.inclusion_factor;
                        updatedRow.future_cir_amount = updatedRow?.accounting_period_total - updatedRow?.prior_cir_amount - updatedRow?.current_cir_amount;
                    }
                }

                set_table((previousTableData) => {
                    const newData = [...previousTableData];
                    const index = newData?.findIndex((currentRow) => currentRow?.description === row?.description);
                    if (index === -1) {
                        toast.error(`Failed to update manual adjustment for ${row?.description}`);
                        return previousTableData;
                    }

                    newData[index] = updatedRow;
                    return newData;
                });
            }}
        />
    }

    const [show_prior_period, set_show_prior_period] = useState(false);
    useEffect(() => {
        const poa = getGroupData()?.periods_of_account[0]; //TODO select the correct PoA
        const shouldShow = processDateJS(company_period?.start_date)?.isSameOrBefore(processDateJS(poa?.period_start)) ?? false;
        set_show_prior_period(shouldShow);
    }, [company_period])

    const calculate_sub_total = (table) => {
        let counter = 0;
        for (const row of table) {
            const value = row['current_cir_amount']
            counter = counter + value;
        }
        return counter;
    }

    useEffect(() => {
        const ntlr_table = fetch_data('ntlr');
        set_ntlr_data(ntlr_table)

        const ntdc_table = fetch_data('ntdc');
        set_ntdc_data(ntdc_table)

        const tlr_table = fetch_data('tlr')
        set_tlr_data(tlr_table);

        const tdc_table = fetch_data('tdc');
        set_tdc_data(tdc_table);

        const ofa_table = fetch_data('ofa')
        set_ofa_data(ofa_table);

        const tax_ebitda_table = fetch_data('tax_ebitda')
        set_tax_ebitda_data(tax_ebitda_table)
    }, [company_period, show_prior_period])

    const save_data = () => {
        const all_data = [...ntlr_data, ...ntdc_data, ...tlr_data, ...tdc_data, ...ofa_data, ...tax_ebitda_data]

        const index = adjusted_caps?.findIndex(cap => {
            if (cap?.company_ID !== company_period?.company_ID) return false;
            if (cap?.start_date && new Date(cap?.start_date)?.toISOString() !== new Date(company_period?.start_date)?.toISOString()) return false;
            if (cap?.end_date && new Date(cap?.end_date)?.toISOString() !== new Date(company_period?.end_date)?.toISOString()) return false;

            return true;
        })

        if (index === -1) {
            return;
        }

        const data = adjusted_caps;
        const edited_cap = adjusted_caps[index];

        for (const row of all_data) {
            const metric = metrics?.filter(m => m?.metric_id === row?.id);
            if (metric && metric?.length > 0) {
                const key = metric[0]?.metric_id;
                edited_cap[key] = {total: row?.accounting_period_total, value: row?.current_cir_amount, manual_adjustment: row?.manual_adjustment}
                edited_cap.adjustment_status = row?.manual_adjustment ? 'Manual Adjustment' : edited_cap?.adjustment_status;
            }
        }

        data[index] = edited_cap;
        set_adjusted_caps(data)
        updateGroupData(data)
    }

    return (
        <div className="ap-container">
            <div className="d-flex justify-content-end flex-row gap-2 ap-my-spacing-6" >
                <Button onClick={() => {navBack(false)}}>Back</Button>
                <Button hiddenInReadonly onClick={() => save_data()}>Save</Button>
            </div>
            <div>
                <Panel className='mt-4' title='Group Detail'>
                    <div className='row'>
                        <p className='col-4'>Group Name:</p>
                        <Input
                            className='col'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={savedData['group_name']}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Ultimate Parent:</p>
                        <Input
                            className='col'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={savedData['ult_parent_name']}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Selected Period</p>
                        <Input className='col'
                            value={getFormattedSelectedPeriod()}
                            required={true}
                            disabled
                            hideTitleOnInput
                        ></Input>
                    </div>
                    <div className='row'>
                        <p className='col-4'>Selected Company + Accounting period</p>
                        <Input className='col'
                            value={savedData?.companies?.filter((c) => c?.company_ID === company_period?.company_ID)[0]['company_name']}
                            required={true}
                            disabled
                            hideTitleOnInput
                        ></Input>
                    </div>
                </Panel>
                <Panel title='Company Detail' className='mt-4'>
                    <div className='row'>
                        <p className='col-4'>Company Name</p>
                        <Input
                            className='col'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={savedData?.companies?.filter((c) => c?.company_ID === company_period?.company_ID)[0]['company_name']}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Accounting Period Start Date:</p>
                        <Input
                            className='col'
                            title={"Start Date"}
                            hideTitleOnInput
                            value={processDateJS(company_period?.start_date)?.format('DD/MM/YYYY')}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Accounting Period End Date:</p>
                        <Input
                            className='col'
                            title={"Start Date"}
                            hideTitleOnInput
                            value={processDateJS(company_period?.end_date)?.format('DD/MM/YYYY')}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Group Period of Account Start Date</p>
                        <Input
                            className='col'
                            title={"Group Period Start Date"}
                            hideTitleOnInput
                            value={processDateJS(company_period?.group_period_start)?.format('DD/MM/YYYY')}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Group Period of Account End Date</p>
                        <Input
                            className='col'
                            title={"Group Period End Date"}
                            hideTitleOnInput
                            value={processDateJS(company_period?.group_period_end)?.format('DD/MM/YYYY')}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Regarded Period Start Date:</p>
                        <Input
                            className='col'
                            title={"Start Date"}
                            hideTitleOnInput
                            value={processDateJS(company_period?.inclusion_period_start)?.format('DD/MM/YYYY')}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Regarded Period End Date:</p>
                        <Input
                            className='col'
                            title={"Start Date"}
                            hideTitleOnInput
                            value={processDateJS(company_period?.inclusion_period_end)?.format('DD/MM/YYYY')}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Regarded Period Length </p>
                        <Input
                            className='col'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={company_period?.inclusion_period_length?.toLocaleString()}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Accounting Period Length </p>
                        <Input
                            className='col'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={company_period?.ap_length?.toLocaleString()}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Disregarded period proportion: </p>
                        <Input
                            className='col'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={(1 - company_period?.inclusion_factor)?.toLocaleString()}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Computation Currency: </p>
                        <Input
                            className='col'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={company_period?.currency}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Exchange Rate (GBP1:CURX):  </p>
                        <Input
                            className='col'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={company_period?.fx_rate}
                            disabled={true}
                            required={true}
                        />
                    </div>
                </Panel>
            </div>

            <Panel style={{ marginTop: 20, padding: 5 }}>
                <Table originalData={ntlr_data} hasTitle style={{ marginTop: 20 }}>
                    <Column field="description" >Non-trading loan Relationships</Column>
                    <Column field="manual_adjustment" renderCell={(row, field) => render_manual_adjustment(row, set_ntlr_data)}>Allow Manual Adjustment</Column>
                    <Column field="accounting_period_total" renderCell={(row, field) => formatNumber(row[field])} style={{ textAlign: 'center' } }>Accounting Period Total</Column>
                    <Column field="prior_cir_amount" renderCell={(row, field) => render_prior_cir_input(row, ntlr_data, set_ntlr_data)}>Amount allocated to prior CIR periods</Column>
                    <Column field="current_cir_amount" renderCell={(row, field) => render_current_cir_input(row, ntlr_data, set_ntlr_data)}>Amount allocated to current CIR period</Column>
                    <Column field="future_cir_amount" renderCell={(row, field) => render_future_cir_input(row[field])}>Amount to be regarded in future CIR periods</Column>
        </Table>
                <div>
                    <h1>Non-trading loan relationships - Subtotal: {calculate_sub_total(ntlr_data)?.toLocaleString()}</h1>
                </div>
            </Panel>

            <Panel style={{ marginTop: 20, padding: 5 }}>
                <Table originalData={ntdc_data} hasTitle style={{ marginTop: 20 }} striped>
                    <Column field="description"  >Non-trading derivative contracts</Column>
                    <Column field="manual_adjustment" renderCell={(row, field) => render_manual_adjustment(row, set_ntdc_data)}>Allow Manual Adjustment</Column>
                    <Column field="accounting_period_total" renderCell={(row, field) => formatNumber(row[field])} style={{ textAlign: 'center' }}>Accounting Period Total</Column>
                    <Column field="prior_cir_amount" renderCell={(row, field) => render_prior_cir_input(row, ntdc_data, set_ntdc_data)}>Amount allocated to prior CIR periods</Column>
                    <Column field="current_cir_amount" renderCell={(row, field) => render_current_cir_input(row, ntdc_data, set_ntdc_data)}>Amount allocated to current CIR period</Column>
                    <Column field="future_cir_amount" renderCell={(row, field) => render_future_cir_input(row[field])}>Amount to be regarded in future CIR periods</Column>
                </Table>

                <div>
                    <h1>Non-trading derivative contracts - Subtotal: {calculate_sub_total(ntdc_data)?.toLocaleString()}</h1>
                </div>
            </Panel>

            <Panel style={{ marginTop: 20, padding: 5 }}>
                <Table originalData={tlr_data} hasTitle style={{ marginTop: 20 }} striped>
                    <Column field="description" >Trading loan relationships</Column>
                    <Column field="manual_adjustment" renderCell={(row, field) => render_manual_adjustment(row, set_tlr_data)}>Allow Manual Adjustment</Column>
                    <Column field="accounting_period_total" renderCell={(row, field) => formatNumber(row[field])} style={{ textAlign: 'center' }}>Accounting Period Total</Column>
                    <Column field="prior_cir_amount" renderCell={(row, field) => render_prior_cir_input(row, tlr_data, set_tlr_data)}>Amount allocated to prior CIR periods</Column>
                    <Column field="current_cir_amount" renderCell={(row, field) => render_current_cir_input(row, tlr_data, set_tlr_data)}>Amount allocated to current CIR period</Column>
                    <Column field="future_cir_amount" renderCell={(row, field) => render_future_cir_input(row[field])}>Amount to be regarded in future CIR periods</Column>
                </Table>

                <div>
                    <h1>Trading loan relationships - Subtotal: {calculate_sub_total(tlr_data)?.toLocaleString()}</h1>
                </div>
            </Panel>

            <Panel style={{ marginTop: 20, padding: 5 }}>
                <Table originalData={tdc_data} hasTitle style={{ marginTop: 20 }} striped>
                    <Column field="description" >Trading derivative contracts</Column>
                    <Column field="manual_adjustment" renderCell={(row, field) => render_manual_adjustment(row, set_tdc_data)}>Allow Manual Adjustment</Column>
                    <Column field="accounting_period_total" renderCell={(row, field) => formatNumber(row[field])} style={{ textAlign: 'center' }}>Accounting Period Total</Column>
                    <Column field="prior_cir_amount" renderCell={(row, field) => render_prior_cir_input(row, tdc_data, set_tdc_data)}>Amount allocated to prior CIR periods</Column>
                    <Column field="current_cir_amount" renderCell={(row, field) => render_current_cir_input(row, tdc_data, set_tdc_data)}>Amount allocated to current CIR period</Column>
                    <Column field="future_cir_amount" renderCell={(row, field) => render_future_cir_input(row[field])}>Amount to be regarded in future CIR periods</Column>
                </Table>

                <div>
                    <h1>Trading derivative contracts - Subtotal: {calculate_sub_total(tdc_data)?.toLocaleString()}</h1>
                </div>
            </Panel>

            <Panel style={{ marginTop: 20, padding: 5 }}>
                <Table originalData={ofa_data} hasTitle style={{ marginTop: 20 }} striped>
                    <Column field="description" >Other financing arrangements</Column>
                    <Column field="manual_adjustment" renderCell={(row, field) => render_manual_adjustment(row, set_ofa_data)}>Allow Manual Adjustment</Column>
                    <Column field="accounting_period_total" renderCell={(row, field) => formatNumber(row[field])} style={{ textAlign: 'center' }}>Accounting Period Total</Column>
                    <Column field="prior_cir_amount" renderCell={(row, field) => render_prior_cir_input(row, ofa_data, set_ofa_data)}>Amount allocated to prior CIR periods</Column>
                    <Column field="current_cir_amount" renderCell={(row, field) => render_current_cir_input(row, ofa_data, set_ofa_data)}>Amount allocated to current CIR period</Column>
                    <Column field="future_cir_amount" renderCell={(row, field) => render_future_cir_input(row[field])}>Amount to be regarded in future CIR periods</Column>
                </Table>

                <div>
                    <h1>Other financing arrangements - Subtotal: {calculate_sub_total(ofa_data)?.toLocaleString()}</h1>
                </div>
            </Panel>

            <Panel style={{ marginTop: 20, padding: 5 }}>
                <Table originalData={tax_ebitda_data} hasTitle style={{ marginTop: 20 }} striped>
                    <Column field="description" >Tax-EBITDA section</Column>
                    <Column field="manual_adjustment" renderCell={(row, field) => render_manual_adjustment(row, set_tax_ebitda_data)}>Allow Manual Adjustment</Column>
                    <Column field="accounting_period_total" renderCell={(row, field) => formatNumber(row[field])} style={{ textAlign: 'center' }}>Accounting Period Total</Column>
                    <Column field="prior_cir_amount" renderCell={(row, field) => render_prior_cir_input(row, tax_ebitda_data, set_tax_ebitda_data)}>Amount allocated to prior CIR periods</Column>
                    <Column field="current_cir_amount" renderCell={(row, field) => render_current_cir_input(row, tax_ebitda_data, set_tax_ebitda_data)}>Amount allocated to current CIR period</Column>
                    <Column field="future_cir_amount" renderCell={(row, field) => render_future_cir_input(row[field])}>Amount to be regarded in future CIR periods</Column>
                </Table>
                <div>
                    <h1>Tax-EBITDA section - Subtotal: {calculate_sub_total(tax_ebitda_data)?.toLocaleString()}</h1>
                </div>
            </Panel>
            <div className="d-flex flex-row justify-content-end gap-2 ap-my-spacing-6" style={{ padding: "1em 1em 1em 0em" }}>
            <Button onClick={() => {navBack(false)}}>Back</Button>
            <Button hiddenInReadonly onClick={() => save_data()}>Save</Button>
            </div>
            
        </div>
    );
};

export default EditAutomaticAllocations;