import dayjs from "dayjs";
import { generateCAPId, generateCAPIdByCompName } from "./capProcessor";
import { getDefaultCompanyLeaveDate, getOriginatingPoaMinStartDateJS, processDateJS } from "./dateProcessor";
import { cap_adjusted_values } from "./CapCurrencyValues";

const nonTradingKeys = [
    {
        cfd: 'cfd_non_trading_loan_relationships_gbp',
        cfdadj: 'cfdadj_non_trading_loan_relationships_gbp',
        bfd: 'bfd_non_trading_loan_relationships_gbp',
        bfdadj: 'bfdadj_non_trading_loan_relationships_gbp',
        cpr: 'cpr_non_trading_loan_relationships_gbp',
        cpd: 'cpd_non_trading_loan_relationships_gbp'
    },
    {
        cfd: 'cfd_non_trading_derivatives_gbp',
        cfdadj: 'cfdadj_non_trading_derivatives_gbp',
        bfd: 'bfd_non_trading_derivatives_gbp',
        bfdadj: 'bfdadj_non_trading_derivatives_gbp',
        cpr: 'cpr_non_trading_derivatives_gbp',
        cpd: 'cpd_non_trading_derivatives_gbp'
    }
];

const tradingKeys = [
    {
        cfd: 'cfd_trading_loan_relationships_gbp',
        cfdadj: 'cfdadj_trading_loan_relationships_gbp',
        bfd: 'bfd_trading_loan_relationships_gbp',
        bfdadj: 'bfdadj_trading_loan_relationships_gbp',
        cpr: 'cpr_trading_loan_relationships_gbp',
        cpd: 'cpd_trading_loan_relationships_gbp'
    },
    {
        cfd: 'cfd_trading_derivatives_gbp',
        cfdadj: 'cfdadj_trading_derivatives_gbp',
        bfd: 'bfd_trading_derivatives_gbp',
        bfdadj: 'bfdadj_trading_derivatives_gbp',
        cpr: 'cpr_trading_derivatives_gbp',
        cpd: 'cpd_trading_derivatives_gbp'
    }
];

const ofaKeys = [
    {
        cfd: 'cfd_other_financing_gbp',
        cfdadj: 'cfdadj_other_financing_gbp',
        bfd: 'bfd_other_financing_gbp',
        bfdadj: 'bfdadj_other_financing_gbp',
        cpr: 'cpr_other_financing_amounts_gbp',
        cpd: 'cpd_other_financing_amounts_gbp'
    }
];

const allKeys = [...nonTradingKeys, ...tradingKeys, ...ofaKeys];

const subtotalKeysGBP = {
    cfd: 'cfd_subtotal_gbp',
    cfdadj: 'cfdadj_subtotal_gbp',
    bfd: 'bfd_subtotal_gbp',
    bfdadj: 'bfdadj_subtotal_gbp',
    cpr: 'cpr_subtotal_gbp',
    cpd: 'cpd_total_gbp'
};

const disallowanceAdjCondition = (adjustedCap) => ({
    trading: (
        !!adjustedCap?.trade_small_or_negligible || 
        !!adjustedCap?.trade_uncommercial_or_non_statutory
    ), 
    nonTrading: (
        !!adjustedCap?.investment_business_ceased || 
        !!adjustedCap?.cta10_change_in_ownership_rules
    )
});

export function recalculateTotal (adjustedCap, property, localCurrency = false) {
    const subtotalKey = localCurrency ? subtotalKeysGBP[property].replace('_gbp', '') : subtotalKeysGBP[property];
    adjustedCap[subtotalKey] = 0;

    allKeys.forEach((keys) => {
        const propKey = localCurrency ? keys[property].replace('_gbp', '') : keys[property];
        adjustedCap[subtotalKey] += (Number(adjustedCap[propKey]) || 0);
    });
}

export function recalculateAllTotals (adjustedCap, localCurrency = false) {
    Object.keys(subtotalKeysGBP).forEach(key => {
        recalculateTotal(adjustedCap, key, localCurrency);
    });
}

export function calculateCFDADJ (adjustedCap) {
    if (!adjustedCap) return;

    const conditionsMet = disallowanceAdjCondition(adjustedCap);

    if (conditionsMet.trading) {
        tradingKeys.forEach((keys) => {
            const bfd = Number(adjustedCap[keys.bfd]) || 0;
            const bfdadj = Number(adjustedCap[keys.bfdadj]) || 0;
            const cpd = Number(adjustedCap[keys.cpd]) || 0;
            const cpr = Number(adjustedCap[keys.cpr]) || 0;

            adjustedCap[keys.cfdadj] = Number(bfd + bfdadj + cpd - cpr);
        })
    }
    
    if (conditionsMet.nonTrading) {
        nonTradingKeys.forEach((keys) => {
            const bfd = Number(adjustedCap[keys.bfd]) || 0;
            const bfdadj = Number(adjustedCap[keys.bfdadj]) || 0;
            const cpd = Number(adjustedCap[keys.cpd]) || 0;
            const cpr = Number(adjustedCap[keys.cpr]) || 0;

            adjustedCap[keys.cfdadj] = Number(bfd + bfdadj + cpd - cpr);
        })
    }

    if(Object.values(conditionsMet).includes(true)) {        
        ofaKeys.forEach((keys) => {
            const bfd = Number(adjustedCap[keys.bfd]) || 0;
            const bfdadj = Number(adjustedCap[keys.bfdadj]) || 0;
            const cpd = Number(adjustedCap[keys.cpd]) || 0;
            const cpr = Number(adjustedCap[keys.cpr]) || 0;

            adjustedCap[keys.cfdadj] = Number(bfd + bfdadj + cpd - cpr);
        });

        recalculateTotal(adjustedCap, 'cfdadj');
    }
};


export function calculateCFD (adjustedCap) {
    if (!adjustedCap) return;
    
    allKeys.forEach((keys) => {
        const bfd = Number(adjustedCap[keys.bfd]) || 0;
        const bfdadj = Number(adjustedCap[keys.bfdadj]) || 0;
        const cpd = Number(adjustedCap[keys.cpd]) || 0;
        const cpr = Number(adjustedCap[keys.cpr]) || 0;
        const cfdadj = Number(adjustedCap[keys.cfdadj]) || 0;

        adjustedCap[keys.cfd] = Number(bfd + bfdadj + cpd - cpr + cfdadj);
    });
    
    recalculateTotal(adjustedCap, 'cfd');
}

export function setBFDasPrevCFD (adjustedCap, priorAdjustedCap) {
    allKeys.forEach(keys => {
        adjustedCap[keys.bfd] = (Number(priorAdjustedCap[keys.cfd]) || 0);
        
        adjustedCap[subtotalKeysGBP.bfd] += adjustedCap[keys.bfd];
    })
    
    recalculateTotal(adjustedCap, 'bfd');
}

export function setBFDADJasNegativeBFD (adjustedCap, localCurrency = false) {
    const conditionsMet = disallowanceAdjCondition(adjustedCap);

    if(conditionsMet.trading) {
        tradingKeys.forEach(keys => {
            const adjKey = localCurrency ? keys.bfdadj.replace('_gbp', '') : keys.bfdadj;
            const bfdKey = localCurrency ? keys.bfd.replace('_gbp', '') : keys.bfd;
            adjustedCap[adjKey] = (Number(0 - adjustedCap[bfdKey]) || 0);
        });
    }
    
    if(conditionsMet.nonTrading) {
        nonTradingKeys.forEach(keys => {
            const adjKey = localCurrency ? keys.bfdadj.replace('_gbp', '') : keys.bfdadj;
            const bfdKey = localCurrency ? keys.bfd.replace('_gbp', '') : keys.bfd;
            adjustedCap[adjKey] = (Number(0 - adjustedCap[bfdKey]) || 0);
        });
    }
    
    if(Object.values(conditionsMet).includes(true)) {
        ofaKeys.forEach(keys => {
            const adjKey = localCurrency ? keys.bfdadj.replace('_gbp', '') : keys.bfdadj;
            const bfdKey = localCurrency ? keys.bfd.replace('_gbp', '') : keys.bfd;
            adjustedCap[adjKey] = (Number(0 - adjustedCap[bfdKey]) || 0);
        });

        recalculateTotal(adjustedCap, 'bfdadj');
    }
}

export function convertGBPToLC (adjustedCap) {
    allKeys.forEach(keys => {
        Object.values(keys).forEach(gbpKey => {
            const lcKey = gbpKey.replace('_gbp', '');
            adjustedCap[lcKey] = (Number(adjustedCap[gbpKey]) || 0) * (Number(adjustedCap.fx_rate) || 1);
        })
    });

    recalculateAllTotals(adjustedCap, true);
}

export function convertLCToGBP (adjustedCap) {
    allKeys.forEach(keys => {
        Object.values(keys).forEach(gbpKey => {
            const lcKey = gbpKey.replace('_gbp', '');
            adjustedCap[gbpKey] = (Number(adjustedCap[lcKey]) || 0) / (Number(adjustedCap.fx_rate) || 1);
        })
    });

    recalculateAllTotals(adjustedCap);
}

export function updateCaps(newCaps, oldCaps) {
    if (!newCaps || !oldCaps) {
        return oldCaps || newCaps;
    }

    return newCaps.map(newCap => {
        const oldCap = oldCaps.find(oldCap => {
            return generateCAPId(oldCap) === generateCAPId(newCap);
        })

        return { ...oldCap, ...newCap };
    })
}

export function updateCapsByName(newCaps, oldCaps) {
    if (!newCaps || !oldCaps) {
        return oldCaps || newCaps;
    }

    return newCaps.map(newCap => {
        const oldCap = oldCaps.find(oldCap => {
            return generateCAPIdByCompName(oldCap) === generateCAPIdByCompName(newCap);
        })

        return { ...oldCap, ...newCap };
    })
}

export function carryOverAdjustedCaps(newCaps, oldCaps) {
    if (!newCaps || !oldCaps) {
        return oldCaps || newCaps;
    }

    return newCaps.map(newCap => {
        const oldCap = oldCaps.find(oldCap => {
            return generateCAPId(newCap) === generateCAPId(oldCap);
        });

        return oldCap ? {...newCap, ...oldCap} : newCap;
    })
}

export function initiateAdjustedCapProps(grpData, poa, compAP) {
    const company = grpData?.companies?.find(c => c.company_ID === compAP?.company_ID);

    const companyJoinDate = processDateJS(company?.date_join || getOriginatingPoaMinStartDateJS());
    const companyLeaveDate = processDateJS(company?.date_left || getDefaultCompanyLeaveDate());

    const group_period_start = processDateJS(poa?.period_start);
    const group_period_end = processDateJS(poa?.period_end);

    const relevantPeriodStart = processDateJS(dayjs.max(companyJoinDate, group_period_start));
    const relevantPeriodEnd = processDateJS(dayjs.min(companyLeaveDate, group_period_end));

    const start_date = processDateJS(compAP?.start_date);
    const end_date = processDateJS(compAP?.end_date);

    const inclusion_period_start = dayjs.max(processDateJS(relevantPeriodStart), start_date);
    const inclusion_period_end = dayjs.min(processDateJS(relevantPeriodEnd), end_date);

    const grpPeriodLength = group_period_end?.diff(group_period_start, "day") + 1;
    const proportion_of_poa_as_group_company = relevantPeriodStart?.diff(relevantPeriodEnd, 'day') / grpPeriodLength;

    const inclusion_period_length = inclusion_period_end?.diff(inclusion_period_start, "day") + 1;

    const ap_length = end_date?.diff(start_date, "day") + 1;

    return {
            company_ID: company?.company_ID,
            company_name: company?.company_name,
            group_period_start,
            group_period_end,
            inclusion_period_start,
            inclusion_period_end,
            start_date,
            end_date,
            proportion_of_poa_as_group_company,
            inclusion_period_length,
            ap_length,
            inclusion_factor: inclusion_period_length / ap_length,
    }
}

export function applyInclusionFactorAdjustments (adjCap, correspondingCompanyAP) {
    if (adjCap?.inclusion_factor && correspondingCompanyAP) {
        Object.entries(adjCap)?.forEach(([key, entry]) => {
            if (cap_adjusted_values?.includes(key)) {
                if (adjCap?.hasOwnProperty(key) && adjCap[key] && adjCap[key]?.manual_adjustment === true) {
                    adjCap.adjustment_status = 'Manual Adjustment';
                }
                else {
                    const compAPVal = (correspondingCompanyAP && Object.keys(correspondingCompanyAP).includes(key)) ? correspondingCompanyAP[key] : 0;
                    adjCap[key] = {value: (Number(compAPVal) || 0) * adjCap.inclusion_factor, manual_adjustment: false};
                }
            }
            else {
                adjCap[key] = entry
            }
        })
    }

    return adjCap;
}

export function getCompanyAPsforPoA(compAPs, poa) {
    return compAPs.filter(cap => 
        (processDateJS(cap?.date_left)?.isAfter(processDateJS(poa?.period_start)) ?? true) &&
        (processDateJS(cap?.end_date)?.isAfter(processDateJS(poa?.period_start)) ?? true) &&
        (processDateJS(cap?.date_join)?.isBefore(processDateJS(poa?.period_end)) ?? true) &&
        (processDateJS(cap?.start_date)?.isBefore(processDateJS(poa?.period_end)) ?? true)
    );
}
