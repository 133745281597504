import { Loading, Panel } from "@appkit4/react-components";
import { Button } from '../../components/ReadonlyAwareInputs'
import { BackButton, NextButton } from "../../components/ProgressNav";
import { GridCellKind } from "@glideapps/glide-data-grid";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../services/AuthProvider";
import { getGroupData, setGroupData, getPoaIndex, getGroupName, getSelectedPeriod } from "../../services/GroupContext";
import { processDate } from "../../utils/dateProcessor";
import { calculateCFD, calculateCFDADJ, convertGBPToLC, setBFDADJasNegativeBFD, setBFDasPrevCFD } from "../../utils/adjustedCapsUtil";
import sqldatabaseService from "../../services/sqldatabase/sqldatabase.service";
import toast from "react-hot-toast";
import CFDDataEditor from "./components/CFDDataEditor";

const EditCFD = () => {
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isPosting, setIsPosting] = useState(false);

    const auth = useContext(AuthContext)

    const savedData = getGroupData();

    const [adjustedCaps, setAdjustedCaps] = useState([]);

    const disallowanceDataSections = [
        {
            title: 'Brought Forward Disallowances',
            editable: false,
            col1Data: [
                { displayValue: 'Non-trading loan relationship amounts', key: "bfd_non_trading_loan_relationships" },
                { displayValue: 'Non-trading derivative amounts', key: "bfd_non_trading_derivatives" },
                { displayValue: 'Trading loan relationship amounts', key: "bfd_trading_loan_relationships" },
                { displayValue: 'Trading derivative amounts' , key: "bfd_trading_derivatives" },
                { displayValue: 'Other financing amounts' , key: "bfd_other_financing" },
                { displayValue: 'Total', key: "bfd_subtotal" }
            ]
        },
        {
            title: 'Brought Forward Disallowances Adjustments (Negative)',
            editable: false,
            validation: 'negative',
            col1Data: [
                { displayValue: 'Non-trading loan relationship amounts', key: "bfdadj_non_trading_loan_relationships" },
                { displayValue: 'Non-trading derivative amounts', key: "bfdadj_non_trading_derivatives" },
                { displayValue: 'Trading loan relationship amounts', key: "bfdadj_trading_loan_relationships" },
                { displayValue: 'Trading derivative amounts' , key: "bfdadj_trading_derivatives" },
                { displayValue: 'Other financing amounts' , key: "bfdadj_other_financing" },
                { displayValue: 'Total', key: "bfdadj_subtotal" }
            ]
        },
        {
            title: 'CPD',
            editable: false,
            col1Data: [
                { displayValue: 'CPD Non-trading loan relationship amounts', key: "ntlr_disallowance" },
                { displayValue: 'CPD Non-trading derivative amounts', key: "ntdc_disallowance" },
                { displayValue: 'CPD Trading loan relationship amounts', key: "tlr_disallowance" },
                { displayValue: 'CPD Trading derivative amounts' , key: "bfd_tdc_disallowance_gbptrading_derivatives" },
                { displayValue: 'CPD Other financing amounts' , key: "ofa_disallowance" },
                { displayValue: 'Total', key: "disallowance_subtotal" }
            ]
        },
        {
            title: 'CPR',
            editable: false,
            col1Data: [
                { displayValue: 'Non-trading loan relationship amounts', key: "cpr_non_trading_loan_relationships" },
                { displayValue: 'Non-trading derivative amounts', key: "cpr_non_trading_derivatives" },
                { displayValue: 'Trading loan relationship amounts', key: "cpr_trading_loan_relationships" },
                { displayValue: 'Trading derivative amounts' , key: "cpr_trading_derivatives" },
                { displayValue: 'Other financing amounts' , key: "cpr_other_financing" },
                { displayValue: 'Total', key: "cpr_subtotal" }
            ]
        },
        {
            title: 'Carried Forward Disallowances Adjustments (Negative)',
            editable: true,
            validation: 'negative',
            col1Data: [
                { displayValue: 'Non-trading loan relationship amounts', key: "cfdadj_non_trading_loan_relationships" },
                { displayValue: 'Non-trading derivative amounts', key: "cfdadj_non_trading_derivatives" },
                { displayValue: 'Trading loan relationship amounts', key: "cfdadj_trading_loan_relationships" },
                { displayValue: 'Trading derivative amounts' , key: "cfdadj_trading_derivatives" },
                { displayValue: 'Other financing amounts' , key: "cfdadj_other_financing" },
                { displayValue: 'Total', key: "cfdadj_subtotal" }
            ]
        },
        {
            title: 'Carried Forward Disallowances',
            editable: false,
            col1Data: [
                { displayValue: 'Non-trading loan relationship amounts', key: "cfd_non_trading_loan_relationships" },
                { displayValue: 'Non-trading derivative amounts', key: "cfd_non_trading_derivatives" },
                { displayValue: 'Trading loan relationship amounts', key: "cfd_trading_loan_relationships" },
                { displayValue: 'Trading derivative amounts' , key: "cfd_trading_derivatives" },
                { displayValue: 'Other financing amounts' , key: "cfd_other_financing" },
                { displayValue: 'Total', key: "cfd_subtotal" }
            ]
        },
    ]
    
    useEffect(() => {
        if (!savedData) {
            throw 'Something went wrong with fetching your cached data';
        }
        
        const calculatedAdjustedCaps = getSelectedPeriod().adjusted_caps?.sort((a, b) => {
            const nameComparison = a?.company_name?.localeCompare(b?.company_name);
            return (nameComparison===0) ? (processDate(a.start_date) -  processDate(b.start_date) ) : nameComparison;
        });

        if (auth?.isReadOnlyUser(getGroupName())) {
            setIsLoading(false);
            setAdjustedCaps(calculatedAdjustedCaps);
        } else {
            const processedCompanies = [];
            
            calculatedAdjustedCaps?.forEach((adjustedCap, index) => {
                if(processedCompanies.includes(adjustedCap.company_ID)) {
                    // every non-first adjusted cap per company
                    // set BFD ammount as CFD ammount
                    const prevAdjustedCap = calculatedAdjustedCaps[index-1];
                    setBFDasPrevCFD(adjustedCap, prevAdjustedCap);
                    
                    // set BFDAdj ammount as current year BFD
                    setBFDADJasNegativeBFD(adjustedCap);
                } else {
                    //first adjustedCap for each company
                    processedCompanies.push(adjustedCap.company_ID);
                }
                
                // set CFDAdj as BFD + BFDAdj + CPD - CPR
                calculateCFDADJ(adjustedCap);
                
                // calculate CFD
                calculateCFD(adjustedCap);
    
                // convert values to local currency
                convertGBPToLC(adjustedCap);
            });
            
            setAdjustedCaps(calculatedAdjustedCaps);
    
            postAdjustdCaps(calculatedAdjustedCaps);
        }
        
    }, []);
    
    const postAdjustdCaps = (newAdjustedCaps) => {
        if (!savedData) {
            throw 'Something went wrong with fetching your cached data';
        }
        const poa = savedData?.periods_of_account[getPoaIndex()];
        poa.adjusted_caps = newAdjustedCaps;
        setGroupData(savedData);
        
        if (auth?.isReadOnlyUser(getGroupName())) {
            setIsLoading(false);
            return;
        }
        setIsPosting(true);
    
        const promise = sqldatabaseService?.postResponse(savedData);
        return toast.promise(promise, {
            loading: 'Processing Adjusted Caps',
            success: (response) => {
                setGroupData(savedData);
                setIsLoading(false);
                setIsPosting(false);
                return `Adjusted Caps Calculations saved to database`
            },
            error: 'Failed to save Adjusted Caps Calculations',
        });
    }

    const save = () => {
        if (!savedData) {
            throw 'Something went wrong with fetching your cached data';
        }

        postAdjustdCaps(adjustedCaps);
    }

    return (
        <div className="ap-container">
            {isLoading ? (
              <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
              ></Loading>
            ) :
            <Panel title="Carried Forward Disallowances" className="ap-mb-spacing-4">
                {/* <p>description here?</p> */}
                <p className='err'>{isError}</p>
                <div id="portal" style={{ position: 'fixed', left: 0, top: 0, zIndex: 9999 }} />
                
                <CFDDataEditor {...{adjustedCaps, setAdjustedCaps, disallowanceDataSections}} />
                
                <div className='container-fluid mt-4'>
                    <div className='d-flex justify-content-between align-items-center'> 
                        <div>
                            <BackButton disabled={isPosting}/>
                        </div>
                        <div className='d-flex gap-2'>
                            <Button hiddenInReadonly loading={isPosting} onClick={save}>Save</Button>
                            <NextButton loading={isPosting} preNavigation={save} />
                        </div>
                    </div>
                </div>

            </Panel>}
        </div>
    );
}

export default EditCFD;
