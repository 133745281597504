export function formatAbbreviatedReturn(group_period_sheet, group_level_elections_sheet, uk_group_companies_sheet){

    const group_bolds = ['B2','B7','B11','B15','B18','B24','B29','B33','B37','B40','B46','C24','C29'];
    for(let j = 0; j < group_bolds?.length; j++){
        group_period_sheet.getCell(group_bolds[j]).font = {bold:true}
    }
    group_period_sheet.mergeCells('B2:D2');
    group_period_sheet.mergeCells('B4:D5');
    group_period_sheet.mergeCells('B7:C7');
    group_period_sheet.mergeCells('B9:D9');
    group_period_sheet.mergeCells('B11:D11');
    group_period_sheet.mergeCells('B33:D33');
    group_period_sheet.mergeCells('B37:D37');
    group_period_sheet.mergeCells('B40:D40');
    group_period_sheet.mergeCells('B46:C46');
    const group_borders = ['B2', 'B4', 'B7', 'B9', 'B15', 'B18'];
    for(let j = 0; j < group_borders?.length; j++){
        group_period_sheet.getCell(group_borders[j]).border= {
            top: {style:'medium'},
            left: {style:'medium'},
            bottom: {style:'medium'},
            right: {style:'medium'}
            }
    };

    group_period_sheet.getCell('B11:D11').border= {top: {style:'medium'},left: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B12').border= {left: {style:'medium'}};
    group_period_sheet.getCell('D12').border= {right: {style:'medium'}, bottom: {style:'thin'}, left: {style:'thin'}};
    group_period_sheet.getCell('B13').border= {bottom: {style:'medium'},left: {style:'medium'}};
    group_period_sheet.getCell('C13').border= {bottom: {style:'medium'}};
    group_period_sheet.getCell('D13').border= {bottom: {style:'medium'},right: {style:'medium'}, left: {style:'thin'}};

    group_period_sheet.getCell('B15').border= {top: {style:'medium'},left: {style:'medium'}};
    group_period_sheet.getCell('C15').border= {top: {style:'medium'}, bottom: {style:'thin'}, left: {style:'thin'}};
    group_period_sheet.getCell('D15').border= {top: {style:'medium'},right: {style:'medium'}, left: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B16').border= {bottom: {style:'medium'},left: {style:'medium'}};
    group_period_sheet.getCell('C16').border= {bottom: {style:'medium'}, left: {style:'thin'}};
    group_period_sheet.getCell('D16').border= {bottom: {style:'medium'},right: {style:'medium'}, left: {style:'thin'}};

    group_period_sheet.getCell('B18').border= {top: {style:'medium'},left: {style:'medium'}};
    group_period_sheet.getCell('C18').border= {top: {style:'medium'}, bottom: {style:'thin'}, left: {style:'thin'}};
    group_period_sheet.getCell('D18').border= {top: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'}, left: {style:'thin'}};
    group_period_sheet.getCell('B19').border= {left: {style:'medium'}}
    group_period_sheet.getCell('C19').border= {bottom: {style:'thin'}, left: {style:'thin'}}
    group_period_sheet.getCell('D19').border= {right: {style:'medium'}, left: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B20').border= {left: {style:'medium'}}
    group_period_sheet.getCell('C20').border= {bottom: {style:'thin'}, left: {style:'thin'}}
    group_period_sheet.getCell('D20').border= {right: {style:'medium'}, left: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B21').border= {left: {style:'medium'}, bottom: {style:'thin'}}
    group_period_sheet.getCell('C21').border= {bottom: {style:'thin'}, left: {style:'thin'}}
    group_period_sheet.getCell('D21').border= {right: {style:'medium'},left: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B22').border= {bottom: {style:'medium'},left: {style:'medium'}};
    group_period_sheet.getCell('C22').border= {bottom: {style:'medium'}, left: {style:'thin'}};
    group_period_sheet.getCell('D22').border= {bottom: {style:'medium'},right: {style:'medium'}, left: {style:'thin'}};

    group_period_sheet.getCell('B24').border= {top: {style:'medium'},left: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C24').border= {top: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'},left: {style:'thin'}};
    group_period_sheet.getCell('B25').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C25').border= {right: {style:'medium'}, bottom: {style:'thin'},left: {style:'thin'}};
    group_period_sheet.getCell('B26').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C26').border= {right: {style:'medium'}, bottom: {style:'thin'},left: {style:'thin'}};
    group_period_sheet.getCell('B27').border= {bottom: {style:'medium'},left: {style:'medium'}};
    group_period_sheet.getCell('C27').border= {bottom: {style:'medium'},right: {style:'medium'}};

    group_period_sheet.getCell('B29').border= {top: {style:'medium'},left: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C29').border= {top: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B30').border= {left: {style:'medium'}, right: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C30').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B31').border= {bottom: {style:'medium'},left: {style:'medium'}, right: {style:'thin'}};
    group_period_sheet.getCell('C31').border= {bottom: {style:'medium'},right: {style:'medium'}};

    group_period_sheet.getCell('B33:D33').border= {top: {style:'medium'},left: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B34').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C34').border= {bottom: {style:'thin'}}
    group_period_sheet.getCell('D34').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B35').border= {bottom: {style:'medium'},left: {style:'medium'},right: {style:'medium'}};
    group_period_sheet.getCell('C35').border= {bottom: {style:'medium'}, right: {style:'medium'}};
    group_period_sheet.getCell('D35').border= {bottom: {style:'medium'},right: {style:'medium'}};

    group_period_sheet.getCell('B37:D37').border= {top: {style:'medium'},left: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B38').border= {bottom: {style:'medium'},left: {style:'medium'},right: {style:'medium'}};
    group_period_sheet.getCell('C38').border= {bottom: {style:'medium'}, right: {style:'medium'}};
    group_period_sheet.getCell('D38').border= {bottom: {style:'medium'},right: {style:'medium'}};

    group_period_sheet.getCell('B40:D40').border= {top: {style:'medium'},left: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B41').border= {left: {style:'medium'},right: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C41').border= {right: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('D41').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B42').border= {left: {style:'medium'},right: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C42').border= {right: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('D42').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B43').border= {left: {style:'medium'},right: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C43').border= {right: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('D43').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B44').border= {left: {style:'medium'},right: {style:'thin'}, bottom: {style:'medium'}};
    group_period_sheet.getCell('C44').border= {right: {style:'thin'}, bottom: {style:'medium'}};
    group_period_sheet.getCell('D44').border= {right: {style:'medium'}, bottom: {style:'medium'}};

    group_period_sheet.getCell('B46:C46').border= {top: {style:'medium'},left: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B47').border= {bottom: {style:'medium'},left: {style:'medium'}};
    group_period_sheet.getCell('C47').border= {bottom: {style:'medium'},right: {style:'medium'}};

    group_period_sheet.getColumn(1).width = 2;
    group_period_sheet.getColumn(2).width = 90;
    group_period_sheet.getColumn(3).width = 90;
    group_period_sheet.getColumn(4).width = 60;
    group_period_sheet.getColumn(5).width = 2;
    group_period_sheet.getCell('B4').alignment = { vertical: 'top', horizontal: 'left' };
    group_period_sheet.getRow(35).height = 100;
    group_period_sheet.getRow(38).height = 100;

    const group_Greys = ['B7', 'C7', 'B9', 'C9', 'D9', 'B11', 'C11', 'D11', 'B15', 'B18', 'B24', 'B33', 'C33', 'D33', 'B37', 'C37', 'D37','B40', 'C40', 'D40','B46', 'C46', ];
    for (let i = 0; i < group_Greys.length; i++){
        group_period_sheet.getCell(group_Greys[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFC0C0C0'}
            };        
    }

    const group_Lemons = ['B2', 'C2', 'D2', 'B4', 'B22','B27','C27', 'B34', 'C34','D34','B35','C35','D35','B38','C38','D38','C42','D42','C43','D43','C44','D44','B47','C47',];
    for (let i = 0; i < group_Lemons.length; i++){
        group_period_sheet.getCell(group_Lemons[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFACD'}
            };        
    }

    const group_Golds = ['D12', 'D13', 'D15', 'D16', 'D18','D19','D20', 'D21', 'D22','C25','C26','C30','C31',];
    for (let i = 0; i < group_Golds.length; i++){
        group_period_sheet.getCell(group_Golds[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFF00'}
            };        
    }

    const gle_bolds = ['B2','B4','B6','B7','B8','B10','B12','B20','B25','B29','B33', 'C12', 'D12']
    for(let j = 0; j < gle_bolds?.length; j++){
        group_level_elections_sheet.getCell(gle_bolds[j]).font = {bold:true}
    }
    group_level_elections_sheet.mergeCells('C7:D7');
    group_level_elections_sheet.mergeCells('C8:D8');
    group_level_elections_sheet.mergeCells('C10:D10');

    group_level_elections_sheet.getCell('B2').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('C2').border= {top: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('D2').border= {right: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}};

    group_level_elections_sheet.getCell('B4').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('C4').border= {top: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('D4').border= {right: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}};

    group_level_elections_sheet.getCell('B6').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('C6').border= {top: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('D6').border= {right: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B7').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D7').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B8').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D8').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B9').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C9').border= {bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('D9').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B10').border= {left: {style:'medium'}, bottom: {style:'medium'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C10').border= {bottom: {style:'medium'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D10').border= {right: {style:'medium'}, bottom: {style:'medium'}};

    group_level_elections_sheet.getCell('B12').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C12').border= {top: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D12').border= {right: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B13').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C13').border= {bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D13').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B14').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C14').border= {bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D14').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B15').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C15').border= {bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D15').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B16').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C16').border= {bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D16').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B17').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C17').border= {bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D17').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B18').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C18').border= {bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D18').border= {right: {style:'medium'}, bottom: {style:'thin'}};

    group_level_elections_sheet.getCell('B20').border= {left: {style:'medium'}, top: {style:'medium'}};
    group_level_elections_sheet.getCell('C20').border= {top: {style:'medium'}};
    group_level_elections_sheet.getCell('D20').border= {right: {style:'medium'}, top: {style:'medium'}};
    group_level_elections_sheet.getCell('B21').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('C21').border= {bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('D21').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B22').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('C22').border= {bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('D22').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B23').border= {left: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('C23').border= {bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('D23').border= {right: {style:'medium'}, bottom: {style:'medium'}};

    group_level_elections_sheet.getCell('B25').border= {left: {style:'medium'}, top: {style:'medium'}};
    group_level_elections_sheet.getCell('C25').border= {top: {style:'medium'}};
    group_level_elections_sheet.getCell('D25').border= {right: {style:'medium'}, top: {style:'medium'}};
    group_level_elections_sheet.getCell('B26').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('C26').border= {bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('D26').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B27').border= {left: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('C27').border= {bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('D27').border= {right: {style:'medium'}, bottom: {style:'medium'}};
    
    group_level_elections_sheet.getCell('B29').border= {left: {style:'medium'}, top: {style:'medium'}};
    group_level_elections_sheet.getCell('C29').border= {top: {style:'medium'}};
    group_level_elections_sheet.getCell('D29').border= {right: {style:'medium'}, top: {style:'medium'}};
    group_level_elections_sheet.getCell('B30').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('C30').border= {bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('D30').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B31').border= {left: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('C31').border= {bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('D31').border= {right: {style:'medium'}, bottom: {style:'medium'}};

    group_level_elections_sheet.getCell('B33').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('C33').border= {right: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B34').border= {left: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('C34').border= {right: {style:'medium'}, bottom: {style:'medium'}};

    group_level_elections_sheet.getColumn(1).width = 2;
    group_level_elections_sheet.getColumn(2).width = 165;
    group_level_elections_sheet.getColumn(3).width = 20;
    group_level_elections_sheet.getColumn(4).width = 20;
    group_level_elections_sheet.getColumn(5).width = 2;
    group_level_elections_sheet.getRow(9).height = 2;
    group_level_elections_sheet.getRow(23).height = 80;
    group_level_elections_sheet.getRow(27).height = 80;
    group_level_elections_sheet.getRow(31).height = 80;

    group_level_elections_sheet.getCell('B23').alignment = { vertical: 'top', horizontal: 'left' };
    group_level_elections_sheet.getCell('B27').alignment = { vertical: 'top', horizontal: 'left' };
    group_level_elections_sheet.getCell('B31').alignment = { vertical: 'top', horizontal: 'left' };
    
    group_level_elections_sheet.getCell('C13').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('C14').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('C15').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('C16').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('C17').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('C18').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('D13').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('D14').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('D15').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('D16').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('D17').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('D18').alignment = { horizontal: 'center' };

    const gle_Greys = ['B2', 'C2', 'D2', 'B4', 'C4', 'D4', 'B7', 'B8', 'B9', 'B10', 'B12', 'B20', 'B25', 'B29', 'B33', 'C20', 'D20',  'C25', 'D25', 'C29', 'D29', 'C33'];
    for (let i = 0; i < gle_Greys.length; i++){
        group_level_elections_sheet.getCell(gle_Greys[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFC0C0C0'}
            };        
    }

    const gle_Lemons = ['B6', 'C6', 'D6', 'B34', 'C34'];
    for (let i = 0; i < gle_Lemons.length; i++){
        group_level_elections_sheet.getCell(gle_Lemons[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFACD'}
            };        
    }

    const gle_Golds = ['C7', 'D7', 'C8', 'D8', 'C9','D9','C10', 'D10', 'C13','D13','C14','D14','C15','D15','C16','D16','C17','D17','C18','D18','B23','C23','D23','B27','C27','D27','B31','C31','D31',];
    for (let i = 0; i < gle_Golds.length; i++){
        group_level_elections_sheet.getCell(gle_Golds[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFF00'}
            };        
    }

    uk_group_companies_sheet.getColumn(1).width = 2;
    uk_group_companies_sheet.getColumn(2).width = 8;
    uk_group_companies_sheet.getColumn(3).width = 115;
    uk_group_companies_sheet.getColumn(4).width = 35;
    uk_group_companies_sheet.getColumn(5).width = 35;
    uk_group_companies_sheet.getColumn(6).width = 5;
    uk_group_companies_sheet.getRow(1).height = 3;
    uk_group_companies_sheet.getRow(3).height = 5;
    uk_group_companies_sheet.getRow(5).height = 5;
    uk_group_companies_sheet.getRow(11).height = 5;
    uk_group_companies_sheet.getRow(14).height = 35;

    uk_group_companies_sheet.mergeCells("B2:E2");
    uk_group_companies_sheet.mergeCells("B4:E4");
    uk_group_companies_sheet.mergeCells("B6:E6");
    uk_group_companies_sheet.mergeCells("B7:C7");
    uk_group_companies_sheet.mergeCells("B8:C8");
    uk_group_companies_sheet.mergeCells("B9:C9");
    uk_group_companies_sheet.mergeCells("B10:C10");
    uk_group_companies_sheet.mergeCells("D7:E7");
    uk_group_companies_sheet.mergeCells("D8:E8");
    uk_group_companies_sheet.mergeCells("D9:F9");
    uk_group_companies_sheet.mergeCells("D10:E10");
    uk_group_companies_sheet.mergeCells("C12:E13");
    uk_group_companies_sheet.mergeCells("B12:B13");

    uk_group_companies_sheet.getCell('B2').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'}};
    uk_group_companies_sheet.getCell('B4').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'}};
    uk_group_companies_sheet.getCell('B6').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'}};
    uk_group_companies_sheet.getCell('B7').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('D7').border= {right: {style:'thin'}};
    uk_group_companies_sheet.getCell('B8').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('D8').border= {right: {style:'thin'}};
    uk_group_companies_sheet.getCell('B9').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('D9').border= {right: {style:'thin'}};
    uk_group_companies_sheet.getCell('B10').border= {left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('D10').border= {right: {style:'thin'}, bottom: {style:'medium'},};
    uk_group_companies_sheet.getCell('B12').border= {left: {style:'medium'}, bottom: {style:'medium'}, top: {style:'medium'}};
    uk_group_companies_sheet.getCell('C12').border= {right: {style:'medium'}, bottom: {style:'medium'}, top: {style:'medium'}};
    for (let row = 14; row < 315; row++){
        uk_group_companies_sheet.getCell('B' + row).border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
        uk_group_companies_sheet.getCell('C' + row).border= {bottom: {style:'thin'}, right: {style:'thin'}};
        uk_group_companies_sheet.getCell('D' + row).border= {bottom: {style:'thin'}, right: {style:'thin'}};
        uk_group_companies_sheet.getCell('E' + row).border= {bottom: {style:'thin'}, right: {style:'medium'}};
    }
    
    uk_group_companies_sheet.getCell('B315').border= {left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('C315').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('D315').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('E315').border= {bottom: {style:'medium'}, right: {style:'medium'}};
    uk_group_companies_sheet.getCell('C320').border= {left: {style:'medium'}, top: {style:'medium'}};
    uk_group_companies_sheet.getCell('D320').border= {top: {style:'medium'}};
    uk_group_companies_sheet.getCell('E320').border= {right: {style:'medium'}, top: {style:'medium'}};
    uk_group_companies_sheet.getCell('C321').border= {left: {style:'medium'}, bottom: {style:'medium'}};
    uk_group_companies_sheet.getCell('D321').border= {bottom: {style:'medium'}};
    uk_group_companies_sheet.getCell('E321').border= {right: {style:'medium'}, bottom: {style:'medium'}};

    const ugc_bolds = ['B2','B4','B7','B8','B9','B10','C12','C14','D14','E14','F14','D15','E15','F15','H12','H13','H14','I14','J14','C320','H317','H318','I318','H321','I321','J321'];
    for(let j = 0; j < ugc_bolds?.length; j++){
        uk_group_companies_sheet.getCell(ugc_bolds[j]).font = {bold:true}
    }

    const ugc_Greys = ['B2','B4', 'B7', 'B8', 'B9', 'B10', 'B12', 'C12','C320', 'D320', 'E320'];
    for (let i = 0; i < ugc_Greys.length; i++){
        uk_group_companies_sheet.getCell(ugc_Greys[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFC0C0C0'}
            };        
    }

    let buildLemonsList = [];
    let startLRow = 15
    for (let i = 0; i < 301; i++){
        let row = startLRow + i
        buildLemonsList.push('C' + row);
        buildLemonsList.push('D' + row);
        buildLemonsList.push('E' + row);
    }
    const ugc_Lemons_others = ['B6', 'C321'];
    const ugc_Lemons = ugc_Lemons_others.concat(buildLemonsList);
    for (let i = 0; i < ugc_Lemons.length; i++){
        uk_group_companies_sheet.getCell(ugc_Lemons[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFACD'}
            };        
    }

    const ugc_Golds = ['D7', 'D8', 'D9', 'D10'];
    for (let i = 0; i < ugc_Golds.length; i++){
        uk_group_companies_sheet.getCell(ugc_Golds[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFF00'}
            };        
    }

    const ugc_Blues =  ['H1', 'D321'];
    for (let i = 0; i < ugc_Blues.length; i++){
        uk_group_companies_sheet.getCell(ugc_Blues[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFE0FFFF'} 
            };        
    }


    return [group_period_sheet, group_level_elections_sheet, uk_group_companies_sheet]
}