/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal, Accordion, AccordionItem } from '@appkit4/react-components'; 
import { Button, Input, InputNumber } from "../../components/ReadonlyAwareInputs";
import { setANGIETotals, setQNGIETotals, setEBITDATotals, setNGIETotals } from '../../services/calculations/GroupDataCalcs';
import { formatCurrency } from '../../utils/formatters';
import toast from 'react-hot-toast';
import { WarningModal, generateTableRow, groupDataWarningModal, renderSummary, handleCancel, renderPrimaryAccordionTitle, renderSecondaryAccordionTitle, renderTableKeyList, sumArray } from './groupDataUtil';
import "./ngie.scss"

const NGIE = ({ setVisible, currentPeriod, setCurrentPeriod, save, isReadOnly, errorState:[isError, setIsError], isParentError }) => {

  const [modalCurrency, setModalCurrency] = useState("");
  const [fxRate, setFxRate] = useState(1);

  const tables = {
    //#region Relevant expense amounts recognised in group's P&L (s.411) (enter as positive numbers)
    'loan_relationship_interest_payable': generateTableRow('Interest payable under a loan relationship - s411(1)(a)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Amounts should be entered as positive figures."]),
    'loan_relationship_ancillary_expenses': generateTableRow('Expenses ancillary to a loan relationship or related transaction (including bank charges) - 411(1)(b)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Bank charges representing relevant expense amounts should be entered here. Bank charges may or may not be regarded as relevant expense amounts, regardless of whether they are included in the interest note in the accounts. Generally where charges are related to borrowing/financing - e.g. facility fees, overdraft fees, they should be included as relevant expense amounts. Bank transfer fees, currency conversion fees, etc, may not be relevant amounts.", "Care is needed over the treatment of credit card charges. In many cases there may be a position to take that credit card charges are not relevant amounts, but this may differ depending on the facts and circumstances."]),
    'loan_relationship_losses': generateTableRow('Losses arising from a loan relationship/related transaction (excluding exchange losses and impairment losses) - 411(1)(c)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Amounts should be entered as positive figures. No amount should be included in respect of exchange losses or impairment losses - these do not represent relevant expense amounts per s411 TIOPA 2010."]),
    'interest_relevant_non_lending_relationships': generateTableRow('Interest payable or, for periods of account beginning on or after 1 April 2023, all debits (excluding exchange losses and impairment losses) under relevant non-lending relationships - s411(1)(j)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Amounts should be entered as positive figures.", "Note: This definition was amended and expanded by Finance Act 2023 to include all debits that are brought into account as a result of being relevant non-lending relationships, (or would be if the company were subject to corporation tax), other than:", "1) exchange losses; and", "2) impairment losses."]),
    'derivatives_losses': generateTableRow('Losses arising from a relevant derivative contract or a related transaction (Excluding exchange losses, impairment losses, and all losses on certain "trading" derivatives - see "Help" for further information) - s411(1)(e)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Amounts should be entered as positive figures.", "Note that this amount should only include amounts arising in respect of relevant deriviative contracts as defined in s412 TIOPA 2010 and under s411(1)(e) TIOPA 2010 also does not include any amounts in respect of:", "1) Exchange losses;", "2) Impairment losses; and", "3) Losses in respect of risks arising in the ordinary course of the trade (other than risks arising in the ordinary course of a financial trade) where the derivative contract was entered into wholly for reasons unrelated to the capital structure of the worldwide group (or any member of the worldwide group).", "Note - where the Disregard Regulations could apply to the derivatives additional adjustments may be required under s420 TIOPA 2010."]),
    'derivatives_ancillary_losses': generateTableRow('Expenses ancillary to a relevant derivative or related transaction - s411(1)(f)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Amounts should be entered as positive figures.", "Expenses ancillary to derivative contracts include fees arising on the derivative."]),
    'finance_charges_finance_lease': generateTableRow('Financing charges implicit in payments made under a finance lease - s411(1)(g)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Amounts should be entered as positive figures.", "The definition of a finance lease is included in s494 TIOPA 2010 and is limited to leases that fall to be treated as finance leases or right-of-use leases that would fall to be treated as finance leases if the company were required to determine whether it was so treated. As such, care is required in respect of right-of-use leases, as it will be necessary to consider if these would be classified as operating leases or finance leases in an accounting standard with such a distinction."]),
    'finance_charges_debt_factoring': generateTableRow('Financing charges related to debt factoring or any similar transaction - s411(1)(h)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Amounts should be entered as positive figures."]),
    'alternative_finance_return_payable': generateTableRow('Alternative finance return payable under alternative finance arrangements - s411(1)(k)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Amounts should be entered as positive figures.", "s412 TIOPA 2010 clarifies that alternative finance arrangements has the same meaning as in Parts 5 and 6 CTA 2009."]),
    'interest_payable_manufactured': generateTableRow('Manufactured interest payable - s411(1)(l)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Amounts should be entered as positive figures.", " s412 TIOPA 2010 clarifies that manufactured interest has the same meaning as in Chapter 9, Part 6 CTA 2009."]),
    'finance_charges_service_concession': generateTableRow('Financing charges implicit in payments made under a service concession arrangement if and to the extent that the arrangement is accounted for as a financial liability - s411(1)(i)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Amounts should be entered as positive figures."]),
    'financing_charges_debtor_repo': generateTableRow('Financing charges in respect of the advance under a debtor repo or debtor quasi-repo - s411(1)(m)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Amounts should be entered as positive figures.", "s412 TIOPA 2010 clarifies that debtor repo and debtor quasi repo has the same meaning as in Chapter 10, Part 6 CTA 2009."]),
    'financing_charges_interest_payable': generateTableRow('Financing charges treated as interest payable under a loan relationship under the finance arrangements rules (Chapter 2 of Part 16 of CTA 2010), or would be so treated if the company were within the charge to corporation tax - s411(1)(n)', useState([{ description: '', value: '' }]), useState(false), 'positive',  [" Amounts should be entered as positive figures.", "s412 TIOPA 2010 clarifies which provisions are relevant provisions:", "- s761(3) CTA 2010 (type 1 finance arrangements: borrower a company);", "- s762(3) CTA 2010 (type 1 finance arrangements: borrower a partnership);", "- s766(3) CTA 2010 (type 2 finance arrangements);", " - s769(3) CTA 2010 (type 3 finance arrangements)."]),
    'preference_shares_payable_dividends': generateTableRow('Dividends payable on preference shares accounted for as a financial liability - s411(1)(d)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Amounts should be entered as positive figures. Note that this reflects any dividend expense associated with preference shares accounted for as debt."]),
    'depreciation_relevant_expense': generateTableRow('Capitalised expense amounts brought into account in financial statements of the group in determining the carrying value of a non-relevant asset, where the carrying value of that asset is written down - s410(3)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Amounts should be entered as positive figures.", "Under s410(2)-(3), any relevant expense amounts that were previously capitalised into an asset that is not a relevant asset (see s417(5)-(6)) and for which the carrying amount is written down in the period need to be included in Net Group-Interest Expense. Note that this does not apply to relevant assets, as these amounts are already excluded from Group-EBITDA via the Capital (Expenditure) Adjustment. Separate adjustments are made in respect of relevant assets to arrive at ANGIE."]),
    //#endregion Relevant expense amounts recognised in group's P&L (s.411) (enter as positive numbers)

    //#region Relevant income amounts recognised in group's P&L (s.411) (enter as negative numbers)
    'interest_receivable_non_lending_relationships': generateTableRow('Dividends receivable in respect of preference shares accounted for as a financial asset - s411(2)(c)', useState([{ description: '', value: '' }]), useState(false), 'negative'),
    'loan_relationship_profits': generateTableRow('Profits arising from a loan relationship or a related transaction (excluding exchange gains and the reversal of impairment losses) - s411(2)(b)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Amounts should be entered as negative figures. No amount should be included in respect of exchange gains or the reversal of impairment losses - these do not represent relevant income amounts per s411 TIOPA 2010"]),
    'preference_shares_dividends_receivable': generateTableRow('Interest receivable or, for periods of account beginning on or after 1 April 2023, all credits (excluding exchange gains and reversals of impairment losses) arising in respect of relevant non-lending relationships - s411(2)(h)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Amounts should be entered as negative figures.", "Note: This definition is amended by Finance Act 2023 to expand the definition to include all credits that are brought into account as a result of being relevant non-lending relationships, (or would be if the company were subject to corporation tax), other than:", " 1) exchange gains; and", " 2) the reversal of impairment losses."]),
    'derivatives_gains': generateTableRow('Gains arising from a relevant derivative contract or a related transaction (excluding exchange gains, the reversal of impairment losses, and all gains on certain "trading" derivatives - see "Help" for further information - s411(2)(d)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Amounts should be entered as negative figures.", "Note that this amount only includes amounts arising in respect of relevant deriviative contracts as defined in s412 TIOPA 2010 and under s411(2)(d) TIOPA 2010 also does not include any amounts in respect of:", "1) Exchange gains;", "2) Reversals of impairment losses; and", "3) Gains in respect of risks arising in the ordinary course of the trade (other than risks arising in the ordinary course of a financial trade) where the derivative contract was entered into wholly for reasons unrelated to the capital structure of the worldwide group (or any member of the worldwide group).", "Note - where the Disregard Regulations could apply to the derivatives additional adjustments may be required under s420 TIOPA 2010."]),
    'finance_income_finance_lease': generateTableRow('Financing income implicit in amounts received under a finance lease - s411(2)(e)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Amounts should be entered as negative figures.", "The definition of a finance lease is included in s494 TIOPA 2010 and is limited to leases that fall to be treated as finance leases or right-of-use leases that would fall to be treated as finance leases if the company were required to determine whether it was so treated. As such, care is required in respect of right-of-use leases, as it will be necessary to consider if these would be classified as operating leases or finance leases in an accounting standard with such a distinction."]),
    'finance_income_debt_factoring': generateTableRow('Financing income relating to debt factoring or any similar transaction - s411(2)(f)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Amounts should be entered as negative figures."]),
    'finance_income_service_concession': generateTableRow('Financing income implicit in amounts received under a service concession arrangement if and to the extent that the arrangement is accounted for as a financial asset - s411(2)(g)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Amounts should be entered as negative figures."]),
    'finance_receivable_alternative_finance_arrangement': generateTableRow('Alternative finance return receivable under alternative finance arrangements - s411(2)(i)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Amounts should be entered as negative figures.", "s412 TIOPA 2010 clarifies that alternative finance arrangements has the same meaning as in Parts 5 and 6 CTA 2009."]),
    'interest_receivable_manufactured': generateTableRow('Manufactured interest receivable - s411(2)(j)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Amounts should be entered as negative figures.", "s412 TIOPA 2010 clarifies that manufactured interest has the same meaning as in Chapter 9, Part 6 CTA 2009."]),
    'finance_income_debtor_repo': generateTableRow('Financing income in respect of the advance under a creditor repo or creditor quasi-repo - s411(2)(k)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Amounts should be entered as negative figures.", "s412 TIOPA 2010 clarifies that debtor repo and debtor quasi repo has the same meaning as in Chapter 10, Part 6 CTA 2009."]),
    'finance_income_interest_receivable': generateTableRow('Financing income treated as interest receivable under a loan relationship under the finance arrangements rules (Chapter 2 of Part 16 of CTA 2010), or would be so treated if the company were within the charge to corporation tax - s411(2)(l)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Amounts should be entered as negative figures.", "s412 TIOPA 2010 clarifies which provisions are relevant provisions:", "- s761(3) CTA 2010 (type 1 finance arrangements: borrower a company);", "- s762(3) CTA 2010 (type 1 finance arrangements: borrower a partnership);", "- s766(3) CTA 2010 (type 2 finance arrangements);", " - s769(3) CTA 2010 (type 3 finance arrangements)."]),
    'loan_relationship_interest_receivable': generateTableRow('Interest receivable under loan relationships', useState([{ description: '', value: '' }]), useState(false), 'negative'),
    'depreciation_relevant_income': generateTableRow('Capitalised income amounts brought into account in financial statements of the group in determining the carrying value of a non-relevant asset, where the carrying value of that asset is written down - s410(5)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Amounts should be entered as negative figures.", "Under s410(4)-(5), any relevant income amounts that were previously capitalised into an asset that is not a relevant asset (see s417(5)-(6)) and for which the carrying amount is written down in the period need to be included in Net Group-Interest Expense. Note that this does not apply to relevant assets, as these amounts are already excluded from Group-EBITDA via the Capital (Expenditure) Adjustment. Separate adjustments are made in respect of relevant assets to arrive at ANGIE."]),
    //#endregion Relevant income amounts recognised in group's P&L (s.411) (enter as negative numbers)
    
    //#region Other adjustments required to calculate NGIE
    'adjustments_eliminate_derivatives': generateTableRow('Adjustment to eliminate amounts included above in respect of derivatives subject to fair value accounting to which Regulation 7, 8 or 9 of the Disregard Regulations would apply - s420(3)', useState([{ description: '', value: '' }]), useState(false), ["Under s420 TIOPA 2010, it is necessary to exclude derivative contract amounts that would have been Disregarded under Disregard Regulations 7, 8 or 9 on making the relevant assumptions. Amongst other assumptions, for the purpose of this section it is necessary to assume that all group members are subject to Corporation Tax and that the Disregard Regulations have been entered into by the group (regardless of whether any UK group entity has made an election)."]),
    'adjustments_include_replacement_derivatives': generateTableRow('Adjustment to include replacement "Group-Interest" derivative contract amounts for derivatives subject to fair value accounting to which Regulation 7, 8 or 9 of the Disregard Regulations would apply - s420(6)', useState([{ description: '', value: '' }]), useState(false), ["Under s420 TIOPA 2010, it is also necessary to include derivative contract amounts that would have been brought back into account under Disregard Regulations 7, 8 or 9 on making the assumption that all group members are subject to Corporation Tax and that the Disregard Regulations have been entered into by the group (regardless of whether any UK group entity has made an election)"]),
    'adjustments_exclude_relevant_income': generateTableRow("Adjustment to exclude relevant income amounts relating to financial liabilities owed by any member of an associated worldwide group headed by a company specified in the election - s427(3)(a)", useState([{ description: '', value: '' }]), useState(false), 'positive', ["Where an Interest Allowance (Non-Consolidated Investment) election is made, it is necessary under s427 TIOPA 2010 to adjust NGIE to eliminate relevant interest amounts arising from lending to the associated worldwide group."]),
    'excludePartnershipIncomeAdjustment': generateTableRow("Adjustment to exclude relevant income and expense amounts in respect of a consolidated partnership to the extent that they are included above - s430(2)(a)", useState([{ description: '', value: '' }]), useState(false)),
    'taxAdjPBT': generateTableRow('Adjustment to PBT in respect of changes in accounting policy to align with the tax treatment that would arise for interest items assuming the group was within the charge to corporation tax - s426(2)', useState([{ description: '', value: '' }]), useState(false), ["Where an Interest Allowance (Non-Consolidated Investment) election is made and there has been a change in accounting policy in the period, it is necessary to make adjustments to the amounts reflected in the financial statements on the basis that the change of accounting policy provisions listed in s426 apply to the group on the assumption that the group was a company that was within the charge to corporation tax; held the assets and owed the liabilities recognised in the financial statements; and carried on the trades and other activities giving rise to amounts recognised as items of profit and loss."]),
    'adjustments_specialist_regimes': generateTableRow('Other adjustments required as a result of specialist regimes', useState([{ description: '', value: '' }]), useState(false), ["Use this row to enter any additional adjustments that are required as a result of the application of specialist regimes or elections"]),
    'adjustments_other': generateTableRow('Other adjustments for which there is no space above', useState([{ description: '', value: '' }]), useState(false), ["Use this row to enter any additional adjustements that may be required"]),
    //#endregion Other adjustments required to calculate NGIE
  };

  const expenses = {
    loanRelationshipExpenses: [
      tables['loan_relationship_interest_payable']?.state[0],
      tables['loan_relationship_ancillary_expenses']?.state[0],
      tables['loan_relationship_losses']?.state[0],
      tables['interest_relevant_non_lending_relationships']?.state[0]
    ],
    derivativeContractExpenses: [
      tables['derivatives_losses']?.state[0],
      tables['derivatives_ancillary_losses']?.state[0]
    ],
    leaseAndFinanceExpenses: [
      tables['finance_charges_finance_lease']?.state[0],
      tables['finance_charges_debt_factoring']?.state[0],
      tables['finance_charges_service_concession']?.state[0],
      tables['alternative_finance_return_payable']?.state[0],
      tables['interest_payable_manufactured']?.state[0],
      tables['financing_charges_debtor_repo']?.state[0],
      tables['financing_charges_interest_payable']?.state[0],
    ],
    otherRelevantExpenses: [
      tables['preference_shares_payable_dividends']?.state[0],
      tables['depreciation_relevant_expense']?.state[0],
    ]
  }

  const [totalLoanExpenses, setTotalLoanExpenses] = useState(0);
  useEffect(() => {
    setTotalLoanExpenses(sumArray(expenses?.loanRelationshipExpenses));
  }, [expenses?.loanRelationshipExpenses]);

  const [totalDerivExpenses, setTotalDerivExpenses] = useState(0);
  useEffect(() => {
    setTotalDerivExpenses(sumArray(expenses?.derivativeContractExpenses));
  }, [expenses?.derivativeContractExpenses]);

  const [totalLeaseFinanceExpenses, setTotalLeaseFinanceExpenses] = useState(0);
  useEffect(() => {
    setTotalLeaseFinanceExpenses(sumArray(expenses?.leaseAndFinanceExpenses));
  }, [expenses?.leaseAndFinanceExpenses]);

  const[totalOtherRelevantExpenses, setTotalOtherRelevantExpenses] = useState(0);
  useEffect(() => {
    setTotalOtherRelevantExpenses(sumArray(expenses?.otherRelevantExpenses))
  }, [expenses?.otherRelevantExpenses]);

  const income = {
    loanAmountsIncome: [
      tables['interest_receivable_non_lending_relationships']?.state[0],
      tables['loan_relationship_profits']?.state[0],
      tables['preference_shares_dividends_receivable']?.state[0],
    ],
    derivContractIncome: [
      tables['derivatives_gains']?.state[0],
    ],
    financeLeaseIncome: [
      tables['finance_income_finance_lease']?.state[0],
      tables['finance_income_debt_factoring']?.state[0],
      tables['finance_income_service_concession']?.state[0],
      tables['finance_receivable_alternative_finance_arrangement']?.state[0],
      tables['interest_receivable_manufactured']?.state[0],
      tables['finance_income_debtor_repo']?.state[0],
      tables['finance_income_interest_receivable']?.state[0],
    ],
    otherRelevantIncome: [
      tables['loan_relationship_interest_receivable']?.state[0],
      tables['depreciation_relevant_income']?.state[0],
    ]
  }

  const [totalLoanIncome, setTotalLoanIncome] = useState(0);
  useEffect(() => {
    setTotalLoanIncome(sumArray(income?.loanAmountsIncome));
  }, [income?.loanAmountsIncome]);

  const [totalDerivContractIncome, setTotalDerivContractIncome] = useState(0);
  useEffect(() => {
    setTotalDerivContractIncome(sumArray(income?.derivContractIncome));
  }, [income?.derivContractIncome]);

  const [totalFinanceLeaseIncome, setTotalFinanceLeaseIncome] = useState(0);
  useEffect(() => {
    setTotalFinanceLeaseIncome(sumArray(income?.financeLeaseIncome));
  }, [income?.financeLeaseIncome]);

  const [otherRelevantIncomeTotal, setOtherRelevantIncomeTotal] = useState(0);
  useEffect(() => {
    setOtherRelevantIncomeTotal(sumArray(income?.otherRelevantIncome));
  }, [income?.otherRelevantIncome]);

  const adjustments = {
    autoDisregardRegGroup: [
      tables['adjustments_eliminate_derivatives']?.state[0],
      tables['adjustments_include_replacement_derivatives']?.state[0]
    ],
    other: [
      tables['adjustments_specialist_regimes']?.state[0],
      tables['adjustments_other']?.state[0]
    ]
  }

  const [autoDisregardRegGroupTotal, setAutoDisregardRegGroupTotal] = useState(0);
  useEffect(() => {
    setAutoDisregardRegGroupTotal(sumArray(adjustments?.autoDisregardRegGroup));
  }, [adjustments?.autoDisregardRegGroup]);

  const [otherAdjustmentsTotal, setOtherAdjustmentsTotal] = useState(0);
  useEffect(() => {
    setOtherAdjustmentsTotal(sumArray(adjustments?.other));
  }, [adjustments?.other]);

  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalAdjustments, setTotalAdjustments] = useState(0);

  const hasInterestAllowanceElection = currentPeriod?.interest_allowance_non_consolidated_election || false;

  const [primaryActiveKeys, setPrimaryActiveKeys] = useState([]);
  const [expenseActiveKeys, setExpenseActiveKeys] = useState([]);
  const [incomeActiveKeys, setIncomeActiveKeys] = useState([]);
  const [adjustmentsActiveKeys, setAdjustmentsActiveKeys] = useState([]);
  const onClickAccordion = (activeKeys) => {
    if (!activeKeys?.includes(1)) setExpenseActiveKeys([]);
    if (!activeKeys?.includes(2)) setIncomeActiveKeys([]);
    if (!activeKeys?.includes(3)) setAdjustmentsActiveKeys([]);
    setPrimaryActiveKeys(activeKeys)
  }
  const onClickExpenseAccordion = (activeKeys) => {
    setExpenseActiveKeys(activeKeys);
  }
  const onClickIncomeAccordion = (activeKeys) => {
    setIncomeActiveKeys(activeKeys);
  }
  const onClickAdjustmentsAccordion = (activeKeys) => {
    setAdjustmentsActiveKeys(activeKeys);
  }

  const submit = () => {
    const newPeriod = {
      ...currentPeriod,
      currency: modalCurrency,
      fx_rate: fxRate,

      ngie: Object.keys(tables)?.reduce((acc, k) => {
        acc[k] = tables[k]?.state[0];
        return acc;
      }, {})
    }

    setNGIETotals(newPeriod);

    if (newPeriod?.angie) {
      setANGIETotals(newPeriod);
    }
    if (newPeriod?.qngie && newPeriod?.angie_amount_before_any_adjustments) {
      setQNGIETotals(newPeriod);
    }
    if (newPeriod?.ebitda) {
      setEBITDATotals(newPeriod);
    }

    setCurrentPeriod(newPeriod);
    const promise = save(newPeriod);
    toast.promise(promise, {
      loading: 'Saving NGIE data...',
      success: () => {
        setVisible(false);
        return 'Saved NGIE data!'
      },
      error: 'Something went wrong with saving NGIE data',
    })
  }


  /*useEffect(() => {
    setIsError( Object.keys(tables)?.some( k => tables[k]?.error[0]) )
  }, [Object.keys(tables)?.map(k => tables[k]?.error[0])])*/

  useEffect(() => {
    const hasError = Object.values(tables).some(table => table?.error[0]);
    setIsError(hasError)
  })
  
  useEffect(() => {
    let total = totalLoanExpenses + totalDerivExpenses + totalLeaseFinanceExpenses + totalOtherRelevantExpenses;
    setTotalExpenses(total);
  }, [totalLoanExpenses, totalDerivExpenses, totalLeaseFinanceExpenses, totalOtherRelevantExpenses])

  useEffect(() => {
    let total = totalLoanIncome + totalDerivContractIncome + totalFinanceLeaseIncome + otherRelevantIncomeTotal;
    setTotalIncome(total);
  }, [totalLoanIncome, totalDerivContractIncome, totalFinanceLeaseIncome, otherRelevantIncomeTotal])
  
  useEffect(() => {
    const total = 
      autoDisregardRegGroupTotal + 
      otherAdjustmentsTotal + 
      sumArray([
        tables['adjustments_exclude_relevant_income']?.state[0], 
        tables['excludePartnershipIncomeAdjustment']?.state[0], 
        tables['taxAdjPBT']?.state[0]
      ])
    setTotalAdjustments(total);
  }, [autoDisregardRegGroupTotal, otherAdjustmentsTotal, tables['adjustments_exclude_relevant_income']?.state[0], tables['excludePartnershipIncomeAdjustment']?.state[0], tables['taxAdjPBT']?.state[0]]);

  useEffect(() => {
    const ngie = currentPeriod?.ngie;
    if (!ngie) {
      return;
    }
    setModalCurrency(currentPeriod?.currency ? currentPeriod?.currency : "GBP")
    setFxRate(currentPeriod?.fx_rate && (currentPeriod?.currency !== "GBP") ? currentPeriod?.fx_rate : 1)

    Object.keys(tables)?.forEach(key => {
      tables[key]?.state[1](ngie[key])
    })
  }, [])

  const [isModalChanged, setIsModalChanged] = useState(false);

  useEffect(() => {
    const currTotal = totalIncome + totalExpenses + totalAdjustments;
    if (currTotal > 0 && currentPeriod?.local_curr_net_group_interest_expense !== currTotal) {
      setIsModalChanged(true);
    } else {
      setIsModalChanged(false);
    }
  }, [totalIncome, totalExpenses, totalAdjustments]);

  const calculateNetGroupInterestExpense = () => {
    return totalIncome + totalExpenses + totalAdjustments;
  }

  const [showWarningModal, setShowWarningModal] = useState(false);

  return (
    <>
      <Modal
        visible={true}
        title={`Net Group Interest Expense (Income)`}
        onCancel={() => handleCancel(isModalChanged, isReadOnly, setVisible, setShowWarningModal)}
        maskCloseable={false}
        modalStyle={{ width: '90%', maxHeight: '90%' }}
        bodyStyle={{ maxHeight: '90%' }}
        >

        <table className="table">
          <tbody>
            <tr>
              <td>
                <div className="row">
                  <p className="col-1">Select input value currency:</p>
                  <Input
                    className="col-3"
                    value={modalCurrency}
                    title={'eg. EUR, USD'}
                    onChange={(value) => {
                      setModalCurrency(value);
                      if(value === 'GBP') {
                        setFxRate(1);
                      }
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="row">
                  <p className="col-4">
                    Enter FX rate: 1 GBP = X Local Currency (if required):
                  </p>
                  <InputNumber
                    className="col"
                    value={modalCurrency === 'GBP' ? 1 : fxRate}
                    disabled={modalCurrency === 'GBP'}
                    min={0}
                    onChange={(value) => setFxRate(value)}
                    />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        
        <div className='row group-data-modal-top-input-header'>
          <p>
            Please note, any changes to the currency or exchange rate will also impact the other group values
          </p>
        </div>

        {isError && <div className='err'>Description missing - a description must be provided for every value.</div>}


        <Accordion multiple={false} onClick={onClickAccordion} activeKeys={primaryActiveKeys}>
          <AccordionItem title={renderPrimaryAccordionTitle("Relevant expense amounts recognised in the group's P&L (s.411) (enter as positive numbers)", totalExpenses, ["This is the total of the amounts entered as relevant expense amounts in the drop down menus below. All amounts included below should be entered as positive figures.", "Please note - amounts in respect of defined benefit pension schemes are not included in Net Group Interest Expense."])} itemKey="1" style={{border: 'shading'}}>
            <Accordion multiple={true} onClick={onClickExpenseAccordion} activeKeys={expenseActiveKeys}>
              <AccordionItem title={renderSecondaryAccordionTitle('Loan Relationship Amounts', totalLoanExpenses, ["This is the total of expense amounts associated with loan relationships to which the group is party entered in the drop down menu below. All amounts included below should be entered as positive figures."])} itemKey='4' style={{ backgroundColor: '#ffffff' }}>
                      {renderTableKeyList(tables, [
                        'loan_relationship_interest_payable',
                        'loan_relationship_ancillary_expenses',
                        'loan_relationship_losses',
                        'interest_relevant_non_lending_relationships'
                      ])}
              </AccordionItem>

              <AccordionItem title={renderSecondaryAccordionTitle('Derivative Contract Amounts', totalDerivExpenses, ["This is the total of expense amounts associated with derivative contracts to which the group is party entered in the drop down menu below. All amounts included below should be entered as positive figures."])} itemKey='5' style={{ backgroundColor: '#ffffff' }}>
                      {renderTableKeyList(tables, [
                        'derivatives_losses',
                        'derivatives_ancillary_losses'
                      ])}
              </AccordionItem>

              <AccordionItem title={renderSecondaryAccordionTitle('Finance Leases and Other Financing Arrangements', totalLeaseFinanceExpenses, ["This is the total of expense amounts associated with finance leases and other types of financing arrangement to which the group is party - e.g. debt factoring, service concession arrangements or alternative finance arrangements entered in the drop down menu below. All amounts included below should be entered as positive figures."])} itemKey='6' style={{ backgroundColor: '#ffffff' }}>
                      {renderTableKeyList(tables, [
                        'finance_charges_finance_lease',
                        'finance_charges_debt_factoring',
                        'finance_charges_service_concession',
                        'alternative_finance_return_payable',
                        'interest_payable_manufactured',
                        'financing_charges_debtor_repo',
                        'financing_charges_interest_payable'
                      ])}
              </AccordionItem>

              <AccordionItem title={renderSecondaryAccordionTitle('Other Relevant Expense Amounts', totalOtherRelevantExpenses)} itemKey='7' style={{ backgroundColor: '#ffffff' }}>
                      {renderTableKeyList(tables, [
                        'preference_shares_payable_dividends',
                        'depreciation_relevant_expense'
                      ])}
              </AccordionItem>
            </Accordion>
          </AccordionItem>


          <AccordionItem title={renderSecondaryAccordionTitle("Relevant income amounts recognised in the group's P&L (s.411) (enter as negative numbers)", totalIncome, ["This is the total of the amounts entered as relevant income amounts in the drop down menus below. All amounts included below should be entered as negative figures.", "Please note - amounts in respect of defined benefit pension schemes are not included in Net Group Interest Expense."])} itemKey="2">
            <Accordion multiple={true} onClick={onClickIncomeAccordion} activeKeys={incomeActiveKeys}>
              <AccordionItem title={renderSecondaryAccordionTitle('Loan Relationship Amounts', totalLoanIncome, ["This is the total of income amounts associated with loan relationships to which the group is party entered in the drop down menu below. All amounts included below should be entered as negative figures."])} itemKey='8' style={{ backgroundColor: '#ffffff' }}>
                      {renderTableKeyList(tables, [
                        'interest_receivable_non_lending_relationships',
                        'loan_relationship_profits',
                        'preference_shares_dividends_receivable',
                      ])}
              </AccordionItem>

              <AccordionItem title={renderSecondaryAccordionTitle('Derivative Contract Amounts', totalDerivContractIncome, ["This is the total of income amounts associated with derivative contracts to which the group is party entered in the drop down menu below. All amounts included below should be entered as negative figures."])} itemKey='9' style={{ backgroundColor: '#ffffff' }}>
                      {renderTableKeyList(tables, [
                        'derivatives_gains'
                      ])}
              </AccordionItem>

              <AccordionItem title={renderSecondaryAccordionTitle('Finance Leases and Other Financing Arrangements', totalFinanceLeaseIncome, ["This is the total of income amounts associated with finance leases and other types of financing arrangement to which the group is party - e.g. debt factoring, service concession arrangements or alternative finance arrangements entered in the drop down menu below. All amounts included below should be entered as negative figures."])} itemKey='10' style={{ backgroundColor: '#ffffff' }}>
                      {renderTableKeyList(tables, [
                        'finance_income_finance_lease',
                        'finance_income_debt_factoring',
                        'finance_income_service_concession',
                        'finance_receivable_alternative_finance_arrangement',
                        'interest_receivable_manufactured',
                        'finance_income_debtor_repo',
                        'finance_income_interest_receivable',
                      ])}
              </AccordionItem>

              <AccordionItem title={renderSecondaryAccordionTitle('Other Relevant Income Amounts', otherRelevantIncomeTotal)} itemKey='11' style={{ backgroundColor: '#ffffff' }}>
                      {renderTableKeyList(tables, [
                        'loan_relationship_interest_receivable',
                        'depreciation_relevant_income',
                      ])}
              </AccordionItem>
            </Accordion>
          </AccordionItem>

          <AccordionItem title={renderPrimaryAccordionTitle('Other adjustments required to calculate NGIE', totalAdjustments)} itemKey="3">
            <Accordion multiple={true} onClick={onClickAdjustmentsAccordion} activeKeys={adjustmentsActiveKeys}>
              <AccordionItem title={renderSecondaryAccordionTitle('Automatic Application of Disregard Regulations at a Group Level', autoDisregardRegGroupTotal)} itemKey='12' style={{ backgroundColor: '#ffffff' }}>
                        {renderTableKeyList(tables, [
                          'adjustments_eliminate_derivatives',
                          'adjustments_include_replacement_derivatives',
                        ])}
                </AccordionItem>

              <AccordionItem title={renderSecondaryAccordionTitle('Adjustments for the Interest Allowance (Non-Consolidated Investment) Election', sumArray([tables['adjustments_exclude_relevant_income']?.state[0]]))} itemKey='13' style={{ backgroundColor: '#ffffff' }}>
                        {renderTableKeyList(tables, [
                          'adjustments_exclude_relevant_income',
                        ])}
                </AccordionItem>

              <AccordionItem title={renderSecondaryAccordionTitle('Adjustments for the Interest Allowance (Consolidated Partnership) Election', sumArray([tables['excludePartnershipIncomeAdjustment']?.state[0]]))} itemKey='14' style={{ backgroundColor: '#ffffff' }}>
                        {renderTableKeyList(tables, [
                          'excludePartnershipIncomeAdjustment',
                        ])}
                </AccordionItem>

              <AccordionItem title={renderSecondaryAccordionTitle('Adjustments in respect of the interest allowance (alternative calculation) election (s423-426)', sumArray([tables['taxAdjPBT']?.state[0]]))} itemKey='15' style={{ backgroundColor: '#ffffff' }}>
                        {renderTableKeyList(tables, [
                          'taxAdjPBT',
                        ])}
                </AccordionItem>

              <AccordionItem title={renderSecondaryAccordionTitle('Other', otherAdjustmentsTotal)} itemKey='16' style={{ backgroundColor: '#ffffff' }}>
                        {renderTableKeyList(tables, [
                          'adjustments_specialist_regimes',
                          'adjustments_other',
                        ])}
              </AccordionItem>
            </Accordion>
          </AccordionItem>
        </Accordion>

        <h3>Summary</h3>
        {renderSummary(modalCurrency, fxRate, calculateNetGroupInterestExpense())}


        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', paddingTop: '30px', gap: 10 }}>
          <Button neverReadonly onClick={() => {handleCancel(isModalChanged, isReadOnly, setVisible, setShowWarningModal)}} kind="secondary">Cancel</Button>
          <Button hiddenInReadonly disabled={isError || isParentError} onClick={submit}>Submit</Button>
        </div>

      </Modal>

      <WarningModal
        visible={showWarningModal}
        setIsVisible={setShowWarningModal}
        callBackFunc={setVisible}
      >
      </WarningModal>
    </>
  );
};

export default NGIE;
