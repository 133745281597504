import { useContext, useEffect } from "react";
import authAPI from "../services/auth/auth.service";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../services/AuthProvider";

// handles callback url from openAM
const AuthCallback = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  // call backend authorize function to login

  useEffect(() => {
    const login = async () => {
      try {
      const response = await authAPI?.getToken(window?.location?.search);
      console.log('testing', response)
      if (response?.status === 200) {
        // update variables in authContext
        await auth.login(response?.data);
        navigate("/");
      }
      else {
        console.log(response?.err)
        navigate("/app/logout");
      }
    } catch (error) {
      console.log(error);
    }
  }
  login()
  }, [])
};

export default AuthCallback;
