import React from 'react';

const RevCalcHeader = ( props ) => {
    const groupName = props.groupname;

    return (
        <div className="row">
            <div className="col-6">
            <div className="title3">{groupName} </div>
            </div>
            <div className="col-6"></div>
        </div>
    );
}

export default RevCalcHeader;
