import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { processDateJS } from "../../utils/dateProcessor";
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore, isSameOrAfter)
dayjs.extend(utc)

const calculateUnusedAllowanceData = (savedData, poaIndex) => {
    // this will create a list of the unused allowance for each POA,
    // regardless of whether the period is expired or not

    let currPoaData = savedData?.periods_of_account[poaIndex];
    let currPoaStartDate = currPoaData?.period_start; // current POA start date
    let currPoaEndDate = currPoaData?.period_end; // curent POA end date
    let allowance_calculations = []; // this will contain an element for each previous POA    
    let unused_allowances_for_group = []; 

    // calculate unused allowance for each POA
    savedData?.periods_of_account?.forEach(poa => {
        // only include POAs starting before current period
        if (processDateJS(poa?.period_start)?.isBefore(processDateJS(currPoaStartDate))) { 
            // aggregate amounts used
            let total_amount_used = 0;
            // get the used allowance data for this period
            const unused_allowance_array = savedData?.unused_allowances_for_group?.filter((unusedPOA) => processDateJS(unusedPOA?.period_start)?.isSame(processDateJS(poa?.period_start)))
            if (unused_allowance_array && unused_allowance_array.length > 0) {
                if (!unused_allowance_array?.hasOwnProperty('used_allowances_breakdown')) {
                    poa.used_allowances_breakdown = []
                }
                unused_allowance_array?.used_allowances_breakdown?.forEach(allowance => {
                    if(allowance?.amount_used && processDateJS(allowance?.utilisation_period_start)?.isBefore(processDateJS(currPoaStartDate))){
                        if(allowance?.amount_used != null && allowance?.amount_used > 0){
                            total_amount_used += allowance.amount_used
                        } 
                    }
                }) 
            }
            // calculate unused amount
            let unused_generated = poa?.unused_generated ? poa?.unused_generated : 0;
            let poa_unused_amount = unused_generated - total_amount_used;
            // add to list of allowances
            if (poa_unused_amount > 0){
                unused_allowances_for_group?.push({
                    period_start: poa?.period_start, 
                    period_end: poa?.period_end, 
                    interest_allowance: (poa?.interest_allowance && poa?.interest_allowance != null) ? poa?.interest_allowance : 0,
                    unused_generated: unused_generated,
                    unused_allowance: poa_unused_amount, 
                    amount_used: total_amount_used,
                    aggregate_net_tax_interest_expense: (poa?.aggregate_net_tax_interest_expense_income && poa?.aggregate_net_tax_interest_expense_income != null) ? poa?.aggregate_net_tax_interest_expense_income : 0,
                    debug_source: "poa_unused_amount"
                })                
            }
        }        
    });

    // add pre-CIR allowances 
    savedData?.unused_interest?.forEach(entry => {
        const amount_used = (entry?.reactivations ? entry?.reactivations  : 0) + (entry?.reactivations_prior_model_use ? entry?.reactivations_prior_model_use : 0)
        unused_allowances_for_group?.push({
            period_start: entry?.start_date, 
            period_end: entry?.end_date,
            interest_allowance: entry?.interest_allowance,
            unused_generated: entry?.unused,  
            unused_allowance: entry?.unused_available,
            amount_used: amount_used,
            used_allowances_breakdown: [{utilisation_period_start: entry?.start_date, utilisation_period_end: entry?.end_date, amount_used: amount_used}],
            aggregate_net_tax_interest_expense: entry?.interest_expense,
            reactivations_prior_first_period: entry?.reactivations_prior_model_use,
            debug_source: "unused_interest"})           
    })

    

    
    // CARRY FORWARD RESTRICTIONS CALCULATIONS
    unused_allowances_for_group?.forEach(poa => {
        if ((!processDateJS(poa?.period_start)?.isSame(processDateJS(currPoaStartDate)) && !processDateJS(poa?.period_end)?.isSame(processDateJS(currPoaEndDate))) && poa?.period_start && poa?.period_end){ // exclude current POA
            // TEST WHICH SUBSECTIONS APPLY
           let subsections = {
                s3952: false,
                s3953: false,
                s3954: false,
                s3956: false,
                s3958: false
            };     

            // s395(2)
            if (processDateJS(poa?.period_start)?.isSameOrAfter(processDateJS(currPoaStartDate)?.subtract(5, 'year')) && 
                processDateJS(poa?.period_end)?.isSameOrAfter(processDateJS(currPoaEndDate)?.subtract(5, 'year'))){
                    subsections.s3952 = true;}
            // s395(3)
            else if (processDateJS(poa?.period_end)?.isSameOrBefore(processDateJS(currPoaStartDate)?.subtract(5, 'year'))){
                        subsections.s3953 = true;}
            // s395(4)
            else if (processDateJS(poa?.period_start)?.isBefore(processDateJS(currPoaStartDate)?.subtract(5, 'year')) && 
                processDateJS(poa?.period_end)?.isSameOrAfter(processDateJS(currPoaEndDate)?.subtract(5, 'year'))){
                        subsections.s3954 = true;}            
            // s395(6)
            else if (processDateJS(poa?.period_start)?.isSameOrAfter(processDateJS(currPoaStartDate)?.subtract(5, 'year')) && 
                processDateJS(poa.period_end)?.isBefore(processDateJS(currPoaEndDate)?.subtract(5, 'year'))){
                        subsections.s3956 = true;}       
            // s395(8)
            else if (subsections.s3953 === false && 
                (processDateJS(poa?.period_start)?.isBefore(processDateJS(currPoaStartDate)?.subtract(5, 'year')) && 
                processDateJS(poa?.period_end)?.isBefore(processDateJS(currPoaEndDate)?.subtract(5, 'year')))){
                        subsections.s3958 = true;}
     
            // CALCULATE UNUSED ALLOWANCES
            if (!(poa?.interest_allowance) || poa?.interest_allowance == null){poa.interest_allowance = 0}
            // 395(A-B)
            let calc395AB   = poa?.interest_allowance - Math.min(poa?.interest_allowance, poa?.aggregate_net_tax_interest_expense);
            // 395(5) (X)
            let calc3955X = (processDateJS(poa?.period_end)?.add(5, "year")?.diff(processDateJS(currPoaStartDate), "day")) + 1;
            // 395(5) (Y)
            let calc3955Y = (processDateJS(poa?.period_end)?.diff(processDateJS(poa?.period_start), "day")) + 1;
            // 395(5)
            let calc3955    = calc395AB * calc3955X / calc3955Y;
            // 395(7) (X)
            let calc3957X = (processDateJS(poa?.period_end)?.add(5, "year")?.diff(processDateJS(currPoaStartDate), "day")) + 1;
            // 395(7) (Z)
            let cacl3957Z = (processDateJS(currPoaEndDate)?.diff(processDateJS(currPoaStartDate), "day")) + 1;
            // 395(7)
            // let calc3957    = 0;
            // 395(9)
            // let calc3959    = Math.min(calc3955, calc3957);
            // Group - Unexpired Allowance
            // let groupUnexpiredAllowance = 0;
            // Group - Unused Allowance
            // let groupUnusedAllowance    = 0;
            // GR - 395(7) (C-D)
            let GR_3957_CD = currPoaData?.aggregate_net_tax_interest_expense - Math.min(currPoaData?.gr_interest_allowance, currPoaData?.aggregate_net_tax_interest_expense);
            // FR - 395(7) (C-D)
            let FR_3957_CD = currPoaData?.aggregate_net_tax_interest_expense - Math.min(currPoaData?.fr_interest_allowance, currPoaData?.aggregate_net_tax_interest_expense);
            // GR - 395(7)
            let GR_3957 = GR_3957_CD * calc3957X / cacl3957Z;
            // FR - 395(7)
            let FR_3957 = FR_3957_CD * calc3957X / cacl3957Z;

            let GR_3959 = Math.min(calc3955, GR_3957);
            let FR_3959 = Math.min(calc3955, FR_3957);
            
            // GR - Unexpired Allowance
            let GR_unexpired_allowance = 0; 
            if(subsections?.s3952 === true){ GR_unexpired_allowance = poa?.interest_allowance}
            else if(subsections?.s3953 === true){ GR_unexpired_allowance = 0}
            else if(subsections?.s3954 === true){ GR_unexpired_allowance = calc3955}
            else if(subsections?.s3956 === true){ GR_unexpired_allowance = GR_3957}
            else if(subsections?.s3958 === true){ GR_unexpired_allowance = GR_3959}
            else{ GR_unexpired_allowance = 0}

            // FR - Unexpired Allowance
            let FR_unexpired_allowance = 0; 
            if(subsections?.s3952 === true){ FR_unexpired_allowance = poa?.interest_allowance}
            else if(subsections?.s3953 === true){ FR_unexpired_allowance = 0}
            else if(subsections?.s3954 === true){ FR_unexpired_allowance = calc3955}
            else if(subsections?.s3956 === true){ FR_unexpired_allowance = FR_3957}
            else if(subsections?.s3958 === true){ FR_unexpired_allowance = FR_3959}
            else{ FR_unexpired_allowance = 0}

            if(!(poa?.unused_allowance) || poa?.unused_allowance == null){poa.unused_allowance = 0;}
            if(!(poa?.reactivations_prior_first_period) || poa?.reactivations_prior_first_period == null){poa.reactivations_prior_first_period = 0} 
            // GR - Unused Allowance
            let GR_unused_allowance = Math.min(poa?.unused_allowance, GR_unexpired_allowance) 
            // FR - Unused Allowance
            let FR_unused_allowance = Math.min(poa?.unused_allowance, FR_unexpired_allowance) 

            // values for debugging purposes ONLY
            let allowancesForPeriod = {
                GR_3959: GR_3959,
                FR_3959: FR_3959,
                calc395AB: calc395AB,
                calc3955X: calc3955X,
                calc3955Y: calc3955Y,
                calc3955: calc3955,
                calc3957X: calc3957X,
                cacl3957Z: cacl3957Z,
                GR_3957_CD: GR_3957_CD,
                FR_3957_CD: FR_3957_CD,
                GR_3957: GR_3957,
                FR_3957: FR_3957,
                debug_unused: poa?.unused_allowance,
                debug_reactivations: poa?.reactivations_prior_first_period,
                debug_FRunexpired: FR_unexpired_allowance,
                debug_GRunexpired: GR_unexpired_allowance,
                debug_subsections: subsections,
                period_start: poa?.period_start,
                period_end: poa?.period_end,
                gr_unexpired_allowance: GR_unexpired_allowance,
                fr_unexpired_allowance: FR_unexpired_allowance,
                gr_unused_allowance: GR_unused_allowance,
                fr_unused_allowance: FR_unused_allowance
            }

            allowance_calculations?.push(allowancesForPeriod);
            poa.gr_unexpired_allowance = GR_unexpired_allowance
            poa.fr_unexpired_allowance = FR_unexpired_allowance
            poa.gr_unused_allowance = GR_unused_allowance
            poa.fr_unused_allowance = FR_unused_allowance
        }
    })
    savedData.unused_allowances_for_group = unused_allowances_for_group;

    // aggregage allowances
    let GR_unused_allowance = 0;
    let FR_unused_allowance = 0;
    let GR_unexpired_allowance = 0;
    let FR_unexpired_allowance = 0;
    allowance_calculations?.forEach(entry => {
        GR_unused_allowance += entry?.gr_unused_allowance;
        FR_unused_allowance += entry?.fr_unused_allowance;
        GR_unexpired_allowance += entry?.gr_unexpired_allowance;
        FR_unexpired_allowance += entry?.fr_unexpired_allowance;
    }) 

    // aggregate GR/FR unexpired/unused allowances for group (should always be a positive amount)
    savedData.periods_of_account[poaIndex].allowance_calculations = {
        gr_unused_allowance: GR_unused_allowance,
        fr_unused_allowance: FR_unused_allowance,
        gr_unexpired_allowance: GR_unexpired_allowance,
        fr_unexpired_allowance: FR_unexpired_allowance,
        allowances_breakdown: allowance_calculations
    }

    return savedData;
}

// allocate allowances to prior POA's
const calculateUsedAllowances =  (savedData, poaIndex) => {
    let currPoaData = savedData?.periods_of_account[poaIndex];
    let currPoaStartDate = currPoaData?.period_start; // current POA start date
    let currPoaEndDate = currPoaData?.period_end; // curent POA end date
    let total_amount_available = currPoaData?.group_bf_interest_allowance;
    
    // sort unused allowances array by date, and filter out any future periods
    const sorted_unused_allowances = savedData?.unused_allowances_for_group?.sort((a, b) => {
        return a?.period_start - b?.period_start
    })
    sorted_unused_allowances?.filter((poa) => processDateJS(poa?.period_start)?.isSameOrBefore(currPoaStartDate))
    // loop through periods with unused allowances and allocate
    const updated_unused_allowances = sorted_unused_allowances?.map((poa) => {
        // must import the selected POA data to get the version of the unused allowance value which accounts for expired allowances.
        const unused_allowance = currPoaData?.apply_group_ratio_election ? poa?.gr_unused_allowance : poa?.fr_unused_allowance;
        if (!poa?.hasOwnProperty('used_allowances_breakdown')) {
            poa.used_allowances_breakdown = [];
        }
        const amount_used = total_amount_available > 0 && unused_allowance ? Math?.min(total_amount_available, unused_allowance) : 0;
        total_amount_available -= amount_used
        if (amount_used > 0) {
            poa?.used_allowances_breakdown?.push({utilisation_period_start: currPoaStartDate, utilisation_period_end: currPoaEndDate, amount_used: amount_used})
        }
        // recalculate total amount used in this poa
        let total_amount_used = 0;
        poa?.used_allowances_breakdown?.forEach((utilisation) => {
            total_amount_used += utilisation && utilisation?.hasOwnProperty('amount_used') && utilisation?.amount_used > 0 ? utilisation?.amount_used : 0;
        })
        poa.amount_used = total_amount_used;
        poa.unused_allowance = poa?.unused_generated  - total_amount_used; 
        return poa;
    })
    savedData.unused_allowances_for_group = updated_unused_allowances;
    return savedData;
}

export  {calculateUnusedAllowanceData, calculateUsedAllowances};