import { useState } from "react";
import toast from "react-hot-toast";
import { Modal } from "@appkit4/react-components"

const SupportPanel = ({visible, setVisible}) => {

    return <>
        <Modal
        visible={visible}
        title={"Support"}
        ariaLabel={"Support"}
        onCancel={() => setVisible(false)}
        maskCloseable={true}
        modalStyle={{width: '50rem'}}
        footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '100px' }}
        header={""}
        icons={""}
        >
            <p> 
               We have developed a number of resources to assist you in using the CIR Analyser
            </p>
            <p>
               <h4><a href="https://docs.google.com/document/d/18KH_puRXES4LTxqU65naXAmNSnL91bZvUVrk5-IsHRw/" target="_blank">CIR Analyser User Guide</a></h4> 
               The User Guide is structured to walk you through every aspect of the CIR Analyser, from initial setup and data input to generating outputs. Should you encounter any challenges or have questions as you use the CIR Analyser, this guide should be your first point of reference.
            </p>
            <p>
               <h4><a href="https://docs.google.com/document/d/1JcXfodSvNTHuOti5b9gfAqnlweRVHstulaR-uidx-GU/" target="_blank">CIR Analyser Technical Guide</a></h4> 
               The Technical Guide is an important resource for understanding how the technicalities of the CIR legislation are applied within the framework of the CIR Analyser. It provides a high-level overview of certain parts of the legislation to support you in inputting values and understanding certain data validations. 
            </p>
            <p>
               <h4><a href="https://docs.google.com/document/d/1YptKViu-YsGFB8w3rKHRrZKPzbmbf6udUwaW5C80dqM/" target="_blank">CIR Technical Paper</a></h4> 
               The CIR Technical Paper is a general guide that provides details on the application of the CIR legislation.
            </p>
            <p>
               <h4><a href="https://www.gov.uk/hmrc-internal-manuals/corporate-finance-manual/cfm95000" target="_blank">Link to HMRC CIR Guidance</a></h4> 
               HMRC's CIR Guidance is included in CFM95000 onwards of the Corporate Finance Manual and provides HMRC's interpretation of the legislation.
            </p>
            <p>
               <h4><a href="https://docs.google.com/spreadsheets/d/1yNS91A9JXBRrhj0ynShGkSjJMgWROxEsrriGiCAmbpg/" target="_blank">List of CIR Champions</a></h4> 
               A group of CIR champions, who have experience of using the CIR Analyser can be found by clicking on the link above. Your local CIR Champion should be able to assist with technical CIR support and using the CIR Analyser.
            </p>
            <p>
               <h4><a href="mailto:uk_cir_app_support@pwc.com?subject=CIR App Support" target="_blank">Email uk_cir_app_support@pwc.com</a></h4> 
               If the above resources do not provide an answer to your question, or you have an issue in using the CIR Analyser for your group, you can contact us at uk_cir_app_support@pwc.com by clicking on the link above. Please ensure that you have checked the above resources before reaching out.
            </p>
        </Modal>
    </>

}

export default SupportPanel;