import { Button } from '@appkit4/react-components';
import { useNavigate } from 'react-router-dom'
import { getGroupName, setLastNavigatedLocation } from '../services/GroupContext';
import sqlService from '../services/sqldatabase/sqldatabase.service';
const ErrorHandler = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();
  const navtohome = async () => {
    const groupName = undefined;
    if (groupName) {
      try {
        await sqlService?.postRemoveGroupLock(groupName);
      }
      catch (error) {
        console.log(error)
      }
    }
    navigate('/');
    setLastNavigatedLocation('/');
    resetErrorBoundary();
  };
  
  return (
    <div className="ap-container">
        <h1>
            Error: { error?.hasOwnProperty('message') ? error?.message : error }
        </h1>
        <Button onClick={navtohome}>Return to homepage</Button>
    </div>
  );
};

export default ErrorHandler;