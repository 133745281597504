// these functions calculate in local currency - please save a copy of the result in both GBP and LC
const calculateNGIE = (NGIEValues) => {
    let NGIE_Total = 0;
    if (NGIEValues) {
        Object.entries(NGIEValues)?.forEach(([key, subsection]) => {
            if (Array.isArray(subsection)) { 
                subsection?.forEach((entry) => {
                    NGIE_Total += entry?.value ? entry?.value : 0;
                })
            }
        })
    }
    return NGIE_Total;
}

const calculateANGIE = (localCurrNGIE, ANGIEValues, includeAdjustments = true) => {
    let total = localCurrNGIE;
    let totalAdjustments = 0;
    const adjustmentRowKeys = [
        'angie_proportion_associated_groups_s427_4',
        'exclude_qic_income_exempt_s440_s442_2a',
        'exclude_qic_expense_exempt_s438_s442_2a'
    ]
    if (ANGIEValues) {
        Object.entries(ANGIEValues)?.forEach(([key, subsection]) => {
            if (Array.isArray(subsection)) { 
                subsection?.forEach((entry) => {
                    if (adjustmentRowKeys?.includes(key)) {
                        totalAdjustments += entry?.value ? entry?.value : 0;
                    }
                    total += entry?.value ? entry?.value : 0;
                })
            }
        })
    }

    total = total > 0 ? total : 0;
    const totalBeforeAdjustments = Math.max(total - totalAdjustments, 0);
    
    return { total, totalBeforeAdjustments };
}

const calculateQNGIE = (localCurrANGIE, QNGIEValues, interestAllowanceElection = false) => {
    let QNGIE_Total = 0;
    if (QNGIEValues) {
        Object.entries(QNGIEValues)?.forEach(([key, subsection]) => {
            if (Array.isArray(subsection)) { 
                subsection?.forEach((entry) => {
                    QNGIE_Total += entry?.value ? entry?.value : 0;
                })
            }
        })
    }
    QNGIE_Total = Math.max(QNGIE_Total + localCurrANGIE, 0);
    return QNGIE_Total
}

const calculateEBITDA = (localCurrNGIE, EBITDAValues) => {
    let EBITDA_Total = 0;
    if (EBITDAValues) {
        Object.entries(EBITDAValues)?.forEach(([key, subsection]) => {
            if (Array.isArray(subsection)) { 
                subsection?.forEach((entry) => {
                    EBITDA_Total += entry?.value ? entry?.value : 0;
                })
            }
            else {
                EBITDA_Total += typeof subsection === 'number' ? subsection: 0;
            }
        })
    }
    EBITDA_Total += localCurrNGIE;
    return EBITDA_Total;
}

const setNGIETotals = (poa) => {
    let NGIE_Total = calculateNGIE(poa?.ngie);
    poa.net_group_interest_expense = NGIE_Total / poa.fx_rate;
    poa.local_curr_net_group_interest_expense = NGIE_Total;
}

const setANGIETotals = (poa) => {
    let angieCalc = calculateANGIE(poa.local_curr_net_group_interest_expense ?? 0, poa.angie)
    poa.adjusted_net_group_interest_expense = angieCalc.total / poa.fx_rate;
    poa.local_curr_adjusted_net_group_interest_expense = angieCalc.total;
    poa.angie_amount_before_any_adjustments = angieCalc.totalBeforeAdjustments;
}

const setQNGIETotals = (poa) => {
    let ngieTotal = Number.isFinite(poa.angie_amount_before_any_adjustments) ? 
        poa.angie_amount_before_any_adjustments : 
        0;
    let QNGIE_Total = calculateQNGIE(ngieTotal, poa.qngie);
    poa.qualifying_net_group_interest_expense = QNGIE_Total / poa.fx_rate;
    poa.local_curr_qualifying_net_group_interest_expense = QNGIE_Total;
}

const setEBITDATotals = (poa) => {
    let ngieCalc = poa.local_curr_net_group_interest_expense;
    ngieCalc -= Number.isFinite(poa.ngie?.adjustments_exclude_relevant_income) ?
        poa.ngie.adjustments_exclude_relevant_income :
        0;
    let EBITDA_Total = calculateEBITDA(poa.angie_amount_before_any_adjustments ?? 0, poa.ebitda);
    poa.group_ebitda = EBITDA_Total / poa.fx_rate;
    poa.local_curr_group_ebitda = EBITDA_Total;
}

export { setNGIETotals, setANGIETotals, setQNGIETotals, setEBITDATotals };