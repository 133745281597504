import { AuthContext } from "../services/AuthProvider";
import { useContext } from "react";
import authService from "../services/auth";

// this component blocks any users from accessing a page if they aren't logged in
export const ProtectedRoute = ({ children }) => {
  const auth = useContext(AuthContext);
  if (!auth?.hasUser()) {
    console.log('@protected route: user is not logged in')
    // user is not authenticated, so call the backend login function to redirect to PwC Identity
    authService
      .login()
      .then((data) => {
        window.location.href = data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // if user is logged in return the child page
  else return children;
};
