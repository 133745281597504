import { Button, Input, InputNumber } from "../../components/ReadonlyAwareInputs";
import { useEffect, useState, useRef } from 'react';
import { json } from 'react-router-dom';


const MAX_INT = 1e+16



const canAddRow = (tableData) => {
  if(tableData && tableData?.length > 0) {
    return tableData[0]?.value && tableData?.slice(1)?.every(row => {
      if (!row?.description || !row?.value) {
        return false;
      }
      
      return true;
    })
  }
  return true;
};



export default function DynamicTable(inputTableData = [[]], updateParentTableData, setIsError, valueType = '') {
  const valueMin = valueType === 'positive' ? 0 : -MAX_INT;
  const valueMax = valueType === 'negative' ? 0 : MAX_INT;
  const [validators, setValidators] = useState(inputTableData?.map(data => {
    return true;
  }));

  const [tableData, updateTableData] = useState([]);
  const [maxKey, setMaxKey] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);

  const inputRefs = useRef([]);

  useEffect(() => {
    if (updateCount < 1) {
      const timeoutId = setTimeout(() => {
        updateTableData(inputTableData?.map((data, index) => ({ ...data, key: maxKey + index })));
        setMaxKey(inputTableData?.length - 1);
        setUpdateCount(updateCount + 1);  // Increment the update count
      }, 300);

      return () => clearTimeout(timeoutId);
    } else {
    }
  }, [inputTableData]);

  
  const addTableRow = () => {
    if (canAddRow(tableData)) {
      setValidators(prevState => [...prevState, true]);
      setMaxKey(prevState => prevState+1);
      updateTableData((prevState) => [...prevState, { description: '', value: '', key: maxKey + 1 }]);
      updateParentTableData((prevState) => [...prevState, { description: '', value: '' }]);

      const timeoutId = setTimeout(() => {
        if (inputRefs?.current?.length > 0) {
          inputRefs?.current[inputRefs?.current?.length -1]?.focus();
        }
      }, 50);
      return () => clearTimeout(timeoutId);
    }
  };

  const deleteTableRows = (index) => {
    if(tableData?.length === 1) {
      setValidators([true]);
      updateTableData([{ description: '', value: '', key: maxKey+1 }]);
      updateParentTableData([{ description: '', value: '' }]);
    } else {
      setValidators(prevState => {
        const temp = prevState;
        temp?.filter((_, i) => i !== index);
        return temp;
      });

      inputRefs.current = inputRefs?.current?.filter((_, i) => i !== index);
      
      updateTableData(prevState => {
        const rows = [...prevState];
        return rows?.filter((_, i) => i !== index);
      });
      updateParentTableData(prevState => {
        const rows = [...prevState];
        return rows?.filter((_, i) => i !== index);
      })
    }
  };

  const isDescriptionValid = (index, desc, val) => {
    return !(((index > 0) && val) ? (!desc || desc?.length === 0) : false)
  }

  const isValueValid = (val) => {
    return !(val > valueMax || val < valueMin);
  }

  const tableChange = (index, key, value) => {
    const rowsInput = [...tableData];
    rowsInput[index][key] = value;
    
    const isValid = 
      isDescriptionValid(index, rowsInput[index]['description'], rowsInput[index]['value']) &&
      isValueValid(rowsInput[index]['value']);
    
    setValidators(prevState => {
      const newValidators = prevState;
      newValidators[index] = isValid;
      return newValidators
    });

    updateTableData(rowsInput);
    updateParentTableData(rowsInput);
  };

  useEffect(() => {
    setIsError(
      validators?.includes(false)
    )
  }, [tableData, validators])

  const handleKeyDown = (event, index) => {
    if (event?.key === 'Enter' && index === tableData?.length-1) {
      addTableRow();
    }
  }

  return <div>
    <table className="table" style={{ width: '100%' }}>
      <thead>
        <tr>
          <th className="col" key={1}>
            Description
          </th>
          <th className="col" key={2}>
            Value
          </th>
          <th style={{ width: '2%' }}>
            <Button
              add
              disabled={!canAddRow(tableData)}
              className="btn btn-outline-success"
              onClick={() => addTableRow()}
            >
              <span className="Appkit4-icon icon-plus-outline"></span>
            </Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {tableData?.map((data, index) => {
          return (
            <tr key={data['key']}>
              <td key={1}>
                <Input
                  inputRef={el => inputRefs.current[index] = el}
                  defaultValue={data["description"]}
                  onChange={(val) => tableChange(index, 'description', val)}
                  error={!isDescriptionValid(index, data['description'], data['value'])}
                />
              </td>
              <td key={2}>
                <InputNumber
                  format=','
                  value={data["value"]}
                  onChange={(val) => tableChange(index, 'value', val)} 
                  error={!isValueValid(data['value'])}
                  onKeyDown={(event) => handleKeyDown(event, index)}
                  />
              </td>
              <td>
                <Button
                  className="btn btn-outline-danger"
                  onClick={() => deleteTableRows(index)}
                >
                  <span className="Appkit4-icon icon-close-outline"></span>
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
}
