import { processDateJS } from "../../utils/dateProcessor"
import { calculateDTRAdjustment, convertGbpToLc, covertToPercentage } from "../../pages/entity-input/util/DoubleTaxReliefCalc";
/**
 * Please be careful when editing metric_id or display_name. In some parts of the code, these are hard coded values
 * so please ensure you've updated those parts of the code as well.
 */

const displayNames = {
    dn1: "Total per Computation Schedule for period",
    dn2: "      Exclude Exchange gains/(losses)",
    dn3: "      Exclude Impairment gains/(losses)",
    dn4: "      Exclude Tax-Interest Income subject to Double Tax Relief - s388",
    dn5: "Adjustment for election to apply the Disregard Regulations to pre 1 April 2020 derivative contracts (Regulations 7, 8 and 9)",
    dn6: "Adjustment for election for amortised cost basis for fair valued creditor relationships",
    dn7: "Adjustment for election for Qualifying Infrastructure Company",
    dn8: "Exclude defined benefit pension income/expense included on loan relationships statement",
    dn9: "Add bank charges and similar interest type expenses not included in the total above (i.e. not in Loan Relationships schedule)",
    dn10: "Adjustment for GAAP transition items",
    dn11: "Adjustment for finance costs associated with leases",
    dn12: "Adjustment to include Expense (income) associated with finance leases",
    dn13: "Adjustment to include Expense (income) associated with debt factoring transactions",
    dn14: "Adjustment to include Expense (income) associated with service concession arrangement if and to the extent accounted for as a financial liability",
    dn15: "Adjustment for election to apply the Disregard Regulations to pre 1 April 2020 derivative contracts (Regulations 7, 8 and 9)",
    dn16: "Exclude amounts on contracts arising in the ordinary course of trade",
    dn17: "Exclude amounts for derivatives without an underlying subject matter specified in s384(4) / s387(4)",
    dn18: "Adjustment for election to apply the Disregard Regulations to pre 1 April 2020 derivative contracts (Regulations 7, 8 and 9)",
    dn19: "Other Adjustments 1",
    dn20: "Other Adjustments 2",
    dn21: "Other Adjustments 3"
}

const metrics = [
    { metric_id: "start_date", display_name: "Accounting Period Start Date", mandatory: true, type: 'date', editable: false },
    { metric_id: "end_date", display_name: "Accounting Period End Date", mandatory: true, type: 'date', editable: false },
    { metric_id: "currency", display_name: "Computation Currency", mandatory: true, type: 'text' },
    { metric_id: "fx_rate", display_name: "Exchange Rate (GBP1:CURX)", mandatory: true, type: 'number' },
    { metric_id: "onesource_sync", display_name: "Enable OneSource data extraction", mandatory: false, type: 'boolean', read_only: (cap) => { return !cap.from_onesource; }},
    { metric_id: "updated_date_db", display_name: "Last updated from OneSource", mandatory: false, editable: false },
    { type: 'blank' },

    { type: 'header', display_name: "Net Tax-Interest Expense/(Income) Section" },
    // { type: 'blank' },
    { type: 'subheader', display_name: "Non-trading loan relationships" },
    { metric_id: "nltr_total_per_source_sheet_for_period", display_name: displayNames?.dn1, mandatory: true, type: 'number', calculation_group: ['ntlr'] , onesourcedredged: true, tooltipMessage: <p>This represents the total amount reflected in the loan relationship schedule of the relevant tax computations, representing the starting point for the non trading loan relationship tax interest calculation. It is likely to include both amounts which should be included as tax interest amounts (i.e. amounts which are correctly included) and amounts which should not be part of the calculations, and therefore need to be adjusted out. It may also be the case that this starting point does not include all relevant amounts, and that adjustments will need to be made to reflect amounts which are not included on the loan relationship schedule.</p>},
    { type: 'note', display_name: "Less: included in the above" },
    { metric_id: "nltr_exclude_exchange_gains_losses", display_name: displayNames?.dn2, mandatory: true, type: 'number', calculation_group: ['ntlr'], tooltipMessage: <><p>Under s383(3)(a) and s386(3)(a) TIOPA 2010 "relevant" loan relationship debits and credits do not include amounts in respect of exchange gains or losses.</p><p>This adjustment allows for the exclusion of exchange gains and losses included in the initial total above. Exchange losses should be removed as negative adjustments (reducing tax-interest expense), while exchange gains should be removed as positive adjustments (reducing tax-interest income).</p></>},
    { metric_id: "nltr_exclude_impairment_gains_losses", display_name: displayNames?.dn3, mandatory: true, type: 'number', calculation_group: ['ntlr'], tooltipMessage: <><p>Under s383(3)(b) and s386(3)(b) TIOPA 2010 "relevant" loan relationship debits and credits do not include amounts relating to impairment losses or any reversal of such amounts.</p><p>This adjustment allows for the exclusion of impairment losses or reversals which are already included in the initial total above. Impairment losses should be removed as negative adjustments (reducing tax-interest expense), while reversals should be removed as positive adjustments (reducing tax-interest income).</p></>},
    { metric_id: "nltr_exclude_tax_interest_income_double_relief", display_name: displayNames?.dn4, mandatory: true, type: 'number', calculation_group: ['ntlr'], tooltipMessage:  <><p>Under s388 TIOPA 2010 an adjustment must be made to remove the amount of "notional untaxed income" arising where double tax relief is claimed (i.e the tax charge in respect of tax-interest income amounts is reduced under s18(2) TIOPA 2010).</p><p>The adjustment is a positive adjustment decreasing tax-interest income by the amount of the double tax relief claimed divided by the corporation tax rate for the period.</p><p>If expense relief is claimed, the deduction for expense releief should reduce the tax-interest income in line with the application of s112 TIOPA 2010, but no s388 adjustment is required.</p></>},
    { metric_id: "nltr_adjustment_amortised_cost_basis", display_name: displayNames?.dn6, mandatory: true, type: 'number', calculation_group: ['ntlr'],  tooltipMessage: <><p>Where an election is made under s456 TIOPA 2010, tax-interest expense / income amounts on loan assets subject to fair value accounting are instead calculated on an amortised cost basis of accounting. However, where there is a hedging relationship between the loan relationship and a hedging instrument, it is assumed that the loan relationship has been designated as a fair value hedge.</p><p>The adjustment requires the removal of fair value gains/losses and inclusion of other adjustments necessary to reflect an amortised cost basis.</p></>},
    { metric_id: "nltr_adjustment_qualifying_election", display_name: displayNames?.dn7, mandatory: true, type: 'number', calculation_group: ['ntlr'], tooltipMessage:  <><p>Under s432 - s450 TIOPA 2010, qualifying infrastructure companies ("QICs") are broadly prevented from having any tax-interest income amounts. Many, but not all, tax-interest expense amounts are also excluded. The exemption is complex and should be carefully considered, taking into account the application of all of s432 -s450 TIOPA 2010.</p><p>This adjustment requires the removal of tax-interest income amounts and certain tax-interest expense amounts. A separate calculation of the specific amounts excluded should be separately prepared to support this adjustment.</p></>},
    { metric_id: "nltr_exclude_defined_benefit_pension", display_name: displayNames?.dn8, mandatory: true, type: 'number', calculation_group: ['ntlr'], tooltipMessage: <><p>Interest associated with a defined benefit pension scheme is not a relevant income or expense amount, so if included on the loan relationship schedule, it must be removed.</p><p>The adjustment to remove defined benefit pension expense is a negative adjustment to decrease tax-interest expense. The adjustment to remove defined benefit pension income is a positive adjustment to decrease tax-interest income. No adjustment is required if the defined benefit pension income/expense is not included on the loan relationships statement.</p></>},
    { metric_id: "nltr_bank_expenses", display_name: displayNames?.dn9, mandatory: true, type: 'number', calculation_group: ['ntlr'], tooltipMessage:  <><p>Tax-interest expense includes relevant debits brought into account in respect of loan relationships. Under s306A(2) CTA 2009 this includes expenses incurred in bringing loan relationships into existence, in making and receiving payments, or giving effect to related transactions. Many bank charges, including facility fees and overdraft fees constitute tax-interest expense amounts, regardless of whether they are included within interest expense or admin expenses. The treatment of bank transfer fees, currency conversion fees and credit card charges is less clear and requires detailed consideration.</p><p>This adjustment allows for bank charges not included within the loan relationships schedule to be included as tax-interest expense, as a positive adjustment. It also allows an exclusion of any bank charges currently included in the loan relationship schedule that do not meet the definition of tax-interest, as a negative adjustment.</p></>},
    { metric_id: "nltr_adjustment_gaap_transition", display_name: displayNames?.dn10, mandatory: true, type: 'number', calculation_group: ['ntlr'], tooltipMessage:  <><p>Under Paragraphs 29 and 30, Schedule 5, Finance (No2) Act 2017 amounts brought into account under the Change of Accounting Practice Regulations and due to changes to the loan relationship and derivative contract rules in Finance (No2) Act 2015 are ignored in the tax-interest and tax-EBITDA calculation if the first period affected by the change in accounting began before 1 April 2017.</p><p>The adjustment requires expense amounts to be removed as negative adjustments reducing tax-interest expense and income amounts to be removed as positive adjustments, reducing tax-interest income.</p></>},
    { metric_id: "nltr_adjustment_lease_finance_cost", display_name: displayNames?.dn11, mandatory: true, type: 'number', calculation_group: ['ntlr'], tooltipMessage: <><p>Adjustments may be needed to properly reflect finance costs associated with leases.</p><p>Where the opening amount above includes finance costs associated with leases, an adjustment should be made to remove these from the calculation (i.e. a negative adjustment should be made, reducing tax interest) if the lease in question is an operating lease (or would be under the old accounting treatment pre IFRS16). If the lease would be a finance lease, the finance cost is correctly included and no adjustment is needed.</p><p>Conversely, if the amount is not already included, no adjustment is required for operating leases, but for finance leases a positive adjustment should be made (increasing tax interest) in order to include the financing component.</p></> },
    { metric_id: "nltr_other_adjustments_1", display_name: displayNames?.dn19, mandatory: true, type: 'number', calculation_group: ['ntlr'], tooltipMessage: <p>Non-trading loan relationships Other Adjustments 1.</p> },
    { metric_id: "nltr_other_adjustments_2", display_name: displayNames?.dn20, mandatory: true, type: 'number', calculation_group: ['ntlr'], tooltipMessage: <p>Non-trading loan relationships Other Adjustments 2.</p> },
    { metric_id: "nltr_other_adjustments_3", display_name: displayNames?.dn21, mandatory: true, type: 'number', calculation_group: ['ntlr'], tooltipMessage: <p>Non-trading loan relationships Other Adjustments 3.</p> },
    {
        metric_id: "ntlr_subtotal", display_name: "Non-trading loan relationships - Subtotal", type: 'calculation', style:'bold', calculation: (company) => {
            return sumCalculationGroup(company, 'ntlr')
        }
    },

    { type: 'blank' },

    { type: 'subheader', display_name: "Non-trading derivative contracts" },
    { metric_id: "ntdc_total_per_source_sheet_for_period", display_name: displayNames?.dn1, mandatory: true, type: 'number', calculation_group: ['ntdc'], onesourcedredged: true, tooltipMessage: <p>This is the total amount reflected in the derivative contracts schedule, the starting point for the non-trading derivative contracts tax-interest calculation. It is likely to include amounts which are correctly included as tax-interest amounts and amounts which should not be part of the calculations, so need to be adjusted out. The starting point may also not include all relevant amounts, so adjustments may be needed to include amounts not within the derivative contracts schedule.</p> },
    { type: 'note', display_name: "Less: included in the above" },
    { metric_id: "ntdc_exclude_exchange_gains_losses", display_name: displayNames?.dn2, mandatory: true, type: 'number', calculation_group: ['ntdc'], tooltipMessage: <><p>Under s384(3)(a) and s387(3)(a) TIOPA 2010 "relevant" derivative contract debits and credits do not include amounts in respect of exchange gains or losses.</p><p>This adjustment allows for the exclusion of exchange gains and losses included in the initial total above. Exchange losses should be removed as negative adjustments (reducing tax-interest expense), while exchange gains should be removed as positive adjustments (reducing tax-interest income).</p></> },
    { metric_id: "ntdc_exclude_impairment_gains_losses", display_name: displayNames?.dn3, mandatory: true, type: 'number', calculation_group: ['ntdc'], tooltipMessage: <><p>Under s384(3)(b) and s387(3)(b) TIOPA 2010 "relevant" derivative contract debits and credits do not include amounts relating to impairment losses, or their reversal.</p><p>This adjustment allows for the exclusion of impairment losses, or their reversal included in the initial total above. Impairment losses should be removed as negative adjustments (reducing tax-interest expense), while reversals should be removed as positive adjustments (reducing tax-interest income).</p></> },
    { metric_id: "ntdc_exclude_tax_interest_income_double_relief", display_name: displayNames?.dn4, mandatory: true, type: 'number', calculation_group: ['ntdc'], tooltipMessage: <><p>Under s388 TIOPA 2010 an adjustment must be made to remove the amount of "notional untaxed income" arising where double tax relief is claimed (i.e the tax charge in respect of tax-interest income amounts is reduced under s18(2) TIOPA 2010).</p><p>The adjustment is a positive adjustment decreasing tax-interest income by the amount of the double tax relief claimed divided by the corporation tax rate for the period.</p><p>If expense relief is claimed, the deduction for expense releief should reduce the tax-interest income in line with the application of s112 TIOPA 2010, but no s388 adjustment is required.</p></> },
    { metric_id: "ntdc_exclude_ordinary_trade", display_name: displayNames?.dn16, mandatory: true, type: 'number', calculation_group: ['ntdc'] },
    { metric_id: "ntdc_exclude_derivatives_without_subject_matters_384(4)_s387(4)", display_name: displayNames?.dn17, mandatory: true, type: 'number', calculation_group: ['ntdc'] },
    { metric_id: "ntdc_adjustment_qualifying_election", display_name: displayNames?.dn7, mandatory: true, type: 'number', calculation_group: ['ntdc'], tooltipMessage: <><p>Under s432 - s450 TIOPA 2010, qualifying infrastructure companies ("QICs") are broadly prevented from having any tax-interest income amounts. Many, but not all, tax-interest expense amounts are also excluded. The exemption is complex and should be carefully considered, taking into account the application of all of s432 -s450 TIOPA 2010.</p><p>This adjustment requires the removal of tax-interest income amounts and certain tax-interest expense amounts. A separate calculation of the specific amounts excluded should be separately prepared to support this adjustment.</p></> },
    { metric_id: "ntdc_adjustment_gaap_transition", display_name: displayNames?.dn10, mandatory: true, type: 'number', calculation_group: ['ntdc'], tooltipMessage: <><p>Under Paragraphs 29 and 30, Schedule 5, Finance (No2) Act 2017 amounts brought into account under the Change of Accounting Practice Regulations and due to changes to the loan relationship and derivative contract rules in Finance (No2) Act 2015 are ignored in the tax-interest and tax-EBITDA calculation if the first period affected by the change in accounting began before 1 April 2017.</p><p>The adjustment requires expense amounts to be removed as negative adjustments reducing tax-interest expense and income amounts to be removed as positive adjustments, reducing tax-interest income.</p></> },
    { metric_id: "ntdc_adjustment_disregard_regulations", display_name: displayNames?.dn18, mandatory: true, type: 'number', calculation_group: ['ntdc'], tooltipMessage: <><p>Where an election under paragraph 31, Schedule 5, Finance (No2) Act 2017 has been made, the company is treated as having made an election for Disregard Regulations 7, 8, and 9 to apply for derivative contracts entered into by the company before 1 April 2020 for the purposes of calculating Tax-Interest Expense/Income and Tax-EBITDA of the company or a relevant transferee company.</p><p>Under this adjustment any increase in tax-interest expense (or decrease in tax-interest income) should be entered as a positive adjustment and any decrease in tax-interest expense (or increase in tax-interest income) should be entered as a negative adjustment.</p></> },
    { metric_id: "ntdc_other_adjustments_1", display_name: displayNames?.dn19, mandatory: true, type: 'number', calculation_group: ['ntdc'], tooltipMessage: <p>Non-trading derivative contracts Other Adjustments 1.</p> },
    { metric_id: "ntdc_other_adjustments_2", display_name: displayNames?.dn20, mandatory: true, type: 'number', calculation_group: ['ntdc'], tooltipMessage: <><p>Non-trading derivative contracts Other Adjustments 2.</p></> },
    { metric_id: "ntdc_other_adjustments_3", display_name: displayNames?.dn21, mandatory: true, type: 'number', calculation_group: ['ntdc'], tooltipMessage: <><p>Non-trading derivative contracts Other Adjustments 3.</p></> },
    {
        metric_id: "ntdc_subtotal", display_name: "Non-trading derivative contracts - Subtotal", type: 'calculation', style:'bold', calculation: (company) => {
            return sumCalculationGroup(company, 'ntdc')
        }
    },


    { type: 'blank' },

    { type: 'subheader', display_name: "Trading loan relationships" },
    { metric_id: "tlr_total_per_source_sheet_for_period", display_name: displayNames?.dn1, mandatory: true, type: 'number', calculation_group: ['tlr'], onesourcedredged: true, tooltipMessage: <p>This is the total amount reflected in the loan relationship schedule, the starting point for the trading loan relationship tax-interest calculation. It is likely to include amounts which are correctly included as tax-interest amounts and amounts which should not be part of the calculations, so need to be adjusted out. The starting point may also not include all relevant amounts, so adjustments may be needed to include amounts not within the loan relationship schedule.</p> },
    { type: 'note', display_name: "Less: included in the above" },
    { metric_id: "tlr_exclude_exchange_gains_losses", display_name: displayNames?.dn2, mandatory: true, type: 'number', calculation_group: ['tlr'], tooltipMessage: <><p>Under s383(3)(a) and s386(3)(a) TIOPA 2010 "relevant" loan relationship debits and credits do not include amounts in respect of exchange gains or losses.</p><p>This adjustment allows for the exclusion of exchange gains and losses included in the initial total above. Exchange losses should be removed as negative adjustments (reducing tax-interest expense), while exchange gains should be removed as positive adjustments (reducing tax-interest income).</p></>},
    { metric_id: "tlr_exclude_impairment_gains_losses", display_name: displayNames?.dn3, mandatory: true, type: 'number', calculation_group: ['tlr'], tooltipMessage:  <><p>Under s383(3)(b) and s386(3)(b) TIOPA 2010 "relevant" loan relationship debits and credits do not include amounts relating to impairment losses or any reversal of such amounts.</p><p>This adjustment allows for the exclusion of impairment losses or reversals which are already included in the initial total above. Impairment losses should be removed as negative adjustments (reducing tax-interest expense), while reversals should be removed as positive adjustments (reducing tax-interest income).</p></>},
    { metric_id: "tlr_exclude_tax_interest_income_double_relief", display_name: displayNames?.dn4, mandatory: true, type: 'number', calculation_group: ['tlr'], tooltipMessage:  <><p>Under s388 TIOPA 2010 an adjustment must be made to remove the amount of "notional untaxed income" arising where double tax relief is claimed (i.e the tax charge in respect of tax-interest income amounts is reduced under s18(2) TIOPA 2010).</p><p>The adjustment is a positive adjustment decreasing tax-interest income by the amount of the double tax relief claimed divided by the corporation tax rate for the period.</p><p>If expense relief is claimed, the deduction for expense releief should reduce the tax-interest income in line with the application of s112 TIOPA 2010, but no s388 adjustment is required.</p></>},
    { metric_id: "tlr_adjustment_amortised_cost_basis", display_name: displayNames?.dn6, mandatory: true, type: 'number', calculation_group: ['tlr'], tooltipMessage: <><p>Where an election is made under s456 TIOPA 2010, tax-interest expense / income amounts on loan assets subject to fair value accounting are instead calculated on an amortised cost basis of accounting. However, where there is a hedging relationship between the loan relationship and a hedging instrument, it is assumed that the loan relationship has been designated as a fair value hedge.</p><p>The adjustment requires the removal of fair value gains/losses and inclusion of other adjustments necessary to reflect an amortised cost basis.</p></>},
    { metric_id: "tlr_adjustment_qualifying_election", display_name: displayNames?.dn7, mandatory: true, type: 'number', calculation_group: ['tlr'], tooltipMessage: <><p>Under s432 - s450 TIOPA 2010, qualifying infrastructure companies ("QICs") are broadly prevented from having any tax-interest income amounts. Many, but not all, tax-interest expense amounts are also excluded. The exemption is complex and should be carefully considered, taking into account the application of all of s432 -s450 TIOPA 2010.</p><p>This adjustment requires the removal of tax-interest income amounts and certain tax-interest expense amounts. A separate calculation of the specific amounts excluded should be separately prepared to support this adjustment.</p></>},
    { metric_id: "tlr_exclude_defined_benefit_pension", display_name: displayNames?.dn8, mandatory: true, type: 'number', calculation_group: ['tlr'], tooltipMessage: <><p>Interest associated with a defined benefit pension scheme is not a relevant income or expense amount, so if included on the loan relationship schedule, it must be removed.</p><p>The adjustment to remove defined benefit pension expense is a negative adjustment to decrease tax-interest expense. The adjustment to remove defined benefit pension income is a positive adjustment to decrease tax-interest income. No adjustment is required if the defined benefit pension income/expense is not included on the loan relationships statement.</p></>},
    { metric_id: "tlr_bank_expenses", display_name: displayNames?.dn9, mandatory: true, type: 'number', calculation_group: ['tlr'], tooltipMessage:  <><p>Tax-interest expense includes relevant debits brought into account in respect of loan relationships. Under s306A(2) CTA 2009 this includes expenses incurred in bringing loan relationships into existence, in making and receiving payments, or giving effect to related transactions. Many bank charges, including facility fees and overdraft fees constitute tax-interest expense amounts, regardless of whether they are included within interest expense or admin expenses. The treatment of bank transfer fees, currency conversion fees and credit card charges is less clear and requires detailed consideration.</p><p>This adjustment allows for bank charges not included within the loan relationships schedule to be included as tax-interest expense, as a positive adjustment. It also allows an exclusion of any bank charges currently included in the loan relationship schedule that do not meet the definition of tax-interest, as a negative adjustment.</p></>},
    { metric_id: "tlr_adjustment_gaap_transition", display_name: displayNames?.dn10, mandatory: true, type: 'number', calculation_group: ['tlr'], tooltipMessage: <><p>Under Paragraphs 29 and 30, Schedule 5, Finance (No2) Act 2017 amounts brought into account under the Change of Accounting Practice Regulations and due to changes to the loan relationship and derivative contract rules in Finance (No2) Act 2015 are ignored in the tax-interest and tax-EBITDA calculation if the first period affected by the change in accounting began before 1 April 2017.</p><p>The adjustment requires expense amounts to be removed as negative adjustments reducing tax-interest expense and income amounts to be removed as positive adjustments, reducing tax-interest income.</p></> },
    { metric_id: "tlr_adjustment_lease_finance_cost", display_name: displayNames?.dn11, mandatory: true, type: 'number', calculation_group: ['tlr'], tooltipMessage: <><p>Adjustments may be needed to properly reflect finance costs associated with leases.</p><p>Where the opening amount above includes finance costs associated with leases, an adjustment should be made to remove these from the calculation (i.e. a negative adjustment should be made, reducing tax interest) if the lease in question is an operating lease (or would be under the old accounting treatment pre IFRS16). If the lease would be a finance lease, the finance cost is correctly included and no adjustment is needed.</p><p>Conversely, if the amount is not already included, no adjustment is required for operating leases, but for finance leases a positive adjustment should be made (increasing tax interest) in order to include the financing component.</p></> },
    { metric_id: "tlr_other_adjustments_1", display_name: displayNames?.dn19, mandatory: true, type: 'number', calculation_group: ['tlr'], tooltipMessage: <><p>Trading loan relationships Other Adjustments 1.</p></> },
    { metric_id: "tlr_other_adjustments_2", display_name: displayNames?.dn20, mandatory: true, type: 'number', calculation_group: ['tlr'], tooltipMessage: <><p>Trading loan relationships Other Adjustments 2.</p></> },
    { metric_id: "tlr_other_adjustments_3", display_name: displayNames?.dn21, mandatory: true, type: 'number', calculation_group: ['tlr'], tooltipMessage: <><p>Trading loan relationships Other Adjustments 3.</p></> },
    {
        metric_id: "tlr_subtotal", display_name: "Trading loan relationships - Subtotal", type: 'calculation', style:'bold', calculation: (company) => {
            return sumCalculationGroup(company, 'tlr')
        }
    },

    { type: 'blank' },

    { type: 'subheader', display_name: "Trading derivative contracts" },
    { metric_id: "tdc_total_per_source_sheet_for_period", display_name: displayNames?.dn1, mandatory: true, type: 'number', calculation_group: ['tdc'], onesourcedredged: true, tooltipMessage: <p>This is the total amount reflected in the derivative contracts schedule, the starting point for the trading derivative contracts tax-interest calculation. It is likely to include amounts which are correctly included as tax-interest amounts and amounts which should not be part of the calculations, so need to be adjusted out. The starting point may also not include all relevant amounts, so adjustments may be needed to include amounts not within the derivative contracts schedule.</p> },
    { type: 'note', display_name: "Less: included in the above" },
    { metric_id: "tdc_exclude_exchange_gains_losses", display_name: displayNames?.dn2, mandatory: true, type: 'number', calculation_group: ['tdc'], tooltipMessage: <><p>Under s384(3)(a) and s387(3)(a) TIOPA 2010 "relevant" derivative contract debits and credits do not include amounts in respect of exchange gains or losses.</p><p>This adjustment allows for the exclusion of exchange gains and losses included in the initial total above. Exchange losses should be removed as negative adjustments (reducing tax-interest expense), while exchange gains should be removed as positive adjustments (reducing tax-interest income).</p></> },
    { metric_id: "tdc_exclude_impairment_gains_losses", display_name: displayNames?.dn3, mandatory: true, type: 'number', calculation_group: ['tdc'], tooltipMessage: <><p>Under s384(3)(b) and s387(3)(b) TIOPA 2010 "relevant" derivative contract debits and credits do not include amounts relating to impairment losses, or their reversal.</p><p>This adjustment allows for the exclusion of impairment losses, or their reversal included in the initial total above. Impairment losses should be removed as negative adjustments (reducing tax-interest expense), while reversals should be removed as positive adjustments (reducing tax-interest income).</p></>},
    { metric_id: "tdc_exclude_tax_interest_income_double_relief", display_name: displayNames?.dn4, mandatory: true, type: 'number', calculation_group: ['tdc'], tooltipMessage: <><p>Under s388 TIOPA 2010 an adjustment must be made to remove the amount of "notional untaxed income" arising where double tax relief is claimed (i.e the tax charge in respect of tax-interest income amounts is reduced under s18(2) TIOPA 2010).</p><p>The adjustment is a positive adjustment decreasing tax-interest income by the amount of the double tax relief claimed divided by the corporation tax rate for the period.</p><p>If expense relief is claimed, the deduction for expense releief should reduce the tax-interest income in line with the application of s112 TIOPA 2010, but no s388 adjustment is required.</p></>},
    { metric_id: "tdc_exclude_ordinary_trade", display_name: displayNames?.dn16, mandatory: true, type: 'number', calculation_group: ['tdc'] },
    { metric_id: "tdc_exclude_derivatives_without_subject_matters_384(4)_s387(4)", display_name: displayNames?.dn17, mandatory: true, type: 'number', calculation_group: ['tdc'] },
    { metric_id: "tdc_adjustment_qualifying_election", display_name: displayNames?.dn7, mandatory: true, type: 'number', calculation_group: ['tdc'], tooltipMessage: <><p>Under s432 - s450 TIOPA 2010, qualifying infrastructure companies ("QICs") are broadly prevented from having any tax-interest income amounts. Many, but not all, tax-interest expense amounts are also excluded. The exemption is complex and should be carefully considered, taking into account the application of all of s432 -s450 TIOPA 2010.</p><p>This adjustment requires the removal of tax-interest income amounts and certain tax-interest expense amounts. A separate calculation of the specific amounts excluded should be separately prepared to support this adjustment.</p></> },
    { metric_id: "tdc_adjustment_gaap_transition", display_name: displayNames?.dn10, mandatory: true, type: 'number', calculation_group: ['tdc'], tooltipMessage: <><p>Under Paragraphs 29 and 30, Schedule 5, Finance (No2) Act 2017 amounts brought into account under the Change of Accounting Practice Regulations and due to changes to the loan relationship and derivative contract rules in Finance (No2) Act 2015 are ignored in the tax-interest and tax-EBITDA calculation if the first period affected by the change in accounting began before 1 April 2017.</p><p>The adjustment requires expense amounts to be removed as negative adjustments reducing tax-interest expense and income amounts to be removed as positive adjustments, reducing tax-interest income.</p></>},
    { metric_id: "tdc_adjustment_disregard_regulations", display_name: displayNames?.dn18, mandatory: true, type: 'number', calculation_group: ['tdc'], tooltipMessage: <><p>Where an election under paragraph 31, Schedule 5, Finance (No2) Act 2017 has been made, the company is treated as having made an election for Disregard Regulations 7, 8, and 9 to apply for derivative contracts entered into by the company before 1 April 2020 for the purposes of calculating Tax-Interest Expense/Income and Tax-EBITDA of the company or a relevant transferee company. </p><p>Where an election under paragraph 31, Schedule 5, Finance (No2) Act 2017 has been made, the company is treated as having made an election for Disregard Regulations 7, 8, and 9 to apply for derivative contracts entered into by the company before 1 April 2020 for the purposes of calculating Tax-Interest Expense/Income and Tax-EBITDA of the company or a relevant transferee company.</p></> },
    { metric_id: "tdc_other_adjustments_1", display_name: displayNames?.dn19, mandatory: true, type: 'number', calculation_group: ['tdc'], tooltipMessage: <><p>Trading derivative contracts Other Adjustments 1.</p></>},
    { metric_id: "tdc_other_adjustments_2", display_name: displayNames?.dn20, mandatory: true, type: 'number', calculation_group: ['tdc'], tooltipMessage: <><p>Trading derivative contracts Other Adjustments 2.</p></>},
    { metric_id: "tdc_other_adjustments_3", display_name: displayNames?.dn21, mandatory: true, type: 'number', calculation_group: ['tdc'], tooltipMessage: <><p>Trading derivative contracts Other Adjustments 3.</p></>},
    {
        metric_id: "tdc_subtotal", display_name: "Trading derivative contracts - Subtotal", type: 'calculation', style:'bold', calculation: (company) => {
            return sumCalculationGroup(company, 'tdc')
        }
    },
    

    { type: 'blank' },

    { type: 'subheader', display_name: "Other financing arrangements" },
    { metric_id: "ofa_total_per_source_sheet_for_period", display_name: displayNames?.dn1, mandatory: true, type: 'number', calculation_group: ['ofa'], onesourcedredged: true, tooltipMessage: <p>This is the total amount reflected in the other financing arrangements schedule, the starting point for the other financing arrangements tax-interest calculation. It is likely to include amounts which are correctly included as tax-interest amounts and amounts which should not be part of the calculations, so need to be adjusted out. The starting point may also not include all relevant amounts, so adjustments may be needed to include amounts not within the other financing arrangements schedule.</p> },
    { type: 'note', display_name: "Less: included in the above" },
    { metric_id: "ofa_exclude_tax_interest_income_double_relief", display_name: displayNames?.dn4, mandatory: true, type: 'number', calculation_group: ['ofa'], tooltipMessage: <><p>Under s388 TIOPA 2010 an adjustment must be made to remove the amount of "notional untaxed income" arising where double tax relief is claimed (i.e the tax charge in respect of tax-interest income amounts is reduced under s18(2) TIOPA 2010).</p><p>The adjustment is a positive adjustment decreasing tax-interest income by the amount of the double tax relief claimed divided by the corporation tax rate for the period.</p><p>If expense relief is claimed, the deduction for expense releief should reduce the tax-interest income in line with the application of s112 TIOPA 2010, but no s388 adjustment is required.</p></> },
    { metric_id: "ofa_adjustment_qualifying_election", display_name: displayNames?.dn7, mandatory: true, type: 'number', calculation_group: ['ofa'], tooltipMessage: <><p>Under s432 - s450 TIOPA 2010, qualifying infrastructure companies ("QICs") are broadly prevented from having any tax-interest income amounts. Many, but not all, tax-interest expense amounts are also excluded. The exemption is complex and should be carefully considered, taking into account the application of all of s432 -s450 TIOPA 2010.</p><p>This adjustment requires the removal of tax-interest income amounts and certain tax-interest expense amounts. A separate calculation of the specific amounts excluded should be separately prepared to support this adjustment.</p></> },
    { metric_id: "ofa_adjustment_gaap_transition", display_name: displayNames?.dn10, mandatory: true, type: 'number', calculation_group: ['ofa'], tooltipMessage: <><p>Under Paragraphs 29 and 30, Schedule 5, Finance (No2) Act 2017 amounts brought into account under the Change of Accounting Practice Regulations and due to changes to the loan relationship and derivative contract rules in Finance (No2) Act 2015 are ignored in the tax-interest and tax-EBITDA calculation if the first period affected by the change in accounting began before 1 April 2017.</p><p>The adjustment requires expense amounts to be removed as negative adjustments reducing tax-interest expense and income amounts to be removed as positive adjustments, reducing tax-interest income.</p></> },
    { metric_id: "ofa_adjustment_finance_lease", display_name: displayNames?.dn12, mandatory: true, type: 'number', calculation_group: ['ofa'], tooltipMessage: <><p>Adjustments may be needed to properly reflect finance costs associated with leases.</p><p>Where the opening amount above includes finance costs associated with leases, an adjustment should be made to remove these from the calculation (i.e. a negative adjustment should be made, reducing tax interest) if the lease in question is an operating lease (or would be under the old accounting treatment pre IFRS16). If the lease would be a finance lease, the finance cost is correctly included and no adjustment is needed.</p><p>Conversely, if the amount is not already included, no adjustment is required for operating leases, but for finance leases a positive adjustment should be made (increasing tax interest) in order to include the financing component.</p></> },
    { metric_id: "ofa_adjustment_debt_factoring", display_name: displayNames?.dn13, mandatory: true, type: 'number', calculation_group: ['ofa'], tooltipMessage: <><p>Under s382(5)(b) and s385(5)(b) TIOPA 2010 any expense or income amounts associated with debt factoring transactions should be included in the calculation of tax-interest.</p><p>This adjustment allows for debt factoring expense amounts not included within the total above to be included as tax-interest expense, as a positive adjustment. It also allows debt factoring income amounts not included within the total above to be included as tax-interest income, as a negative adjustment.</p></> },
    { metric_id: "ofa_adjustment_service_concession", display_name: displayNames?.dn14, mandatory: true, type: 'number', calculation_group: ['ofa'], tooltipMessage: <><p>Under s382(5)(c) and s385(5)(c) TIOPA 2010 expense or income amounts associated with service concession arrangements, to the extent that these are accounted for as financial liabilities, should be included in the calculation of tax-interest.</p><p>This adjustment allows for service concession expense amounts not included within the total above to be included as tax-interest expense, as a positive adjustment. It also allows service concession income amounts not included within the total above to be included as tax-interest income, as a negative adjustment.</p></> },
    { metric_id: "ofa_other_adjustments_1", display_name: displayNames?.dn19, mandatory: true, type: 'number', calculation_group: ['ofa'], tooltipMessage: <><p>Other financing arrangements Other Adjustments 1.</p></> },
    { metric_id: "ofa_other_adjustments_2", display_name: displayNames?.dn20, mandatory: true, type: 'number', calculation_group: ['ofa'], tooltipMessage: <><p>Other financing arrangements Other Adjustments 2.</p></> },
    { metric_id: "ofa_other_adjustments_3", display_name: displayNames?.dn21, mandatory: true, type: 'number', calculation_group: ['ofa'], tooltipMessage: <><p>Other financing arrangements Other Adjustments 3.</p></> },
    {
        metric_id: "ofa_subtotal", display_name: "Other financing arrangements - Subtotal", type: 'calculation', style:'bold', calculation: (company) => {
            return sumCalculationGroup(company, 'ofa')
        }
    },

    { type: 'blank' },
    { metric_id: "net_tax_interest_totals",style:'bold', display_name: "Total net Tax-Interest Expense / (Income)", type: 'calculation', calculation: (company) => { return calculateNetTaxInterestExpense(company) } },
    { type: 'blank' },

    { type: 'header', display_name: "Tax-EBITDA section" },
    { metric_id: "cte_condition_a", display_name: "Adjusted corporation tax earnings - Condition A (TIOPA 2010, s406(3))", mandatory: true, type: 'number', calculation_group: ['cte_subtotal', 'tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s406(3)(a) TIOPA 2010 the starting point for Tax-EBITDA is the amounts brought into account in determining total taxable profits.</p><p>This amount should be after any amounts claimed as Group Relief, although note that group relief amounts are separately added back below under s407(1)(g).</p></> },
    { metric_id: "cte_condition_b", display_name: "Adjusted corporation tax earnings - Condition B (TIOPA 2010, s406(4))", mandatory: true, type: 'number', calculation_group: ['cte_subtotal', 'tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>For loss-making companies, there will be no taxable total profits as a starting point for Condition A. However, under s406(4)(a) TIOPA 2010 losses arising in the period that are not set off against profits of the company for the period should be reflected here as Condition B amounts - i.e. loss making companies should effectively have negative total taxable profits as a starting point.</p><p>Losses carried forward, back, or surrendered as group relief are included in this amount, however capital losses are excluded under s407(4) TIOPA 2010.</p></> },
    { metric_id: "cte_restrictions_reactivations_included", display_name: "CIR (Restrictions) and reactivations included in the above", mandatory: true, type: 'number', calculation_group: ['cte_subtotal', 'tax_ebitda'], onesourcedredged: true, tooltipMessage: <p>To the extent that the starting position for any company includes any CIR adjustments already (i.e. if reactivations or restrictions have already been included), these need to be stripped out to give the correct starting point. Restrictions should be included as negative adjustments (these amounts have been disallowed, increasing tax-EBITDA, so need to be deducted), while reactivations should be added back (these amounts have given extra deductions, reducing tax EBITDA, and therefore need to be added back).</p> },
    { metric_id: "tebitda_adjustments", display_name: "Adjustments", mandatory: true, type: 'number', calculation_group: ['cte_subtotal', 'tax_ebitda'], tooltipMessage:<p>Adjustments to the amounts included for corporation tax earnings, if not already included above.</p> },
    { metric_id: "cte_subtotal",style:'bold', display_name: "Corporation tax earnings (excluding restrictions and reactivations)", type: 'calculation', calculation: (company) => { return sumCalculationGroup(company, 'cte_subtotal') } },

    { type: 'blank' },

    { type: 'subheader', display_name: "Adjustments - s407 TIOPA 2010" },
    { metric_id: "s407_tiopa_net_tax_interest_expense", display_name: "Net tax-interest expense/(income) - s407(1)(a)", editable: false, type: 'calculation', calculation: (company) => { return calculateNetTaxInterestExpense(company) }, calculation_group: ['tax_ebitda'], tooltipMessage: <><p>Under s407(1)(a) TIOPA 2010 net tax-interest expense or income should be excluded from tax-EBITDA.</p><p>This should reflect the total net tax-interest for the accounting period because the tax-EBITDA calculation at this stage is based on the tax-EBITDA for the accounting period as a whole, with apportionment adjustments taking place at a later stage. This number is automatically included based on the net tax interest expense/(income) figure calculated above.</p></> },
    { metric_id: "s407_tiopa_capital_allowance_charges", display_name: "Capital allowances and charges - s407(1)(b)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s407(1)(b) TIOPA 2010, all allowances or charges under the Capital Allowances Act 2001 are excluded from tax-EBITDA.</p><p>This should reflect all capital allowances and balancing charges including 'full expensing' and 'super deduction' amounts.</p></> },
    { metric_id: "s407_tiopa_relevant_intangible_debits_credits", display_name: "Relevant intangible debits and credits - s407(1)(c)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s407(1)(c) TIOPA 2010, all 'relevant' intangible fixed asset debits and credits, as specified in s408 TIOPA 2010 are exlcuded from tax-EBITDA.</p><p>Care is needed with the adjustments as it does not include all intangible fixed asset debits or credits. As a general rule, amortisation (including fixed rate amounts) debits, impairments and losses on disposal are excluded (added back as a positive adjustment) as are reversals of these amounts (deducted as a negative adjustment). Profits on disposal are only removed to the extent that they reverse previiously excluded debits. As such, any excess of realisation proceeds above original cost are generally included in tax-EBITDA.</p><p>Expenditure qualifying as R&D for tax purposes (e.g. s1308 deductions) is not included in the intangible fixed asset regime and should not be added back.</p></> },
    { metric_id: "s407_tiopa_adjustment_intangible_debit_credits", display_name: "Adjustment to intangible debits and credits brought in automatically (e.g. disposals above original cost)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], tooltipMessage: <><p>In general the automatic adjustment includes all debits and credits and therefore this adjustment allows for an adjustment to that amount.</p><p>Care is needed with the intangible fixed asset debits and credits included as it does not include all intangible fixed asset debits or credits. As a general rule, amortisation (including fixed rate amounts) debits, impairments and losses on disposal are excluded (added back as a positive adjustment) as are reversals of these amounts (deducted as a negative adjustment). Profits on disposal are only removed to the extent that they reverse previiously excluded debits. As such, any excess of realisation proceeds above original cost are generally included in tax-EBITDA.</p><p>Expenditure qualifying as R&D for tax purposes (e.g. s1308 deductions) is not included in the intangible fixed asset regime and should not be added back.</p></> },

    { metric_id: "s407_tiopa_other_period_losses", display_name: "Losses from other periods - s407(1)(d)(i) and (ii)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true,  tooltipMessage: <><p>Under s407(1)(d) TIOPA 2010 losses arising in other accounting periods (i.e. carried forward or back) and utilised in the period, other than capital losses, should be excluded from tax-EBITDA. This includes adjustments for losses of non-UK resident companies carrying on UK property businesses as introduced with retrospective effect under Finance (No. 2) Act 2023.</p><p>Under s407(1)(d) TIOPA 2010 losses arising in other accounting periods (i.e. carried forward or back) and utilised in the period, other than capital losses, should be excluded from tax-EBITDA. This includes adjustments for losses of non-UK resident companies carrying on UK property businesses as introduced with retrospective effect under Finance (No. 2) Act 2023.</p></> },
    { metric_id: "s407_tiopa_loan_relationship_deficts_other_periods", display_name: "Loan relationship deficits from other periods - s407(1)(e)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s407(1)(e) TIOPA 2010, loan relationship deficits arising in other accounting periods and utilised in the current period should be excluded from tax-EBITDA.</p><p>This should reflect a positive adjustment for loan relationship deficits from other accounting periods utilised in the current period.</p></> },
    { metric_id: "s407_tiopa_expenses_management_other_periods", display_name: "Expenses of management from other periods - s407(1)(f)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s407(1)(f) TIOPA 2010, expenses of management referable to other periods and utilised in the current period should be excluded from tax-EBITDA.</p><p>This should reflect a positive adjustment for management expenses referable to other accounting periods utilised in the current period.</p></> },
    { metric_id: "s407_tiopa_group_relief", display_name: "Group relief - s407(1)(g)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s407(1)(g) TIOPA 2010, deductions for group relief, including group relief for carried forward losses, should be excluded from tax-EBITDA if and to the extent it represents a loss of the worldwide group. This should reflect a positive adjustment.</p><p>Group relief surrendered is already included in Condition B and is not separately adjusted. As such, following this adjustment the loss should be included only once in the worldwide group.</p><p>Per HMRC Guidance at CFM95723 the loss will only be a loss of the claimant’s group where surrendering and claimant companies are members of the same CIR group, both for the period in which the loss arises, and the period to which the claim relates.</p></> },

    { metric_id: "s407_relief_rd_expenditure_credit", display_name: "R&D expenditure credit - s407(3)(a)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s407(1)(h) and s407(3)(a) TIOPA 2010 R&D expenditure credits under s104A CTA 2009 should be excluded from tax-EBITDA.</p><p>This exclusion is based on the taxable RDEC credit and therefore may be different to the adjustment included in Statement A of the computation if some RDEC credits are included in the income statement.</p><p>The adjustment should be a negative adjustment as a reduction to tax-EBITDA.</p></> },
    { metric_id: "s407_relief_rd_additional_relief", display_name: "R&D additional relief - s407(3)(b)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s407(1)(h) and s407(3)(b) TIOPA 2010, any deductions given as additional relief under s1044, 1063, 1068 or 1087 of CTA 2009 should be excluded from tax-EBITDA.  Only the additional relief is added back.</p><p>The adjustment should be a positive adjustment as an increase to tax-EBITDA.</p></> },
    { metric_id: "s407_relief_trading_loss", display_name: "Deemed trading loss for pre-trading expenditure - s407(3)(c)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s407(1)(h) and s407(3)(c) TIOPA 2010, any deductions treated as a trading loss as additional Vaccine Relief deductions that may not have been brought into account for pre-trading activities at the time incurred are ecluded from tax-EBITDA. The provisions were repealed in 2011 and therefore are unlikely to still be relevant for more recent periods.</p><p>The adjustment should be a positive adjustment as an increase to tax-EBITDA.</p></> },
    { metric_id: "s407_relief_contaminated_land_relief", display_name: "Contaminated land relief - s407(3)(d)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s407(1)(h) and s407(3)(d) TIOPA 2010, any deductions for contaminated land relief under s1147 or 1149 CTA 2009 should be excluded from tax-EBITDA. This includes both the deduction for capital land remediation expenditure under s1147 CTA 2009 and the uplift under s1149 CTA 2009.</p><p>The adjustment should be a positive adjustment as an increase to tax-EBITDA.</p></> },
    { metric_id: "s407_relief_creative_industries", display_name: "Creative industries - s407(3)(e)-(j)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s407(1)(h) and s407(3)(e)-(j) TIOPA 2010, any deducions under s1199 CTA 2009 (film tax relief); s1216CF CTA 2009 (television tax relief); s1217CF CTA 2009 (video games tax relief); S1217H CTA 2009 (relief for theatrical productions); s1217RD CTA 2009 (orchestra tax relief); and s1218ZCE CTA 2009 (museums and galleries tax relief) should be excluded from tax-EBITDA.</p><p>The adjustment should be a positive adjustment as an increase to tax-EBITDA.</p></>},
    { metric_id: "s407_relief_charitable_donation", display_name: "Qualifying charitable donation (from current or earlier period) - s407(3)(k)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s407(1)(h) and s407(3)(k) TIOPA 2010, deductions for charitable donations (whether made in the current accounting period or an earlier period) are excluded from tax-EBITDA.</p><p>The adjustment should be a positive adjustment as an increase to tax-EBITDA.</p></> },
    { metric_id: "s407_relief_patent_box_profits", display_name: "Profits from patent box chargeable at a lower rate - s407(3)(l)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s407(1)(h) and s407(3)(l) TIOPA 2010, the Patent Box deduction under s357A CTA 2010 is excluded from tax-EBITDA.</p><p>The adjustment should be a positive adjustment as an increase to tax-EBITDA</p></> },

    { metric_id: "s407_relief_dtr_adjustment_tax_rate_equal_uk", display_name: "DTR adjustment - s409", mandatory: true, type: 'calculation', calculation_group: ['tax_ebitda'], editable: false, calculation: (company) => {
        company.net_dtr_adjustment_lc = convertGbpToLc(company.fx_rate, company.net_dtr_adjustment_gbp)
        return calculateDTRAdjustment(company);
    }, tooltipMessage: <><p>Under s409 TIOPA 2010 an adjustment must be made to remove the amount of "notional untaxed income" arising where double tax relief is claimed (i.e the tax charge in respect of tax-interest income amounts is reduced under s18(2) TIOPA 2010).</p><p>The adjustment is a negative adjustment decreasing tax-EBITDA by the amount of the double tax relief claimed divided by the corporation tax rate for the period.</p><p>Where amounts are extracted from ONESOURCE, this row makes an automatic adjustment based on the effective headline tax rate for the period. If the corporation tax rate of the company is different from this rate it will be necessary to either adjust the rate in the reconciliation at the bottom or make a separate adjustment in one of the "Other adjustment" rows.</p><p>If expense relief is claimed, the deduction for expense relief should reduce the tax-EBITDA in line with the application of s112 TIOPA 2010, but no s409 adjustment is required. Where double tax relief has been claimed on a tax-interest income amount a second adjustment needs to be made in the tax-Interest section.</p></>},    
    { metric_id: "s407_relief_dtr_adjustment_tax_rate_not_equal_uk", display_name: "DTR adjustment - s409 (where effective tax rate <> UK tax rate)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], tooltipMessage: <><p>Under s409 TIOPA 2010 an adjustment must be made to remove the amount of "notional untaxed income" arising where double tax relief is claimed (i.e the tax charge in respect of tax-interest income amounts is reduced under s18(2) TIOPA 2010).</p><p>The adjustment is a negative adjustment decreasing tax-EBITDA by the amount of the double tax relief claimed divided by the corporation tax rate for the period.</p><p>Where amounts are extracted from ONESOURCE, this row makes an automatic adjustment based on the effective headline tax rate for the period. If the corporation tax rate of the company is different from this rate it will be necessary to either adjust the rate in the reconciliation at the bottom or make a separate adjustment in one of the "Other adjustment" rows.</p><p>If expense relief is claimed, the deduction for expense relief should reduce the tax-EBITDA in line with the application of s112 TIOPA 2010, but no s409 adjustment is required. Where double tax relief has been claimed on a tax-interest income amount a second adjustment needs to be made in the tax-Interest section.</p></> },
    { metric_id: "s407_relief_adjustment_qualifying_infrastructure", display_name: displayNames?.dn7, mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], tooltipMessage: <><p>Under s441 TIOPA 2010, where a company is a QIC, the tax-EBITDA of the company for the accounting period is nil.</p><p>This adjustment should be such that the overall tax-EBITDA for the company for the accounting period is nil. However, if the company ceases to be a QIC under a joint QIC election in which one of the members has failed the conditions, the exemption will no longer apply for the part of the company's accounting period for which the joint election has effect and for which the failing company has failed the condition.</p><p>A separate calculation should be prepared and retained on file to support this adjustment.</p></> },
    { metric_id: "s407_relief_adjustment_gaap_items", display_name: displayNames?.dn10, mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], tooltipMessage: <><p>Under Paragraphs 29 and 30, Schedule 5, Finance (No2) Act 2017 amounts brought into account under the Change of Accounting Practice Regulations and due to changes to the loan relationship and derivative contract rules in Finance (No2) Act 2015 are ignored in the tax-interest and tax-EBITDA calculation if the first period affected by the change in accounting began before 1 April 2017.</p><p>The adjustment requires expense amounts to be removed as positive adjustments increasing tax-EBITDA and income amounts to be removed as negative adjustments, reducing tax-EBITDA. Where adjustments are made in respect of tax-Interest amounts, a second adjustment needs to be made in the tax-Interest section.</p></> },
    { metric_id: "s407_relief_finance_lease_depreciation", display_name: "Finance lease depreciation - s460(1)(d)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s460(1)(d) TIOPA 2010 any depreciation in respect of an asset leased to the company under a finance lease that is not a long funding lease (commonly referred to as an SP3/91 deduction) is excluded from the Tax EBITDA calculation.</p><p>The definition of a finance lease under s494 TIOPA 2010 is limited to finance leases where the operating/finance lease distinction is maintained (e.g. under FRS 102) and right-of-use leases (e.g. under FRS 101/ IFRS post application of IFRS 16) if the lease in question would be an operating lease under the old accounting treatment pre IFRS16.</p><p>The adjustment is a positive adjustment increasing tax-EBITDA. Where the adjustment is included automatically from ONESOURCE, it is assumed all leases are finance leases. Any adjustment automatically included in respect of leases that are not finance leases can be reversed below.</p></> },
    { metric_id: "s407_relief_reversal_of_lease_depreciation", display_name: "Reversal of right-of-use lease depreciation in respect of operating leases", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], tooltipMessage: <><p>Under s460(1)(d) TIOPA 2010 any depreciation in respect of an asset leased to the company under a finance lease that is not a long funding lease (commonly referred to as an SP3/91 deduction) is added back in the Tax EBITDA calculation.</p><p>This adjustment allows for the reversal of amounts extracted automatically from ONESOURCE in respect of leases that are not finance leases, or for the inclusion of amounts in respect of finance leases that were not automatically included. See the "Finance lease depreciation - s460(1)(d)" adjustment for further information.</p></> },
    { metric_id: "s407_relief_long_funding_lease_deduction", display_name: "Long funding lease deduction - s460(1)(a)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s460(1)(a) TIOPA 2010, deductions under s363 CTA 2010 for long funding operating leases should be excluded from the calculation of Tax EBITDA.</p><p>The adjustment should be a positive adjustment as an increase to tax-EBITDA.</p></> },
    { metric_id: "s407_relief_finance_lease_rentals_receivable", display_name: "Capital element of finance lease rentals receivable - s460(1)(c)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s460(1)(c) TIOPA 2010, where the company is a lessor, the capital component of the rental earnings under a finance lease which is not a long funding lease is excluded from the calculation of Tax-EBITDA.</p><p>The adjustment should be a negative adjustment as a reduction of tax-EBITDA</p></> },
    { metric_id: "s407_relief_long_funding_lessee", display_name: "Long funding lessee - amount by which a deduction is reduced under section 379 of CTA 2010 - s460(1)(b)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], onesourcedredged: true, tooltipMessage: <><p>Under s460(1)(b) TIOPA 2010, where a deduction is reduced under s379 CTA 2010, which applies to lessees under a long funding operating lease, this is excluded from the calculation of Tax EBITDA.</p><p>As s379 reduces a deduction, the adjustment should be a positive adjustment as an increase to Tax-EBITDA.</p></> },
    { metric_id: "s407_relief_adjustment_misc_losses", display_name: "Adjustment for miscellaneous losses not included in Condition B above", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], tooltipMessage:<p>The ONESOURCE adjustments do not include an add back for miscellaneous losses. If these losses (or any other losses utilised) have not been adjusted for above, make adjustments here - losses should be added back as positive adjustments, increasing Tax EBITDA.</p> },
    { metric_id: "s407_relief_group_relief", display_name: "Group relief included above not covered by s407(1)(g)", mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], tooltipMessage: <><p>In general the automatic adjustment includes all group relief deductions and therefore this adjustment allows for an adjustment to that amount to exclude amounts that do not represent losses of the worldwide group.</p><p>Per HMRC Guidance at CFM95723 the loss will only be a loss of the claimant’s group where surrendering and claimant companies are members of the same CIR group, both for the period in which the loss arises, and the period to which the claim relates.</p><p>This adjustment should reflect a negative adjustment to reverse a positive adjustment already made for group relief that did not represent a loss of the worldwide group.</p></> },
    { metric_id: "s407_relief_adjustment_disregarded_regulations", display_name: displayNames?.dn15, mandatory: true, type: 'number', calculation_group: ['tax_ebitda'], tooltipMessage: <><p>Where an election under paragraph 31, Schedule 5, Finance (No2) Act 2017 has been made, the company is treated as having made an election for Disregard Regulations 7, 8, and 9 to apply for derivative contracts entered into by the company before 1 April 2020 for the purposes of calculating Tax-Interest Expense/Income and Tax-EBITDA of the company or a relevant transferee company.</p><p>Under this adjustment any increase in tax-EBITDA should be entered as a positive adjustment and any decrease in tax-EBITDA should be entered as a negative adjustment. Where adjustments are made in respect of tax-Interest amounts, a second adjustment needs to be made in the tax-Interest section.</p></> },
    { metric_id: "tax_ebitda", display_name: "Tax-EBITDA - Subtotal", type: 'calculation', style:'bold', calculation: (company) => {
        
        return calculateTaxEBITDA(company) } },
    
    { type: 'blank' },
    
    // Double tax relief adjustment
    { type: 'header', display_name: "DTR adjustment s409 section" },
    { metric_id: "net_dtr_adjustment_gbp", display_name: "Credit for foreign taxes under s18(2) TIOPA 2010 in GBP", mandatory: true, type: 'number', onesourcedredged: true, tooltipMessage: <><p>This row should reflect the amount, in GBP, of any credit relief for foreign taxes which is reflected in the corporation tax computation.</p></>},
    { metric_id: "net_dtr_adjustment_lc", display_name: "Credit for foreign taxes under s18(2) TIOPA 2010 in Local Currency", mandatory: true, type: 'calculation', calculation: (company) => {
        company.net_dtr_adjustment_lc = convertGbpToLc(company.fx_rate, company.net_dtr_adjustment_gbp)
        return company.net_dtr_adjustment_lc;
    }, tooltipMessage: <><p>This row should reflect the amount, in the local currency, of any credit relief for foreign taxes which is reflected in the corporation tax computation.</p></> },
    { metric_id: "corporation_tax_rate_percentage", display_name: "Corporation Tax Rate", mandatory: true, type: 'calculation', calculation: (company) => {
        return covertToPercentage(company.corporation_tax_rate);
    }, tooltipMessage: <><p>This row should reflect the standard corporation tax rate applicable for the accounting period, as reflected in the corporation tax computation.</p></> },
    { metric_id: "ct_rate_override", display_name: "CT Rate override", mandatory: true, type: 'number', tooltipMessage: <><p>Where the company is subject to a different rate of corporation tax for the accounting period (e.g. due to the application of the banking surcharge or similar), the effective corporation tax rate applicable to the company for the accounting period should be entered here.</p></> },
    { metric_id: "s407_relief_dtr_adjustment_tax_rate_uk", display_name: "DTR adjustment - s409", mandatory: true, type: 'calculation', style:'bold', editable: false, calculation: (company) => {
        return calculateDTRAdjustment(company);
    }, tooltipMessage: <><p>This row reflects the total DTR adjustment which should be made as a result of the DTR claimed in the period (calculated as the amount of the credit relief claimed divided by the corporation tax rate for the period) - this figure is automatically reflected in the adjustments row above.</p></> },

    { type: 'blank' },

    { type: 'subheader', display_name: "CIR specific attributes" },
    { metric_id: "non_consenting_company", display_name: "Non-consenting company?", mandatory: true, type: 'boolean', calculation_group: ['cir_attributes'], tooltipMessage: <><p>To the extent that a company is a non-consenting company (i.e. it has not consented to the appointment of the reporting company for the Group), this should be identified here. Where a company is non-consenting, it must be reported as such in the CIR return, and it is only possible to allocate a maximum of the pro-rata share of any disallowance for the period to the company.</p></>},
    { metric_id: "trade_small_or_negligible", display_name: "Trade ceased and/or small or negligible?", mandatory: true, type: 'boolean', calculation_group: ['cir_attributes'], tooltipMessage: <><p>Under 378(3), where a company is allocated a disallowance under the CIR rules in respect of an amount that would otherwise be brought into account in calculating the profits or losses of a trade carried on by that company, and in a subsequent accounting period, the company either ceases to carry on that trade, or the scale of the trade becomes small or negligible, the allocated disallowance cannot be carried forward for potential reactivation to any accounting period following the period in which the trade ceases or becomes small or negligible.</p><p>To the extent that this is the case, this should be identified here, and any disallowed amount of the company which could otherwise be carried forward should be adjusted accordingly on the subsequent pages.</p></> },
    { metric_id: "trade_uncommercial_or_non_statutory", display_name: "Trade uncommercial or non-statutory?", mandatory: true, type: 'boolean', calculation_group: ['cir_attributes'], tooltipMessage: <><p>Under 378(4), where a company is allocated a disallowance under the CIR rules in respect of an amount that would otherwise be brought into account in calculating the profits or losses of a trade carried on by that company, and in a subsequent accounting period, the trade becomes uncommercial and non-statutory, the allocated disallowance cannot be carried forward for potential reactivation to the period in which the trade became uncommercial or non-statutory or any accounting period following that period.</p><p>For these purposes, a trade is uncommercial and non-statutory if, were the company to make a loss, relief for the loss would be denied by s44 CTA 2010.</p><p>To the extent that this is the case, this should be identified here, and any disallowed amount of the company which could otherwise be carried forward should be adjusted accordingly on the subsequent pages.</p></> },
    { metric_id: "investment_business_ceased", display_name: "Investment business ceased?", mandatory: true, type: 'boolean', calculation_group: ['cir_attributes'], tooltipMessage: <><p>Under 378(6), where a company is allocated a disallowance under the CIR rules in respect of an amount that would otherwise be brought into account in calculating the profits or losses of an investment business carried on by that company, and in a subsequent accounting period, the company ceases to carry on the investment business or the scale of the investment business becomes small or negligible, the allocated disallowance cannot be carried forward for potential reactivation to any accounting period following the period in which the investment business ceases or becomes small or negligible.</p><p>To the extent that this is the case, this should be identified here, and any disallowed amount of the company which could otherwise be carried forward should be adjusted accordingly on the subsequent pages.</p></> },
    { metric_id: "cta10_change_in_ownership_rules", display_name: "CTA10 Change in Company Ownership rules apply?", mandatory: true, type: 'boolean', calculation_group: ['cir_attributes'], tooltipMessage: <><p>Care must be taken in considering the carry forward of attributes for CIR purposes, where the change in ownership rules in CTA 2010 apply.</p><p>Where a CIR group has either unused interest allowance or excess debt cap carried forward, these are attributes of the CIR group, which are lost on a change in ownership which results in the CIR group ceasing to exist.</p><p>There may also be situations where disallowed amounts which have been allocated to individual companies, and are company attributes, may also be lost as a result of the application of the change in ownership rules. This may particularly be the case where a company with a disallowed amount carried forward is acquired alongside other group companies, and either that company or any other co-acquired company has a major change in business.</p><p>The potential restrictions which may arise as a consequence of a change in ownership are complex and should be carefully considered.</p><p>To the extent that the change in ownership rules apply, this should be identified here, and any disallowed amount of the company which could otherwise be carried forward should be adjusted accordingly on the subsequent pages.</p></> },

    { type: 'blank' },

    { type: 'subheader', display_name: "Company Type" },
    { metric_id: "type_reit_company", display_name: "REIT", mandatory: true, type: 'boolean', calculation_group: ['company_type'], tooltipMessage: <><p>Special rules apply where a property rental business company has profits or gains which are not subject to corporation tax or chargeable gains tax under s534 or s535/s535A CTA 2010.</p><p>Where the company carries on residual business, which is treated under s541 CTA 2010 as a separate company to the property rental business company, for CIR purposes the residual business company is treated as carrying on a residual business within the charge to corporation tax, the results of which are subject to the application of the CIR rules.</p><p>S452 TIOPA 2010 contains detailed provisions setting out the application of the CIR rules in such circumstances.</p><p>Where these rules are relevant, this should be identified here and separate calculations should be undertaken to make the necessary adjustments to the inputs to the CIR calculations.</p></> },
    { metric_id: "type_ring_fence_company", display_name: "Oil and gas ring fence company", mandatory: true, type: 'boolean', calculation_group: ['company_type'], tooltipMessage: <><p>Where a company is within the Oil and Gas ring fence, this should be identified here. Amounts which are taken into account in calculating the ring fence income or a company’s aggregate gain or loss under s197(3) TCGA 1992 are to be ignored for CIR purposes, and just and reasonable adjustments are to be made to the financial statements of the worldwide group.</p><p>These adjustments should be computed separately and reflected in the inputs to the CIR calculations.</p></> },
    { metric_id: "type_investment_manager", display_name: "Investment manager", mandatory: true, type: 'boolean', calculation_group: ['company_type'], tooltipMessage: <><p>Where a member or members of a group holds rights or interests in an entity (“S”), manage S in the ordinary course of carrying on a business of providing investment management services, and the management of S is not coordinated to any extent with the management of any other entity, S and its subsidiaries do not form part of the group for CIR purposes, and S and its subsidiaries are not regarded as consolidated subsidiaries of any member of the group.</p><p>Where these rules are relevant, this should be identified here and separate calculations should be undertaken to make the necessary adjustments to the inputs to the CIR calculations.</p><p>It should be noted that where s454A applies, S and its subsidiaries are likely to form a separate Worldwide Group for CIR purposes, requiring a separate CIR calculation to be prepared for the S group.</p></> },
    { metric_id: "type_insurance_company", display_name: "Insurance company", mandatory: true, type: 'boolean', calculation_group: ['company_type'], tooltipMessage: <><p>Where the Worldwide Group includes an insurance company, which itself has a subsidiary (“S”) which it holds as a portfolio investment, the Worldwide Group does not include S or any of its subsidiaries. </p><p>Where these rules are relevant, this should be identified here and separate calculations should be undertaken to make the necessary adjustments to the inputs to the CIR calculations.</p><p>It should be noted that where s453 applies, S and its subsidiaries are likely to form a separate Worldwide Group for CIR purposes, requiring a separate CIR calculation to be prepared for the S group.</p></> },
    { metric_id: "type_shipping_company", display_name: "Shipping company", mandatory: true, type: 'boolean', calculation_group: ['company_type'], tooltipMessage: <><p>Where a company is within the Tonnage Tax regime, its tonnage tax profits are treated as nil for the purpose of computing the company’s adjusted corporation tax earnings for CIR purposes.</p><p>Where this is relevant, this should be identified here and separate calculations should be undertaken to make the necessary adjustments to the inputs to the CIR calculations.</p></> },
    { metric_id: "type_cooperative_company", display_name: "Coopoerative/community benefit society etc", mandatory: true, type: 'boolean', calculation_group: ['company_type'], tooltipMessage: <><p>Where an amount is treated as interest under a loan relationship as a result of s499 CTA 2009 in respect of amounts payable by co-operative and community benefit societies or UK Agricultural or fishing co-operatives, that amount is not treated as an amount of tax interest income or expense for CIR purposes.</p><p>Where this is relevant, this should be identified here and separate calculations should be undertaken to make the necessary adjustments to the inputs to the CIR calculations.</p></> },
    { metric_id: "type_charity_company", display_name: "Charity", mandatory: true, type: 'boolean', calculation_group: ['company_type'], tooltipMessage: <><p>Where an amount would be treated as a tax interest expense amount, the creditor is a charity, the company is a wholly owned subsidiary of the charity, and the charitable gift condition (if the company were to make a donation to the charity, it would be a qualifying charitable donation) is met at all times during the accounting period in which the amount is brought into account, the amount will not be treated as a tax interest expense amount.</p><p>Where this is relevant, this should be identified here and separate calculations should be undertaken to make the necessary adjustments to the inputs to the CIR calculations.</p></> },
    { metric_id: "type_banking_company", display_name: "Banking company", mandatory: true, type: 'boolean', calculation_group: ['company_type'], tooltipMessage: <><p>Where a banking company carries on a trade consisting of or including dealing in financial instruments, debits or credits arising directly from dealing in financial instruments (other than impairment losses or the reversal of impairment losses) will be treated as tax interest expense or income amounts for the purposes of the CIR calculations.</p><p>Where this is relevant, this should be identified here and separate calculations should be undertaken to make the necessary adjustments to the inputs to the CIR calculations.</p></> },

    { type: 'blank' },

    { type: 'subheader', display_name: "Elections:" },
    { metric_id: "elections_s456_s457", display_name: "S456 & 457 Election for amortised cost basis for fair valued creditor relationships", mandatory: true, type: 'boolean', calculation_group: ['elections'], tooltipMessage: <><p>S456/457 - where an election has been made, loan assets of the company which are subject to fair value accounting are instead to be treated as being subject to an amortised cost basis of accounting for the purposes of determining the debits and credits relevant to the computation of tax interest expense/income. Where there is a hedging relationship, it is to be assumed to have been designated as a fair value hedge.</p><p>Where this election has been made, adjustments should be included at the relevant points in the computation of tax interest and tax EBITDA in the rows above.</p><p>An election under s456 must be made within 12 months of the end of the relevant accounting period, applies to that and all subsequent accounting periods of the company, and is irrevocable.</p></> },
    { metric_id: "elections_amortised_cost_basis", display_name: "Election for amortised cost basis for fair valued creditor relationships - Date of election", mandatory: true, type: 'date', calculation_group: ['elections'], tooltipMessage: <><p>Where an amortised cost basis election has been made, enter the date of the election here.</p></> },
    { metric_id: "election_s433_s434", display_name: "S433 & 434 Qualifying infrastructure election", mandatory: true, type: 'boolean', calculation_group: ['elections'], tooltipMessage: <><p>s432 - 450. Where a company is a QIC, broadly it has no tax interest income, and no tax EBITDA. Many, but not all, tax interest expense amounts are also excluded. The application of the exemption can, however, be complex, and may require a number of adjustments to the amounts included as tax interest expense (e.g. for amounts arising between the QIC and other members of the Group or related parties). It is necessary to carefully consider the election in detail, calculate the required adjustments separately (and keep a record of that calculation).Where this election has been made, adjustments should be included at the relevant points in the computation of tax interest and tax EBITDA in the rows above.</p></> },
    { metric_id: "qualifying_infrastructure_election_date", display_name: "Qualifying Infrastructure Election - Date of election  company", mandatory: true, type: 'date', calculation_group: ['elections'], tooltipMessage: <><p>Where a QIC election has been made, enter the date of the election here.</p></> },
    { metric_id: "qualifying_infrastructure_first_ap_start", display_name: "Qualifying Infrastructure Election - First relevant AP start", mandatory: true, type: 'date', calculation_group: ['elections'], tooltipMessage: <><p>Where a QIC election has been made, enter the start date of the first relevant accounting period here.</p></> },
]

const sumCompanyMetrics = (company, metrics) => {
    let total = 0;
    for (const metricId of metrics) {
        const value = company[metricId];
        if (!value) {
            continue;
        }

        total += Number(value);
    }

    return total;
}

const sumCalculationGroup = (company, calculationGroup) => {
    const group_metrics = metrics?.filter(metric => {
        const group = metric?.calculation_group;
        if (Array.isArray(group)) {
            return group?.includes(calculationGroup);
        } else {
            return group === calculationGroup;
        }
    });

    if (group_metrics?.length === 0) {
        return 0;
    }

    let total = 0;
    for (const metric of group_metrics) {
        let value;
        if (metric?.type === 'calculation') {
            value = metric?.calculation(company)
        } else {
            if (typeof (company[metric?.metric_id]) === 'object') {
                value = company[metric?.metric_id]?.value
            } else {
                value = company[metric?.metric_id] ? company[metric?.metric_id] : 0
            }
        }
        if (!value) {
            continue;
        }
        total += Number(value);
    }
    return total;
}

const calculateNetTaxInterestExpense = (company) => {
    return sumCalculationGroup(company, 'ntlr') + sumCalculationGroup(company, 'ntdc') + sumCalculationGroup(company, 'tlr') + sumCalculationGroup(company, 'tdc') + sumCalculationGroup(company, 'ofa')
}

const calculateTaxEBITDA = (company) => {
    return sumCalculationGroup(company, 'tax_ebitda')
}

const bfd_cfd_values = [
    { bfd: 'bfd_non_trading_loan_relationships', cfd: 'cfd_non_trading_loan_relationships' },
    { bfd: 'bfd_non_trading_derivatives', cfd: 'cfd_non_trading_derivatives' },
    { bfd: 'bfd_trading_loan_relationships', cfd: 'cfd_trading_loan_relationships' },
    { bfd: 'bfd_trading_derivatives', cfd: 'cfd_trading_derivatives' },
    { bfd: 'bfd_other_financing', cfd: 'cfd_other_financing' },
    { bfd: 'bfd_subtotal', cfd: 'cfd_subtotal' }
]

// copies bfd value in prior_ap into cfd value in current_ap
const convertBFDCFD = (prior_ap, current_ap) => {
    bfd_cfd_values?.forEach(field => {
        if (prior_ap?.hasOwnProperty(field?.cfd)) {
            current_ap[field?.bfd] = prior_ap[field?.cfd]
        }
    })
    return current_ap;
}

const getBFDValues = (savedData, poa, ap) => {
    // search for prior AP in the same POA
    if (poa?.hasOwnProperty('adjusted_caps') && poa?.adjusted_caps?.length > 0) {
        const prior_ap = poa?.adjusted_caps?.find((cap) => cap?.company_ID === ap?.company_ID && processDateJS(cap?.end_date)?.isSame(processDateJS(ap?.start_date)?.subtract(1, 'day')))
        if (prior_ap) {
            // return bfd amounts in current period = cfd amounts in prior period
            return convertBFDCFD(prior_ap, ap);
        }
    }
    // find prior poa and search here
    const prior_poa = savedData?.periods_of_account?.find((prior_poa) => processDateJS(prior_poa?.period_end)?.isSame(processDateJS(poa?.period_start)?.subtract(1, 'day')))
    if (prior_poa && prior_poa?.hasOwnProperty('adjusted_caps') && prior_poa?.adjusted_caps?.length > 0) {
        const prior_poa_ap = prior_poa?.adjusted_caps?.find((cap) => cap?.company_ID === ap?.company_ID && processDateJS(cap?.end_date)?.isSame(processDateJS(ap?.start_date)?.subtract(1, 'day')))
        if (prior_poa_ap) {
            // return bfd amounts in current period = cfd amounts in prior period
            return convertBFDCFD(prior_poa_ap, ap);
        }
    }
    return ap;
}

export { metrics, calculateNetTaxInterestExpense, calculateTaxEBITDA, sumCalculationGroup, getBFDValues }