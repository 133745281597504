import { processDateJS } from "./dateProcessor";

export function generateCAPId(cap) {
    if (!cap) {
        return;
    }
    return String(String(cap.company_ID) + '-' + String(processDateJS(cap.start_date)) + '-' + String(processDateJS(cap.end_date)));
}

export function generateCAPIdByCompName(cap) {
    if(!cap) {
        return;
    }
    return String(String(cap.company_name) + '-' + String(processDateJS(cap.start_date)) + '-' + String(processDateJS(cap.end_date)));
}
