const getReviewCalc3Values = (poa, savedData) => {
    let group_ANTIE = 0;
    const company_totals = savedData?.companies?.map(company => {
        // aggregate fields (sum) to company level
        let NTLR_Subtotal = 0;
        let NTDC_Subtotal = 0;
        let TLR_Subtotal = 0;
        let TDC_Subtotal = 0;
        let OFA_Subtotal = 0;
        let cap_ANTIE = 0;
        let NC_Company;
        const caps = poa?.adjusted_caps?.filter((cap) => cap?.company_ID === company?.company_ID);
        console.log('company (caps)', caps)
        caps?.forEach((cap) => {
            const ntlr = cap?.ntlr_subtotal_gbp?.value
            const ntdc = cap?.ntdc_subtotal_gbp?.value
            const tlr = cap?.tlr_subtotal_gbp?.value
            const tdc = cap?.tdc_subtotal_gbp?.value
            const ofa = cap?.ofa_subtotal_gbp?.value
            console.log({
                ntlr, 
                ntdc,
                tlr,
                tdc,
                ofa
            }) 
            const cap_ntie = cap?.net_tax_interest_expense_gbp//(ntlr + ntdc + tlr + tdc + ofa)
            cap_ANTIE += cap_ntie > 0 ? cap_ntie : 0;
            NTLR_Subtotal += ntlr;
            NTDC_Subtotal += ntdc;
            TLR_Subtotal += tlr;
            TDC_Subtotal += tdc;
            OFA_Subtotal += ofa;
            NC_Company = cap?.non_consenting_company;
        })

        // save totals for each company
        // company allocation
        const company_NTIEI = NTLR_Subtotal + NTDC_Subtotal + TLR_Subtotal + TDC_Subtotal + OFA_Subtotal;
        const company_ANTIE = Math?.max(company_NTIEI, 0);
        const company_ANTII = company_NTIEI < 0 ? -company_NTIEI : 0;
        console.log('company_ANTIE', company_ANTIE)
        // aggregate company antie to group level
        group_ANTIE += company_ANTIE;

        return {
            company_ID: company?.company_ID,
            antie: company_ANTIE,
            antii: company_ANTII,
            ntiei: company_NTIEI,
            cap_antie: cap_ANTIE,
            non_consenting_company: NC_Company
        }
    });
    //company max disallowance = company antie * group disallowance / group antie 
    //then split between APs () ap expense / sum(ap expense for every ap in this company but only when there is an expense (not income))

    let updatedCaps = [];
    // max disallowance allocation
    company_totals?.forEach((company) => {        
        // splitting this up to handle NaN due to dividing by 0
        const firstCalc = poa?.group_disallowance * company?.antie / group_ANTIE; 
        let disallowanceCalcValue = 0; 
        if(firstCalc > 0){ disallowanceCalcValue = firstCalc}
      
        const company_Disallowance = Math.max(Math.min(disallowanceCalcValue, poa?.group_disallowance), 0)
        console.log('company_Disallowance', company_Disallowance)
        // loop through each ap in the company
        const caps = poa?.adjusted_caps?.filter((cap) => cap?.company_ID === company?.company_ID);
        updatedCaps?.push(...caps?.map((cap) => {
            console.log('cap', cap)
            // max ap disallowance allocation
            // const NTIE = Math.max(cap.net_tax_interest_totals, 0);
            const NTIE = Math.max(cap?.net_tax_interest_expense_gbp, 0);

            // splitting this up to handle NaN due to dividing by 0
            console.log('cap ntie', NTIE)
            console.log('cap_antie', company?.cap_antie)
            console.log('company_Disallowance', company_Disallowance)
            const capsCalc = company_Disallowance * NTIE / company?.cap_antie;
            let capsDisallowanceValue = 0;
            if (capsCalc > 0){capsDisallowanceValue = capsCalc}
            cap.disallowance_automatic_allocation_gbp = Math.max(Math.min(capsDisallowanceValue, poa?.group_disallowance), 0)
            cap.non_consenting_max_disallowance_gbp = cap?.disallowance_automatic_allocation_gbp
            cap.consenting_max_disallowance_gbp = Math.max(Math.min(company?.antie, NTIE, poa?.group_disallowance), 0)
            // if the cap doesn't have a disallowance with automatic_allocation = true (eg. either it doesn't exist or is false)
            if (!(cap?.hasOwnProperty('disallowance_gbp') && cap?.disallowance_gbp?.hasOwnProperty('automatic_allocation') && cap?.disallowance_gbp?.automatic_allocation === false)) {
                cap.disallowance_gbp = {automatic_allocation: true, value: Math.round(cap?.disallowance_automatic_allocation_gbp)}
                console.log('setting disallowance')
            }
            console.log('company.non_consenting_company ',  company?.non_consenting_company)
            console.log('capsDisallowanceValue', capsDisallowanceValue)
            console.log('NTIE', NTIE)
            console.log('cap.non_consenting_max_disallowance_gbp', cap?.non_consenting_max_disallowance_gbp)
            console.log('cap.consenting_max_disallowance_gbp', cap?.consenting_max_disallowance_gbp)
            // disallowance section allocation
            
            if ( ! cap?.apply_s377_3_election) {
                cap = calculate_disallowance_subtotals(cap, NTIE, cap?.disallowance_gbp?.value);
            }
            cap.company_antie_gbp = company?.antie;
            cap.company_antii_gbp = company?.antii;
            cap.company_net_tax_interest_expense_income_gbp = company?.ntiei

            return cap;
        }))
    })

    console.log('::65 updated_caps', updatedCaps)
    // update cap values in main json
    poa.adjusted_caps = updatedCaps;
    return poa;
}

const calculate_disallowance_subtotals = (cap, NTIE, disallowance) => {
    let available_disallowance = disallowance;
    cap.ntlr_disallowance_gbp = NTIE > 0 && cap?.ntlr_subtotal_gbp?.value > 0 ? Math.round(Math.min(available_disallowance, cap?.ntlr_subtotal_gbp?.value)) : 0;
    available_disallowance -= cap?.ntlr_disallowance_gbp;
    cap.ntdc_disallowance_gbp = NTIE > 0 && cap?.ntdc_subtotal_gbp?.value > 0 ? Math.round(Math.min(available_disallowance, cap?.ntdc_subtotal_gbp?.value)) : 0;
    available_disallowance -= cap?.ntdc_disallowance_gbp; 
    cap.tlr_disallowance_gbp = NTIE > 0 && cap.tlr_subtotal_gbp?.value > 0 ? Math?.round(Math.min(available_disallowance, cap?.tlr_subtotal_gbp?.value)) : 0;
    available_disallowance -= cap?.tlr_disallowance_gbp;
    cap.tdc_disallowance_gbp = NTIE > 0 && cap?.ntlr_subtotal_gbp?.value > 0 ? Math.round(Math.min(available_disallowance, cap?.tdc_subtotal_gbp?.value)) : 0;
    available_disallowance -= cap?.tdc_disallowance_gbp;
    cap.ofa_disallowance_gbp = NTIE > 0 && cap?.ofa_subtotal_gbp?.value > 0 ? Math?.round(Math.min(available_disallowance, cap?.ofa_subtotal_gbp?.value)) : 0;
    available_disallowance -= cap?.ofa_disallowance_gbp;
    return cap;
}

export { getReviewCalc3Values, calculate_disallowance_subtotals };