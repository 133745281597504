import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { processDateJS } from "../../utils/dateProcessor";
dayjs.extend(utc)

const getExcessDebtCapPriorPeriod = (savedData, poa) => {
    const priorPeriodEndDate = processDateJS(poa?.period_start)?.subtract(1, 'day');
    const priorPOA = savedData?.periods_of_account?.find((period) => period?.end_date === priorPeriodEndDate);
    if (priorPOA === undefined) {
        poa.excess_debt_cap_from_prior_period = savedData?.excess_debt_capt_before_model;
    }
    else {
        poa.excess_debt_cap_from_prior_period = priorPOA?.group_excess_debt_cap
    }
    return poa;
}

export {getExcessDebtCapPriorPeriod}