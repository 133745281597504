export function convertGbpToLc(fx_rate, value) {
    return (Number(value) || 0) * (Number(fx_rate) || 1);
}

export function calculateDTRAdjustment(cap) {
    if (cap.ct_rate_override) {
        return (Number(cap.net_dtr_adjustment_lc) || 0)/cap.ct_rate_override;
    }
    else {
        return (Number(cap.net_dtr_adjustment_lc) || 0)/(cap.corporation_tax_rate || 1);
    }
}

export function covertToPercentage(corporation_tax_rate) {
    corporation_tax_rate = (Number(corporation_tax_rate) || 1) * 100;
    const corporation_tax_rate_percentage = corporation_tax_rate + "%";
    return corporation_tax_rate_percentage;
}