import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import minMax from "dayjs/plugin/minMax"
import { calculateNetTaxInterestExpense, calculateTaxEBITDA, metrics } from "./EntityCalculations";
import { cap_adjusted_values } from '../../utils/CapCurrencyValues'
import { processDateJS, getOriginatingPoaMinStartDateJS } from "../../utils/dateProcessor";
import { getGroupData, getSelectedPeriod } from "../GroupContext";
import { generateCAPId } from "../../utils/capProcessor";

dayjs.extend(isSameOrAfter)
dayjs.extend(minMax)
dayjs.extend(utc)

const   getReviewCalc1Values = (poaIndex, savedData) => {
    const new_cap_data = getSelectedPeriod()?.adjusted_caps?.map(cap => {
        const company = savedData?.companies?.filter(c => c?.company_ID === cap?.company_ID)[0];
        // This shouldn't really happen but just a check in case
        if (!company) { 
            throw new Error('Failed to find company with id', cap?.company_ID)
        }

        if (!company?.uk_group_company) {
            return cap;
        }
        
        // calculate inclusion period
        const company_join = processDateJS(company?.date_join || getOriginatingPoaMinStartDateJS())
        const company_leave = processDateJS(company?.date_left || '3030-01-01')
        const group_period_start = processDateJS(savedData?.periods_of_account[poaIndex]?.period_start);
        const group_period_end = processDateJS(savedData?.periods_of_account[poaIndex]?.period_end);

        if (company_leave?.isBefore(group_period_start) || company_join?.isAfter(group_period_end)) {
            // if adjusted cap is not includied in PoA, remove it
            return;
        }

        const relevant_period_start = processDateJS(dayjs.max(company_join, group_period_start));
        const relevant_period_end = processDateJS(dayjs.min(company_leave, group_period_end));

        const startDate = processDateJS(cap?.start_date);
        const endDate = processDateJS(cap?.end_date);
        const inclusion_period_start = processDateJS(dayjs.max(relevant_period_start, startDate));
        const inclusion_period_end = processDateJS(dayjs.min(relevant_period_end, endDate));

        const inclusion_period_length = inclusion_period_end?.diff(inclusion_period_start, "day") + 1;
        const ap_length = endDate?.diff(startDate, "day") + 1
        const inclusion_factor = inclusion_period_length / ap_length;

        Object.entries(cap)?.forEach(([key, entry]) => {
            if (cap_adjusted_values?.includes(key)) {
                if (cap?.hasOwnProperty(key) && cap[key] && cap[key]?.manual_adjustment === true) {
                    adjustment_status = 'Manual Adjustment';
                }
            }
        })
        
        let adjustment_status = inclusion_factor < 1 ? 'Automatic Adjustment' : 'No Adjustment';
        const net_tax_interest_expense = calculateNetTaxInterestExpense(cap);
        const tax_ebitda = calculateTaxEBITDA(cap);

        const calculated_data = {
            adjustment_status,
            net_tax_interest_expense,
            tax_ebitda
        };

        // Add the pro-rated values of the calculations
       /* metrics.filter(m => m.calculation_group).forEach(metric => {
            const value = cap[metric.metric_id];
            const adjusted_value = value * inclusion_factor;
            calculated_data[metric.metric_id] = adjusted_value;
        });*/

        return { ...cap, ...calculated_data }
    })

    return new_cap_data;
}

export default getReviewCalc1Values;