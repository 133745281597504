import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)

export function processDateJS(date) {
    try {
        if (!date) {
            return date;
        }

        let year, month, day;

        if (dayjs.isDayjs(date)) {
            year = date?.year();
            month = date?.month();
            day = date?.date();
        }
        else if (date instanceof Date) {
            year = date?.getFullYear();
            month = date?.getMonth();
            day = date?.getDate();
        }
        // If input is a string in 'DD/MM/YYYY' format, parse it accordingly
        else if (typeof date === 'string' && date.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
            const parsedDate = dayjs(date, 'DD/MM/YYYY');
            if (!parsedDate.isValid()) {
                throw new Error("Invalid date string format. Expected 'DD/MM/YYYY'.");
            }
            year = parsedDate.year();
            month = parsedDate.month();
            day = parsedDate.date();
        }
        else if (new Date(date) instanceof Date && !isNaN(new Date(date))) {
            date = new Date(date);
            year = date?.getFullYear();
            month = date?.getMonth();
            day = date?.getDate();
        }
        else {
            throw new Error("Invalid date input. Please provide a JavaScript Date object, dayjs object, or a date string.");
        }

        // Return the parsed date in UTC format
        return dayjs.utc({ year: year, month: month, day: day, hour: 0, minute: 0, second: 0 });
    } catch (error) {
        console.error('Error processing date:', error?.message);
        return undefined;
    }
}

export function processDate(date) {
    try {
        return processDateJS(date)?.toDate();
    } catch (error) {
        console.error('Error processing date:', error?.message);
        return null;
    }
}

export function getToday() {
    return processDate(dayjs()?.toDate());
}

export function getTodayDayjs() {
    return dayjs(getToday());
}

export function getYesterday() {
    return processDate(dayjs()?.subtract(1, 'day')?.toDate());
}

export function getYesterdayDayjs() {
    return dayjs(getYesterday());
}

export function getOriginatingPoaMinStartDateJS() {
    return processDateJS(new Date(2017, 3, 1));
}

export function getDefaultCompanyLeaveDate() {
    return processDateJS(new Date(3030, 0, 1));
}

export function getDefaultPeriodEnd(period_start){
    return processDateJS(period_start)?.add(1, 'year')?.subtract(1, 'day');
}

export function getMaxPeriodEnd(period_start){
    return processDateJS(period_start)?.add(18, 'months')?.subtract(1, 'day');
}
