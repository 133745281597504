import { getGroupName } from "../services/GroupContext";
import { AuthContext } from '../services/AuthProvider';
import { useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";

// Redirects users to the home screen if they don't have access to the cached group;
export const GroupAccessRoute = ({ children }) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  
  const groupName = getGroupName();
  
  const hasAccess = auth.isAdmin() || auth.getScope()?.find((scope) => scope?.group === groupName);

  useEffect(() => {
    if ( ! hasAccess){
      toast.error(`You don't have access to group: ${groupName}`);
      navigate("/");
    }
  }, [])

  if ( hasAccess ) {
    return children;
  }
};
