import { useLocation, useNavigate } from "react-router-dom";
import { Steppers, Stepper, Button, Tooltip } from "@appkit4/react-components";
import React, { useEffect, useContext, useState, useRef } from 'react';
import { AuthContext } from '../services/AuthProvider';
import toast from 'react-hot-toast';
import { getGroupName } from '../services/GroupContext';

export const steps = [
  { name: "Manage Access", path: "/app/useraccess" },
  { name: "Group Config", path: "/app/groupconfig" },
  { name: "Group POA", path: "/app/grouppoa" },
  { name: "Group Companies", path: "/app/groupcompanies", isPeriodAccessPage: true },
  { name: "Reporting Company", path: "/app/reportingcompany", isPeriodAccessPage: true },
  { name: "Group Data", path: "/app/groupdata", isPeriodAccessPage: true },
  { name: "Company APs", path: "/app/companyap", isPeriodAccessPage: true },
  { name: "Entity Input", path: "/app/entityinput", isPeriodAccessPage: true },
  { name: "Brought Forward Disallowances", path: "/app/editbfd", isPeriodAccessPage: true },
  { name: "Review", path: "/app/reviewcalc1", isPeriodAccessPage: true, children: [
    { name: "Summary of automatic allocations", path: "/app/reviewcalc1" },
    { name: "Fixed/Group ratio election", path: "/app/reviewcalc2" },
    { name: "Disallowance Allocation", path: "/app/reviewcalc3" },
    { name: "Reactivation of interest", path: "/app/reviewcalc4" },
  ] },
  { name: "Carried Forward Disallowances", path: "/app/editcfd", isPeriodAccessPage: true },
  { name: "Summary", path: "/app/summary", isPeriodAccessPage: true },
  { name: "Outputs and Next Steps", path: "/app/outputs", isPeriodAccessPage: true },
];

export const allPages = steps.flatMap( step =>  step.children?.map( s => s.path) ?? step.path ?? "/error" );
export const periodAccessPages = steps.flatMap( step => 
  step.children?.map( stepChild => {
    stepChild.isPeriodAccessPage = stepChild.isPeriodAccessPage ?? step.isPeriodAccessPage;
    return stepChild;
  })
  ?? step
  ?? null
).filter( step => step?.isPeriodAccessPage )
  .map( step => step.path ?? "/error");

function ProgressNav() {
  const stepperSpace = 135;
  const navigate = useNavigate();
  const location = useLocation();
  const activeItemRef = useRef(null);

  useEffect(() => {
    if (activeItemRef.current) {
      activeItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end'
      });
    }
  }, [location]);

  const findCurrentStepIndex = () => {
    const path = location.pathname.toLowerCase();
    
    const currentStepIndex = steps.findIndex( step => {
      if (step.children) {
        return step.children.map(c => c.path.toLowerCase()).includes(path);
      }
      
      return path.toLowerCase() === step.path;
    })

    return currentStepIndex !== -1 ? currentStepIndex : 0;
  }

  const currentStep = findCurrentStepIndex();

  return (
    <div className="ap-container">
      <div className="d-flex flex-row w-100" style={{ padding: "0em 0em 1em 0em", paddingBottom: '10px', overflow:"auto", whiteSpace: "nowrap" }}>
        <div className="mx-1">
          <Steppers space={stepperSpace} activeIndex={currentStep} onActiveIndexChange={(index) => navigate(steps[index].path)}>
            {steps.map(({name}, index) => {
              if (index <= currentStep)
                return (
                  <Stepper
                  label={name}
                  key={`${index}-${name}`}
                  ref={index === currentStep ? activeItemRef : null}
                  />
                );
            })}
          </Steppers>
        </div>
        <div className="">
          <Steppers space={stepperSpace} readonly activeIndex={-1}>
            {steps.map(({name}, index) => {
              if (index > currentStep)
                return (
                  <Stepper
                    label={name}
                    key={`${index}-${name}`}
                  />
                );
            })}
          </Steppers>
        </div>
      </div>
    </div>
  );
}

const BackButton = ({...args}) => {
    const auth = useContext(AuthContext)
    const [isError, setIsError] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const currentUrl = `${location.pathname}${location.search}${location.hash}`;

    const [isReadOnly, setIsReadOnly] = useState();

    useEffect(() => {
        setIsReadOnly(auth.isReadOnlyUser(getGroupName()));
    }, []);

    const pages = allPages;

    const goBack = () => {

        let previousUrl = null;
        
        for (let i = 0;  i < pages.length; i++) {
            if (currentUrl.toLowerCase() === pages[i].toLowerCase()) {
                if (i > 0) {
                    previousUrl = pages[i-1];
                }
                else {
                    previousUrl = null
                }
                break;
            }
        }
    
        if (isError) {
            toast.error('There is an error. Please fix before proceeding...');
            return;
        }

        navigate(previousUrl);
        return;

    };

    return (
        // Only show tooltip for editing users, not read-only users
        isReadOnly ? (
            <Button {...args} onClick={goBack}>Back</Button>
        ) : (
            <div>
                <Tooltip className='col-1' content='Note: Any unsaved changes will be discarded'><Button {...args} onClick={goBack}>Back</Button></Tooltip>
            </div>
        )
    );
};

/**
 * @param {import("@appkit4/react-components").ButtonProps & { preNavigation }} props
 * @param {() => Promise|void} props.preNavigation A function that runs before navigating (will await return value before navigating).
 * @returns {JSX.Element}
 *
 * @example <caption>Changing button contents</caption>
 * <NextButton>New Button Text</NextButton>
 *
 * @example <caption>Passing in other arguments</caption>
 * <NextButton disabled={isDisabledState} />
 *
 */
const NextButton = ({ preNavigation, nextPage, children, ...args }) => {  
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = `${location.pathname}${location.search}${location.hash}`;

  const pages = allPages;

  const navigateNext = () => {
    const currPageIndex = pages.findIndex( page => currentUrl.toLowerCase() === page.toLowerCase() );
    const nextPageIndex = currPageIndex + 1;
    let nextUrl = nextPageIndex < pages.length ? pages[nextPageIndex] : null;

    navigate(nextUrl);
  };

  const startNavigation = async () => {
    try {
      if (preNavigation) 
        await preNavigation();

      if (nextPage)
        return navigate(nextPage); 

      navigateNext();

    } catch (e){
      toast.error( typeof e === "string" ? e : (e?.message) || "Error: failed to navigate" );
    }
  }

  return (
    <Button {...args} onClick={startNavigation}>{children ?? "Next Page"}</Button>
  );
}

export { ProgressNav, BackButton, NextButton };