import React, { useState, useEffect } from 'react';
import { Modal, Combobox } from '@appkit4/react-components'; 
import { Button, } from "../../components/ReadonlyAwareInputs";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getPOAEndDates } from '../../services/GroupContext';
import { processDateJS } from '../../utils/dateProcessor';
import "./ReportingCompany.scss";
import CustomTooltipIcon from '../../components/customTooltipIcon/customTooltipIcon';

dayjs.extend(utc)

const IneligibleModal = ({ visible, setVisible, activeCompany, onRevocation, selectedPeriod }) => {
  const [validRevocation, setValidRevocation] = useState(true);
  const [firstIneligiblePeriod, setFirstIneligiblePeriod] = useState();

  const POAEndDates = getPOAEndDates();

  useEffect(() => {
    const isValidRevocation = () => {
      if (!firstIneligiblePeriod) {
        return false;
      }

      return true;
    }

    setValidRevocation(isValidRevocation());
  }, [firstIneligiblePeriod])

  const handleSubmit = () => {
      const currentPoAEnd = processDateJS(selectedPeriod?.period_end)?.utc();

      const firstPeriodLapsed = processDateJS(firstIneligiblePeriod)?.utc();

      // Determine if the company should be active or inactive
      const isActive = () => {
          const poaEndDate = processDateJS(activeCompany?.first_appointed_period_end_date)?.utc();

          if (activeCompany?.appointer === "HMRC" && poaEndDate?.isSame(currentPoAEnd)) {
              return true;
          }

          if (activeCompany?.appointer === "Company" && poaEndDate?.isSameOrBefore(currentPoAEnd) && !firstPeriodLapsed) {
              return true;
          }

          if (activeCompany?.appointer === "Company" && poaEndDate?.isSameOrBefore(currentPoAEnd) && firstPeriodLapsed?.isAfter(currentPoAEnd)) {
              return true;
          }

          return false;
      };

      const newStatus = isActive() ? "Active" : "Inactive";

      const modifiedCompany = {
          ...activeCompany,
          reason_appointment_lapsed: 'Ineligibility',
          first_period_appointment_lapsed: firstPeriodLapsed.format('DD/MM/YYYY'),
          status: newStatus,  // Set status dynamically
      };

      onRevocation(modifiedCompany);  // Call the revocation handler
      setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      title={`Record Ineligibility`}
      ariaLabel={`Record Ineligibility`}
      onCancel={() => setVisible(false)}
      maskCloseable={false}
      modalStyle={{ width: '33.75rem' }}
      footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '64px' }}
      header={""}
      icons={""}
      footer={
        <>
          <Button neverReadonly onClick={() => setVisible(false)} kind="secondary">Cancel</Button>
          <Button hiddenInReadonly disabled={!validRevocation} onClick={handleSubmit}>Submit</Button>
        </>
      }
      bodyStyle={{ minHeight: '200px' }
      }>

      <div style={{ display: 'grid', gridGap: '10px' }}>
        <div className="row">
          <label className="col-sm-2 col-form-label">Reporting Company Name</label>
          <div className="col-sm-10">
            <b>{activeCompany?.company_name}</b>
          </div>
        </div>

        <div className="row">
          <label className="col-sm-2 col-form-label">Please enter the first period for which the ineligibility applies:</label>
          <div className="flex-container-modal">
            <Combobox className="flex-grow" data={POAEndDates} placeholder='First Ineligible Period' required={true} dropdownRenderMode='portal' onSelect={(value) => { setFirstIneligiblePeriod(value) }} value={firstIneligiblePeriod} ></Combobox>
            <CustomTooltipIcon tooltipText={<p>Select the end of the first Period of Account for which the reporting company is ineligible.</p>}/>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default IneligibleModal;
