import { Panel } from '@appkit4/react-components'; 
import { getGroupData, getGroupName, getFormattedSelectedPeriod } from '../services/GroupContext';

const getUltParent = () => {
    const savedData = getGroupData();
    if (!savedData) {
        return
    }

    const ultimateParent = savedData.companies.find(comp => comp.company_ID === savedData.ult_parent_ID)
    return ultimateParent?.company_name
}

const CompanyPanel = ({ showPeriod = true }) => {
    return (
        <Panel>
            <div className="row">
                <label className="col-sm-2 col-form-label">Group Name</label>
                <div className="col-sm-10">
                    <b>{getGroupName()}</b>
                </div>
            </div>
            <div className="row">
                <label className="col-sm-2 col-form-label">Ultimate Parent</label>
                <div className="col-sm-10">
                    <b>{getUltParent()}</b>
                </div>
            </div>
            {showPeriod && 
                <div className="row">
                    <label className="col-sm-2 col-form-label align-self-center">Period of Account</label>
                    <div className="col-sm-10">
                        <b>{getFormattedSelectedPeriod()}</b>
                    </div>
                </div>
            }
        </Panel>
    )
}

export default CompanyPanel;