import { Notice } from "@appkit4/react-components"
import { getUserIsReadonly } from "../services/GroupContext";

const ReadonlyBanner = () => {

    if ( ! getUserIsReadonly() ){
        return <></>;
    }

    return (
        <div className='container-fluid'>
            <Notice
                message='You are viewing in read only mode, any changes will not be saved.'
                status='icon-warning-fill'
                closeable={false}
                style={{backgroundColor: "white", width: "100%"}}
            />
        </div>
    )
}

export default ReadonlyBanner