import { Panel, Tooltip, Loading } from '@appkit4/react-components'; 
import { Button, Input, CalendarPicker, Switch } from "../../components/ReadonlyAwareInputs";
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton, NextButton } from '../../components/ProgressNav';
import ANGIE from './ANGIE';
import QNGIE from './QNGIE';
import EBITDA from './EBITDA';
import { getGroupData, setGroupData, getSelectedPeriod, getGroupName, getFormattedSelectedPeriod } from '../../services/GroupContext';
import toast from 'react-hot-toast';
import NGIE from './NGIE';
import sqlService from '../../services/sqldatabase/sqldatabase.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AuthContext } from '../../services/AuthProvider';
import { formatCurrency } from '../../utils/formatters';
import { getTodayDayjs, getYesterdayDayjs, processDate, processDateJS } from '../../utils/dateProcessor';
import CompanyPanel from '../../components/CompanyPanel';
dayjs.extend(utc)

const GroupData = () => {
    const selectedPeriod = getSelectedPeriod();
    const { period_start, period_end } = selectedPeriod;

    const auth = useContext(AuthContext)
    const navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState(false)
    const [isPosting, setIsPosting] = useState(false);

    const [showNGIE, setShowNGIE] = useState(false);
    const [NGIEError, setNGIEError] = useState(false);
    const [showANGIE, setShowANGIE] = useState(false);
    const [ANGIEError, setANGIEError] = useState(false);
    const [showQNGIE, setShowQNGIE] = useState(false);
    const [QNGIEError, setQNGIEError] = useState(false);
    const [showEBITDA, setShowEBITDA] = useState(false);
    const [EBITDAError, setEBITDAError] = useState(false);

    const [showGR, setShowGR] = useState(false)
    const [showGRBE, setShowGRBE] = useState(false)
    
    const [isError, setIsError] = useState(false);

    const [investorTable, setInvestorTable] = useState([{
        "investor_group": "",
        "blended_group_ratio": "",
        "blended_group_ebitda": ""
    }])
    const [duplicateInvestorErrors, setDuplicateInvestorErrors] = useState([]);

    const [groupName, setGroupName] = useState();
    const [ultimateParent, setUltimateParent] = useState();

    const [periodData, setPeriodData] = useState();
    const [periodIndex, setPeriodIndex] = useState();

    const [blendedGroupRatio, setBlendedGroupRatio] = useState()
    const [blendedNetGroupInterestExpense, setBlendedNetGroupInterestExpense] = useState(0);

    const [electionConsolidatedIAS, setElectionConsolidatedIAS] = useState(false)
    const [electionConsolidatedIASDate, setElectionConsolidatedIASDate] = useState()
    const [electionConsolidatedIASLink, setElectionConsolidatedIASLink] = useState()
    const [electionConsolidatedIASMaxDate, setElectionConsolidatedIASMaxDate] = useState(false);
    
    const [electionNoStatementsDrawnUp, setElectionNoStatementsDrawnUp] = useState(false)
    const [electionNoStatementsDrawnUpDate, setElectionNoStatementsDrawnUpDate] = useState()
    const [electionNoStatementsDrawnUpLink, setElectionNoStatementsDrawnUpLink] = useState()
    const [electionNoStatementsDrawnUpMaxDate, setElectionNoStatementsDrawnUpMaxDate] = useState(false);
    const [recordedElectionsValidators, setRecordedElectionsValidators] = useState({s484: true, s486: true});
    const [irrevocableElections,setIrrevocableElections] = useState({
        disregardRegulations:{
            switch:getGroupData()?.disregard_regulations_to_derivative_contracts,
            date:getGroupData()?.disregard_regulations_election_year,
            comment:getGroupData()?.disregard_regulation_comment
        },
        EBITDA:{
            switch:getGroupData()?.group_ebitda_gains,
            date:getGroupData()?.group_ebitda_gains_year,
            comment:getGroupData()?.group_ebitda_gains_comment
        },
        alternativeCalculation:{
            switch:getGroupData()?.interest_allowance_alt_calc,
            date:getGroupData()?.interest_allowance_alt_calc_year,
            comment:getGroupData()?.interest_allowance_alt_calc_comment
        },
        consolidatedPartnership:{
            switch:getGroupData()?.interest_allowance_consolidated_partnership,
            date:getGroupData()?.interest_allowance_consolidated_partnership_year,
            comment:getGroupData()?.interest_allowance_consolidated_partnership_comment
        }

    })

    const isReadOnly = auth?.isReadOnlyUser(getGroupName());

    useEffect(() => {
        setIsError(
            Object.values(recordedElectionsValidators)?.some(value => value === false)
            || duplicateInvestorErrors?.some(value => value === true)
            || NGIEError || ANGIEError || QNGIEError || EBITDAError
        );
    }, [
        recordedElectionsValidators?.s484, 
        recordedElectionsValidators?.s486, 
        duplicateInvestorErrors, 
        NGIEError, ANGIEError, QNGIEError, EBITDAError,
    ]);

    const formatNumber = (value) => {
        if (!value) return ''; // Return empty string if value is null or undefined
        // Convert value to a number, add commas for thousands separator, and return as string
        return Number(value)?.toLocaleString('en-US');
    };

    // Function to parse formatted number back to a plain number
    const parseNumber = (value) => {
        // Remove commas from the value and return as a plain number
        return parseFloat(value?.replace(/,/g, ''));
    };

    const handleInputChange = (value) => {
        // Parse the value and set the state
        setBlendedNetGroupInterestExpense(parseNumber(value));
    };

    let interestAllowanceElection = false;

    useEffect(() => {
        const savedData = getGroupData();
        if (!savedData) {
            return;
        }

        setGroupName(savedData?.group_name);
        setUltimateParent(savedData?.ult_parent_name);

        if (!savedData?.periods_of_account) {
            navigate('/app/grouppoa');
            toast.error('No period of accounts have been set up yet!');
            return;
        }
    }, [])

    useEffect(() => {
        if (!selectedPeriod) {
            setPeriodData(null);
            setPeriodIndex(null)
            return
        }

        const savedData = getGroupData();
        if (!savedData) {
            toast.error('Failed get to get group data...')
            return;
        }

        // Let's find the PoA we're trying to update 
        const allPeriods = savedData?.periods_of_account;
        if (!allPeriods) {
            //toast.error('Failed get to get group period of accounts...')
            return;
        }
        
        if(selectedPeriod?.period_start && selectedPeriod?.period_end) {
            for (let index = 0; index < allPeriods?.length; index++) {
                const searchingPeriod = allPeriods[index];
                if (processDateJS(searchingPeriod?.period_start)?.isSame(selectedPeriod?.period_start, 'day') && processDateJS(searchingPeriod?.period_end)?.isSame(selectedPeriod?.period_end, 'day')) {
                    
                    searchingPeriod.fx_rate = searchingPeriod?.fx_rate ?? 1;

                    setPeriodData(searchingPeriod);
                    setPeriodIndex(index);
                    setShowGR(searchingPeriod?.group_ratio_election);
                    setShowGRBE(searchingPeriod?.group_ratio_blended_election)
                    setBlendedGroupRatio(searchingPeriod?.blended_group_ratio)
                    setBlendedNetGroupInterestExpense(searchingPeriod?.blended_net_group_interest_expense)
                    setElectionConsolidatedIAS(searchingPeriod?.election_consolidated_ias)
                    setElectionConsolidatedIASDate(processDateJS(searchingPeriod?.election_consolidated_ias_date))
                    setElectionConsolidatedIASLink(searchingPeriod?.election_consolidated_ias_link)
                    setElectionNoStatementsDrawnUp(searchingPeriod?.election_no_statements_drawn_up)
                    setElectionNoStatementsDrawnUpDate(processDateJS(searchingPeriod?.election_no_statements_drawn_up_date))
                    setElectionNoStatementsDrawnUpLink(searchingPeriod?.election_no_statements_drawn_up_link)
                    if (searchingPeriod?.hasOwnProperty('investor_group_data') && searchingPeriod?.investor_group_data.length > 0) {
                        setInvestorTable(searchingPeriod?.investor_group_data)
                    }
                    const periodEndDate = processDateJS(searchingPeriod?.period_end);
                    setElectionConsolidatedIASMaxDate(periodEndDate);
                    setElectionNoStatementsDrawnUpMaxDate(periodEndDate?.subtract(1, 'day'));
                    return;
                }
            }
    
            toast.error(`Failed to find period data for the period: ${selectedPeriod?.period_start} : ${selectedPeriod?.period_end}`)
        }
    }, [])

    const save = (newPeriodData = {}) => {
        let savedData = getGroupData();
        if (!savedData) {
            throw 'Failed get to get group data...';
        }

        // Let's find the PoA we're trying to update 
        const allPeriods = savedData?.periods_of_account;
        if (!allPeriods) {
            throw 'Failed get to get group period of accounts...';
        }

        setIsPosting(true);

        let period = {...periodData, ...newPeriodData};
        period.election_consolidated_ias = electionConsolidatedIAS;
        period.election_consolidated_ias_date = electionConsolidatedIASDate;
        period.election_consolidated_ias_link = electionConsolidatedIASLink;
        period.election_no_statements_drawn_up = electionNoStatementsDrawnUp;
        period.election_no_statements_drawn_up_date = electionNoStatementsDrawnUpDate;
        period.election_no_statements_drawn_up_link = electionNoStatementsDrawnUpLink;

        if (showGR != null) {
            period.group_ratio_election = showGR;
            if (showGRBE != null) {
                period.group_ratio_blended_election = showGRBE;
                if(showGRBE){
                    period.investor_group_data = investorTable;
                    period.blended_group_ratio = blendedGroupRatio;
                    period.blended_net_group_interest_expense = blendedNetGroupInterestExpense;
                    period.election_interest_allowance_non_consolidated_investment = interestAllowanceElection;
                }
            }
        }
        setPeriodData(period)
        allPeriods[periodIndex] = period;
        savedData.periods_of_account = allPeriods;
        savedData = {...savedData,
            disregard_regulations_to_derivative_contracts:irrevocableElections?.disregardRegulations?.switch,
            disregard_regulations_election_year:irrevocableElections?.disregardRegulations?.date,
            disregard_regulation_comment:irrevocableElections?.disregardRegulations?.comment,

            group_ebitda_gains:irrevocableElections?.EBITDA?.switch,
            group_ebitda_gains_year:irrevocableElections?.EBITDA?.date,
            group_ebitda_gains_comment:irrevocableElections?.EBITDA?.comment,

            interest_allowance_alt_calc:irrevocableElections?.alternativeCalculation?.switch,
            interest_allowance_alt_calc_year:irrevocableElections?.alternativeCalculation?.date,
            interest_allowance_alt_calc_comment:irrevocableElections?.alternativeCalculation?.comment,

            interest_allowance_consolidated_partnership:irrevocableElections?.consolidatedPartnership?.switch,
            interest_allowance_consolidated_partnership_year:irrevocableElections?.consolidatedPartnership?.date,
            interest_allowance_consolidated_partnership_comment:irrevocableElections?.consolidatedPartnership?.comment

        }
        setGroupData(savedData);
        
        if (isReadOnly) {
            return;
        }

        const promise = sqlService?.postResponse(savedData);
        return toast.promise(promise, {
            loading: 'Saving Group Data...',
            success: () => {
                setIsPosting(false);
                return 'Saved Group Data';
            },
            error: 'Error saving group data',
        })
    }

    useEffect(() => {
        if (!periodData) {
            console.error('If the screen is blank, it is likely because it could not find the given PoA data')
            return;
        }
    }, [periodData])

    useEffect(() => {
        setDuplicateInvestorErrors(investorTable?.map(
            (outerV, outerI) => investorTable?.some(
                (innerV, innerI) => outerV['investor_group'] === innerV['investor_group'] 
                                    && outerI !== innerI
            )
        ));
    }, [investorTable])

    const addTableRows = () => {
        setInvestorTable((prevState) => ([...prevState,
        {
            "investor_group": "",
            "blended_group_ratio": "",
            "blended_group_ebitda": ""
        }]));
    }

    const deleteTableRows = (e, index) => {
        const rows = [...investorTable];
        rows?.splice(index, 1);
        setInvestorTable(rows);
    }

    const tableChange = (index, value, name) => {
        const rowsInput = [...investorTable];
        rowsInput[index][name] = value;
        setInvestorTable(rowsInput);
    }

    if (isLoading) {
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            ></Loading>
        );
    }

    return (
        <div className="ap-container">
            <CompanyPanel />
            {
                periodData && (
                    <>
                        <Panel className='mt-2' title='Group Level Information'>
                        <p className='mt-2'>These buttons allow you to enter the group-level data required to complete the CIR calculations. This information is obtained from (actual or deemed) consolidated financial statements of the worldwide group. The income statement/profit and loss account should provide a useful starting point, but it will be necessary to review the notes to the financial statements and obtain further, more granular, information (e.g. from the extended trial balance) to make all the necessary adjustments.</p>
                            <div className='row'>
                                <p className='col'>NGIE - Net Group-Interest Expense (Income)</p>
                                <p className='col'>Total: GBP { periodData?.net_group_interest_expense ? formatCurrency(Math.round(periodData?.net_group_interest_expense)) : 0 }</p>
                                <Button viewEditButton neverReadonly className='col' onClick={() => { setShowNGIE(true) }} />
                                {showNGIE && <NGIE
                                    setVisible={setShowNGIE}
                                    currentPeriod={periodData}
                                    setCurrentPeriod={setPeriodData}
                                    save={save}
                                    errorState={[NGIEError, setNGIEError]}
                                    isParentError={isError}
                                    isReadOnly={isReadOnly}
                                ></NGIE>}
                            </div>
                            <div className='row'>
                                <p className='col'>ANGIE - Aggregate Net Group-Interest Expense</p>
                                <p className='col'>Total: GBP { periodData?.adjusted_net_group_interest_expense ? formatCurrency(Math.round(periodData?.adjusted_net_group_interest_expense)) : 0 } </p>
                                <Button viewEditButton neverReadonly className='col' onClick={() => { setShowANGIE(true) }} />
                                {showANGIE && <ANGIE
                                    setVisible={setShowANGIE}
                                    currentPeriod={periodData}
                                    setCurrentPeriod={setPeriodData}
                                    save={save}
                                    errorState={[ANGIEError, setANGIEError]}
                                    isParentError={isError}
                                    isReadOnly={isReadOnly}
                                ></ANGIE>}
                            </div>
                            <div className='row'>
                                <div className='d-flex flex-row col ap-pr-spacing-0'>
                                    <p className='ap-mt-spacing-2'>Enable Group Ratio election calculation:</p>
                                    <Tooltip className='' content={()=> {
                                        return <div>
                                            <p>This toggle opens the ability to enter information for QNGIE and Group EBITDA, to allow the group ratio and group ratio debt cap figures to be calculated.</p>
                                            <p>The application of the group ratio can be toggled on or off at a later stage, once it has been determined whether it is beneficial to apply the group ratio election.</p>
                                        </div>
                                    }}>
                                        <Button className='tooltip-width' icon='icon-information-outline' kind='text' />
                                    </Tooltip>
                                </div>
                                <div className='col ' style={{height:40}} >
                                    <Switch style={{ paddingLeft: 0,width:'fit-content' }} className='col' showIndicator defaultChecked={showGR} value={showGR} onChange={(value) => { if(showGR){setShowGRBE(false)} setShowGR(value) }} />
                                </div>
                            </div>
                            {showGR &&
                                <div>
                                    <div className='row'>
                                        <p className='col'>QNGIE - Qualifying net group-interest expense:</p>
                                        <p className='col'>Total: GBP { periodData?.qualifying_net_group_interest_expense ? formatCurrency(Math.round(periodData?.qualifying_net_group_interest_expense)) : 0 }</p>
                                        <Button viewEditButton neverReadonly className='col' onClick={() => { setShowQNGIE(true) }} />
                                        {showQNGIE && <QNGIE
                                            setVisible={setShowQNGIE}
                                            currentPeriod={periodData}
                                            setCurrentPeriod={setPeriodData}
                                            save={save}
                                            errorState={[QNGIEError, setQNGIEError]}
                                            isParentError={isError}
                                            isReadOnly={isReadOnly}
                                        ></QNGIE>}
                                    </div>
                                    <div className='row'>
                                        <p className='col'>Group EBITDA:</p>
                                        <p className='col'>Total: GBP { periodData?.group_ebitda ? formatCurrency(Math.round(periodData?.group_ebitda)) : 0 } </p>
                                        <Button viewEditButton neverReadonly className='col' onClick={() => { setShowEBITDA(true) }} />
                                        {showEBITDA && <EBITDA
                                            setVisible={setShowEBITDA}
                                            currentPeriod={periodData}
                                            setCurrentPeriod={setPeriodData}
                                            save={save}
                                            errorState={[EBITDAError, setEBITDAError]}
                                            isParentError={isError}
                                            isReadOnly={isReadOnly}
                                        ></EBITDA>}
                                    </div>
                                </div>}
                            {showGR && <div className='row'>
                                <div className='d-flex flex-row col ap-pr-spacing-0'>
                                    <p className=''>Group ratio - blended election:</p>
                                    <Tooltip className='' content={()=> {
                                        return <div>
                                            <p>This election can only be made where a reporting company has been appointed. The election is most relevant to Joint Ventures which have a high proportion of related party debt such that the Group Ratio may not otherwise be beneficial.</p>
                                            <p>The effect of the election is to allow the group to apply a different Group Ratio based on the Group Ratio(s) of the investor groups, potentially increasing Interest Allowance. It also modifies QNGIE.</p>
                                            <p>Refer to s401 - s404 TIOPA 2010 and Paragraph 14, Schedule 7A, TIOPA 2010 for further details.</p>
                                        </div>
                                    }}>
                                        <Button className='tooltip-width' icon='icon-information-outline' kind='text' />
                                    </Tooltip>
                                </div>
                                <div className='col' style={{height:40}} >
                                    <Switch
                                        style={{ paddingLeft: 0,width:'fit-content' }}
                                        className='col' showIndicator
                                        defaultChecked={showGRBE}
                                        onChange={(value) => { setShowGRBE(value) }}
                                    />
                                </div>
                            </div>}

                            {showGRBE && showGR &&
                                <div className=''>
                                    <table className="table ap-px-spacing-4" style={{ width: '100%' }}>
                                        <thead st>
                                            <tr>
                                                { /* Need to */}

                                                <th className="col-6 row" key={1}>Investor group
                                        <Tooltip className='' content={()=> {
                                        return <div>
                                            <p>This is the name of the investor group and will be included in the CIR return.</p>
                                        </div>
                                    }}>
                                        <Button className='tooltip-width' icon='icon-information-outline' kind='text' />
                                    </Tooltip>
                                                </th>
                                                <th className="col-1"></th>
                                                <th className="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {investorTable?.map((data, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td key={1}>
                                                            <Input
                                                                className="col"
                                                                value={data['investor_group']}
                                                                title='Please provide investor name'
                                                                onChange={(evnt) => { tableChange(index, evnt, 'investor_group') }}
                                                                error={duplicateInvestorErrors[index]}
                                                            />
                                                        </td>
                                                        <td><Button className="btn btn-outline-danger" onClick={(e) => deleteTableRows(e, index)}><span className="Appkit4-icon icon-close-outline"></span></Button></td>
                                                        <td></td>
                                                    </tr>
                                                )
                                            })}
                                            <tr>
                                                <td><Button className='col' kind='secondary' onClick={addTableRows}>Add another investor</Button></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div className='row'>
                                        <div className='d-flex flex-row col ap-pr-spacing-0'>
                                        <p className="ap-mt-spacing-2">Blended group ratio</p>
                                        <Tooltip className='col' content={()=> {
                                        return <div>
                                            <p>This is the blended group ratio under the election, which should be at least 30%. The ratio needs to be separately calculated outside the CIR Analyser.</p>
                                        </div>
                                    }}>
                                        <Button className='tooltip-width' icon='icon-information-outline' kind='text' />
                                    </Tooltip>
                                    </div>
                                    <div classname='col'>
                                        <Input
                                            value={blendedGroupRatio}
                                            error={blendedGroupRatio && !blendedGroupRatio?.match(/^[3-9][0-9]$|^100$/)}
                                            onChange={(value) => setBlendedGroupRatio(value)}
                                            suffix={'%'} />
                                    </div>
                                    </div>
                                    <div className='row'>
                                        <p className="col">Blended net group-interest expense</p>
                                        <Input
                                            className="col"
                                            type="text"
                                            min={0}
                                            value={formatNumber(blendedNetGroupInterestExpense)}
                                            onChange={handleInputChange}
                                            onFocus={() => {
                                                // Ensure that the value is set to a number when the input is focused
                                                if (blendedNetGroupInterestExpense === '') {
                                                    setBlendedNetGroupInterestExpense(0);
                                                }
                                            }}
                                        />
                                    </div>

                                </div>
                            }
                            
                            <div className='d-flex flex-row justify-content'>
                                    <div className='d-flex flex-row col'>
                                        <div>
                                            Interest allowance (non-consolidated investment) election
                                        </div>
                                        <Tooltip className='' content={()=> {
                                        return <div>
                                            <p>This election can only be made where a reporting company has been appointed. It is made on an investment by investment basis and is revocable.</p>
                                            <p>The effect of the election is broadly to remove amounts included in respect of the non-consolidated investment and loans made to them. Instead, the appropriate portion of the non-consolidated investment’s Group EBITDA (ignoring loans from the worldwide group) is included, and ANGIE/QNGIE are increased by the appropriate portion of the ANGIE/QNGIE of the non-consolidated investment.</p>
                                            <p>Refer to s427 - s429 TIOPA 2010 and Paragraph 17, Schedule 7A, TIOPA 2010 for further details.</p>
                                        </div>
                                    }}>
                                            <Button className='tooltip-width' icon='icon-information-outline' kind='text' />
                                        </Tooltip>
                                    </div>
                                    <div className='col' style={{height:40}} >
                                        <Switch className='col' style={{width:'fit-content'}} showIndicator defaultChecked={interestAllowanceElection} onChange={(value) => {interestAllowanceElection = value}} />
                                    </div>
                            </div>

                        </Panel>




                        {/* Irrevocable Elections section */}

                        <Panel title='Irrevocable Elections' className='mt-4'>
                            <p>The following elections are irrevocable for the Group once made.  Please select all elections that have been made by the Group in any period before the first period for which the model is used:</p>
                                <div className='row'>
                                    <p className='col-4'>Apply the Disregard Regulations to pre-1 April 2020 derivative contracts</p>
                                    <Tooltip className='' content={()=> {
                                        return <div>
                                            <p>This election could only be made before 1 April 2018 by a company which was a UK group company of a CIR group on 1 April 2017. To be effective, an election must have been made by every UK group company of the worldwide group on 1 April 2017.</p>
                                            <p>The effect of the election is to treat the company as having made an election for Disregard Regulations 7, 8, and 9 to apply for derivative contracts entered into by the company before 1 April 2020 for the purposes of calculating Tax-Interest Expense/Income and Tax-EBITDA of the company or a relevant transferee company. The election does not apply to derivative contracts entered into after this date.</p>
                                            <p>Refer to Paragraph 31, Schedule 5, Finance (No2) Act 2017 for further details.</p>
                                        </div>
                                    }}>
                                        <Button className='tooltip-width' icon='icon-information-outline' kind='text' />
                                        </Tooltip>
                                    <Switch 
                                    disabled={
                                            processDateJS(getGroupData()?.disregard_regulations_election_year)?.isBefore(processDateJS(period_start)) ||
                                            processDateJS(getGroupData()?.disregard_regulations_election_year)?.isAfter(processDateJS(period_end)) ||  
                                            processDateJS(period_start)?.isAfter(processDateJS(new Date('2018-04-01T00:00:00.000Z'))) 
                                        }
                                    onChange={(e)=>{setIrrevocableElections(state=>({
                                        ...state,
                                        disregardRegulations:{
                                            ...state.disregardRegulations,
                                            switch:e,
                                            date:period_end
                                        }
                                    }))}}
                                    className='col-1' showIndicator checked={irrevocableElections?.disregardRegulations?.switch}/>
                                    <Input className='col' 
                                    onChange={(e)=>{setIrrevocableElections(state=>({
                                        ...state,
                                        disregardRegulations:{
                                            ...state.disregardRegulations,
                                            comment:e
                                        }
                                    }))}}
                                    disabled={
                                            processDateJS(getGroupData()?.disregard_regulations_election_year)?.isBefore(processDateJS(period_start)) ||
                                            processDateJS(getGroupData()?.disregard_regulations_election_year)?.isAfter(processDateJS(period_end)) ||  
                                            processDateJS(period_start)?.isAfter(processDateJS(new Date('2018-04-01T00:00:00.000Z'))) 
                                    }
                                    title='Comment' value={irrevocableElections?.disregardRegulations?.comment}  />
                                </div>
                                {showGR&&
                                <>
                                    <div className='row'>
                                        <p className='col-4'>Group EBITDA (chargeable gains) election</p>
                                        <Tooltip className='' content={()=> {
                                        return <div>
                                            <p>This election can only be made where a reporting company has been appointed.</p>
                                            <p>Where the election applies, the effect is broadly that, for the purposes of computing Group EBITDA for the group ratio, the recalculated profits amount under s419(1) in respect of disposals of relevant assets is calculated on a chargeable gains basis, rather than an accounting basis. The mechanics of the election is complicated - for example it is necessary to assume, inter alia, that SSE does not apply. Relevant losses generated can be carried forward to future periods.</p>
                                            <p>Refer to s422 TIOPA 2010 and Paragraph 15, Schedule 7A, TIOPA 2010 for further details.</p>
                                        </div>
                                    }}>
                                            <Button className='tooltip-width' icon='icon-information-outline' kind='text'></Button>
                                            </Tooltip>
                                        <Switch 
                                        disabled={
                                        getGroupData()?.group_ebitda_gains && 
                                        (
                                            processDateJS(getGroupData()?.group_ebitda_gains_year)?.isBefore(processDateJS(period_start)) ||
                                            processDateJS(getGroupData()?.group_ebitda_gains_year)?.isAfter(processDateJS(period_end))
                                        )
                                        }
                                        onChange={(e)=>{setIrrevocableElections(state=>({
                                            ...state,
                                            EBITDA:{
                                                ...state.EBITDA,
                                                switch:e,
                                                date:period_end
                                            }
                                        }))}}
                                        className='col-1' showIndicator checked={irrevocableElections?.EBITDA?.switch}/>
                                        <Input className='col' 
                                        onChange={(e)=>{setIrrevocableElections(state=>({
                                            ...state,
                                            EBITDA:{
                                                ...state.EBITDA,
                                                comment:e
                                            }
                                        }))}}
                                        disabled={
                                            getGroupData()?.group_ebitda_gains && 
                                            (
                                                processDateJS(getGroupData()?.group_ebitda_gains_year)?.isBefore(processDateJS(period_start)) ||
                                                processDateJS(getGroupData()?.group_ebitda_gains_year)?.isAfter(processDateJS(period_end))
                                            )
                                            }
                                        title='Comment' value={irrevocableElections?.EBITDA?.comment} />
                                    </div>
                                </>
                                }
                                <div className='row'>
                                        <p className='col-4'>Interest allowance (alternative calculation) election</p>
                                        <Tooltip className='' content={()=> {
                                        return <div>
                                            <p>This election can only be made where a reporting company has been appointed.</p>
                                            <p>Where the election applies, the effect is that, for the purposes of computing ANGIE, QNGIE and Group EBITDA, adjustments are made to reflect capitalised interest, employer pension contributions, unpaid employees remuneration, employee share acquisitions and changes in accounting policy applying tax principles, rather than accounting principles. As the election impacts ANGIE, it can be relevant even where no Group Ratio election is made.</p>
                                            <p>Refer to s423 - s426 TIOPA 2010 and Paragraph 16, Schedule 7A, TIOPA 2010 for further details.</p>
                                        </div>
                                    }}>
                                            <Button className='tooltip-width' icon='icon-information-outline' kind='text'></Button>
                                            </Tooltip>
                                        <Switch 
                                        disabled={
                                            getGroupData()?.interest_allowance_alt_calc && 
                                            (
                                                processDateJS(getGroupData()?.interest_allowance_alt_calc_year)?.isBefore(processDateJS(period_start)) ||
                                                processDateJS(getGroupData()?.interest_allowance_alt_calc_year)?.isAfter(processDateJS(period_end))
                                            )
                                            }
                                        onChange={(e)=>{setIrrevocableElections(state=>({
                                            ...state,
                                            alternativeCalculation:{
                                                ...state.alternativeCalculation,
                                                switch:e,
                                                date:period_end
                                            }
                                        }))}}
                                        className='col-1' showIndicator checked={irrevocableElections?.alternativeCalculation?.switch} />
                                        <Input className='col' 
                                        disabled={
                                            getGroupData()?.interest_allowance_alt_calc && 
                                            (
                                                processDateJS(getGroupData()?.interest_allowance_alt_calc_year)?.isBefore(processDateJS(period_start)) ||
                                                processDateJS(getGroupData()?.interest_allowance_alt_calc_year)?.isAfter(processDateJS(period_end))
                                            )
                                            }
                                        onChange={(e)=>{setIrrevocableElections(state=>({
                                            ...state,
                                            alternativeCalculation:{
                                                ...state.alternativeCalculation,
                                                comment:e
                                            }
                                        }))}}
                                        title='Comment' value={irrevocableElections?.alternativeCalculation?.comment} />
                                    </div>
                                    <div className='row'>
                                        <p className='col-4'>Interest allowance (consolidated partnership) election</p>
                                        <Tooltip className='' content={()=> {
                                        return <div>
                                            <p>This election can only be made where a reporting company has been appointed.</p>
                                            <p>The election must specify one or more consolidated partnerships of the worldwide group. It is possible to make subsequent elections specifying one or more additional consolidated partnerships.</p>
                                            <p>Where the election applies, the effect is that, for the purposes of computing ANGIE, QNGIE and Group-EBITDA, adjustments are made such that the specified partnership is treated as if it were accounted for using the equity method.</p>
                                            <p>Refer to s430 TIOPA 2010 and Paragraph 18, Schedule 7A, TIOPA 2010 for further details.</p>
                                        </div>
                                    }}>
                                        <Button className='tooltip-width' icon='icon-information-outline' kind='text'></Button>
                                        </Tooltip>
                                        <Switch 
                                        disabled={
                                            getGroupData()?.interest_allowance_consolidated_partnership && 
                                            (
                                                processDateJS(getGroupData()?.interest_allowance_consolidated_partnership_year)?.isBefore(processDateJS(period_start)) ||
                                                processDateJS(getGroupData()?.interest_allowance_consolidated_partnership_year)?.isAfter(processDateJS(period_end))
                                            )
                                            }
                                        onChange={(e)=>{setIrrevocableElections(state=>({
                                            ...state,
                                            consolidatedPartnership:{
                                                ...state.consolidatedPartnership,
                                                switch:e,
                                                date:period_end
                                            }
                                        }))}}
                                        className='col-1' showIndicator checked={irrevocableElections?.consolidatedPartnership?.switch} />
                                        <Input
                                            className='col'
                                            disabled={
                                                getGroupData()?.interest_allowance_consolidated_partnership && 
                                                (
                                                    processDateJS(getGroupData()?.interest_allowance_consolidated_partnership_year)?.isBefore(processDateJS(period_start)) ||
                                                    processDateJS(getGroupData()?.interest_allowance_consolidated_partnership_year)?.isAfter(processDateJS(period_end))
                                                )
                                                }
                                            title='Comment'
                                            value={irrevocableElections?.consolidatedPartnership?.comment}
                                            onChange={(e)=>{setIrrevocableElections(state=>({
                                                ...state,
                                                consolidatedPartnership:{
                                                    ...state.consolidatedPartnership,
                                                    comment:e
                                                }
                                            }))}}
                                        />
                                    </div>
                        </Panel>


                        <Panel className='mt-2' title='Elections altering the period of account or deemed financial statements of the worldwide group'>
                            <p className='mt-2'>The group data for the CIR calculations is based on the IAS consolidated financial statements of the Worldwide Group for the period of account.</p>
                            <p className='mt-2'>Where no appropriate consolidated financial statements are prepared for the group, various provisions in s481 - s489 TIOPA 2010 apply to deem financial statements to be prepared for the purposes of the CIR calculations. These sections include elections to alter the deeming in certain cicumstances. Where these elections have been made, this can be recorded here. Recording the elections here does not alter any of the calculations undertaken by the CIR Analyser - they just potentially impact the amounts you should include as data input for NGIE, ANGIE, QNGIE and Group-EBITDA above.</p>

                            <div className='d-flex flex-row gap-1 '>
                                <p className='col-4'>Election not to treat consolidated IAS financial statements as deemed to be drawn up for the relevant period:</p>
                                <Tooltip className='' content={()=> {
                                        return <div>
                                            <p>This election can be made where the ultimate parent prepares solo entity financial statements for a period, but no group consolidated financial statements are prepared.</p>
                                            <p>In the absence of the election, s484 TIOPA 2010 deems consolidated financial statements to be drawn up under International Accounting Standards (IAS) in respect of the same period as the financial statements of the ultimate parent. Where this election, under s484(3) TIOPA 2010, is made s484 TIOPA 2010 no longer applies and s485 TIOPA 2010 applies instead. In conjunction with an election under s486 TIOPA 2010, this can provide greater flexibility in respect of the Period of Account of the group.</p>
                                            <p>An election under s484 TIOPA 2010 is irrevocable, applies to financial statements in respect of periods ending on or after the date specified in the election, and must be made before that specified date. Further details can be found in s484 TIOPA 2010. </p>
                                        </div>
                                    }}>
                                    <Button className='tooltip-width' icon='icon-information-outline' kind='text' />
                                </Tooltip>
                                <Switch className='col' showIndicator defaultChecked={electionConsolidatedIAS} value={electionConsolidatedIAS} onChange={(value) => {
                                    if (!value) {
                                        setElectionConsolidatedIASDate(undefined);
                                        setElectionConsolidatedIASLink('');

                                        const validators = recordedElectionsValidators;
                                        validators.s484 = true;
                                        setRecordedElectionsValidators(validators);
                                    }
                                    setElectionConsolidatedIAS(value)
                                }} />
                                <CalendarPicker 
                                    disabled={!electionConsolidatedIAS} 
                                    value={electionConsolidatedIASDate} 
                                    onChange={(value, isValid) => {
                                        const validators = recordedElectionsValidators;
                                        validators.s484 = isValid;
                                        setRecordedElectionsValidators(validators);
                                        setElectionConsolidatedIASDate(value ? processDate(value) : undefined);
                                    }} 
                                    className='col' 
                                    fieldTitle='Date of election (optional)' 
                                    format='DD/MM/YYYY' placeholder='dd/mm/yyyy'
                                    maxDate={electionConsolidatedIASMaxDate}
                                    />
                                <Input disabled={!electionConsolidatedIAS} value={electionConsolidatedIASLink} onChange={(value) => { setElectionConsolidatedIASLink(value) }} className='col-4' title='Link to election (optional)' />
                            </div>

                            <div className='d-flex flex-row gap-1 '>
                                <p className='col-4'>Election to deemed period of account where no actual financial statements drawn up:</p>
                                <Tooltip className='' content={()=> {
                                        return <div>
                                            <p>An election can be made under s486 TIOPA 2010 where financial statements would be deemed to be prepared under s485 TIOPA 2010. The effect of the election is to allow the group to specify the end date for which the group is deemed to draw up financial statements (i.e. the end date of the period of account). </p>
                                            <p>An election under s486 TIOPA 2010 is irrevocable, must be made before the specified end day, and prevents another election under the same section being made for another three years. Further details can be found in s486 TIOPA 2010.
                                            </p>
                                        </div>
                                    }}>
                                    <Button className='tooltip-width' icon='icon-information-outline' kind='text' />
                                </Tooltip>
                                <Switch className='col' showIndicator defaultChecked={electionNoStatementsDrawnUp} value={electionNoStatementsDrawnUp} onChange={(value) => {
                                    if (!value) {
                                        setElectionNoStatementsDrawnUpDate(undefined);
                                        setElectionNoStatementsDrawnUpLink('');

                                        const validators = recordedElectionsValidators;
                                        validators.s486 = true;
                                        setRecordedElectionsValidators(validators);
                                    }
                                    setElectionNoStatementsDrawnUp(value)
                                }} />
                               <CalendarPicker 
                                    disabled={!electionNoStatementsDrawnUp} 
                                    value={electionNoStatementsDrawnUpDate} 
                                    onChange={(value, isValid) => {
                                        const validators = recordedElectionsValidators;
                                        validators.s486 = isValid;
                                        setElectionNoStatementsDrawnUpDate(value ? processDate(value) : undefined)
                                        setRecordedElectionsValidators(validators);
                                    }} 
                                    className='col' 
                                    fieldTitle='Date of election (optional)' 
                                    format='DD/MM/YYYY' placeholder='dd/mm/yyyy'
                                    maxDate={electionNoStatementsDrawnUpMaxDate}
                                />
                               <Input disabled={!electionNoStatementsDrawnUp} value={electionNoStatementsDrawnUpLink} onChange={(value) => setElectionNoStatementsDrawnUpLink(value)}  className='col-4' title='Link to election (optional)' />
  

                            </div>
                        </Panel>


                        <div className='container-fluid mt-4'>
                            <div className='d-flex justify-content-between align-items-center'> 
                                <div>
                                    <BackButton disabled={isPosting}/>
                                </div>
                                <div className='d-flex gap-2'>
                                    <Button hiddenInReadonly loading={isPosting} disabled={isError} onClick={() => save()}>Save</Button>
                                    <NextButton loading={isPosting} disabled={isError} preNavigation={save} />
                                </div>
                            </div>
                        </div>

                    </>
                )
            }
        </div>
    );
};

export default GroupData;