import { useEffect, useState, useCallback, useContext, useRef } from "react";
import "@glideapps/glide-data-grid/dist/index.css";
import {
    GridCellKind,
} from "@glideapps/glide-data-grid";
import dayjs from "dayjs";
import { Panel, Loading } from "@appkit4/react-components"; 
import { Button, Input, DataEditor } from "../../components/ReadonlyAwareInputs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import utc from 'dayjs/plugin/utc';
import toast from "react-hot-toast";
import { getGroupData, setGroupData, getGroupName, getSelectedPeriod, getFormattedSelectedPeriod, getPoaIndex } from '../../services/GroupContext';
import { useNavigate } from "react-router-dom";
import { BackButton, NextButton, ProgressNav } from '../../components/ProgressNav';
import sqlService from '../../services/sqldatabase/sqldatabase.service'
import { getBFDValues, metrics } from "../../services/calculations/EntityCalculations";
import { AuthContext } from "../../services/AuthProvider";
import { processDate, processDateJS } from "../../utils/dateProcessor";
import { get_company_data } from "../../services/dredger/dredger.service";
import { is_overlapping_cap } from "../../utils/PeriodUtils";
import { addCharge } from "../../services/Charging.service";
import { generateCAPId } from "../../utils/capProcessor";
import CompanyPanel from "../../components/CompanyPanel";
import CustomTooltip from "../../components/customTooltip/customTooltip";
import ReadonlyBanner from "../ReadonlyBanner";
import { applyInclusionFactorAdjustments, carryOverAdjustedCaps, getCompanyAPsforPoA } from "../../utils/adjustedCapsUtil";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc)

const EntityInput = () => {

    const [isLoading, setLoading] = useState(true);
    const selectedPeriod = getSelectedPeriod();
    const {period_start, period_end} = selectedPeriod;
    const auth = useContext(AuthContext)
    const [data, set_data] = useState([])
    const [header_columns, set_header_columns] = useState([]);
    const [is_refreshing, set_is_refreshing] = useState(false);
    const [isPosting, setIsPosting] = useState(false);

    const [wbsCode, setWbsCode] = useState("");
    const [wbsCodeError, setWbsCodeError] = useState(false);

    const currentRow = useRef();
    const [toolTipInfo, setToolTipInfo] = useState({})

    const navigate = useNavigate()

    useEffect(() => {
        set_data([])

        // If the period of account has not been set properly, let's return now. 
        if (!selectedPeriod || !period_start || !period_end) {
            return;
        }

        const saved_data = getGroupData();
        if (!saved_data) {
            toast.error('Failed to fetch the Group being edited')
            return;
        }

        // Let's find all the company accounting periods that fall within this given Group PoA
        const company_aps = getCompanyAPsforPoA(saved_data?.company_accounting_periods, getSelectedPeriod());

        const columns = company_aps?.map(cap => {
            delete cap['unmapped_fields']

            const companies = saved_data?.companies?.filter(c => cap?.company_ID === c?.company_ID);
            if (!companies || companies?.length <= 0) {
                toast.error('Failed to find company with ID: ', cap?.company_ID)
                return {};
            }

            const company_name = companies[0].company_name;
            const poa = saved_data?.periods_of_account?.find(poa => processDateJS(poa?.period_start)?.isSame(processDateJS(period_start)) && processDateJS(poa?.period_end)?.isSame(processDateJS(period_end)))
            // get initial BFD values and add them to the table
            const cap_data = getBFDValues(saved_data, poa, cap);

            return {
                ...cap_data,
                company_ID: cap?.company_ID,
                company_name: company_name,
                start_date: processDate(cap?.start_date),
                end_date: processDate(cap?.end_date),
                currency: cap?.currency || 'GBP',
                fx_rate: cap?.fx_rate || 1,
                from_onesource: cap?.from_onesource || false,
            }
        });

        columns.sort((a, b) => {
            const nameComparison = a?.company_name?.localeCompare(b?.company_name);
            return (nameComparison===0) ? (processDate(a.start_date) -  processDate(b.start_date) ) : nameComparison;
        });
        set_data(columns);

        let header_columns = [{ title: "Metric Name", width: Math.max(300, window?.innerWidth / 3) }];
        columns?.forEach(company => {
            header_columns?.push({ title: company?.company_name, width: 200 })
        })

        set_header_columns(header_columns);

        setWbsCode(saved_data?.wbsCode || "");

        setLoading(false);
    }, [])

    const ensureBoolean = (value) => {
        if (typeof value === 'boolean') {
            return value;
        }

        if (typeof value === 'number') {
            return value !== 0;
        }

        if (typeof value === 'string') {
            const lowercased = value?.toLowerCase();
            if (lowercased === 'true' || lowercased === '1') { return true; }
            if (lowercased === 'false' || lowercased === '0') { return false; }
        }

        return Boolean(value);
    }


    // If fetching data is slow you can use the DataEditor ref to send updates for cells
    // once data is loaded.
    const fetchData = useCallback(([col, row]) => {
        const input = metrics;
        const metric = input[row];
        if (metric?.type === 'blank') {
            return {
                kind: GridCellKind?.Text,
                allowOverlay: false,
                data: " ",
                displayData: " ",
                span: [0, data?.length]
            };
        }

        if (metric?.type === 'header') {
            return {
                kind: GridCellKind?.Text,
                allowOverlay: false,
                data: metric?.display_name,
                displayData: metric?.display_name,
                span: [0, data?.length],
                themeOverride: {
                    ...(metric?.style === 'bold' && { baseFontStyle: '600 15px' }),
                    bgCell: "#F4F3F7"
                }
            };
        }

        if (metric?.type === 'subheader' || metric?.type === 'note') {
            return {
                kind: GridCellKind?.Text,
                allowOverlay: false,
                data: metric?.display_name,
                displayData: metric?.display_name,
                span: [0, data?.length],
                themeOverride: {
                    ...(metric?.style === 'bold' && { baseFontStyle: '600 15px' }),
                    ...(!metric?.editable ? { bgCell: "#ffd5b7"} : { bgCell: "#FFFFFF"}),
                    bgCell: "#FFFFFF"
                }
            };
        }

        // Col 0 is the metric name
        if (col === 0) {
            return {
                kind: GridCellKind?.Text,
                data: metric?.display_name,
                allowOverlay: false,
                displayData: metric?.display_name + (metric?.tooltipMessage ? ' 🛈' : ''),
                allowWrapping: true,
                themeOverride: {
                    ...(metric?.style === 'bold' && { baseFontStyle: '600 15px' }),
                    ...(!metric?.editable ? { bgCell: "#ffd5b7"} : { bgCell: "#FFFFFF"}),
                    bgCell: "#ffd5b7"
                }
            };
        }

        const company = data[col - 1];

        let cellType = GridCellKind?.Text;
        let value = company[metric?.metric_id];
        let displayValue = value ? String(value) : '';
        let editable = metric?.hasOwnProperty('editable') ? metric?.editable : true;

        if (metric?.type === 'date') {
            value = displayValue = value ? processDateJS(value)?.format('DD/MM/YYYY') : '';
        } else if (metric?.metric_id === 'fx_rate') {
            editable = company['currency'] === 'GBP' ? false : true;
        } else if (metric?.type === 'number') {
            cellType = GridCellKind?.Number;
            if (isNaN(value) || !value) {
                value = 0;
            }

            displayValue = value?.toLocaleString()

        } else if (metric?.type === 'calculation') {
            cellType = GridCellKind.Number;
            value = metric?.calculation(company);
            displayValue = value?.toLocaleString();
            editable = false;

        } else if (metric?.type === 'boolean') {
            cellType = GridCellKind?.Boolean
            value = ensureBoolean(value);
            displayValue = String?.valueOf(value)
        }

        // Do not allow to edit data cells if onesource_sync is true (unless it's the onesource_sync itself - you should be able to edit this whenever)
        let edit_data_lock = metric?.metric_id !== 'onesource_sync' && company['onesource_sync'] && metric?.onesourcedredged === true;

        // Check if the cap was used in previous periods, disable any editing on this page
        if (is_overlapping_cap(company?.start_date, period_start, getGroupData()?.model_first_period_start)) {
            edit_data_lock = true;
        }

        const read_only = metric?.hasOwnProperty('read_only') ? metric?.read_only(company) : false;

        metric.editable = editable;
        return {
            kind: cellType,
            data: value,
            allowOverlay: !edit_data_lock && editable,
            displayData: displayValue,
            readonly: read_only,

            themeOverride: {
                ...(metric?.style === 'bold' && { baseFontStyle: '600 15px' }),
                ...(!edit_data_lock && editable ? { bgCell: "#FFFFFF"} : { bgCell: "#ffd5b7"})
            }
        };
    }, [data]);

    const [rowGrouping, setRowGrouping] = useState(() => ({
        groups: [{
            headerIndex: 0,
            isCollapsed: false
        }, {
            headerIndex: 10,
            isCollapsed: true,
            subGroups: [{
                headerIndex: 15,
                isCollapsed: false
            }, {
                headerIndex: 20,
                isCollapsed: false
            }]
        }, {
            headerIndex: 30,
            isCollapsed: false
        }],
        navigationBehavior: "block",
        selectionBehavior: "block-spanning",
        themeOverride: {
            bgCell: "rgba(0, 100, 255, 0.1)"
        }
    }));

    if (isLoading) {
        <Loading
            loadingType="linear"
            indeterminate={true}
            compact={false}
            className="page-loader"
        />
        return
    }

    if (data?.length <= 0) {
        toast.error('There are no company accounting periods within this Group PoA');
        navigate('/app/companyap');
    }

    const refresh_one_source = async () => {
        if (wbsCodeError || !wbsCode) {
            toast.error('Please enter a valid WBS code');
            return;
        }

        const onesource_caps = data?.filter(cap => cap?.onesource_sync);
        if (onesource_caps?.length === 0) {
            toast.error('No company accounting periods have been selected to refresh from OneSource')
            return;
        }

        const userEmail = auth?.getEmail();

        set_is_refreshing(true);

        const chargePromise = addCharge("variable", onesource_caps, wbsCode, selectedPeriod, getGroupData(), null);

        const refreshPromise = get_company_data(onesource_caps, period_start, period_end, userEmail)
        .then( ({ company_accounting_periods }) => {
            // Create a Map for quick lookups so we don't have to keep looping through to find the correct index
            const lookup_map = new Map(
                onesource_caps?.map(item => [
                    `${item?.company_ID}-${item?.start_date}-${item?.end_date}`,
                    { item, index: data?.indexOf(item) }
                ])
            );

            const companies_failed_refresh = [];

            company_accounting_periods?.forEach((new_cap) => {
                const key = `${new_cap?.company_ID}-${new_cap?.start_date}-${new_cap?.end_date}`;
                const oldEntry = lookup_map?.get(key);

                if (!oldEntry) {
                    console?.error(`Could not find matching entry for company ${new_cap?.company_ID} with start date ${new_cap?.start_date} and end date ${new_cap?.end_date}`);
                    companies_failed_refresh?.push(new_cap?.company_ID);
                    return;
                }

                const { item: old_cap, index: dataIndex } = oldEntry;

                const old_cap_start_date = processDateJS(old_cap?.start_date);
                const new_cap_start_date = processDateJS(new_cap?.start_date);
                const old_cap_end_date = processDateJS(old_cap?.end_date);
                const new_cap_end_date = processDateJS(new_cap?.end_date);

                // Ensure the new cap dates are the same as the old cap
                if (!old_cap_start_date?.isSame(new_cap_start_date, 'day') || !old_cap_end_date?.isSame(new_cap_end_date, 'day')) {
                    companies_failed_refresh?.push(old_cap?.company_ID);
                    return;
                }

                data[dataIndex] = {...old_cap, ...new_cap};
            });

            if (companies_failed_refresh?.length > 0) {
                const companies = getGroupData()?.companies;
                const company_names = companies_failed_refresh?.map(id => { companies?.find(c => c?.company_ID === id) }).join('\n');

                toast.error(`Failed to refresh from OneSource for the following companies:\n${company_names}`);
            }
        })
 
        toast.promise( Promise.all([ chargePromise, refreshPromise ]), {
            loading: "Refreshing...",
            error: err => err?.message || typeof err === "string" ? err : "Failed to refresh form OneSource",
            success: "Success",
        })

        set_is_refreshing(false);
    }

    const SUBTOTAL_METRIC_IDS = ['ntlr_subtotal', 'ntdc_subtotal', 'tlr_subtotal', 'tdc_subtotal', 'ofa_subtotal', 'cte_subtotal', 'tax_ebitda', 'bfd_subtotal'];
    const save = () => {
        let savedData = getGroupData();
        if (!savedData) {
            throw 'Something went wrong with fetching your cached data';
        }

        setIsPosting(true);

        const subtotal_metrics = metrics?.filter(m => SUBTOTAL_METRIC_IDS?.includes(m?.metric_id));

        const newAdjustedCaps = data?.map(cap => {
            let cap_copy = { ...cap }
            subtotal_metrics?.forEach(metric => {
                let value = 0;
                if (metric?.calculation) {
                    value = metric?.calculation(cap);
                } else {
                    value = cap_copy[metric?.metric_id];
                }

                cap_copy[metric?.metric_id] = value;
            })

            return cap_copy;
        })

        if ( ! wbsCodeError ){
            savedData.wbsCode = wbsCode;
        }

        savedData.company_accounting_periods = savedData?.company_accounting_periods?.map( existingCap => {
            const cap_with_subtotals = newAdjustedCaps.find( new_cap => generateCAPId(new_cap) === generateCAPId(existingCap))
            if (cap_with_subtotals){
                return {...existingCap, ...cap_with_subtotals};
            }
            return existingCap;
        })
        
        savedData.periods_of_account[getPoaIndex()].adjusted_caps = savedData?.periods_of_account[getPoaIndex()]?.adjusted_caps?.map(adjCap => {
            const correspondingCompanyAP = savedData.company_accounting_periods.find(cap => generateCAPId(cap) === generateCAPId(adjCap));
            return applyInclusionFactorAdjustments(adjCap, correspondingCompanyAP);
        })
        
        if (auth?.isReadOnlyUser(getGroupName())) {
            setGroupData(savedData);
            return;
        }

        const promise = sqlService?.postResponse(savedData);
        return toast.promise(promise, {
            loading: `Saving Entity AP data for period: ${getFormattedSelectedPeriod()}`,
            success: () => {
                setGroupData(savedData);
                setIsPosting(false);
                return `Saved Entity AP data for period: ${getFormattedSelectedPeriod()}`
            },
            error: () => {
                setIsPosting(false);
                return 'Failed to save Entity AP data!';
            },
        });
    }
    const handleColumnResize = (column, newSize) => {
        set_header_columns(prevColumns => {
            const temp = prevColumns?.map((col, index) =>
                col?.title === column?.title ? { ...col, width: newSize } : col
            )
            return temp;
        }
        )
    };

    const onItemHovered = (args) => {
        const cellType = args.kind;
        const {location: [column, row]} = args;
        currentRow.current = row

        if(cellType === "out-of-bounds" || column !== 0 || !metrics[row]?.tooltipMessage) {
            setToolTipInfo({message: "", positionX: 0, positionY: 0})
        }

        if(cellType === "cell" && column === 0 && metrics[row]?.tooltipMessage){
            setTimeout(() => {
                if(row === currentRow.current){
                    setToolTipInfo({message: metrics[row]?.tooltipMessage, positionX: args.bounds.x, positionY: args.bounds.y})
                }
            } ,1000)
        }

    }

    return (
        <>
            <CustomTooltip data={toolTipInfo}/>
            {auth?.isReadOnlyUser(getGroupName()) &&
                <ReadonlyBanner />
            }
            <CompanyPanel />

            <Panel title="OneSource data extraction" className="ap-mb-spacing-4 mt-4">
                <p>
                In order to extract data from OneSource, a usage charge will be applied - please provide the market team WBS code below. It will not be possible to extract the OneSource data until this is provided.
                </p>
                <div className="row items-center">
                    <Input
                        title="WBS Code"
                        className="col-2"
                        value={wbsCode}
                        error={wbsCodeError}
                        onChange={(value) => {
                            value= String(value).trim().slice(0, 11);

                            setWbsCodeError( ! String(value).match(/^\d{11}$|^$/));

                            setWbsCode(value);
                        }}
                    />
                    <Button
                        className="col-2"
                        onClick={refresh_one_source}
                        disabled={is_refreshing}
                    >
                        Refresh OneSource
                    </Button>

                </div>
            </Panel>

            <div id="portal" style={{ position: 'fixed', left: 0, top: 0, zIndex: 9999 }} />
            {selectedPeriod && data?.length > 0 && (<>
                <div className='container-fluid mt-4'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <BackButton disabled={isPosting} />
                        </div>
                        <div className='d-flex gap-2'>
                            <Button hiddenInReadonly loading={isPosting} onClick={save}>Save</Button>
                            <NextButton loading={isPosting} preNavigation={save} />
                        </div>
                    </div>
                </div>

                <DataEditor
                    rowMarkers
                    width={'95vw'}
                    height={'90vh'}
                    smoothScrollX
                    smoothScrollY
                    freezeColumns={1}
                    columns={header_columns}
                    onColumnResize={handleColumnResize}
                    getCellContent={fetchData}
                    rows={metrics?.length}
                    rowGrouping={rowGrouping}
                    onItemHovered={onItemHovered}
                    onCellEdited={([column, row], newValue) => {
                        const metric = metrics[row];
                        if (!metric) {
                            return;
                        }

                        let value = newValue?.data;
                        if (metric?.type === 'date') {
                            const dayjsDate = processDateJS(value);
                            if (!dayjsDate?.isValid()) {
                                toast.error('Date must be entered in dd/mm/yyyy format')
                                return
                            }

                            value = dayjsDate?.toDate()
                        }

                        // // Siva: TODO If the business team ask to implement column reordering on this page, we will need to update this to search by compnay name 
                        // // This is because the order in the data variable will be different 

                        // // Column 0 is the metric name
                        // // Companies always start from index 1
                        const companyIndex = column - 1;
                        let localData = data;
                        const company = localData[companyIndex];
                        company[metric.metric_id] = value;
                        if (metric?.metric_id === 'currency' && value === 'GBP') {
                            company['fx_rate'] = 1;
                        }

                        localData[companyIndex] = company;
                        set_data(localData);
                    }}

                    getRowThemeOverride={i => {
                        const metric = metrics[i];
                        if (metric) {
                            if (metric?.type === 'blank') {
                                return {
                                    bgCell: "#F4F3F7"
                                }
                            }

                            if (metric?.type === 'header') {
                                return {
                                    baseFontStyle: "600 18px",
                                }
                            }

                            if (metric?.type === 'subheader') {
                                return {
                                    baseFontStyle: "600 15px",
                                }
                            }

                            if (metric?.type === 'note') {
                                return {
                                    baseFontStyle: "600 12px",
                                }
                            }
                        }
                    }}
                />
                <div style={{ padding: "1em 1em 1em 0em" }}>

                    <div className='container-fluid mt-4'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <BackButton disabled={isPosting} />
                            </div>
                            <div className='d-flex gap-2'>
                                <Button hiddenInReadonly loading={isPosting} onClick={save}>Save</Button>
                                <NextButton loading={isPosting} preNavigation={save} />
                            </div>
                        </div>
                    </div>

                </div>
                <Button kind='text' onClick={() => {
                    let localData = data;
                    const skippableTypes = ['blank', 'header', 'subheader', 'note'];

                    for (let i = 0; i < localData?.length; i++) {
                        let company = localData[i];
                        for (const metric of metrics) {
                            if (skippableTypes?.includes(metric?.type)) {
                                continue;
                            }

                            const value = company[metric?.metric_id];
                            if (value) {
                                continue;
                            }

                            let randomValue;
                            if (metric?.type === 'number') {
                                randomValue = Math.floor(Math.random() * 1001);
                            } else if (metric?.type === 'boolean') {
                                randomValue = Math.random() < 0.5;
                            } else if (metric?.type === 'date') {
                                randomValue = new Date()
                            }

                            company[metric?.metric_id] = randomValue;
                        }

                        localData[i] = company;
                    }

                    set_data(localData);
                }}>
                    Fill Cells with Fake Data (for testing purposes only)
                </Button>
            </>
        )}
        </>
    );
}

export default EntityInput;
