import { Panel, Loading } from '@appkit4/react-components'; 
import { Button, DataEditor } from "../../components/ReadonlyAwareInputs";
import { useEffect, useState, useCallback, useContext } from 'react';
import { BackButton, NextButton } from '../../components/ProgressNav';
import { getGroupData, setGroupData, getSelectedPeriod, getGroupName, getPoaIndex, getFormattedSelectedPeriod } from '../../services/GroupContext';
import toast from 'react-hot-toast';
import "@glideapps/glide-data-grid/dist/index.css";
import {
    GridCellKind,
} from "@glideapps/glide-data-grid";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { AuthContext } from '../../services/AuthProvider';
import { processDate, processDateJS } from '../../utils/dateProcessor';
import { getBFDValues } from '../../services/calculations/EntityCalculations';
import sqldatabaseService from '../../services/sqldatabase/sqldatabase.service';
import { generateCAPId } from '../../utils/capProcessor';
import { adjustedCapCustomCondition } from './editBFDUtil';
import { setBFDADJasNegativeBFD } from '../../utils/adjustedCapsUtil';
import CompanyPanel from '../../components/CompanyPanel';
import { getLightOrangeDataEditorColor } from '../../utils/stylingUtil';
dayjs.extend(utc)

const EditBFD = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isPosting, setIsPosting] = useState(false);
    const [isError, setIsError] = useState('')
    const [firstCapPerCompany, setFirstCapPerCompany] = useState([]);

    const selectedPeriod = getSelectedPeriod();
    const {period_start, period_end} = selectedPeriod;

    const auth = useContext(AuthContext)
    const savedData = getGroupData();
    const [savedCAPData, setSavedCapData] = useState([])
    const [table_data, set_table_data] = useState([]);
    const [table_columns, set_table_columns] = useState([]);
    const table_rows = [
        { metric_id: "start_date", display_name: "Accounting Period Start Date", mandatory: true, type: 'date', editable: false},
        { metric_id: "end_date", display_name: "Accounting Period End Date", mandatory: true, type: 'date', editable: false},

        { type: 'subheader', display_name: "Brought forward disallowance balances" },
        { metric_id: "bfd_non_trading_loan_relationships", display_name: "Non-trading loan relationship amounts", mandatory: true, type: 'number', editable: true, editable_group: 'bfd' },
        { metric_id: "bfd_non_trading_derivatives", display_name: "Non-trading derivative amounts", mandatory: true, type: 'number', editable: true, editable_group: 'bfd' },
        { metric_id: "bfd_trading_loan_relationships", display_name: "Trading loan relationship amounts", mandatory: true, type: 'number', editable: true, editable_group: 'bfd' },
        { metric_id: "bfd_trading_derivatives", display_name: "Trading derivative amounts", mandatory: true, type: 'number', editable: true, editable_group: 'bfd' },
        { metric_id: "bfd_other_financing", display_name: "Other financing amounts", mandatory: true, type: 'number', editable: true, editable_group: 'bfd' },
        { metric_id: "bfd_subtotal", display_name: "Total", type: 'number', editable: false },

        { type: 'subheader', display_name: "Brought forward disallowance adjustments (negative)" },
        { metric_id: "bfdadj_non_trading_loan_relationships", display_name: "Non-trading loan relationship adjustments", mandatory: true, type: 'number', editable: true, editable_group: 'bfdadj' },
        { metric_id: "bfdadj_non_trading_derivatives", display_name: "Non-trading derivative adjustments", mandatory: true, type: 'number', editable: true, editable_group: 'bfdadj' },
        { metric_id: "bfdadj_trading_loan_relationships", display_name: "Trading loan relationship adjustments", mandatory: true, type: 'number', editable: true, editable_group: 'bfdadj' },
        { metric_id: "bfdadj_trading_derivatives", display_name: "Trading derivative adjustments", mandatory: true, type: 'number', editable: true, editable_group: 'bfdadj' },
        { metric_id: "bfdadj_other_financing", display_name: "Other financing adjustments", mandatory: true, type: 'number', editable: true, editable_group: 'bfdadj' },
        { metric_id: "bfdadj_subtotal", display_name: "Total adjustments", type: 'number', editable: false },
    ];
    
    const getFirstCapPerCompany = (company_aps) => {
        // Step 1: Group the entries by company_ID
        const companyGrouped = company_aps.reduce((acc, current) => {
            const companyID = current.company_ID;
            // Initialize array if it doesn't exist
            if (!acc[companyID]) {
                acc[companyID] = [];
            }
            // Add the current entry to the company's group
            acc[companyID].push(current);
            return acc;
        }, {});
    
        // Step 2: Find the earliest creation_date for each company
        const result = Object.keys(companyGrouped).map(companyID => {
            const earliestEntry = companyGrouped[companyID].reduce((earliest, current) => 
                processDateJS(current?.creation_date)?.isBefore(processDateJS(earliest?.creation_date))
                    ? current
                    : earliest
            );
    
            // Step 3: Generate CAP ID for the earliest entry
            const capID = generateCAPId(earliestEntry);
    
            // Step 4: Return an object with company_ID and capID
            return {
                company_ID: companyID,
                capID: capID
            };
        });
    
        return result;
    };
    

    // get calculated values
    useEffect(() => {
        if (period_start && period_end) {
            const adjustedCaps = getSelectedPeriod()?.adjusted_caps
            const firstCreatedCapIDs = getFirstCapPerCompany(adjustedCaps);
            
            const firstCapLookup = firstCreatedCapIDs.reduce((acc, { company_ID, capID }) => {
                acc[company_ID] = capID;
                return acc;
            }, {});
        
            const calculated_adjusted_caps = adjustedCaps?.map(cap => {
                const companies = savedData?.companies?.filter(c => cap?.company_ID === c?.company_ID);
                if (!companies || companies?.length <= 0) {
                    toast.error('Failed to find company with ID: ', cap?.company_ID)
                    return {};
                }
                
                const company_name = companies[0]?.company_name;
                const poa = savedData?.periods_of_account?.find(poa => processDateJS(poa?.period_start)?.isSame(processDateJS(period_start)) && processDateJS(poa?.period_end)?.isSame(processDateJS(period_end)))
                // get initial BFD values and add them to the table
                let cap_data = cap;
                const capID = generateCAPId(cap);
                const isFirstCreated = capID === firstCapLookup[cap_data.company_ID];
                if(!isFirstCreated) {
                    cap_data = getBFDValues(savedData, poa, cap)
                }

                const adjusted_cap_custom_condition = adjustedCapCustomCondition(cap_data, savedData, period_start);
                if(adjusted_cap_custom_condition) {
                    setBFDADJasNegativeBFD(cap_data, true);
                }
                return {
                    ...cap_data,
                    company_ID: cap?.company_ID,
                    company_name: company_name,
                    start_date: processDate(cap?.start_date),
                    end_date: processDate(cap?.end_date),
                    currency: cap?.currency || 'GBP',
                    fx_rate: cap?.fx_rate || 1,
                    isFirstCreated,
                    adjusted_cap_custom_condition
                }
            });

            calculated_adjusted_caps?.sort((a, b) => {
                const nameComparison = a?.company_name?.localeCompare(b?.company_name);
                return (nameComparison===0) ? (processDate(a.start_date) -  processDate(b.start_date) ) : nameComparison;
            });
            const poa = savedData?.periods_of_account[getPoaIndex()];
            poa.adjusted_caps = calculated_adjusted_caps;
            setupTableData(calculated_adjusted_caps)
            setSavedCapData(calculated_adjusted_caps);

            if (auth?.isReadOnlyUser(getGroupName())) {
                setIsLoading(false);
                return;
            }

            const promise = sqldatabaseService?.postResponse(savedData);
            toast.promise(promise, {
                loading: 'Calculating Inclusion Period',
                success: (response) => {
                    setGroupData(savedData);
                    setIsLoading(false);
                    return `Inclusion Period Calculations saved to database`
                },
                error: 'Failed to save Inclusion Period Calculations',
            });
        }
        else {
            toast.error(`could not find POA data`, {
                duration: 30 * 1000 // 30s
              })
        }
    }, [])

    const setupTableData = (updatedCAPS) => {
        // set table column names based on caps passed in
        let temp_table_columns = [{ title: "", width: (Math.max(400, window?.innerWidth / 4.5)) }];
        let firstCapPerCompany = []
        updatedCAPS?.forEach((cap, index) => {
            const prevComp = index > 0 ? updatedCAPS[index - 1]?.company_ID : null;
            if (index === 0 || cap?.company_ID !== prevComp) {
                const companyName = savedData.companies?.find((c) => c?.company_ID === cap?.company_ID)['company_name']
                temp_table_columns?.push({ title: companyName, width: (Math.max(300, window?.innerWidth / 5)) })
                firstCapPerCompany.push(cap);
            }
        })
        setFirstCapPerCompany(firstCapPerCompany);
        set_table_columns(temp_table_columns);

        // set table data based on metrics in table_rows array
        let tempTableDataBuilder = [];
        table_rows?.forEach((rowHeader) => {
            let tempRowDataBuilder = [];
            updatedCAPS?.forEach((company, index) => {
                const prevComp = index > 0 ? updatedCAPS[index - 1]?.company_ID : null;
                // only show first ap in period for each company
                if (index === 0 || company?.company_ID !== prevComp) {
                    if (rowHeader?.type?.includes('blank')) {
                        tempRowDataBuilder?.push("")
                    }
                    else if (company?.hasOwnProperty(rowHeader?.metric_id)) {
                        if (rowHeader?.type === 'number') {
                            const value = !isNaN(company[rowHeader?.metric_id]) ? parseInt(company[rowHeader?.metric_id]) : 0;
                            tempRowDataBuilder?.push(value);
                        }
                        else {
                            tempRowDataBuilder?.push(company[rowHeader?.metric_id]);
                        }
                        
                    }
                    else {
                        tempRowDataBuilder?.push(0)
                    }
                }
            })
            tempTableDataBuilder?.push(tempRowDataBuilder);
        })
        set_table_data(tempTableDataBuilder);
    }

    // function to render the table cells
    const renderTable = useCallback(([col, row], rows, data) => {
        const metric = rows[row];
        const regularCellTheme = {bgCell: getLightOrangeDataEditorColor()};
        if (metric?.type === 'blank') {
            return {
                kind: GridCellKind?.Text,
                allowOverlay: false,
                data: " ",
                displayData: " ",
                span: [0, savedData?.length]
            };
        }

        if (metric?.type === 'header' || metric?.type === 'subheader' || metric?.type === 'note') {
            if(col === 0) {
                return {
                    kind: GridCellKind?.Text,
                    allowOverlay: false,
                    data: metric?.display_name,
                    displayData: metric?.display_name,
                    span: [0, savedData?.length],
                    themeOverride: {
                        baseFontStyle: "600 15px",
                        bgCell: "#F4F3F7"
                    }
                };
            } else {
                return {
                    kind: GridCellKind?.Text,
                    allowOverlay: false,
                    data: '',
                    displayData: '',
                    span: [0, savedData?.length],
                    themeOverride: {
                        bgCell: "#F4F3F7"
                    }
                }
            }
        }

        // Col 0 is the metric name
        if (col === 0) {
            return {
                kind: GridCellKind?.Text,
                data: metric?.display_name,
                allowOverlay: false,
                displayData: metric?.display_name,
                allowWrapping: true,
                themeOverride: regularCellTheme
            };
        }

        // set up default values
        let cellType = GridCellKind?.Text;
        let value = data[row][col-1];
        let displayValue = value ? String(value) : '0';
        let editable = metric?.hasOwnProperty('editable') ? metric?.editable : false;
        // check if cap should be editable based on elections
        editable = editable && columnIsEditable(col, data[0][col-1], data[1][col-1], metric['editable_group'])

        // if cell is only editable on selection of a checkbox
        if (metric?.type?.includes('edit_number')) {
            const isEditable = data[3][col-1];
            return {
                kind: GridCellKind?.Number,
                data: value,
                allowOverlay: isEditable,
                displayData: value ? Math?.round(value)?.toLocaleString() : '0',
                themeOverride: {...(!isEditable && regularCellTheme)}
            };
        }
        else if (metric?.type?.includes('date')) {
            value = displayValue = value ? processDateJS(value)?.format('DD/MM/YYYY') : '';
        } else if (metric?.type?.includes('number')) {
            cellType = GridCellKind?.Number;
            value = !isNaN(value) ? Math.round(value).toLocaleString() : 0;
            displayValue = value
        } else if (metric?.type?.includes('boolean')) {
            cellType = GridCellKind?.Boolean
            if (value !== true && value !== false) {
                value = false;
                displayValue = false;
            }
        }
        return {
            kind: cellType,
            data: value,
            allowOverlay: editable,
            displayData: displayValue,
            themeOverride: {...(!editable && regularCellTheme)}
        };
    }, [table_columns, table_data]);

    const validateCell = ([col, row], newValue) => {
        const editKey = table_rows[row]?.metric_id;
        const editType = editKey?.split('_')[0];
        if(editType === 'bfd') {
            return newValue.data >=0;
        } else if(editType === 'bfdadj') {
            let bfdKey = editKey?.split('_');
            bfdKey[0] = 'bfd';
            bfdKey = bfdKey.join('_');
            const bfdValue = savedCAPData[col-1][bfdKey];
            return (
                newValue.data <= 0 &&
                (bfdValue ? newValue.data >= (- bfdValue || 0) : newValue.data === 0)
            );
        }
    }

    const [rowGrouping, setRowGrouping] = useState(() => ({
        groups: [{
            headerIndex: 0,
            isCollapsed: false
        }, {
            headerIndex: 10,
            isCollapsed: true,
            subGroups: [{
                headerIndex: 15,
                isCollapsed: false
            }, {
                headerIndex: 20,
                isCollapsed: false
            }]
        }, {
            headerIndex: 30,
            isCollapsed: false
        }],
        height: 55,
        navigationBehavior: "block",
        selectionBehavior: "block-spanning",
        themeOverride: {
            bgCell: "rgba(0, 100, 255, 0.1)"
        }
    }));

    const columnIsEditable = (col, startDate, ap_end_date, group) => {
        if (table_columns && table_columns?.length > 0 && savedCAPData && savedCAPData?.length > 0) {
            const company_name = table_columns[col]?.title
            const cap = savedCAPData?.find(cap => cap?.company_name === company_name && processDateJS(cap?.start_date)?.isSame(processDateJS(startDate)) && processDateJS(cap?.end_date)?.isSame(processDateJS(ap_end_date)))
            
            if (!cap) return false;
            if (group === 'bfd') return !!cap.isFirstCreated;
            if (group === 'bfdadj') return !!cap.adjusted_cap_custom_condition;

            return !!(processDateJS(period_start)?.isSame(processDateJS(savedData?.model_first_period_start)));
        }
        return false;
    }

    const save_data = () => {
        if (isError) {
            throw 'There were errors in the table';
        }
        setIsPosting(true);
        const all_caps = getGroupData()?.company_accounting_periods || [];
        let newAdjustedCaps = []
        const oldAdjustedCaps = getSelectedPeriod()['adjusted_caps'];
        table_columns?.forEach((c_val, c_index) => {
            if (c_index > 0) {
                let AP = {company_name: c_val?.title}
                table_rows?.forEach((r_val, r_index) => {
                    if (r_val?.metric_id) {
                        AP[r_val.metric_id] = table_data[r_index][c_index - 1];
                    }
                })
                newAdjustedCaps?.push(AP)
            }
        })
        // update values in any edited caps
        const new_caps = all_caps?.map(old_cap => {
            const new_cap = newAdjustedCaps?.find(cap => cap?.company_name === old_cap?.company_name && processDateJS(cap?.start_date)?.isSame(processDateJS(old_cap?.start_date)) && processDateJS(cap?.end_date)?.isSame(processDateJS(old_cap?.end_date)) )
            if (new_cap) {
                return {
                    ...old_cap,
                    'bfd_non_trading_loan_relationships': new_cap?.bfd_non_trading_loan_relationships,
                    'bfd_non_trading_derivatives': new_cap?.bfd_non_trading_derivatives,
                    'bfd_trading_loan_relationships': new_cap?.bfd_trading_loan_relationships,
                    'bfd_trading_derivatives': new_cap?.bfd_trading_derivatives,
                    'bfd_other_financing': new_cap?.bfd_other_financing,
                    'bfd_subtotal': new_cap?.bfd_subtotal,
                    'bfdadj_non_trading_loan_relationships': new_cap?.bfdadj_non_trading_loan_relationships,
                    'bfdadj_non_trading_derivatives': new_cap?.bfdadj_non_trading_derivatives,
                    'bfdadj_trading_loan_relationships': new_cap?.bfdadj_trading_loan_relationships,
                    'bfdadj_trading_derivatives': new_cap?.bfdadj_trading_derivatives,
                    'bfdadj_other_financing': new_cap?.bfdadj_other_financing,
                    'bfdadj_subtotal': new_cap?.bfdadj_subtotal
                }
            }
            return old_cap;
        });
        const newData = {
            ...getGroupData(),
            company_accounting_periods: new_caps
        }
        newData.periods_of_account[getPoaIndex()].adjusted_caps = new_caps;

        if (auth?.isReadOnlyUser(getGroupName())) {
            setGroupData(newData);
            return;
        }

        const promise = sqldatabaseService?.postResponse(newData);
        return toast.promise(promise, {
            loading: 'Saving BFD data...',
            success: () => {
                setGroupData(newData);
                setIsPosting(false);   
                return 'Saved BFD data!'
            },
            error: 'Something went wrong saving BFD data!',
        })
    }

    
    return (
        <div className="ap-container">

                <div className="ap-container mt-4">
                    <CompanyPanel />
                </div>
                <p></p>

            <Panel title="Brought Forward Disallowances" className="ap-mb-spacing-4">
                <p>This page allows for the adjustment of previously disallowed amounts brought forward, where required.</p>
                <p className='err'>{isError}</p>
                <div id="portal" style={{ position: 'fixed', left: 0, top: 0, zIndex: 9999 }} />
                {isLoading ? 
                    <Loading
                        loadingType="linear"
                        indeterminate={true}
                        compact={false}
                        className="page-loader"
                    ></Loading> :
                    <div>
                        <DataEditor
                            columns={table_columns}
                            getCellContent={([col, row]) => renderTable([col, row], table_rows, table_data)}
                            rows={table_rows?.length}
                            rowGrouping={rowGrouping}
                            validateCell={validateCell}
                            freezeColumns={1}
                            smoothScrollX
                            smoothScrollY
                            onCellEdited={([col, row], newValue) => {
                                const metric = table_rows[row];
                                if (!metric) {
                                    return;
                                }
                                let value = newValue?.data;
                                // Column 0 is the metric name
                                // Companies always start from index 1
                                let localData = [...table_data];
                                localData[row][col-1] = !isNaN(value) ? value : 0;
                                // update totals
                                let bfd_total = 0
                                for (let i = 3; i < 9; i++) {
                                    bfd_total += localData[i][col-1] || 0
                                }
                                let bfdadj_total = 0
                                for (let i = 11; i < 17; i++) {
                                    bfdadj_total += localData[i][col-1] || 0
                                }
                                localData[9][col-1] = bfd_total
                                localData[17][col-1] = bfdadj_total
                                set_table_data(localData);
                            }}
                        />
                        <div className='container-fluid mt-4'>
                    <div className='d-flex justify-content-between align-items-center'> 
                        <div>
                            <BackButton disabled={isPosting}/>
                        </div>
                        <div className='d-flex gap-2'>
                            <Button hiddenInReadonly loading={isPosting} onClick={save_data}>Save</Button>
                            <NextButton loading={isPosting} preNavigation={save_data} />
                        </div>
                    </div>
                    </div>
                </div>
                }
            </Panel>
        </div>
    );
};

export default EditBFD;
