// list of fields which need converting between LC / GBP
const cap_GBP_conversion_values = [
    'ntlr_subtotal',
    'ntdc_subtotal',
    'tlr_subtotal',
    'tdc_subtotal',
    'ofa_subtotal',
    'net_tax_interest_expense',
    'tax_ebitda',
    // objects which require .value
    'bfd_non_trading_loan_relationships',
    'bfd_non_trading_derivatives',
    'bfd_trading_loan_relationships',
    'bfd_trading_derivatives',
    'bfd_other_financing',
    'bfd_subtotal',
    'bfdadj_non_trading_loan_relationships',
    'bfdadj_non_trading_derivatives',
    'bfdadj_trading_loan_relationships',
    'bfdadj_trading_derivatives',
    'bfdadj_other_financing',
    'bfdadj_subtotal'
]

const cap_LC_conversion_values = [
    'bfdadj_non_trading_loan_relationships_gbp',
    'bfdadj_non_trading_derivatives_gbp',
    'bfdadj_trading_loan_relationships_gbp',
    'bfdadj_trading_derivatives_gbp',
    'bfdadj_other_financing_amounts_gbp',
    'bfdadj_subtotal_gbp',
    'cpr_non_trading_loan_relationships_gbp',
    'cpr_non_trading_derivatives_gbp',
    'cpr_trading_loan_realtionships_gbp',
    'cpr_trading_derivatives_gbp',
    'cpr_other_financing_amounts_gbp',
    'cpr_total_gbp',
    'cfd_non_trading_loan_relationships_gbp',
    'cfd_non_trading_derivatives_gbp',
    'cfd_trading_loan_relationships_gbp',
    'cfd_trading_derivatives_gbp',
    'cfd_other_financing_amounts_gbp',
    'cpd_non_trading_loan_relationships_gbp',
    'cpd_non_trading_derivatives_gbp',
    'cpd_trading_loan_relationships_gbp',
    'cpd_trading_derivatives_gbp',
    'cpd_other_financing_amounts_gbp',
    'cpd_total_gbp',
]

// list of fields which need adjustment based on period inclusion
const cap_adjusted_values = [
    's407_relief_adjustment_qualifying_infrastructure',
    's407_relief_adjustment_gaap_items',
    'cte_condition_a',
    'cte_condition_b',
    'cte_restrictions_reactivations_included',
    'cte_subtotal',
    's407_tiopa_net_tax_interest_expense',
    's407_tiopa_capital_allowance_charges',
    's407_tiopa_relevant_intangible_debits_credits',
    's407_tiopa_adjustment_intangible_debit_credits',
    's407_tiopa_loan_relationship_deficts_other_periods',
    's407_tiopa_expenses_management_other_periods',
    's407_tiopa_group_relief',
    's407_relief_rd_expenditure_credit',
    's407_relief_rd_additional_relief',
    's407_relief_trading_loss',
    's407_relief_contaminated_land_relief',
    's407_relief_creative_industries',
    's407_relief_charitable_donation',
    's407_relief_patent_box_profits',
    's407_relief_group_relief',
    's407_relief_adjustment_disregarded_regulations',
    's407_relief_long_funding_lease_deduction',
    's407_relief_finance_lease_rentals_receivable',
    's407_relief_long_funding_lessee',
    's407_relief_adjustment_misc_losses',
    'nltr_total_per_source_sheet_for_period',
    'nltr_exclude_exchange_gains_losses',
    'nltr_exclude_impairment_gains_losses',
    'nltr_exclude_tax_interest_income_double_relief',
    'nltr_adjustment_disregard_regulations',
    'nltr_adjustment_amortised_cost_basis',
    'nltr_adjustment_qualifying_election',
    'nltr_exclude_defined_benefit_pension',
    'nltr_bank_expenses',
    'nltr_adjustment_gaap_transition',
    'nltr_adjustment_lease_finance_cost',
    'ntlr_subtotal',
    'ntdc_total_per_source_sheet_for_period',
    'ntdc_exclude_exchange_gains_losses',
    'ntdc_exclude_impairment_gains_losses',
    'ntdc_exclude_tax_interest_income_double_relief',
    'ntdc_adjustment_disregard_regulations',
    'ntdc_adjustment_amortised_cost_basis',
    'ntdc_adjustment_qualifying_election',
    'ntdc_exclude_defined_benefit_pension',
    'ntdc_bank_expenses',
    'ntdc_adjustment_gaap_transition',
    'ntdc_adjustment_lease_finance_cost',
    'ntdc_subtotal',
    'tlr_total_per_source_sheet_for_period',
    'tlr_exclude_exchange_gains_losses',
    'tlr_exclude_impairment_gains_losses',
    'tlr_exclude_tax_interest_income_double_relief',
    'tlr_adjustment_disregard_regulations',
    'tlr_adjustment_amortised_cost_basis',
    'tlr_adjustment_qualifying_election',
    'tlr_exclude_defined_benefit_pension',
    'tlr_bank_expenses',
    'tlr_adjustment_gaap_transition',
    'tlr_adjustment_lease_finance_cost',
    'tlr_subtotal',
    'tdc_total_per_source_sheet_for_period',
    'tdc_exclude_exchange_gains_losses',
    'tdc_exclude_impairment_gains_losses',
    'tdc_exclude_tax_interest_income_double_relief',
    'tdc_adjustment_disregard_regulations',
    'tdc_adjustment_amortised_cost_basis',
    'tdc_adjustment_qualifying_election',
    'tdc_exclude_defined_benefit_pension',
    'tdc_bank_expenses',
    'tdc_adjustment_gaap_transition',
    'tdc_adjustment_lease_finance_cost',
    'tdc_subtotal',
    'ofa_total_per_source_sheet_for_period',
    'ofa_exclude_exchange_gains_losses',
    'ofa_exclude_impairment_gains_losses',
    'ofa_exclude_tax_interest_income_double_relief',
    'ofa_adjustment_disregard_regulations',
    'ofa_adjustment_amortised_cost_basis',
    'ofa_adjustment_qualifying_election',
    'ofa_exclude_defined_benefit_pension',
    'ofa_bank_expenses',
    'ofa_adjustment_gaap_transition',
    'ofa_adjustment_finance_lease',
    'ofa_adjustment_debt_factoring',
    'ofa_adjustment_service_concession',
    'ofa_adjustment_lease_finance_cost',
    'ofa_subtotal',
    'net_tax_interest_expense',
    'tebitda_adjustments',
    's407_relief_reversal_of_lease_depreciation',
    'tax_ebitda',
    's407_relief_finance_lease_depreciation'
]

export { cap_LC_conversion_values, cap_GBP_conversion_values, cap_adjusted_values };