import { processDateJS } from "./dateProcessor";

export function processAbbreviatedReturn(savedData, poaIndex){
    console.log("Saved data:");
    console.log(savedData);
    console.log("PoA index", poaIndex)
    console.log(savedData.periods_of_account[poaIndex]);

    const companies = savedData?.companies?.filter(company => company?.uk_group_company);
    console.log('companies', companies)
    let activeCompany = {};
    if (savedData?.reporting_company && savedData?.reporting_company[0] != null) {
      const reportingCompanyList = savedData?.reporting_company?.map(Rcomp => {
        const company = savedData?.companies?.find((c) => c?.company_ID === Rcomp?.company_ID);
        if (company) {
          activeCompany = {
            ...company,
            first_appointed_period_end_date: Rcomp?.first_appointed_period_end_date,
            appointment_date: Rcomp?.appointment_date,
            appointer: Rcomp?.appointer,
            nomination_link: Rcomp?.nomination_link,
            reason_appointment_lapsed: Rcomp?.reason_appointment_lapsed,
            revocation_date: Rcomp?.revocation_date,
            first_period_appointment_lapsed: Rcomp?.first_period_appointment_lapsed
          };
        }
      });
    }
    
    const poaData = savedData?.periods_of_account[poaIndex];
    const group_restrictions = poaData?.group_disallowance > 0 ? 'Yes' : 'No';
    const group_reactivations = poaData?.group_interest_reactivation_cap > 0 ? 'Yes' : 'No';
    const gleC13 = savedData.periods_of_account[poaIndex]?.apply_group_ratio_election ? 'E' : '';
    const gleC14 = savedData.periods_of_account[poaIndex]?.group_ratio_blended_election ? 'E' : '';    
    const groupEBITDAGains = (savedData.periods_of_account[poaIndex]?.group_ebitda_gains_year && processDateJS(savedData.periods_of_account[poaIndex]?.period_end).isAfter(processDateJS(savedData.periods_of_account[poaIndex]?.group_ebitda_gains_year)) &&  savedData.periods_of_account[poaIndex]?.group_ebitda_gains) ? 'E' : '';
    const interestAllowance_alternative = (savedData.periods_of_account[poaIndex]?.interest_allowance_alt_calc_year && processDateJS(savedData.periods_of_account[poaIndex]?.period_end).isBefore(processDateJS(savedData.periods_of_account[poaIndex]?.interest_allowance_alt_calc_year)) && savedData.periods_of_account[poaIndex]?.interest_allowance_alt_calc) ? 'E' : '';
    const interestAllowance_nonConsolidated = savedData.periods_of_account[poaIndex]?.election_interest_allowance_non_consolidated_investment ? 'E' : '';
    const interestAllowance_consolidated = (savedData.periods_of_account[poaIndex]?.interest_allowance_consolidated_partnership_year&& processDateJS(savedData.periods_of_account[poaIndex]?.period_end).isAfter(processDateJS(savedData.periods_of_account[poaIndex]?.interest_allowance_consolidated_partnership_year)) && savedData.periods_of_account[poaIndex]?.interest_allowance_consolidated_partnership) ? 'E' : '';
    const arrayOfInvestorGroups = savedData.periods_of_account[poaIndex]?.investor_group_data;
    let investorGroupNames = '';
    if (arrayOfInvestorGroups){
      for (let i = 0; i < arrayOfInvestorGroups.length; i++){
        if(investorGroupNames === ''){ investorGroupNames = arrayOfInvestorGroups[i].investor_group}
        else {investorGroupNames = investorGroupNames + ", " +(arrayOfInvestorGroups[i].investor_group)} 
    }}
    const groupRatioBlended = savedData.periods_of_account[poaIndex]?.group_ratio_blended_election ? 'Yes: ' + investorGroupNames : '';
    const ultimateParent = savedData.companies.find(comp => comp.company_ID === savedData.ult_parent_ID)
    console.log(ultimateParent)
    const ultimate_parent_name = ultimateParent?.company_name;
    const ultimate_parent_utr = ultimateParent?.utr
    const reporting_company = savedData.reporting_company.find(comp => comp.status === 'Active')
    const reporting_company_name = reporting_company?.company_name
    const reporting_company_utr = reporting_company?.utr
    const revised_return = poaData.return_status?.type === "Amended" ? 'Yes' : 'No' 
    const poaStart = processDateJS(savedData.periods_of_account[poaIndex]?.period_start).format('DD/MM/YYYY')
    const poaEnd = processDateJS(savedData.periods_of_account[poaIndex]?.period_end).format('DD/MM/YYYY')
    const poaInvestors = poaData?.investor_group_data
    const investorsArray = poaInvestors?.map(investor => investor['investor_group']);
    const investorsList = investorsArray ? (investorsArray.length > 1 ? investorsArray.join(', ') : investorsArray[0]) : '';


    const excelData = {
        // Group Period Return Type tab values
        start_period_account: ['','Start of period of account', '',poaStart],
        end_period_account:   ['','End of period of account', '',poaEnd],
        reporting_company:    ['','Reporting compamny', 'Name'],
        rep_comp_name:        ['','Reporting company', 'Name', reporting_company_name],
        rep_comp_UTR:         ['',' ', 'UTR (10 Digits)', reporting_company_utr],
        ultimate_parent:      ['','Ultimate parent', 'Name', ultimate_parent_name],
        ult_parent_line2:     ['','', 'UTR (if any, 10 digits)',ultimate_parent_utr],
        ult_parent_line3:     ['','', 'Jurisdiction of incorporation', savedData?.ult_parent_incorporation_juristriction],
        ult_parent_line4:     ['','', 'Legal Entity Identifier (LEI)', savedData?.ult_parent_lei],
        ult_parent_optional:  ['','', 'Name by which group is to be known','(no data mapped - this message will be deleted after testing)'],
        abbreviated_election: ['','Abbreviated return election?', 'No'],
        revised_return:       ['','Revised return', revised_return],
        subject_to_restrictions: ['','Group subject to interest restrictions?', group_restrictions],
        subject_to_reactivations: ['','Group subject to interest reactivations?', group_reactivations],


        // Group Level Elections tab values
        gleRepComp:           ['', 'Name of reporting company', reporting_company_name],
        gleUltParent:         ['', 'Ultimate parent or name of group',  ultimate_parent_name],
        gleEndPoA:            ['', 'End of period of account', poaEnd],
        gleRow13:             ['', 'Group ratio election (enter here only)', gleC13],
        gleRow14:             ['', 'Group ratio (blended) election (see below)', gleC14],
        gleRow15:             ['', 'Group EBITDA (chargeable gains) election', groupEBITDAGains, 'irrevocable'],
        gleRow16:             ['', 'Interest allowance (alternative calculation) election', interestAllowance_alternative,'irrevocable',],
        gleRow17:             ['', 'Interest allowance (non-consolidated investment) election (see below)', interestAllowance_nonConsolidated,],
        gleRow18:             ['', 'Interest allowance (consolidated partnerships) election (see below)', interestAllowance_consolidated, 'irrevocable',],
        gleRow21:             ['', 'Investor group(s) to which election relates'],
        gleRow23:             ['', groupRatioBlended],
        gleRow27:             ['', investorsList],
        gleRow31:             ['', 'TBC'],


        // UK Group Companies tab values
        ukGC_Row7:            ['','Name of reporting company', '', reporting_company_name, '',''],
        ukGC_Row8:            ['','Ultimate parent ', '',ultimate_parent_name, '',''],
        ukGC_Row9:            ['','Name used for group, if applicable', '', 'TBC (not currently saved in the group data?', '',''],
        ukGC_Row10:           ['','End of period of account', '', processDateJS(savedData.periods_of_account[poaIndex]?.period_end).format('DD/MM/YYYY'), '',''],
    
    }

        // initialises the structure to insert the rows into the sheet
        const ukGroupCompaniesTabList = [
            ['','','','10 digits','Y/N']
          ]
          for (let i = 0; i < 300; i++){
            let row = ['', i + 1, '', '', '','', '', '', '', '']
            ukGroupCompaniesTabList.push(row)
          }
      
          const getCompanyData = (companyID) => {
            const company = companies.filter(company => company.company_ID === companyID);
            return company;
          }
      
          const fullCAPData = savedData?.company_accounting_periods
      
          const getCAPData = (companyID) => {
            const companyCAP = fullCAPData.filter(CAP => CAP.company_ID === companyID);
            return companyCAP;
          }
      
          const getAPData = (companyID) => {
            const companyAP = poaData.adjusted_caps.filter(ap => ap.company_ID === companyID);
            return companyAP;
          }
      
          const getCompanyNtiei = (company_ID) => {
            let ntieiTotal = 0; 
            //console.log(poaData.adjusted_caps)
            for (let entry in poaData.adjusted_caps){
              let cap = poaData.adjusted_caps[entry];
              if (cap && cap.company_ID === company_ID){
                if (cap.company_net_tax_interest_expense_income_gbp){
                  // looks like company_net_tax_interest_expense_income_gbp is not populated for any of the groups I tested on - to be checked
                  ntieiTotal += cap.company_net_tax_interest_expense_income_gbp;
                }
              }
            }
            //console.log('ntieiTotal for ', company_ID, ': ', ntieiTotal)
            return ntieiTotal
          }
        
          const getCompanyTaxEbitda = (company_ID) => {
            let ebitdaTotal = 0; 
            //console.log('searching ', company_ID)
            for (let entry in poaData.adjusted_caps){
              let cap = poaData.adjusted_caps[entry];
              //console.log(cap)
              if (cap && cap.company_ID === company_ID){
                if (cap.tax_ebitda){
                  if(isNaN(cap.tax_ebitda)) {
                    ebitdaTotal += cap.tax_ebitda['value']
                  }
                  else {
                    ebitdaTotal += cap.tax_ebitda;
                  }
                }
              }
            }
            //console.log('ebitdaTotal for ', company_ID, ': ', ebitdaTotal)
            return ebitdaTotal;
          }
      
          // insert companies into the sheet rows
          for (let i = 0; i < companies.length; i++){
            let companyData = getCompanyData(companies[i].company_ID);
            let companyCAPData = getCAPData(companyData.companyID);      
            let rowNumber = i + 1;
            let companyName = companies[i].company_name;
            let companyUTR = companies[i].utr;
            let companyQIC = companyCAPData.election_s433_s434 && companyCAPData.election_s433_s434 === true ? 'Y' : 'N';
      
            let row = ['',rowNumber,companyName,companyUTR,companyQIC];
            ukGroupCompaniesTabList[i+1] = row;
          }

    let group_period_data = [
        [], //1
        ['','Before submission export entire workbook in PDF format'],
        [],
        ['','This template is suitable for up to 300 companies. It can be used where some company accounting periods do not end on the same date as the worldwide group period of account'],
        [], //5
        [], //6
        ['','Interest Restriction Return Page 1 (Full return)'], //7
        [], //8
        ['','Identifies worldwide group, return period and type of interest restriction return (Full or abbreviated return)'], //9
        [], //10
        ['','Period of account covered by interest restriction return'],
        excelData.start_period_account,
        excelData.end_period_account,
        [],
        excelData.rep_comp_name, //15
        excelData.rep_comp_UTR,
        [],
        excelData.ultimate_parent,
        excelData.ult_parent_line2,
        excelData.ult_parent_line3, //20
        excelData.ult_parent_line4,
        excelData.ult_parent_optional,
        [],
        ['','Type of return', 'Enter Yes or No'],
        excelData.abbreviated_election, //25
        excelData.revised_return,
        ['', 'For a revised return, specify differences in white space box provided below '],
        [],
        ['','Whether subject to interest restriction or reactivation (if so, full return required)', 'Enter Yes or No'],
        excelData.subject_to_restrictions, //30
        excelData.subject_to_reactivations,
        [],
        ['','Ways in which revised return differs from the return it supersedes'],
        ['','A brief narrative is sufficient, e.g allocation of restictions changed or full return replaces abbreviated return'],
        ['', '(no data mapped - this message will be deleted after testing)'], //35
        [],
        ['','Free space to include notes to the return'],
        ['', '(no data mapped - this message will be deleted after testing)'],
        [],
        ['','Information required only in the unusual circumstances where there are deemed parents (s477 or 478 TIOPA 2010)'], //40
        ['','', 'Name', 'UTR, if any, 10 digits'],
        ['','Deemed parent (1)', '(no data mapped - this message will be deleted after testing)'],
        ['','Deemed parent (2)', '(no data mapped - this message will be deleted after testing)'],
        ['','Deemed parent (3)', '(no data mapped - this message will be deleted after testing)'],
        [], //45
        ['','Key'],
        ['','Descriptive Field', 'Note'],
        [],
        [],
        [], //50
    ];

    let group_level_elections_data = [
        [], //1
        ['','Interest Restriction Return Page 2'],
        [],
        ['','Group level elections (full return)'],
        [], //5
        ['','Fields below copy from p1'],
        excelData.gleRepComp,
        excelData.gleUltParent,
        [],
        excelData.gleEndPoA, //10
        [],
        ['','Group level elections (Other than abbreviated return election)', 'Enter E to elect', 'Enter R to revoke'],
        excelData.gleRow13,
        excelData.gleRow14,
        excelData.gleRow15, //15
        excelData.gleRow16,
        excelData.gleRow17,
        excelData.gleRow18,
        [],
        ['','Group ratio blended election'], //20
        excelData.gleRow21,
        ['','Enter below the investor group(s) to which election relates (enlarge box or insert rows if necessary)'],
        excelData.gleRow23,
        [],
        ['','Interest allowance (consolidated partnerships) election'], //25
        ['','Enter below partnership or partnerships to which election relates (including partnership UTR(s) where applicable), enlarge box or insert rows if necessary'],
        excelData.gleRow27,
        [],
        ['','Interest allowance (consolidated partnerships) election '],
        ['','Enter below partnership or partnerships to which election relates (including partnership UTR(s) where applicable), enlarge box or insert rows if necessary'], //30
        excelData.gleRow31,
        [],
        ['','Key'],
        ['','Descriptive field or date','Note']
    ];

    const group_companies_data_pt1 = [
        [], //1
        ['','Interest Restriction Return Page 3'],
        [],
        ['','Identifies UK group companies and shows net tax-interest and tax-EBITDA amounts',],
        [], //5
        ['','Fields below copy from p1',],
        excelData.ukGC_Row7,
        excelData.ukGC_Row8,
        excelData.ukGC_Row9,
        excelData.ukGC_Row10,//10
        [],
        ['', '', 'Full return', '', ''],
        ['', '', '', '', '',],
        ['', 'Index no.', 'UK Group companies - names', 'UTR', 'QIC election?']
    ]
  
      const group_companies_data_pt2 = [
        [], //316
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', 'Key', '',],
        ['', '', 'Descriptive field or date', 'Numerical entry, £', 'Note'],
        ['', '', '', '', '', ''],
      ]
  
      const array1 = group_companies_data_pt1.concat(ukGroupCompaniesTabList);
      const group_companies_full_data = array1.concat(group_companies_data_pt2);


    return [group_period_data, group_level_elections_data, group_companies_full_data]
}