import { Button, Input, InputNumber, Modal, Select } from "@appkit4/react-components";
import { useEffect, useState } from "react";
import sqlService from "../../../services/sqldatabase/sqldatabase.service";
import toast from "react-hot-toast";
import '../home.scss';

const NewGroupModal = ({ groupList, fetchGroups, resetForm, setResetForm }) => {
    const [selectedGroup, setSelectGroup] = useState();
    const [numOfCopies, setNumOfCopies] = useState(1);
    const [customNamePrefix, setCustomNamePrefix] = useState();
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    useEffect(() => {
        if(resetForm) {
            setSelectGroup('');
            setCustomNamePrefix('');
            setNumOfCopies(1);
            setResetForm(false);
        }
    }, [resetForm]);

    const toastPromise = async (inputFunction) => {
        if (selectedGroup && numOfCopies) {
            setButtonsDisabled(true);
            const promise = inputFunction(selectedGroup, numOfCopies, customNamePrefix)
                .then(response => {
                    if (response.status !== 200) {
                        // Return an object that includes the error message instead of throwing an error
                        console.log('response:', response);
                        return { error: true, message: `Error: Status ${response.status} - ${response.err}` };
                    }
                    return response;
                });
    
            return toast.promise(promise, {
                loading: `Creating ${numOfCopies} copies of group: ${selectedGroup}`,
                success: (response) => {
                    // Check if there was an error
                    if (response.error) {
                        throw new Error(response.message);
                    }
                    setSelectGroup('');
                    setCustomNamePrefix('');
                    setNumOfCopies(1);
                    fetchGroups();
                    return `Created ${numOfCopies} copies of group: ${selectedGroup}`;
                },
                error: (response) => {
                    // Show the error message in the toast
                    return `Failed to create copies. ${response?.error?.err}`;
                }
            }).then(() => setButtonsDisabled(false));
        }
    }

    const generateCopies = async () => {
        toastPromise(sqlService.postCreateGroupCopies)
    }

    const generateCopiesWithAllReferences = async () => {
        toastPromise(sqlService.postCreateGroupCopiesAllResponses)
    }

    return (
        <div>
            <div className='flex-space-between'>
                <Select placeholder={'Select Group'} value={selectedGroup} className='col' style={{width: '35rem'}} searchable data={groupList} onSelect={(val) => setSelectGroup(val)} dropdownRenderMode={'portal'}></Select>
                <div style={{ flex: '1 0 10px'}}></div>
                <Input title={'(optional) name prefix'} value={customNamePrefix} onChange={(val) => { setCustomNamePrefix(val) }} style={{ width: '12rem' }}></Input>
                <div style={{ flex: '1 0 10px'}}></div>
                <InputNumber title={'number of copies'} value={numOfCopies} onChange={(val) => {setNumOfCopies(val)}} style={{width: '12rem'}}></InputNumber>
                <div style={{ flex: '1 0 10px'}}></div>
                <Button className='col-2 m-1' disabled={!selectedGroup || !numOfCopies || numOfCopies < 0 || buttonsDisabled} onClick={() => generateCopies()}>Copy Group</Button>
                <div style={{ flex: '1 0 10px'}}></div>
                <Button className='col-2 m-1' disabled={!selectedGroup || !numOfCopies || numOfCopies < 0 || buttonsDisabled} onClick={() => generateCopiesWithAllReferences()}>Copy Group (All Responses)</Button>
            </div>
        </div>)
}

export default NewGroupModal;